import React from 'react'
import { Grid, IconButton, DialogContent, Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import moment from 'moment'
import * as stylesPeopleExpanded from './styles.module.css'

export default function PeopleRecords({ open, handleClose, infoModel }) {
    const model = infoModel?.column
    return (
        <Dialog className='dialog-material' open={open} maxWidth='xl' onClose={handleClose} arialabelledby='responsive-dialog-title'>
            <DialogContent className='dialog-material'>
                <Grid>
                    <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'><label className={stylesPeopleExpanded.titlePeopleModal}>Expanded Record</label>
                        <IconButton style={{ marginTop: '0px' }} onClick={handleClose}><CloseIcon /></IconButton>
                    </Grid>
                    <Grid style={{ marginTop: '9px' }} container justifyContent='space-between' alignItems='flex-start'>
                        <div className={stylesPeopleExpanded.namePeopleModal}> {model?.firstName || 'N/A'} {model?.lastName || 'N/A'}</div>
                        <div>
                            <div className={stylesPeopleExpanded.expandedRecordTime} style={{ marginRight: '30px', display: 'inline' }}>Added:{' '}{moment(model?.dateAdded).format('MMM DD, YYYY')}</div>
                            <div className={stylesPeopleExpanded.expandedRecordTime} style={{ display: 'inline' }}>Updated: {moment(model?.dateUpdated).format('MMM DD, YYYY')}</div>
                        </div>
                    </Grid>
                    <div className={stylesPeopleExpanded.expandedRecordTitleBody}>{model?.jobTitle || 'N/A'}</div>


                    <hr className={stylesPeopleExpanded.expandedHr} />

                    <div className={stylesPeopleExpanded.flexRowPeople}>
                        {/*  */}
                        <div className={stylesPeopleExpanded.flexColumnPeople} style={{ minWidth: '190px', wordWrap: 'break-word', marginRight: 60 }}>
                            <div className={stylesPeopleExpanded.expandedRecordTitleBody}>Company Details</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}> Name: {!model?.businessName ? 'N/A' : model?.businessName}</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>Address: {model?.address || 'N/A'}</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>City: {!model?.city ? 'N/A' : model?.city}</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>State: {model?.province || 'N/A'}</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>Zip: {!model?.postalCode ? 'N/A' : model?.postalCode}</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>Country: {!model?.country ? 'N/A' : model?.country}</div>
                        </div>
                        {/*  */}
                        <div className={stylesPeopleExpanded.flexColumnPeople} style={{ minWidth: '190px', wordWrap: 'break-word', marginRight: 60 }}>
                            <div className={stylesPeopleExpanded.expandedRecordTitleBody}>Job Details</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}> Role: {!model?.jobLevel ? 'N/A' : model?.jobLevel}</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>Function: {!model?.jobFunction ? 'N/A' : model?.jobFunction}</div>
                        </div>
                        <div className={stylesPeopleExpanded.flexColumnPeople} style={{ minWidth: 190, maxWidth: 190, wordWrap: 'break-word', marginRight: 60 }}>
                            <div className={stylesPeopleExpanded.expandedRecordTitleBody}>Company categories</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>{!model?.businessCategories ? 'N/A' : model?.businessCategories[0]}</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>{!model?.businessCategories ? '' : model?.businessCategories[1] ? model?.businessCategories[1] : 'N/A'}</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>{!model?.businessCategories ? '' : model?.businessCategories[2] ? model?.businessCategories[2] : 'N/A'}</div>
                        </div>
                        <div className={stylesPeopleExpanded.flexColumnPeople} style={{ minWidth: 190, maxWidth: 190, marginRight: 60 }}>
                            <div className={stylesPeopleExpanded.expandedRecordTitleBody}>Company firmographics</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>#Employees: {model.numEmployeesMin || 'N/A'} - {model.numEmployeesMax || 'N/A'}</div>
                        </div>
                    </div>

                    <hr className={stylesPeopleExpanded.expandedHr} />

                    <div className={stylesPeopleExpanded.flexRowPeople}>
                        <div className={stylesPeopleExpanded.flexColumnPeople} style={{ minWidth: '190px', wordWrap: 'break-word', marginRight: 60, maxWidth: '190px' }}>
                            <div className={stylesPeopleExpanded.expandedRecordTitleBody}>Primary Email</div>
                            <div className={stylesPeopleExpanded.expandedRecordEmail}>{!model?.primaryEmail ? 'N/A' : model?.primaryEmail}</div>
                        </div>
                        <div className={stylesPeopleExpanded.flexColumnPeople} style={{ minWidth: 190, maxWidth: 190, wordWrap: 'break-word', marginRight: 60 }}>
                            <div className={stylesPeopleExpanded.expandedRecordTitleBody}> Professional emails</div>
                            <div className={stylesPeopleExpanded.expandedRecordEmail}> {model?.professionalEmails ? model?.professionalEmails[0] : model?.emails ? model?.emails[0] : "N/A"}</div>
                            {/* emails */}
                            <div className={stylesPeopleExpanded.expandedRecordEmail}> {!model?.professionalEmails ? '' : model?.professionalEmails[1] ? model?.professionalEmails[1] : 'N/A'}</div>
                        </div>
                        <div className={stylesPeopleExpanded.flexColumnPeople} style={{ minWidth: 190, maxWidth: 190, marginRight: 60 }}>
                            <div className={stylesPeopleExpanded.expandedRecordTitleBody}>Personal emails</div>
                            <div className={stylesPeopleExpanded.expandedRecordEmail}> {!model?.personalEmails ? 'N/A' : model?.personalEmails[0]}</div>
                            {/* emails */}
                            <div className={stylesPeopleExpanded.expandedRecordEmail}> {!model?.personalEmails ? '' : model?.personalEmails[1] ? model?.personalEmails[1] : 'N/A'}</div>
                        </div>
                        <div className={stylesPeopleExpanded.flexColumnPeople} style={{ minWidth: '190px', wordWrap: 'break-word', marginRight: 60 }}>
                            <div className={stylesPeopleExpanded.expandedRecordTitleBody}>Phone Numbers</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}> {!model?.phones ? 'N/A' : model?.phones[0]}</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}> {!model?.phones ? '' : model?.phones[1] ? model?.phones[1] : 'N/A'}</div>
                        </div>
                        <div className={stylesPeopleExpanded.flexColumnPeople} style={{ minWidth: '190px', wordWrap: 'break-word', marginRight: 60 }}>
                            <div className={stylesPeopleExpanded.expandedRecordTitleBody}>Social</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>{!model?.linkedinURL ? 'N/A' : model?.linkedinURL ? <a style={{ color: '#065E88', textDecoration: 'none' }} rel="noopener noreferrer" href={`${model?.linkedinURL}`}
                                target="_blank">Linkedin</a> : 'N/A'} </div>
                        </div>
                    </div>
                    <hr className={stylesPeopleExpanded.expandedHr} />

                    <div className={stylesPeopleExpanded.flexRowPeople}>
                        <div className={stylesPeopleExpanded.flexColumnPeople} style={{ minWidth: '190px', wordWrap: 'break-word', marginRight: 60 }}>
                            <div className={stylesPeopleExpanded.expandedRecordTitleBody}>Demographics</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}> {!model?.gender ? 'N/A' : model?.gender}</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>Born on: {!model?.birthDate ? 'N/A' : model?.birthDate ? moment(model?.birthDate).format('MMM DD, YYYY') : "N/A"} </div>
                        </div>
                        <div className={stylesPeopleExpanded.flexColumnPeople} style={{ minWidth: 190, maxWidth: 190, wordWrap: 'break-word', marginRight: 60 }}>
                            <div className={stylesPeopleExpanded.expandedRecordTitleBody}>Job skills</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>{!model?.jobSkills ? 'N/A' : model?.jobSkills[0]}</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>{!model?.jobSkills ? '' : model?.jobSkills[1] ? model?.jobSkills[1] : 'N/A'}</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>{!model?.jobSkills ? '' : model?.jobSkills[2] ? model?.jobSkills[2] : 'N/A'}</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>{!model?.jobSkills ? '' : model?.jobSkills[3] ? model?.jobSkills[3] : 'N/A'}</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>{!model?.jobSkills ? '' : model?.jobSkills[4] ? model?.jobSkills[4] : 'N/A'}</div>
                        </div>
                        <div className={stylesPeopleExpanded.flexColumnPeople} style={{ minWidth: 190, maxWidth: 190, marginRight: 60 }}>
                            <div className={stylesPeopleExpanded.expandedRecordTitleBody}>interests</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>{!model?.interests ? 'N/A' : model?.interests[0]}</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>{!model?.interests ? '' : model?.interests[1] ? model?.interests[1] : 'N/A'}</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>{!model?.interests ? '' : model?.interests[2] ? model?.interests[2] : 'N/A'}</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>{!model?.interests ? '' : model?.interests[3] ? model?.interests[3] : 'N/A'}</div>
                            <div className={stylesPeopleExpanded.expandedRecordBody}>{!model?.interests ? '' : model?.interests[4] ? model?.interests[4] : 'N/A'}</div>
                        </div>
                    </div>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
