/* Colors IMPORT */
import { green } from "@material-ui/core/colors";
// Success message
export const success = {
  backgroundColor: green[600],
};
export const info = {
  backgroundColor: "black",
};
// Error message
export const error = {
  backgroundColor: "red",
};
// Icon style
export const iconType = {
  fontSize: 25,
  color: "white",
};
export const iconClose = {
  fontSize: 25,
  color: "white",
  marginLeft: -10,
};
// Icon Variant style
export const iconVariant = {
  opacity: 0.9,
  marginRight: 5,
};
// message style
export const message = {
  display: "flex",
  alignItems: "center",
};
