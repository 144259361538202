import React, { useState, useEffect } from "react";
import Modal from "./modal/modal";
import sliders from "../images/slidersSVG.svg";
import useStore from "../../Store/Store";
import useFilterStore from "../../Store/filterStore.js"; // Import useFilterStore
import styles from "./filters.module.css";

function AllFilters({ searchFilterAutoComplete, suggestions, setSuggestions }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { address, propertyType, mostRecentStatus, isQueryLoading } =
    useStore();
  const { allFilters } = useFilterStore(); // Access allFilters from Zustand store

  const [usedFiltersCount, setUsedFiltersCount] = useState(0);

  const toggleModal = () => {
    if (!isQueryLoading) {
      setIsModalOpen(!isModalOpen);
    }
  };

  const isFilterUsed = (filter) => {
    let used = false;
    if (typeof filter === "string") {
      used = filter.trim() !== "" && filter !== "Any";
    } else if (Array.isArray(filter)) {
      used = filter.length > 0 && !filter.includes("Any");
    }
    if (used) return used;
  };

  const countAllFilters = () => {
    let count = 0;

    Object.entries(allFilters).forEach(([key, filter]) => {
      if (typeof filter === "object" && filter !== null) {
        if (key === "price") {
          // Handle the price filter with currency
          const isMinValueUsed = isFilterUsed(filter.minValue);
          const isMaxValueUsed = isFilterUsed(filter.maxValue);
          if (isMinValueUsed || isMaxValueUsed) {
            count += isMinValueUsed ? 1 : 0;
            count += isMaxValueUsed ? 1 : 0;
            if (isFilterUsed(filter.currency)) count++;
          }
        } else if (key === "floorSize" || key === "lotSize") {
          // Handle floorSize and lotSize filters with unit
          const isMinUsed = isFilterUsed(filter.min);
          const isMaxUsed = isFilterUsed(filter.max);
          if (isMinUsed || isMaxUsed) {
            count += isMinUsed ? 1 : 0;
            count += isMaxUsed ? 1 : 0;
            if (isFilterUsed(filter.unit)) count++;
          }
        } else {
          // For other filters
          Object.entries(filter).forEach(([subKey, subFilter]) => {
            if (Array.isArray(subFilter) || typeof subFilter === "object") {
              count += Object.values(subFilter).filter(isFilterUsed).length;
            } else {
              if (isFilterUsed(subFilter)) count++;
            }
          });
        }
      } else {
        // For non-object filters at the top level
        if (isFilterUsed(filter)) count++;
      }
    });

    return count;
  };

  useEffect(
    () => {
      const allFiltersCount = countAllFilters();
      const otherFiltersCount = [
        propertyType,
        mostRecentStatus,
      ].reduce(
        (totalUsedFilters, filter) =>
          totalUsedFilters + (isFilterUsed(filter) ? 1 : 0),
        0
      );
      setUsedFiltersCount(otherFiltersCount + allFiltersCount);
    },
    /* eslint-disable-line react-hooks/exhaustive-deps */[
      address,
      propertyType,
      mostRecentStatus,
      allFilters,
    ]
  );

  const cursorStyle =
    isQueryLoading === true ? { opacity: "0.5", cursor: "not-allowed" } : { cursor: "pointer" };

  return (
    <div
      className={styles.filterContainer}
      style={{ alignSelf: "flex-end", paddingBottom: "5px" }}
      onClick={toggleModal}
    >
      <div
        className={styles.filterContainer}
        style={{ cursor: isQueryLoading === true ? "not-allowed" : "pointer" }}
      >
        <span
          className={`${styles.filterLabel} ${styles.cursorStyle}`}
          style={cursorStyle}
        >
          All filters
        </span>
        <img
          src={sliders}
          alt="sliders"
          className={`${styles.filterIcon} ${styles.cursorStyle}`}
          style={cursorStyle}
        />
        <span
          className={`${styles.filterLabel} ${styles.cursorStyle}`}
          style={cursorStyle}
        >
          ({usedFiltersCount} Used)
        </span>
      </div>
      {isModalOpen && (
        <Modal
          searchFilterAutoComplete={searchFilterAutoComplete}
          suggestions={suggestions}
          setSuggestions={setSuggestions}
          toggleModal={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
}

export default AllFilters;
