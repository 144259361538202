import React from 'react'
import styles from "./styles.module.css";
import caretDown from "../../../images/caretDownGeneric.svg";

function MostRecentRentalPrice({ priceRentalFilter, setRentalPriceFilter }) {

    const handleRentalPriceValueChange = (event) => {
        const { name, value } = event.target;
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            setRentalPriceFilter(prevState => ({
                ...prevState,
                [name]: value,
                currency: prevState.currency || 'USD'
            }));
        }
    };


    const handleRentalCurrencyChange = (event) => {
        const { value } = event.target;
        setRentalPriceFilter(prevState => ({
            ...prevState,
            currency: value
        }));
    };


    return (
        <div className={styles.RecentRentalContainer}>
            <span className={styles.spanPrice}>Most recent rental price</span>
            <div className={styles.inputPriceGroup}>
                <input
                    autoComplete="off"
                    className={styles.inputPrice}
                    placeholder="Min"
                    onChange={handleRentalPriceValueChange}
                    value={priceRentalFilter.minValue}
                    name="minValue"
                />

                {/* Max Value Input */}
                <input
                    autoComplete="off"
                    className={styles.inputPrice}
                    placeholder="Max"
                    onChange={handleRentalPriceValueChange}
                    value={priceRentalFilter.maxValue}
                    name="maxValue"
                />
                <div style={{ marginRight: '32px' }}>
                    <select
                        className={styles.selectPrice}
                        onChange={handleRentalCurrencyChange}
                        value={priceRentalFilter.currency}
                        name="currency"
                    >
                        <option value="USD">USD</option>
                        <option value="CAD">CAD</option>
                        {/* Add more currency options here */}
                    </select>
                    <img
                        src={caretDown}
                        alt="arrow"
                        className={styles.customArrowPrice}
                    />
                </div>
            </div>
        </div>
    )
}

export default MostRecentRentalPrice