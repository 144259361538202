import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { CircularProgress } from "@material-ui/core";
import jwtDecode from 'jwt-decode';
import { createAccount, authorize } from '../../../Api';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import DatafinitiLogo from '../../HomeDR12/images/datafiniti-logo-white.svg';

function Step4({ isActive, token, clearDfpItemsFromLocalStorage }) {
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
    const { register, handleSubmit, formState: { errors, isValid } } = useForm({
        mode: 'onChange'
    });
    const [isCardComplete, setIsCardComplete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userDataLogin, setUserDataLogin] = useState();
    const [parsedUserData, setParsedUserData] = useState({});
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const signupToken = token;
    const localStorageData = localStorage.getItem('dfp/step2_data');

    const redirectDashboard = () => {
        setTimeout(() => {
            setLoading(false);
            history.push("/property-data");
            localStorage.removeItem("dfp/user-sign-up");
        }, 1500);
    };

    const redirectLogin = () => {
        setTimeout(() => {
            history.push("/login");
            clearDfpItemsFromLocalStorage()
        }, 2000);
    };

    useEffect(() => {
        const userData = JSON.parse(localStorageData);
        setParsedUserData(userData);

        if (signupToken && localStorageData) {
            const decodedToken = jwtDecode(signupToken);
            setUserDataLogin({ email: decodedToken.sub, password: userData.password });
        }
    }, [signupToken, localStorageData]);

    const handleCardChange = (event) => {
        setIsCardComplete(event.complete);
    };

    const handleSuccess = (data) => {
        if (data.status >= 400) {
            if (data?.data?.errors) {
                setErrorMessage(data?.data?.errors[0]);
                setShowErrorMessage(true); // Show error message
                redirectLogin()
            }
        } else {
            setShowSuccessMessage(true); // Show success message
            login(userDataLogin);
        }
    };

    const handleError = (error) => {
        setErrorMessage(error);
        setShowErrorMessage(true); // Show error message
        redirectLogin()
    };

    const [mutateAccount, { isLoading }] = useMutation(
        ({ jwt, userData }) => createAccount(jwt, userData),
        { onSuccess: handleSuccess, onError: handleError }
    );

    const [login, { isLoading: isLoginLoading }] = useMutation((userData) => authorize(userData),
        {
            onSuccess: (data) => {
                localStorage.setItem("dfp/session", data?.data?.token);
                redirectDashboard();
            }, onError: handleError
        }
    );

    const onSubmit = async (data) => {
        setLoading(true);
        if (!stripe || !elements || !isCardComplete) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error, token } = await stripe.createToken(cardElement, {
            name: data.cardholderName,
            address_line1: data.address,
            address_city: data.city,
            address_state: data.state,
            address_zip: data.zipCode,
        });

        if (error) {
            setErrorMessage("Something went wrong, contact our team");
            setShowErrorMessage(true);
            setTimeout(() => {
                setShowErrorMessage(false);
            }, 1500);
            setLoading(false);
        } else {
            const planData = JSON.parse(localStorage.getItem('dfp/planData')) || {};
            const googleID = planData.gclid;

            const userData = {
                first_name: data.cardholderName,
                last_name: parsedUserData.lastName,
                email: userDataLogin.email,
                organization: parsedUserData.organization,
                password: parsedUserData.password,
                payment_token: token.id,
                plan_ids: parsedUserData.plan_ids,
            };

            if (googleID) {
                userData.googleID = googleID;
            }

            mutateAccount({ jwt: signupToken, userData });
        }
    };

    return (
        <div className={styles.container}>
            <img
                src={DatafinitiLogo}
                alt="DatafinitiLogo"
                className={styles.logo}
                style={{ opacity: isActive ? 1 : 0 }}
            />
            <div>
                {showSuccessMessage && (
                    <div className={styles.successMessage}>
                        Account created successfully! Redirecting to dashboard...
                    </div>
                )}
                {showErrorMessage && (
                    <div className={styles.errorMessage}>
                        {errorMessage}
                    </div>
                )}
                <h1 className={styles.heading}>Step 4: Payment</h1>
                <span className={styles.subHeading}>Enter payment information.</span>

                <form autoComplete='off' className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.containerInputStep4}>
                        <input
                            type="text"
                            className={styles.inputStep4}
                            autoComplete='off'
                            placeholder='Cardholder name'
                            {...register('cardholderName', { required: 'Cardholder name is required' })}
                            disabled={!isActive} // Disable if not active
                        />
                        {errors.cardholderName && <p className={styles.error}>{errors.cardholderName.message}</p>}
                    </div>
                    <div className={styles.containerInputStep4}>
                        <input
                            type="text"
                            className={styles.inputStep4}
                            autoComplete='off'
                            placeholder='Address'
                            {...register('address', { required: 'Address is required' })}
                            disabled={!isActive} // Disable if not active
                        />
                        {errors.address && <p className={styles.error}>{errors.address.message}</p>}
                    </div>
                    <div className={styles.containerInputStep4}>
                        <input
                            type="text"
                            className={styles.inputStep4}
                            autoComplete='off'
                            placeholder='City'
                            {...register('city', { required: 'City is required' })}
                            disabled={!isActive} // Disable if not active
                        />
                        {errors.city && <p className={styles.error}>{errors.city.message}</p>}
                    </div>
                    <div className={styles.containerInputStep4}>
                        <input
                            type="text"
                            className={styles.inputStep4}
                            autoComplete='off'
                            placeholder='State'
                            {...register('state', { required: 'State is required' })}
                            disabled={!isActive} // Disable if not active
                        />
                        {errors.state && <p className={styles.error}>{errors.state.message}</p>}
                    </div>
                    <div className={styles.containerInputStep4}>
                        <input
                            type="text"
                            className={styles.inputStep4}
                            autoComplete='off'
                            placeholder='Zip Code'
                            {...register('zipCode', { required: 'Zip Code is required' })}
                            disabled={!isActive} // Disable if not active
                        />
                        {errors.zipCode && <p className={styles.error}>{errors.zipCode.message}</p>}
                    </div>
                    <div className={!isActive ? styles.cardElementContainerDisabled : styles.cardElementContainer}>
                        <CardElement
                            options={{
                                disableLink: true,
                                hidePostalCode: true,
                                iconStyle: "solid",
                                disabled: !isActive ? true : false,
                                style: {
                                    base: {
                                        iconColor: '#CFF0FF',
                                        fontFamily: 'Roboto',
                                        fontSize: '20px',
                                        fontWeight: '400',
                                        color: '#FFFFFF', // Set the font color to white
                                        '::placeholder': {
                                            color: '#CFF0FF',
                                        },
                                    },
                                },
                            }}
                            onChange={handleCardChange}
                        // disabled={!isActive}
                        />
                    </div>
                    {isLoading || isLoginLoading || loading ? <button disabled className={styles.spinnerDisabledButton}> <CircularProgress className={styles.circularProgress} /></button> :
                        <button
                            disabled={!isActive || !isValid || !isCardComplete || loading || isLoading || isLoginLoading}
                            className={styles.completeButton}
                            type="submit"
                        >
                            Complete
                        </button>
                    }
                </form>
            </div>
        </div>
    );
}

export default Step4;
