import React, { useState, useEffect } from 'react'
import styles from "./styles.module.css";
import { useDownloadDataById } from '../../DownloadDr6/RequestDownloads'
// import tableImage from "./images/tableImage.svg";
import tableImage from "../../DownloadDr6/images/tableImage.svg";
import bracketsCurlyBlack from "../../DownloadDr6/images/bracketsCurlyBlack.svg";

function SingleDownload({ downloadId, onClose, parseQueryToFilters }) {

    const [isVisible, setIsVisible] = useState(false);

    const { data: downloadByIdData } = useDownloadDataById(downloadId);

    const buttonStatus = downloadByIdData?.data?.status

    const handleDownloadAllFiles = () => {
        // Ensure there are files to download
        if (downloadByIdData && downloadByIdData.data && downloadByIdData.data.results) {
            downloadByIdData.data.results.forEach(file => {
                // Create an anchor element
                const link = document.createElement('a');
                // Set the href to the file's URL
                link.href = file;
                // Append to the body temporarily
                document.body.appendChild(link);
                // Trigger the download
                link.click();
                // Clean up by removing the element
                document.body.removeChild(link);
            });
        }
    };

    const handleBackdropClick = (event) => {
        setIsVisible(false);
        setTimeout(() => {
            onClose();
        }, 250);
    };


    useEffect(() => {
        // Trigger the slide-in effect when the component mounts
        const timeoutId = setTimeout(() => {
            setIsVisible(true);
        }, 1);

        return () => clearTimeout(timeoutId);
    }, []);

    return (

        <div className={styles.modalBackdropSingleDownload} onClick={handleBackdropClick}
            role="dialog" aria-labelledby="downloadModalTitle" aria-describedby="downloadModalDescription">
            <section className={styles.modalContentSingleDownload} style={{ transform: isVisible ? "translateX(0)" : "translateX(100%)" }} onClick={e => e.stopPropagation()}>

                <header className={styles.containerTitleSingleDownload}>
                    <h1 className={styles.downloadTitle}>Download Data</h1>
                    {buttonStatus === "running" ?
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className={`${styles.startDownloadButton} ${styles.disabledButton}`}>
                                Download in progress
                            </button>
                        </div>
                        :
                        buttonStatus === "completed" ?
                            <button onClick={handleDownloadAllFiles} className={styles.completedDownloadButton}>Download all files</button> :
                            <button className={`${styles.startDownloadButton}`}>

                            </button>
                    }

                </header>
                <hr className={styles.hrStyle} />

                <div style={{ marginBottom: '16px', marginTop: "16px" }}>
                    <h2 style={{ marginBottom: "2px" }} className={styles.h2SingleDownload}>Download ID</h2>
                    <span className={styles.downloadIDSingleDownload}>{downloadId}</span>
                </div>

                <hr className={styles.hrStyle} />


                <div style={{ marginBottom: '16px', marginTop: "16px" }}>
                    <h2 className={styles.h2SingleDownload}>Date started</h2>
                    <span className={styles.spanSingleDownload}>
                        {downloadByIdData?.data?.date_started ? (
                            new Intl.DateTimeFormat('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                timeZoneName: 'short',
                                hour12: false
                            }).format(new Date(downloadByIdData.data.date_started))
                        ) : (
                            'N/A'
                        )}
                    </span>

                </div>

                <hr className={styles.hrStyle} />

                <div style={{ marginBottom: '16px', marginTop: "16px" }}>
                    <h2 className={styles.h2SingleDownload}>Filters</h2>
                    {
                        downloadByIdData?.data?.query ? parseQueryToFilters(downloadByIdData?.data?.query).map((filter, index) => (
                            <span style={{ display: "block" }} key={`${filter.key}-${index}`} className={styles.spanSingleDownload}>{`${filter.key}: ${filter.value}`}</span>
                        )) : <span className={styles.spanSingleDownload}>N/A</span>
                    }
                </div>

                <hr className={styles.hrStyle} />

                <div style={{ marginBottom: '16px', marginTop: "16px" }}>

                    <h2 className={styles.h2SingleDownload}>Records downloaded</h2>
                    <span className={styles.spanSingleDownload}>{downloadByIdData?.data?.num_downloaded ? Number(downloadByIdData?.data?.num_downloaded).toLocaleString() : "N/A"}</span>

                </div>

                <hr className={styles.hrStyle} />

                <div style={{ marginBottom: '16px', marginTop: '16px' }}>
                    <h2 className={styles.h2SingleDownload}>File format</h2>
                    <div style={{ display: 'flex' }}>
                        <span style={{ marginRight: '6px' }} className={styles.spanSingleDownload}>
                            {downloadByIdData?.data?.format.toUpperCase()}
                        </span>
                        {downloadByIdData?.data?.format.toUpperCase() === 'JSON' && (
                            <img
                                src={bracketsCurlyBlack}
                                alt="bracketsCurlyBlack"
                                style={{ width: '13px', height: '13px' }}
                            />
                        )}
                        {downloadByIdData?.data?.format.toUpperCase() === 'CSV' && (
                            <img
                                src={tableImage}
                                alt="tableImage"
                                style={{ width: '13px', height: '13px' }}
                            />
                        )}
                    </div>
                </div>
                <hr className={styles.hrStyle} />
                <div style={{ marginBottom: '16px', marginTop: "16px" }}>

                    <h2 className={styles.h2SingleDownload}>Credit usage</h2>
                    <span className={styles.spanSingleDownload}>{downloadByIdData?.data?.data_type.charAt(0).toUpperCase() + downloadByIdData?.data?.data_type.slice(1).toLowerCase()} Credits: </span>

                    <span className={styles.spanSingleDownload}> {downloadByIdData?.data?.num_records ? Number(downloadByIdData?.data?.num_records).toLocaleString() : "N/A"}</span>

                </div>

                <hr className={styles.hrStyle} />

                <div style={{ marginBottom: '16px', marginTop: "16px" }}>
                    <h2 className={styles.h2SingleDownload}>Result files</h2>
                    {downloadByIdData?.data?.results?.length > 0 ? (
                        downloadByIdData.data.results.map((fileUrl, index) => (
                            <a
                                key={index}
                                href={fileUrl}
                                download
                                className={styles.downloadFilesLink}
                            >
                                File {index + 1}
                            </a>
                        ))
                    ) : (
                        <span className={styles.downloadFilesSpan}>No files ready yet.</span>
                    )}
                </div>

                <hr className={styles.hrStyle} />
            </section>
        </div>
    )
}

export default SingleDownload