import React from 'react';
import styles from "./styles.module.css"; // Ensure this is the correct path to your CSS module

function RecordRecency({ recordRecencyFilter, setRecordRecencyFilter }) {

    const today = new Date();
    const maxDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

    const handleDateAddedChange = (e) => {
        setRecordRecencyFilter({ ...recordRecencyFilter, dateAdded: e.target.value });
    };

    const handleDateUpdatedChange = (e) => {
        setRecordRecencyFilter({ ...recordRecencyFilter, dateUpdated: e.target.value });
    };

    return (
        <div className={styles.RecordRecencyContainer}>
            <h1 className={styles.RecordRecencyTitle}>Record recency</h1>
            <div className={styles.FlexContainer}>
                <div className={styles.FlexColumn}>
                    <span className={styles.RecordRecencyLabel}>Date Added</span>
                    <input
                        className={styles.RecordRecencyInput}
                        type="date"
                        name="dateAdded"
                        max={maxDate}
                        value={recordRecencyFilter.dateAdded}
                        onChange={handleDateAddedChange}
                        required
                    />
                </div>

                <div className={styles.FlexColumn}>
                    <span className={styles.RecordRecencyLabel}>Date Updated</span>
                    <input
                        className={styles.RecordRecencyInput}
                        type="date"
                        name="dateUpdated"
                        max={maxDate}
                        value={recordRecencyFilter.dateUpdated}
                        onChange={handleDateUpdatedChange}
                        required
                    />
                </div>
            </div>
        </div>
    );
}

export default RecordRecency;
