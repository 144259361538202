import React from 'react'
import { hoursList, menuList, peopleList, reviewsListBusiness, roomsList } from './utils/BusinessData'
import { descriptionsList, featuresList } from './utils/utilsData'
import { financingAndLeasingList, merchantsList, pricesListProduct, skusProduct, quantitiesListProduct } from './utils/ProductData'
import { brokersList, depositsList, feesListProperty, leasingTermsListProperty, manageByListProperty, peopleListProperty, pricesListProperty, propertyTaxesListProperty, reviewsListProperty, statusesListProperty } from './utils/PropertyData'
import styles from './styles.module.css'
const NestedInput = ({ inputValue, onChangeNested, firstInputNested, inputOnChange, secondInputValue }) => {
  return (
    <>
      {firstInputNested === false && inputValue?.name === 'descriptions' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{descriptionsList.map((description, i) => <option key={i} value={description?.value}> {description?.name}</option>)}</select>
        : firstInputNested === false && inputValue?.name === 'features' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{featuresList.map((feature, i) => <option key={i} value={feature?.value}> {feature?.name}</option>)}</select>
          : firstInputNested === false && inputValue?.name === 'hours' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{hoursList.map((hour, i) => <option key={i} value={hour?.value}> {hour?.name}</option>)}</select>
            : firstInputNested === false && inputValue?.name === 'menus' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{menuList.map((menuList, i) => <option key={i} value={menuList?.value}> {menuList?.name}</option>)}</select>
              : firstInputNested === false && inputValue?.name === 'people' && inputValue?.unique !== 'peopleProperty' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{peopleList.map((people, i) => <option key={i} value={people?.value}> {people?.name}</option>)}</select>
                : firstInputNested === false && inputValue?.name === 'reviews' && inputValue.unique !== 'propertyReviews' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{reviewsListBusiness.map((reviews, i) => <option key={i} value={reviews?.value}> {reviews?.name}</option>)}</select>
                  : firstInputNested === false && inputValue?.name === 'rooms' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{roomsList.map((room, i) => <option key={i} value={room?.value}> {room?.name}</option>)}</select> : ''}
      {firstInputNested === false && inputValue?.name === 'financingAndLeasing' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{financingAndLeasingList.map((financingAndLeasing, i) => <option key={i} value={financingAndLeasing?.value}> {financingAndLeasing?.name}</option>)}</select> : ''}
      {firstInputNested === false && inputValue?.name === 'merchants' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{merchantsList.map((merchants, i) => <option key={i} value={merchants?.value}> {merchants?.name}</option>)}</select> : ''}
      {firstInputNested === false && inputValue?.name === 'quantities' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{quantitiesListProduct.map((quantities, i) => <option key={i} value={quantities?.value}> {quantities?.name}</option>)}</select> : ''}
      {firstInputNested === false && inputValue?.name === 'prices' && inputValue?.unique !== 'pricesProperty' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{pricesListProduct.map((price, i) => <option key={i} value={price?.value}> {price?.name}</option>)}</select> : ''}
      {firstInputNested === false && inputValue?.name === 'skus' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{skusProduct.map((skus, i) => <option key={i} value={skus?.value}> {skus?.name}</option>)}</select> : ''}
      {firstInputNested === false && inputValue?.name === 'brokers' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{brokersList.map((broker, i) => <option key={i} value={broker?.value}> {broker?.name}</option>)}</select> : ''}
      {firstInputNested === false && inputValue?.name === 'deposits' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{depositsList.map((broker, i) => <option key={i} value={broker?.value}> {broker?.name}</option>)}</select> : ''}
      {firstInputNested === false && inputValue?.name === 'fees' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{feesListProperty.map((fee, i) => <option key={i} value={fee?.value}> {fee?.name}</option>)}</select> : ''}
      {firstInputNested === false && inputValue?.name === 'leasingTerms' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{leasingTermsListProperty.map((leasingTerm, i) => <option key={i} value={leasingTerm?.value}> {leasingTerm?.name}</option>)}</select> : ''}
      {firstInputNested === false && inputValue?.name === 'managedBy' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{manageByListProperty.map((managed, i) => <option key={i} value={managed?.value}> {managed?.name}</option>)}</select> : ''}
      {firstInputNested === false && inputValue?.name === 'people' && inputValue?.unique === 'peopleProperty' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{peopleListProperty.map((managed, i) => <option key={i} value={managed?.value}> {managed?.name}</option>)}</select> : ''}
      {firstInputNested === false && inputValue?.name === 'prices' && inputValue?.unique === 'pricesProperty' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{pricesListProperty.map((price, i) => <option key={i} value={price?.value}> {price?.name}</option>)}</select> : ''}

      {firstInputNested === false && inputValue?.name === 'propertyTaxes' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{propertyTaxesListProperty.map((property, i) => <option key={i} value={property?.value}> {property?.name}</option>)}</select> : ''}

      {firstInputNested === false && inputValue?.name === 'reviews' && inputValue.unique === 'propertyReviews' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{reviewsListProperty.map((reviewProperty, i) => <option key={i} value={reviewProperty?.value}> {reviewProperty?.name}</option>)}</select> : ''}

      {firstInputNested === false && inputValue?.name === 'statuses' ? <select style={{ width: '200px' }} onChange={(e) => onChangeNested(e)}>{statusesListProperty.map((statuses, i) => <option key={i} value={statuses?.value}> {statuses?.name}</option>)}</select> : ''}

      {firstInputNested === true && secondInputValue !== 'dateSeen' && secondInputValue !== 'date' ? <label style={{ marginLeft: "-3px" }} className={styles.labelInputNested}>{`.${secondInputValue}: `}<input type='text' className={styles.inputText} autoFocus onChange={inputOnChange} /></label> : firstInputNested === true && (secondInputValue === 'dateSeen' || secondInputValue === 'date') ? <label style={{ marginLeft: "-3px" }} className={styles.labelInputNested}>{'.'}{secondInputValue}{': '}<input type='date' onChange={e => inputOnChange(e)} /></label>
        : ''}
    </>

  )
}
export default NestedInput
