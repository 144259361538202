import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import JsonViewModal from "../viewJson/index";
import HeaderExpandedRecord from "./header/HeaderExpandedRecord";
import AddressExpandedRecord from "./address/AddressExpandedRecord";
import MostRecentData from "./MostRecentData/MostRecentData";
// import OwnerExpandedRecord from "./Owner/OwnerExpandedRecord";
import OwnerExpandedRecord from "./Owner/OwnerExpandedRecord";
import Images from "./ImagesSection/Images";
import PriceExpandedRecords from "./Price/PriceExpandedRecords";
import StatusHistory from "./StatusHistory/StatusHistory";
import LegalDescription from "./LegalDescription/LegalDescription";
import Description from "./Descriptions/Descriptions";
import TransactionsHistory from "./TransactionsHistory/TransactionsHistory";
import Parking from "./Parking/Parking";
import Interior from "./Interior/Interior";
import Exterior from "./Exterior/Exterior";
import Features from "./Features/Features";

function ExpandedRecordDr6({ recordData, onClose, isLoadingRecord }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isJsonModalVisible, setIsJsonModalVisible] = useState(false); // State for JSON modal visibility
  // const record = recordData
  const toggleJsonModal = () => {
    setIsJsonModalVisible(true); // Toggle JSON modal visibility
  };
  const [record, setRecord] = useState(recordData);  // Manage record as internal state


  useEffect(() => {
    // Trigger the slide-in effect when the component mounts
    if (record || isLoadingRecord) {
      const timeoutId = setTimeout(() => {
        setIsVisible(true);
      }, 1);

      return () => clearTimeout(timeoutId);
    }
  }, [record, isLoadingRecord]);


  const handleClose = () => {
    if (!isJsonModalVisible) {
      // Start the slide-out effect
      setIsVisible(false);
      // Use a timeout to match the CSS transition duration
      setTimeout(() => {
        onClose();
      }, 250); // Adjust timeout to match your CSS transition
    }
  };

  useEffect(() => {
    // Update internal record state when recordData changes
    setRecord(recordData);

  }, [recordData]);  // Depend on recordData to update internal state


  if (isLoadingRecord) {
    return (
      <div className={styles.modalBackdropExpandedRecord} onClick={handleClose}>
        <div className={styles.modalContentExpandedRecord} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', transform: isVisible ? "translateX(0)" : "translateX(100%)", overflowY: isJsonModalVisible ? "hidden" : "scroll" }}
          onClick={(e) => e.stopPropagation()}>
          <p>Loading details...</p> {/* Show loading text or spinner */}
        </div>
      </div>
    );
  }
  return (
    <div className={styles.modalBackdropExpandedRecord} onClick={handleClose}>
      {/* onClick={handleClose} */}
      <div
        className={styles.modalContentExpandedRecord}
        style={{ transform: isVisible ? "translateX(0)" : "translateX(100%)", overflowY: isJsonModalVisible ? "hidden" : "scroll" }}
        onClick={(e) => e.stopPropagation()}
      >
        {/* HEADER */}
        <HeaderExpandedRecord record={record} handleClose={handleClose} toggleJsonModal={toggleJsonModal} />
        {/* HEADER */}
        {/* Location/Address */}
        <AddressExpandedRecord record={record} />
        {/* Most Recent data */}
        <MostRecentData record={record} />

        {/* Most Recent */}

        {/* Owner */}
        <OwnerExpandedRecord record={record} />

        <hr className={styles.hrStyles} />

        {/* Images */}
        <Images record={record} />

        <hr className={styles.hrStyles} />

        {/* Prices, Status */}
        <div>
          <div className={styles.priceStatusContainer}>
            <PriceExpandedRecords record={record} />
            {/*  */}
            <StatusHistory record={record} />
            {/*  */}
          </div>
          <hr className={styles.hrStyles} />
          {/*  */}
          {/* legal description */}
          <LegalDescription record={record} />
          <hr className={styles.hrStyles} />
          <Description record={record} />
          <hr className={styles.hrStyles} />
          {/*  */}
          {/* Transactions History */}
          <TransactionsHistory record={record} />
          <hr className={styles.hrStyles} />

          <div style={{ marginTop: "22px" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "18px", }}>
              <Parking record={record} />
              <Exterior record={record} />
              {/* <TBD /> */}
            </div>
            {/*  */}

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "24px" }}>
              <Interior record={record} />
            </div>

            {/*  */}
            <hr style={{ border: "1px solid #E5E5E5", margin: "0px -40px", }} />
            <Features record={record} />

          </div>
        </div>
      </div>
      {isJsonModalVisible && (
        <JsonViewModal
          jsonData={record}
          onClose={() => setIsJsonModalVisible(false)}
        />
      )}
    </div>
  );
}

export default ExpandedRecordDr6;
