import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { useForm } from 'react-hook-form';
import { updateAccount, authorize, getAccount } from '../../../Api/index';
import { useMutation, useQuery } from 'react-query';
import SnackbarComponent from '../../Snackbar/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

function ProfileSettings() {
    const { data: dataAccount } = useQuery('firstFormAccount', getAccount);
    const [snackMessage, setSnackMessage] = useState({ type: 'error' });
    const [loginLoading, setLoginLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const handleClose = () => { setOpen(false) };
    const handleClickOpen = () => { setOpen(true) };

    const [queryData, setQueryData] = useState({ email: '', first_name: '', last_name: '', organization: '', password: '' });
    const { register, handleSubmit, reset, formState: { errors, isValid }, watch } = useForm({ mode: 'all' });

    const [login] = useMutation((userData) => authorize(userData), { onSuccess: handleSuccessLogin, onError: handleError });

    function handleSuccessLogin(data) {
        setLoginLoading(true);
        if (data.status >= 400) {
            const error = data?.data?.errors;
            setLoginLoading(false);
            setSnackMessage({ type: 'error', myMessage: error, update: Date.now() });
        } else {
            window.localStorage.removeItem('dfp/session');
            localStorage.setItem('dfp/session', data?.data?.token);
            handleClickOpen();
            setTimeout(() => {
                setLoginLoading(false);
                window.location.reload();
            }, 2500);
        }
    }

    const [profileUpdate] = useMutation(
        ({ queryData }) => updateAccount(queryData),
        { onSuccess: handleSuccess, onError: handleError }
    );

    function handleSuccess(data) {
        if (data.status >= 400) {
            const error = data?.data?.errors;
            setSnackMessage({ type: 'error', myMessage: error, update: Date.now() });
        } else {
            setLoginLoading(true);
            login({ email: queryData.email, password: queryData.password });
        }
    }

    function handleError() { }

    const onSubmit = () => {
        if (isValid) {
            setLoginLoading(true);
            profileUpdate({ queryData });
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setQueryData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        if (dataAccount) {
            const newQueryData = {
                email: dataAccount?.data?.email,
                first_name: dataAccount?.data?.first_name,
                last_name: dataAccount?.data?.last_name,
                organization: dataAccount?.data?.organization,
                password: ''
            };
            setQueryData(newQueryData);
            reset(newQueryData); // reset the form values to the new data
        }
    }, [dataAccount, reset]);

    const passwordConfirm = watch('password', '');

    return (
        <div style={{ marginTop: "18px" }}>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className={styles.personalInfoContainer}>
                <div className={errors.first_name ? styles.spacingFormProfileError : styles.spacingFormProfile}  >
                    <label htmlFor='first_name' className={styles.labelStyle}>First Name</label>
                    <input autoComplete='off' type='text' name='first_name'  {...register("first_name", { required: 'First Name is required', onChange: (e) => { handleInputChange(e) } })} className={errors.first_name ? styles.inputPersonalProfileError : styles.inputPersonalProfile} />
                    {errors.first_name && errors.first_name.type === 'required' && <span className={styles.errorsForm}>First name is required</span>}
                </div>
                <div className={errors.last_name ? styles.spacingFormProfileError : styles.spacingFormProfile}>
                    <label htmlFor='last_name' className={styles.labelStyle}>Last Name</label>
                    <input autoComplete='off' name='last_name' defaultValue={queryData.last_name} {...register("last_name", { required: 'Last Name is required', onChange: (e) => { handleInputChange(e) } })} className={errors.last_name ? styles.inputPersonalProfileError : styles.inputPersonalProfile} />
                    {errors.last_name && errors.last_name.type === 'required' && <span className={styles.errorsForm}>Last name is required</span>}
                </div>
                <div className={errors.organization ? styles.spacingFormProfileError : styles.spacingFormProfile}>
                    <label htmlFor='organization' className={styles.labelStyle} >Organization</label>
                    <input autoComplete='off' defaultValue={dataAccount?.data?.organization || ''} name='organization' {...register("organization", { required: 'Organization is required', onChange: (e) => { handleInputChange(e) } })} className={errors.organization ? styles.inputPersonalProfileError : styles.inputPersonalProfile} />
                    {errors.organization && errors.organization.type === 'required' && <span className={styles.errorsForm}>Organization is required</span>}
                </div>

                <div className={errors.email ? styles.spacingFormProfileError : styles.spacingFormProfile}>
                    <label htmlFor='email' className={styles.labelStyle}>Email</label>
                    <input autoComplete='off' defaultValue={dataAccount?.data?.email || ''} name='email' {...register("email", { required: 'Email is required', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i, message: 'Invalid email format' }, onChange: (e) => { handleInputChange(e) } })}
                        className={errors.email ? styles.inputPersonalProfileError : styles.inputPersonalProfile} />
                    {errors.email && errors.email.type === 'required' && <span className={styles.errorsForm}>Email is required</span>}
                    {errors.email && errors.email?.type === 'pattern' && <span className={styles.errorsForm}>Invalid email</span>}
                </div>

                <div className={errors.password ? styles.spacingFormProfileError : styles.spacingFormProfile}>
                    <div style={{ marginBottom: "8px" }}>
                        <label className={styles.labelStyle} style={{ display: "initial" }}>Password</label>
                        <label className={styles.labelStyle} style={{ display: "initial", opacity: "0.5" }}>{" "}(Required)</label>
                    </div>
                    <input autoComplete='off' name='password' type='password'  {...register("password", { required: 'Password is required', minLength: { value: 8, message: 'Password must be at least 8 characters long' }, onChange: (e) => { handleInputChange(e) } })} className={errors.password ? styles.inputPersonalProfileError : styles.inputPersonalProfile} />
                    {errors.password && errors.password.type === 'required' && <span className={styles.errorsForm}>Password is required</span>}
                    {errors.password && errors.password.type === 'minLength' && <span className={styles.errorsForm}>Password is too short</span>}
                </div>

                <div className={errors.password_repeat ? styles.spacingFormProfileError : styles.spacingFormProfile}>
                    <label className={styles.labelStyle}>Confirm Password</label>
                    <input
                        autoComplete='off' name='password_repeat' type='password'
                        {...register('password_repeat', {
                            required: 'Password is required',
                            validate: (value) =>
                                value === passwordConfirm || 'The passwords do not match',
                        })}
                        className={errors.password_repeat ? styles.inputPersonalProfileError : styles.inputPersonalProfile}
                    />
                    {errors.password_repeat && errors.password_repeat.type === 'required' && <span className={styles.errorsForm}>Confirm password is required</span>}
                    {errors.password_repeat && errors.password_repeat.type === 'validate' && <span className={styles.errorsForm}>Password needs to match</span>}
                </div>
                <div className={styles.containerUpdate}>
                    {loginLoading === false
                        ? <button type='submit' className={isValid === true ? styles.buttonUpdate : isValid === false ? styles.buttonUpdateDisabled : styles.buttonUpdateDisabled}>Update</button>
                        : <button className={styles.buttonUpdateDisabled}> <CircularProgress style={{ width: '25px', height: '30px', color: 'white' }} /> </button>}
                </div>
            </form>
            <SnackbarComponent snackData={snackMessage} />
            <Dialog open={open} TransitionComponent={Transition} keepMounted classes={{ paper: styles.paper }} onClose={handleClose}>
                <DialogTitle style={{ color: '#43a047' }}>The Changes were successful</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The page will refresh for your security
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ProfileSettings;
