import React, { useState, useEffect, useRef } from "react";
// import caretDown from "../images/caretDownGeneric.svg";
import caretDown from "./imagesGenericDropdown/caretDownGeneric.svg";
import styles from "./styles.module.css";

function GenericDropdown({
  options,
  label,
  setter,
  selectedOptions,
  valueMap = {},
  isQueryLoading,
}) {
  const [localSelected, setLocalSelected] = useState(
    selectedOptions || ["Any"]
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleOptionChange = (option) => {
    const value = valueMap[option] || option;

    setLocalSelected((prevSelected) => {
      if (value === "Any") {
        return ["Any"];
      }

      const filteredOptions = prevSelected.filter((o) => o !== "Any");
      const newOptions = filteredOptions.includes(value)
        ? filteredOptions.filter((o) => o !== value)
        : [...filteredOptions, value];
      if (newOptions.length === 0) {
        return ["Any"];
      }

      return newOptions;
    });
  };

  useEffect(() => {
    if (Array.isArray(selectedOptions)) {
      setLocalSelected(selectedOptions);
    }
  }, [selectedOptions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setter(localSelected);
    }, 1500); // Adjust the debounce delay as needed

    return () => clearTimeout(timeoutId);
  }, [localSelected, setter]);

  const getDropdownLabel = () => {
    if (localSelected.length === 0 || localSelected[0] === "Any") {
      return "Any";
    }
    return localSelected.length === 1
      ? localSelected[0]
      : `${localSelected[0]} +${localSelected.length - 1} more`;
  };

  useEffect(() => {
    if (isQueryLoading) {
      setIsDropdownOpen(false);
    }
  }, [isQueryLoading]);
  return (
    <div className={styles.formControl}>
      <label className={styles.label}>{label}</label>
      <div className={styles.dropdown}>
        <button
          type="button"
          ref={buttonRef}
          className={styles.dropdownButton}
          onClick={toggleDropdown}
          disabled={isQueryLoading}
        >
          {getDropdownLabel()}
          <span className={styles.arrow}>
            {isQueryLoading ? (
              ""
            ) : (
              <img
                src={caretDown}
                alt="arrow"
                style={{ width: "8px", height: "15px", paddingTop: "3px" }}
              />
            )}
          </span>
        </button>
        {isDropdownOpen && (
          <div className={styles.dropdownContent} ref={dropdownRef}>
            {options.map((option) => (
              <div
                key={option}
                className={
                  option === "Any"
                    ? styles.anyCheckboxContainer
                    : styles.checkboxContainer
                }
              >
                <input
                  type="checkbox"
                  className={styles.checkbox}
                  id={`${label}-${option}`}
                  name={`${label.toLowerCase()}-option`}
                  value={valueMap[option] || option}
                  checked={localSelected.includes(valueMap[option] || option)}
                  onChange={() => handleOptionChange(option)}
                />
                <label
                  htmlFor={`${label}-${option}`}
                  className={styles.checkboxLabel}
                >
                  {option}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default GenericDropdown;
