import React from 'react'
import styles from "./styles.module.css";

function Interior({ record }) {
    let interiorFeatures = 'N/A';
    let appliances = 'N/A';

    if (Array.isArray(record?.features)) {
        // Find 'Interior Features' and join the values if they exist
        const interiorFeatureObj = record.features.find(feature => feature.key === 'Interior Features');
        if (interiorFeatureObj && Array.isArray(interiorFeatureObj.value)) {
            interiorFeatures = interiorFeatureObj.value.join(', ');
        }

        // Find 'Appliances' and join the values if they exist
        const appliancesObj = record.features.find(feature => feature.key === 'Appliances');
        if (appliancesObj && Array.isArray(appliancesObj.value)) {
            appliances = appliancesObj.value.join(', ');
        }
    }


    return (
        <div>
            <span className={styles.interiorTitle}>Interior</span>
            <div className={styles.internalContainer}>
                <span className={styles.internalTitleValues}>Features</span>
                <div className={styles.internalValuesContainer}>
                    {interiorFeatures}
                </div>
            </div>
            {/*  */}
            <div className={styles.internalContainer}>
                <span className={styles.internalTitleValues}>Appliances</span>
                <div className={styles.internalValuesContainer}>
                    {appliances}
                </div>
            </div>
        </div>
    )
}

export default Interior