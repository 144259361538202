import React, { useEffect } from "react";
import styles from "../modal.module.css";
import useFilterStore from "../../../Store/filterStore"; // Adjust the path as needed

function Amenities({ amenitiesFilters, setAmenitiesFilters }) {
  const globalAmenities = useFilterStore((state) => state.allFilters.amenities);

  useEffect(() => {
    // Update local state with global store values when component mounts
    setAmenitiesFilters(globalAmenities);
  }, [globalAmenities, setAmenitiesFilters]);

  const handleAmenityChange = (event, amenity) => {
    const { name, value } = event.target;

    // Regular expression to allow only numeric input
    const re = /^[0-9\b]+$/;

    // Update the state only if the value is a number or an empty string (for backspace)
    if (re.test(value) || value === "") {
      setAmenitiesFilters((prevFilters) => ({
        ...prevFilters,
        [amenity]: {
          ...prevFilters[amenity],
          [name]: value,
        },
      }));
    }
  };

  const handleCheckboxChange = (e, category) => {
    const { name, checked } = e.target;
    setAmenitiesFilters((prevFilters) => {
      const newCategoryValues = new Set(prevFilters[category]);
      if (checked) {
        newCategoryValues.add(name); // Add the feature if checked
      } else {
        newCategoryValues.delete(name); // Remove the feature if unchecked
      }
      return { ...prevFilters, [category]: Array.from(newCategoryValues) };
    });
  };

  return (
    <section className={styles.amenitiesSection}>
      <h1 className={styles.amenitiesHeader}>Amenities</h1>

      <div className={styles.amenitiesGroup}>
        <div className={styles.amenitiesColumn}>
          <label className={styles.labelsAmenities}>Beds</label>
          <div className={styles.amenitiesRow}>
            <input
              autoComplete="off"
              id="bedsMin"
              className={styles.inputAmenities}
              placeholder="Min"
              name="min"
              value={amenitiesFilters.beds.min}
              onChange={(e) => handleAmenityChange(e, "beds")}
            />
            <input
              autoComplete="off"
              id="bedsMax"
              className={styles.inputAmenities}
              placeholder="Max"
              name="max"
              value={amenitiesFilters.beds.max}
              onChange={(e) => handleAmenityChange(e, "beds")}
            />
          </div>
        </div>

        <div className={styles.amenitiesColumn}>
          <label className={styles.labelsAmenities}>Bath</label>
          <div className={styles.amenitiesRow}>
            <input
              autoComplete="off"
              id="bathMin"
              className={styles.inputAmenities}
              placeholder="Min"
              name="min"
              value={amenitiesFilters.bath.min}
              onChange={(e) => handleAmenityChange(e, "bath")}
            />
            <input
              autoComplete="off"
              id="bathMax"
              className={styles.inputAmenities}
              placeholder="Max"
              name="max"
              value={amenitiesFilters.bath.max}
              onChange={(e) => handleAmenityChange(e, "bath")}
            />
          </div>
        </div>

        <div className={styles.amenitiesColumn}>
          <label className={styles.labelsAmenities}>Floors</label>
          <div className={styles.amenitiesRow}>
            <input
              autoComplete="off"
              id="Min"
              className={styles.inputAmenities}
              placeholder="Min"
              name="min"
              value={amenitiesFilters.floors.min}
              onChange={(e) => handleAmenityChange(e, "floors")}
            />
            <input
              autoComplete="off"
              id="bathMax"
              className={styles.inputAmenities}
              placeholder="Max"
              name="max"
              value={amenitiesFilters.floors.max}
              onChange={(e) => handleAmenityChange(e, "floors")}
            />
          </div>
        </div>

        <div className={styles.amenitiesColumn}>
          <label className={styles.labelsAmenities}>Parking Spaces</label>
          <div className={styles.amenitiesRow}>
            <input
              autoComplete="off"
              id="Min"
              className={styles.inputAmenities}
              placeholder="Min"
              name="min"
              value={amenitiesFilters.parkingSpaces.min}
              onChange={(e) => handleAmenityChange(e, "parkingSpaces")}
            // numParkingSpaces
            />
            <input
              autoComplete="off"
              id="bathMax"
              className={styles.inputAmenities}
              placeholder="Max"
              name="max"
              value={amenitiesFilters.parkingSpaces.max}
              onChange={(e) => handleAmenityChange(e, "parkingSpaces")}
            />
          </div>
        </div>

        <div className={styles.amenitiesColumn}>
          <label className={styles.labelsAmenities}>Rooms</label>
          <div className={styles.amenitiesRow}>
            <input
              autoComplete="off"
              id="Min"
              className={styles.inputAmenities}
              placeholder="Min"
              name="min"
              value={amenitiesFilters.rooms.min}
              onChange={(e) => handleAmenityChange(e, "rooms")}
            />
            <input
              autoComplete="off"
              id="bathMax"
              className={styles.inputAmenities}
              placeholder="Max"
              name="max"
              value={amenitiesFilters.rooms.max}
              onChange={(e) => handleAmenityChange(e, "rooms")}
            />
          </div>
        </div>

        <div className={styles.amenitiesColumn}>
          <label className={styles.labelsAmenities}>Units</label>
          <div className={styles.amenitiesRow}>
            <input
              autoComplete="off"
              id="Min"
              className={styles.inputAmenities}
              placeholder="Min"
              name="min"
              value={amenitiesFilters.units.min}
              onChange={(e) => handleAmenityChange(e, "units")}
            />
            <input
              autoComplete="off"
              id="bathMax"
              className={styles.inputAmenities}
              placeholder="Max"
              name="max"
              value={amenitiesFilters.units.max}
              onChange={(e) => handleAmenityChange(e, "units")}
            />
          </div>
        </div>
      </div>

      <section className={styles.specialAmenitiesRowContainer}>
        <div className={styles.amenitiesColumn}>
          <label className={styles.titleSpecialAmenities}>HVAC</label>

          <div className={styles.specialAmenitiesRow}>
            <input
              checked={amenitiesFilters.HVAC.includes("Central Air")}
              onChange={(e) => handleCheckboxChange(e, "HVAC")}
              type="checkbox"
              id="Central Air"
              name="Central Air"
              className={styles.specialAmenitiesInput}
            />
            <label className={styles.specialAmenitiesLabel}>Cental Air</label>
          </div>
          <div className={styles.specialAmenitiesRow}>
            <input
              className={styles.specialAmenitiesInput}
              checked={amenitiesFilters.HVAC.includes("Electric")}
              onChange={(e) => handleCheckboxChange(e, "HVAC")}
              type="checkbox"
              id="Electric"
              name="Electric"
            />
            <label className={styles.specialAmenitiesLabel}>Electric</label>
          </div>
          <div className={styles.specialAmenitiesRow}>
            <input
              className={styles.specialAmenitiesInput}
              checked={amenitiesFilters.HVAC.includes("Fans")}
              onChange={(e) => handleCheckboxChange(e, "HVAC")}
              type="checkbox"
              id="Fans"
              name="Fans"
            />
            <label className={styles.specialAmenitiesLabel}>Fans</label>
          </div>
        </div>

        <div className={styles.amenitiesColumn}>
          <label className={styles.titleSpecialAmenities}>
            Exterior
          </label>

          <div className={styles.specialAmenitiesRow}>
            <input
              type="checkbox"
              id="Pool"
              checked={amenitiesFilters.exterior.includes("Pool")}
              onChange={(e) => handleCheckboxChange(e, "exterior")}
              name="Pool"
              className={styles.specialAmenitiesInput}
            />
            <label className={styles.specialAmenitiesLabel}>Pool</label>
          </div>
          <div className={styles.specialAmenitiesRow}>
            <input
              type="checkbox"
              id="Breezeway"
              checked={amenitiesFilters.exterior.includes("Breezeway")}
              onChange={(e) => handleCheckboxChange(e, "exterior")}
              name="Breezeway"
              className={styles.specialAmenitiesInput}
            />
            <label className={styles.specialAmenitiesLabel}>Breezeway</label>
          </div>
          <div className={styles.specialAmenitiesRow}>
            <input
              type="checkbox"
              id="Storm Shutters"
              checked={amenitiesFilters.exterior.includes("Storm Shutters")}
              onChange={(e) => handleCheckboxChange(e, "exterior")}
              name="Storm Shutters"
              className={styles.specialAmenitiesInput}
            />
            <label className={styles.specialAmenitiesLabel}>
              Storm Shutters
            </label>
          </div>
        </div>

        <div className={styles.amenitiesColumn}>
          <label className={styles.titleSpecialAmenities}>
            Roofing
          </label>

          <div className={styles.specialAmenitiesRow}>
            <input
              type="checkbox"
              id="asphalt"
              name="Asphalt"
              checked={amenitiesFilters.roofing.includes("Asphalt")}
              onChange={(e) => handleCheckboxChange(e, "roofing")}
              className={styles.specialAmenitiesInput}
            />
            <label className={styles.specialAmenitiesLabel}>Asphalt</label>
          </div>
          <div className={styles.specialAmenitiesRow}>
            <input
              type="checkbox"
              id="Fiberglass"
              name="Fiberglass"
              checked={amenitiesFilters.roofing.includes("Fiberglass")}
              onChange={(e) => handleCheckboxChange(e, "roofing")}
              className={styles.specialAmenitiesInput}
            />
            <label className={styles.specialAmenitiesLabel}>Fiberglass</label>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Amenities;
