import React from 'react';
import styles from "./styles.module.css";

function MostRecentStatusFirstDateSeen({ mostRecentStatusFirstDateSeenFilter, setMostRecentStatusFirstDateSeenFilter }) {
    const today = new Date();
    const maxDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

    const handleStartDateChange = (e) => {
        setMostRecentStatusFirstDateSeenFilter({ startDate: e.target.value, endDate: mostRecentStatusFirstDateSeenFilter.endDate });
    };

    const handleEndDateChange = (e) => {
        setMostRecentStatusFirstDateSeenFilter({ startDate: mostRecentStatusFirstDateSeenFilter.startDate, endDate: e.target.value });
    };

    return (
        <section>
            <h1 className={styles.MostRecentStatusFirstDateSeenTitle}>Most recent status first date seen</h1>
            <div className={styles.MostRecentStatusFirstDateSeenDateInputContainer}>
                {/* Input for the start date */}
                <input
                    className={styles.MostRecentStatusFirstDateSeenStartDateInput}
                    type="date"
                    name="startDate"
                    max={maxDate}
                    value={mostRecentStatusFirstDateSeenFilter.startDate}
                    onChange={handleStartDateChange}
                    required
                />
                <span className={styles.MostRecentStatusFirstDateSeenDateLabel}>to</span>
                {/* Input for the end date */}
                <input
                    className={styles.MostRecentStatusFirstDateSeenEndDateInput}
                    type="date"
                    name="endDate"
                    max={maxDate}
                    value={mostRecentStatusFirstDateSeenFilter.endDate}
                    onChange={handleEndDateChange}
                    required
                />
            </div>
        </section>
    );
}

export default MostRecentStatusFirstDateSeen;
