import React, { useState, useEffect } from 'react';
import styles from "./styles.module.css";
import tableImage from "./images/tableImage.svg";
import blackCurlyBrackets from "./images/bracketsCurlyBlack.svg";
import useStore from "../Store/Store";
import { useCreateDownloadMutationProperty, useDownloadDataById } from './RequestDownloads.js'
import SnackbarComponent from '../Snackbar/index.jsx'
import DownloadFiles from './DownloadFiles.jsx';
import { useHistory } from 'react-router-dom';


function DownloadModal({ onClose, dataToDownload, queryToDownload }) {
    const history = useHistory();
    const { downloadData, isQueryLoading } = useStore();
    const [isLoadingLocalState, setIsLoadingLocalState] = useState(false)
    const [isVisible, setIsVisible] = useState(false);

    const [recordsToDownload, setRecordsToDownload] = useState("");
    const [snackMessage, setSnackMessage] = useState({ type: "error" });
    // You can manage any other state related to download here
    const [selectedFormat, setSelectedFormat] = useState('csv');
    const [showAllFilters, setShowAllFilters] = useState(false);

    // State for managing the records to download input value
    const [filters, setFilters] = useState([]);
    const [queryReadyToDownload, setQueryReadyToDownload] = useState({ query: queryToDownload, format: selectedFormat, download: true, num_records: recordsToDownload })

    const [createDownload] = useCreateDownloadMutationProperty(setSnackMessage, queryToDownload);

    const downloadId = queryToDownload === downloadData.downloadQueryStore ? downloadData.download_id : null;

    const { data: downloadByIdData } = useDownloadDataById(downloadId);



    const handleFormatChange = (event) => {
        const newFormat = event.target.value;
        setSelectedFormat(newFormat);
        setQueryReadyToDownload(prevState => ({
            ...prevState,
            format: newFormat
        }));
    };

    // Add this function to handle navigation
    const navigateToDownloads = () => {
        history.push('/download');
        localStorage.setItem("dfp/dataTypeNavigation", "download");
    };


    const handleRecordsToDownloadChange = (event) => {
        const value = event.target.value;
        // Regular expression to match only non-negative integers or an empty string
        const re = /^\d*$/;

        if (re.test(value)) {
            // Only update if the input matches the regex (including empty string to allow clearing the input)
            setRecordsToDownload(value); // Keep as string to allow empty value

            const numericValue = value === "" ? 0 : parseInt(value, 10); // Convert to number for storing in queryReadyToDownload
            setQueryReadyToDownload(prevState => ({
                ...prevState,
                num_records: numericValue
            }));
        }
    };

    function handleOnClickStartDownload() {
        if (!isQueryLoading) {
            setIsLoadingLocalState(true)
            createDownload({ query: queryReadyToDownload });
        }
    }

    const handleDownloadAllFiles = () => {
        // Ensure there are files to download
        if (downloadByIdData && downloadByIdData.data && downloadByIdData.data.results) {
            downloadByIdData.data.results.forEach(file => {
                // Create an anchor element
                const link = document.createElement('a');
                // Set the href to the file's URL
                link.href = file;
                // Append to the body temporarily
                document.body.appendChild(link);
                // Trigger the download
                link.click();
                // Clean up by removing the element
                document.body.removeChild(link);
            });
        }
    };

    const handleBackdropClick = (event) => {
        setIsVisible(false);
        setTimeout(() => {
            onClose();
        }, 250);
    };


    useEffect(() => {
        const parseQueryToFilters = (query) => {
            const filterPattern = /(\w+):((?:"[^"]+"|\([^)]+\)|\[[^\]]+\]|[^ }\]]+))/g;
            let match;
            const parsedFilters = [];

            while ((match = filterPattern.exec(query)) !== null) {
                let key = match[1];
                let value = match[2];

                // Check for values within parentheses and attempt to remove quotes from individual segments
                if (value.startsWith('(') && value.endsWith(')')) {
                    value = value.substring(1, value.length - 1) // Remove the surrounding parentheses
                        .split(' OR ') // Split by the logical OR
                        .map(segment => segment.replace(/^"|"$/g, '')) // Remove quotes from each segment
                        .join(' OR '); // Rejoin with OR
                } else {
                    // For simple quoted values, remove the quotes directly
                    value = value.replace(/^"|"$/g, '');
                }

                parsedFilters.push({ key, value });
            }

            setFilters(parsedFilters);
        };

        parseQueryToFilters(queryToDownload);
    }, [queryToDownload]);


    useEffect(() => {
        // Trigger the slide-in effect when the component mounts
        const timeoutId = setTimeout(() => {
            setIsVisible(true);
        }, 1);

        return () => clearTimeout(timeoutId);
    }, []);

    const buttonStatus = downloadByIdData?.data?.status

    return (
        <div className={styles.modalBackdrop} onClick={handleBackdropClick}
            role="dialog" aria-labelledby="downloadModalTitle" aria-describedby="downloadModalDescription">
            <section className={styles.modalContent} style={{ transform: isVisible ? "translateX(0)" : "translateX(100%)" }} onClick={e => e.stopPropagation()}>

                <header className={styles.containerTitleDownloadData}>
                    <h1 className={styles.downloadTitle}>Download Data</h1>
                    {buttonStatus === "running" ?
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span style={{ fontSize: "10px", marginRight: "16px", display: "block", width: "147px" }}>
                                You can close this window.
                                <span >
                                    All downloads are available
                                    <span style={{ paddingLeft: "5px", color: "#8122B4", cursor: "pointer" }}
                                        onClick={navigateToDownloads}
                                    >
                                        here.
                                    </span>
                                </span>
                            </span>
                            <button className={`${styles.startDownloadButton} ${styles.disabledButton}`}>
                                Download in progress
                            </button>
                        </div>
                        :
                        buttonStatus === "completed" ?
                            <button className={styles.completedDownloadButton} onClick={handleDownloadAllFiles}>Download all files</button> :
                            <button
                                disabled={recordsToDownload === "" || recordsToDownload === 0}
                                onClick={isLoadingLocalState ? undefined : handleOnClickStartDownload}
                                className={`${styles.startDownloadButton} 
                        ${recordsToDownload === "" || recordsToDownload === 0 ? styles.disabledButton : ''}
                        ${isLoadingLocalState ? styles.loadingButton : ''}`}
                            >
                                {isLoadingLocalState === true ? 'Downloading...' : 'Start Download'}
                            </button>
                    }

                </header>
                <hr className={styles.hrStyle} />

                <div className={styles.containerTotalAvailableMatches}>
                    <h2 className={styles.totalAvailableMatchesTitle}>Total available matches</h2>
                    <span className={styles.totalAvailableMatchesNumber}>
                        {dataToDownload?.num_found.toLocaleString("en-US") ?? <span className={styles.totalAvailableMatchesNumber}>
                            N/A
                        </span>}</span>
                </div>

                <hr className={styles.hrStyle} />

                <div className={styles.filtersContainer}>
                    <h2 className={styles.filtersTitle}>Filters</h2>
                    {filters.slice(0, showAllFilters ? filters.length : 5).map((filter, index) => (
                        <div key={index} className={styles.filtersRow}>
                            <span className={styles.filterName}>{filter.key}:</span>
                            <div>
                                <span className={styles.filterValue}>{filter.value}</span>
                            </div>
                        </div>
                    ))}
                    {filters.length > 5 && (
                        <span className={styles.expandSpan} onClick={() => setShowAllFilters(!showAllFilters)}>
                            {showAllFilters ? 'Show Less' : `Expand All (${filters.length})`}
                        </span>
                    )}
                </div>

                <hr className={styles.hrStyle} />

                <div className={styles.recordDownloadContainer}>
                    <h2 className={styles.recordToDownloadTitle}>Records to Download</h2>
                    <input
                        className={styles.recordToDownloadInput}
                        type="text"
                        value={recordsToDownload}
                        onChange={handleRecordsToDownloadChange}
                        disabled={isLoadingLocalState || buttonStatus === "running" || buttonStatus === "completed"}
                    />
                </div>

                <hr className={styles.hrStyle} />

                <div className={styles.fileFormatContainer}>
                    <h2 className={styles.fileFormatTitle}>File Format</h2>
                    <div className={styles.fileFormatInputsContainer}>
                        <div className={styles.fileFormatCSVContainer}>
                            <input
                                className={styles.fileFormatInput}
                                type="radio"
                                value="csv"
                                checked={selectedFormat === 'csv'}
                                onChange={handleFormatChange}
                                disabled={isLoadingLocalState || buttonStatus === "running" || buttonStatus === "completed"}
                            />
                            <span className={styles.fileFormatLabelCSV} htmlFor="CSV">CSV</span>

                            <img
                                src={tableImage}
                                alt="tableImage"
                                style={{ width: "16px", height: "16px" }}
                            />

                        </div>
                        <div className={styles.fileFormatJSONContainer}>
                            <input
                                className={styles.fileFormatInput}
                                type="radio"
                                value="json"
                                checked={selectedFormat === 'json'}
                                onChange={handleFormatChange}
                                disabled={isLoadingLocalState || buttonStatus === "running" || buttonStatus === "completed"}
                            />
                            <span className={styles.fileFormatLabelCSV} htmlFor="JSON">JSON</span>

                            <img
                                src={blackCurlyBrackets}
                                alt="blackCurlyBrackets"
                                style={{ width: "16px", height: "16px" }}
                            />

                        </div>
                    </div>

                </div>
                <hr className={styles.hrStyle} />

                <div className={styles.creditUsageContainer}>
                    <h2 className={styles.creditUsageTitle} htmlFor="Credit Usage">
                        Credit Usage
                    </h2>
                    <span className={styles.creditUsageKey}>Property credits: </span>
                    <span className={styles.creditUsageKey}> {
                        // Ensure recordsToDownload is treated as an integer and default to 0 if not a number
                        Math.floor(parseInt(recordsToDownload) || 0)
                    }</span>
                </div>

                <hr className={styles.hrStyle} />
                {downloadByIdData ? <DownloadFiles downloadByIdData={downloadByIdData} /> :
                    <div className={styles.downloadSectionContainer}>
                        <h2 className={styles.downloadSectionTitle}>Download</h2>
                        <p className={styles.downloadSectionParagraph}>Not Started. Click "Start download" above.</p>
                    </div>
                }
            </section>
            <SnackbarComponent snackData={snackMessage} />
        </div>
    );
}

export default DownloadModal;