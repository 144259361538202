import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import ReactJson from "react-json-view";
import rectangleXmark from "../ExpandedRecordDr6/images/rectangle-xmark.svg";

function JsonViewModal({ jsonData, onClose }) {
  const [isVisible, setIsVisible] = useState(false);

  const handleClose = () => {
    // Start the slide-out effect
    setIsVisible(false);
    // Use a timeout to match the CSS transition duration
    setTimeout(() => {
      onClose();
    }, 250); // Adjust timeout to match your CSS transition
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, 1);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  if (!jsonData) return null;

  return (
    <div className={styles.jsonModalBackdrop} onClick={handleBackdropClick}>
      <div
        className={styles.jsonModalContent}
        onClick={(e) => e.stopPropagation()}
        style={{ transform: isVisible ? "translateX(0)" : "translateX(100%)" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            margin: "0px 0px 19px 0px",
          }}
        >
          <h1 style={{ fontSize: "20px", margin: 0, fontWeight: "500" }}>
            JSON
          </h1>
          <div
            onClick={handleClose}
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <label
              style={{
                fontSize: "12px",
                fontWeight: "500",
                marginRight: "8px",
                cursor: "pointer",
              }}
            >
              Close
            </label>
            <img
              src={rectangleXmark}
              alt="rectangleXmarkIcon"
              style={{ width: "12px" }}
            />
          </div>
        </div>

        {/*  */}
        <hr
          style={{
            border: "1px solid #E5E5E5",
            margin: "0px -40px 24px -40px",
          }}
        />
        {/*  */}
        <ReactJson
          style={{ fontSize: "14px" }}
          src={jsonData}
          displayDataTypes={false}
          enableClipboard={false}
          displayObjectSize={false}
          quotesOnKeys={false}
          sortKeys={true}
          name={false}
          theme={{
            base00: "white",
            base01: "#771009",
            // lines
            base02: "#FFFFFF",
            // lines
            base03: "#771009",
            base04: "#000000",
            // arrays
            base05: "red",
            // arrays
            base06: "blue",
            // value
            base07: "#771009",
            // value
            base08: "brown",
            base09: "#2365B2",
            base0A: "green",
            base0B: "#098658",
            base0C: "#000000",
            base0D: "#000000",
            base0E: "#000000",
            base0F: "#098658",
          }}
        />
      </div>
    </div>
  );
}

export default JsonViewModal;
