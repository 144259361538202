// This JS file was created to create the columns for the tables
export const columns = [
  { id: 'ID', label: 'ID', minWidth: 170 },
  { id: 'DataType', label: 'DATA TYPE', minWidth: 100 },
  { id: 'Credits', label: '#CREDITS', minWidth: 170, format: (value) => value.toLocaleString('en-US') },
  { id: 'DateStarted', label: 'DATE STARTED', minWidth: 170, format: (value) => value.toLocaleString('en-US') },
  { id: 'status', label: 'STATUS', minWidth: 170, format: (value) => value.toFixed(2) },
  { id: 'Query', label: 'QUERY', minWidth: 170, format: (value) => value.toFixed(2) },
  { id: 'View', label: 'VIEW', minWidth: 170, format: (value) => value.toFixed(2) }
]
