// react imports and components
import React, { useState, useEffect, useContext } from 'react'
import { LocalContext } from '../LocalContext'
import SnackbarComponent from '../Snackbar'
import NestedInput from './NestedInput'
// styles and material ui imports
import styles from './styles.module.css'
import CloseIcon from '@material-ui/icons/Close'
import { Button, IconButton, CircularProgress } from '@material-ui/core'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// utils imports
import { businessArray, primaryBusiness } from './utils/BusinessData'
import { peopleArray, statesValuesUS, statesValuesCanada, jobFunctionsValues, jobLevelValues, businessCategories } from './utils/PeopleData'
import { countriesList } from './utils/utilsData'
import { productArray, taxonomyList } from './utils/ProductData'
import { propertyArray, propertyType, statusValuesProperty } from './utils/PropertyData'
import QueryBuilder from './QueryBuilder'
import { useMutation } from 'react-query'

export default function FilterBuilder({ dataSelector, apiData, containerState, setContainerState }) {
  // data selector that is coming from the components
  const dataTypeArrays = dataSelector === 'business' ? businessArray : dataSelector === 'people' ? peopleArray : dataSelector === 'product' ? productArray : dataSelector === 'property' ? propertyArray : ''
  // states of the component filter builder
  const [firstEvent, setFirstEvent] = useState(false)
  const [secondEvent, setSecondEvent] = useState(false)
  const [thirdEvent, setThirdEvent] = useState(false)
  const [firstTimeRender, setFirstTimeRender] = useState(true)
  const [builderResult, setBuilderResult] = useState('')
  const [modalState, setModalState] = useState(false)
  //  
  const [addFilterState, setAddFilterState] = useState(true)
  const [inputState, setInputState] = useState(false)
  const [inputType, setInputType] = useState()
  const [inputValue, setInputValue] = useState()
  const [inputValueData, setInputValueData] = useState()
  const [firstInputNested, setFirstInputNested] = useState(false)
  const [secondInputValue, setSecondInputValue] = useState()

  // 
  const [arrayDivs, setArrayDivs] = useState([])
  const [provinceDisabled, setProvinceDisabled] = useState(true)
  const [validationArray, setValidationArray] = useState([])

  const [snackMessage, setsnackMessage] = useState({ type: 'error' })
  const { value, setValue } = useContext(LocalContext)
  const [page] = useState(1)
  const [pageTableView] = useState(1)
  const [pageMapView] = useState(1)
  const [pageGridView] = useState(1)
  const [pageCSVView] = useState(1)
  const [provinceState, setProvinceState] = useState()
  // query value
  const [params, setParams] = useState({})
  const [downloadValue, setDownloadValue] = useState('')
  // Query Data State to Json View and CSV View
  const [queryData, setQueryData] = useState({})
  const [queryDataCsv, setQueryDataCsv] = useState({})
  // Mutation apiSearch
  const [filterBuilder, { isLoading }] = useMutation(
    ({ page, queryData }) => apiData(page, queryData),
    { onSuccess: handleSuccess, onError: handleError })
  // handles the success and the error of the query mutation
  function handleSuccess(data) {
    if (data?.data?.errors) {
      setsnackMessage({ type: 'error', myMessage: data?.data?.errors[0], update: Date.now() })
    } else if (!data?.data?.errors) {
      setValue({ ...value, data, GridViewDataPagination: data, queryData, queryDataCsv, downloadValue, page, pageTableView, pageMapView, pageGridView, pageCSVView })
    }
  } function handleError() { }
  // data type selection
  const onClickDataType = (array) => {
    setInputValueData(undefined)
    setModalState(false)
    setAddFilterState(false)
    setInputState(true)
    setInputType(array.type)
    setInputValue(array)

    setValidationArray([...validationArray, array.name])
  }
  // icon next to the input, deletes params 
  const closeIconNextToInput = () => {
    setFirstInputNested(false)
    setSecondInputValue(undefined)
    setInputType(undefined)
    setInputValue(undefined)
    setModalState(true)
    setContainerState(false)
    setAddFilterState(true)
    setInputState(false)
    delete params[inputValue?.name]
    const newArray = validationArray.filter(item => item !== inputValue.name)
    setValidationArray(newArray)
  }
  // onChange on the input
  const inputOnChange = (e) => {
    setInputValueData(e.target.value)
  }
  // remote event
  const removeItem = (blueDiv) => {
    if ((blueDiv.inputValue === 'descriptions' || blueDiv.inputValue === 'features' || blueDiv.inputValue === 'quantities' || blueDiv.inputValue === 'hours' || blueDiv.inputValue === 'menus' || blueDiv.inputValue === 'people' || blueDiv.inputValue === 'reviews' || blueDiv.inputValue === 'rooms' || blueDiv.inputValue === 'financingAndLeasing' || blueDiv.inputValue === 'merchants' || blueDiv.inputValue === 'prices' || blueDiv.inputValue === 'skus' || blueDiv.inputValue === 'brokers' || blueDiv.inputValue === 'deposits' || blueDiv.inputValue === 'fees' || blueDiv.inputValue === 'leasingTerms' || blueDiv.inputValue === 'manageBy' || blueDiv.inputValue === 'propertyTaxes' || blueDiv.inputValue === 'statuses' || blueDiv.inputValue === 'currency') && params[`${blueDiv.inputValue}Nested`]) {
      delete params[`${blueDiv.inputValue}Nested`]
    }
    if (params[`${blueDiv.inputValue}Nested`] === '') {
      delete params[`${blueDiv.inputValue}Nested`]
    }
    const newArray = arrayDivs.filter(item => item.inputValue !== blueDiv.inputValue)
    const newValidatedArray = validationArray.filter(item => item !== blueDiv.inputValue)
    const isProvinceInside = arrayDivs.find(({ inputValue }) => inputValue === 'province')
    if (blueDiv.inputValue === 'country') {
      setProvinceDisabled(true)
    }
    if ((blueDiv.inputValue === 'country' && isProvinceInside?.inputValue === 'province')) {
      setsnackMessage({ type: 'error', myMessage: `Can't delete country`, update: Date.now() })
    } else if (inputState === true) {
      setsnackMessage({ type: 'error', myMessage: `Can't remove a filter while adding a new filter`, update: Date.now() })
    }
    else {
      delete params[blueDiv.inputValue]
      setArrayDivs(newArray)
      setValidationArray(newValidatedArray)
      setFirstInputNested(false)
      setInputValue(undefined)
      setInputType(undefined)
      setFirstTimeRender(true)
      setFirstEvent(!firstEvent)
    }
  }
  //onClick date nested
  const onClickDateNested = () => {
    setInputState(false)
    setAddFilterState(true)
    setArrayDivs([...arrayDivs, { inputValue: inputValue.name, inputValueState: inputValueData }])
    setParams({ ...params, [`${inputValue.name}Nested`]: inputValueData })
    setFirstInputNested(false)
  }

  const onChangeDropdown = (e) => {
    setInputState(false)
    setAddFilterState(true)
    const value = inputValue?.name
    const change = e.target.value
    setParams({ ...params, [value]: change })
    setArrayDivs([...arrayDivs, { inputValue: inputValue.name, inputValueState: e.target.value }])
    setFirstEvent(!firstEvent)
    if (value === 'country' && (change === 'US' || change === 'CA')) {
      setProvinceState(change)
      setProvinceDisabled(false)
      const newArray = validationArray.filter(item => item !== 'province')
      setValidationArray(newArray)
    }
  }
  // read about this one
  const onChangeNested = (e) => {
    setFirstInputNested(true)
    const change = e.target.value
    setSecondInputValue(change)
    const value = inputValue?.name
    setParams({ ...params, [value]: change })
  }
  const onClickAddFilterNested = () => {
    if (inputType === 'nested' && (inputValueData === undefined || inputValueData === null || inputValueData === '')) {
      setInputState(false)
      setAddFilterState(true)
      const newArray = validationArray.filter(item => item !== inputValue.name)
      setValidationArray(newArray)
      delete params[inputValue.name]
      setSecondInputValue(undefined)
      setFirstInputNested(false)
      setInputType(undefined)
    } else {
      setInputState(false)
      setAddFilterState(true)
      setArrayDivs([...arrayDivs, { inputValue: inputValue.name, inputValueState: inputValueData }])
      setParams({ ...params, [`${inputValue.name}Nested`]: inputValueData })
      setFirstInputNested(false)
      setFirstTimeRender(false)
      setFirstEvent(!firstEvent)
    }
  }
  const filterBuilderOnClick = () => {
    setFirstInputNested(false)
    setSecondInputValue()
    setInputState(false)
    setAddFilterState(true)
    filterBuilder({ page, queryData })
  }
  // when the user clicks on the add filter when the input is opened, this event will take place
  const onClickSearchButton = () => {
    if (inputState === true && inputType !== 'nested' && (inputValueData === undefined || inputValueData === null || inputValueData === '')) {
      setInputState(false)
      setAddFilterState(true)
      const newArray = validationArray.filter(item => item !== inputValue.name)
      setValidationArray(newArray)
    }
    else if (inputState === true && inputType === 'nested' && (inputValueData === undefined || inputValueData === null || inputValueData === '')) {
      setInputState(false)
      setAddFilterState(true)
      const newArray = validationArray.filter(item => item !== inputValue.name)
      setValidationArray(newArray)
      delete params[inputValue.name]
      setSecondInputValue(undefined)
      setFirstInputNested(false)
      setInputType(undefined)
    }
    else if (inputType === 'nested' && inputState === true) {
      setInputState(false)
      setAddFilterState(true)
      setArrayDivs([...arrayDivs, { inputValue: inputValue.name, inputValueState: inputValueData }])
      setParams({ ...params, [`${inputValue.name}Nested`]: inputValueData })
      setFirstInputNested(false)
      setFirstTimeRender(false)
      setSecondEvent(!secondEvent)
    } else if (inputState === true) {
      setFirstTimeRender(false)
      setInputState(false)
      setAddFilterState(true)
      const value = inputValue?.name
      setArrayDivs([...arrayDivs, { inputValue: inputValue?.name, inputValueState: inputValueData }])
      setParams({ ...params, [value]: inputValueData })
      setSecondEvent(!secondEvent)
    }
    else {
      filterBuilder({ page, queryData })
    }
    setModalState(false)
  }
  const onClickAddFilterButton = () => {
    if (inputState === true && (inputValueData === undefined || inputValueData === null || inputValueData === '')) {
      setInputState(false)
      setAddFilterState(true)
      const newArray = validationArray.filter(item => item !== inputValue.name)
      setValidationArray(newArray)
    } else {
      setInputState(false)
      setAddFilterState(true)
      const value = inputValue.name
      setParams({ ...params, [value]: inputValueData })
      setArrayDivs([...arrayDivs, { inputValue: inputValue.name, inputValueState: inputValueData }])
      setFirstEvent(!firstEvent)
    }
  }
  const onClickFilterModal = () => {
    if (provinceDisabled === true) {
      setValidationArray([...validationArray, 'province'])
      setProvinceDisabled(false)
    }
    setContainerState(false)
    setModalState(modalState !== true)
  }
  useEffect(() => {
    if (containerState === true) {
      setModalState(false)
    }
  }, [containerState])

  useEffect(() => {
    const result = QueryBuilder.buildQuery(dataSelector, params)
    setBuilderResult(result)
    setQueryData({ query: result })
    setQueryDataCsv({ query: result, format: 'csv' })
    setDownloadValue(result)
  }, [firstEvent]) /* eslint-disable-line react-hooks/exhaustive-deps */
  // handle submit event used when the enter is press

  useEffect(() => {
    const result = QueryBuilder.buildQuery(dataSelector, params)
    setBuilderResult(result)
    setQueryData({ query: result })
    setQueryDataCsv({ query: result, format: 'csv' })
    setDownloadValue(result)
    setThirdEvent(!thirdEvent)
  }, [secondEvent]) /* eslint-disable-line react-hooks/exhaustive-deps */
  // handle submit event used when the enter is press

  useEffect(() => {
    if (firstTimeRender === false) {
      filterBuilderOnClick()
    }
  }, [thirdEvent])/* eslint-disable-line react-hooks/exhaustive-deps */

  const handleSubmit = () => {
    onClickSearchButton()
  }
  return (
    <div className={styles.containerFilterBuilder}>
      <div className={styles.containerInsideFB}>
        <div style={{ display: 'inlineBlock' }}>
          {/* blue divs */}
          {arrayDivs?.map((blueDiv, i) => (
            <div onClick={() => { removeItem(blueDiv, i) }} className={styles.blueDivs} key={i}>{blueDiv?.inputValue + ': '}{blueDiv?.inputValueState} <IconButton style={{ padding: '0px', marginBottom: '2px' }} onClick={() => { removeItem(blueDiv, i) }}><CloseIcon fontSize='small' style={{ color: '#43425D', opacity: '0.5', fontSize: '1rem' }} /></IconButton></div>
          ))}
          {/* Add filter */}
          <div style={{ cursor: 'pointer', display: 'inline-block' }} onClick={onClickFilterModal}>
            {addFilterState ? <label className={styles.addFilterStyles}>+ Add Filter</label> : ''}</div>
          {inputState ? <label style={{ fontSize: '16px', fontFamily: 'Roboto', color: '#43425D', marginRight: '3px' }}> {inputValue?.name}</label> : ''}
          {/* normal inputs */}
          {inputState && inputType === 'text' ? <input type='text' className={styles.inputText} autoFocus onChange={e => inputOnChange(e)} />
            : inputState && inputType === 'date' ? <input type='date' onChange={e => inputOnChange(e)} />
              : inputState && inputType === 'propertyType' ?
                <select className={styles.selectDrop} onChange={(e) => onChangeDropdown(e)}> {propertyType?.map((propertyType, i) => <option key={i} value={propertyType?.value}>{propertyType?.name}</option>)}</select>
                : inputState && inputType === 'businessCategories' ?
                  <select className={styles.selectDrop} onChange={(e) => onChangeDropdown(e)}> {businessCategories?.map((businessCategories, i) => <option key={i} value={businessCategories?.value}>{businessCategories?.name}</option>)}</select>
                  : inputState && inputType === 'primaryBusiness' ?
                    <select className={styles.selectDrop} onChange={(e) => onChangeDropdown(e)}> {primaryBusiness?.map((primary, i) => <option key={i} value={primary?.value}>{primary?.name}</option>)}</select>
                    : inputState && inputType === 'taxonomyList' ?
                      <select className={styles.selectDrop} onChange={(e) => onChangeDropdown(e)}> {taxonomyList?.map((taxonomyList, i) => <option key={i} value={taxonomyList?.value}>{taxonomyList?.name}</option>)}</select>
                      : inputState && inputType === 'statusValuesProperty' ?
                        <select className={styles.selectDrop} onChange={(e) => onChangeDropdown(e)}> {statusValuesProperty?.map((status, i) => <option key={i} value={status?.value}>{status?.name}</option>)}</select>
                        : inputState && inputType === 'country' ? <select style={{ width: '200px' }} onChange={(e) => onChangeDropdown(e)}> {countriesList?.map((country, i) => <option key={i} value={country?.value}>{country?.name}</option>)}</select>
                          : inputState && inputType === 'state' && provinceState === 'US' ? <select style={{ width: '200px' }} onChange={(e) => onChangeDropdown(e)}> {statesValuesUS?.map((state, i) => <option style={{ backgroundColor: 'red !important' }} key={i} value={state?.value}>{state?.name}</option>)}</select>
                            : inputState && inputType === 'state' && provinceState === 'CA' ? <select style={{ width: '200px' }} onChange={(e) => onChangeDropdown(e)}> {statesValuesCanada?.map((state, i) => <option style={{ backgroundColor: 'red !important' }} key={i} value={state?.value}>{state?.name}</option>)}</select>
                              // statesValuesCanada      statesValuesUS
                              : inputState && inputType === 'jobFunction' ? <select style={{ width: '200px' }} onChange={(e) => onChangeDropdown(e)}> {jobFunctionsValues.map((jobFunction, i) => <option key={i} value={jobFunction?.value}>{jobFunction?.name}</option>)}</select>
                                : inputState && inputType === 'jobLevel' ? <select style={{ width: '200px' }} onChange={(e) => onChangeDropdown(e)}> {jobLevelValues.map((jobLevel, i) => <option key={i} value={jobLevel?.value}>{jobLevel?.name}</option>)}</select>
                                  : inputState && inputType === 'nested' ? <NestedInput inputValue={inputValue} onChangeNested={onChangeNested} firstInputNested={firstInputNested} inputOnChange={inputOnChange} secondInputValue={secondInputValue} onClickDateNested={onClickDateNested} /> : ''}
          {inputState === true ? <IconButton onClick={() => closeIconNextToInput()} style={{ padding: '0px', marginBottom: '2px', marginLeft: '5px' }}><CloseIcon fontSize='small' style={{ color: '#43425D', opacity: '0.5', fontSize: '1rem' }} /></IconButton> : ''}
          {/* modal */}
          {modalState === true
            ?
            <div className={styles.boxModalContainer}>
              {dataTypeArrays.map((array, i) => (<p key={i} className={validationArray.includes(array.name) === false ? styles.itemsInsideModal : styles.itemsInsideModalDisabled} onClick={() => onClickDataType(array)}>{array.name}</p>))}</div>
            : ''}

          {/* text inside */}
          <div style={{ display: 'inline-block', marginLeft: '5px' }}>{(!addFilterState && inputType === 'text') || (!addFilterState && inputType === 'date') ? <label onClick={onClickAddFilterButton} className={inputType === 'date' && inputValueData === undefined ? styles.addFilterNoDateStyles : styles.addFilterStyles}>+ Add Filter</label> : ''}</div>

          <div style={{ display: 'inline-block', marginLeft: '5px' }}>{!addFilterState && firstInputNested === true ? <label onClick={secondInputValue !== 'date' || secondInputValue !== 'dateSeen' ? onClickAddFilterNested : secondInputValue === 'date' || secondInputValue === 'dateSeen' ? onClickDateNested : ''} className={(secondInputValue === 'date' || secondInputValue === 'dateSeen') && inputValueData === undefined ? styles.addFilterNoDateStyles : styles.addFilterStyles}>+ Add Filter</label> : ''}</div>
        </div>
        {/* Button */}
        {/* <Tooltip title={dataSelector === 'people' ? <Typography fontSize={16}><label style={{ fontWeight: 'bolder' }}>Note: </label>Each search result uses up to 26.67 record credits</Typography> : dataSelector === 'product' ?  <Typography fontSize={16}><label style={{ fontWeight: 'bolder' }}>Note: </label>Each search result uses up to 24 record credits</Typography> : <Typography fontSize={16}><label style={{ fontWeight: 'bolder' }}>Note: </label>Each search result uses up to 20 record credits</Typography>} placement='bottom'>
        </Tooltip> */}
        <form className={styles.formButton} onSubmit={handleSubmit}>
          <input style={{ position: 'absolute', height: '0px', width: '0px', border: 'none', padding: '0px' }} type='input' autoFocus hidefocus='true' />
          <Button onClick={() => { handleSubmit() }} style={{ backgroundColor: isLoading === false ? '#3F3B88' : '#FFFFFF' }} disabled={isLoading} variant='contained' endIcon={isLoading ? '' : <FontAwesomeIcon style={{ marginLeft: '3%' }} icon={faSearch} />} className={styles.buttonSearchFilter}>{isLoading === true ? <CircularProgress size='2rem' style={{ color: '#3F3B88' }} /> : 'Search'}</Button>
        </form>

      </div>
      {modalState === false ? <label style={{ color: '#808495', opacity: '0.5', marginTop: '100px' }}>API CALL: {builderResult}  </label> : null}
      <SnackbarComponent snackData={snackMessage} />
    </div>
  )
}
