import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: { marginTop: "18px" },

    appBar: {
        backgroundColor: '#fff', // White background for the AppBar
        boxShadow: 'none', // Removes box shadow which might look like a border
        borderBottom: 'none', // Ensures no border at the bottom of the AppBar
        color: "#8122B4",
        marginBottom: "18px"
    },
    tabs: {
        minHeight: "0px",
        '& .MuiTabs-indicator': {
            backgroundColor: 'transparent', // Set the indicator (underline) color to transparent
        },
        border: 'none', // Removes any border from the Tabs container
        indicator: {
            display: 'none', // This can remove the underline indicator if present
        },

    },
    tab: {
        minWidth: "0px",
        fontSize: "14px",
        padding: "0px",
        textTransform: "none",
        minHeight: "0px",
        marginRight: "44px",
        color: "#000000",
        '&.Mui-selected': {
            color: '#8122B4',
            fontWeight: 500,
        },
    }
}));
