import React from 'react'
import styles from "./styles.module.css";

function LegalDescription({ record }) {
    return (
        <section className={styles.containerLegalDescription}>
            <header className={styles.headerLegalDescription} >
                <h2 className={styles.titleLegalDescription}>
                    Legal description
                </h2>
                <p className={styles.valueLegalDescription}>
                    {record?.legalDescription ? record.legalDescription : "N/A"}
                </p>
            </header>

            <div className={styles.containerLegalDescriptionBoxes}>
                <div className={styles.legalDescriptionBoxes}>
                    <span>
                        Parcel number
                    </span>
                    <p>
                        {record?.parcelNumbers?.[0]?.number
                            ? record.parcelNumbers[0].number
                            : "N/A"}
                    </p>
                </div>

                <div className={styles.legalDescriptionBoxes}>
                    <span>MLS #</span>
                    <p>
                        {record?.mlsNumber ? record.mlsNumber : "N/A"}
                    </p>
                </div>

                <div className={styles.legalDescriptionBoxes}>
                    <span>Tax ID</span>
                    <p>{record?.taxID ? record.taxID : "N/A"}</p>
                </div>

                <div className={styles.legalDescriptionBoxes}>
                    <span>Zoning</span>
                    <p>{record?.zoning ? record.zoning : "N/A"}</p>
                </div>

                <div className={styles.legalDescriptionBoxes}>
                    <span>CBSA code</span>
                    <p>{record?.cbsaCode ? record.cbsaCode : "N/A"}</p>
                </div>

                <div className={styles.legalDescriptionBoxes}>
                    <span>Census block</span>
                    <p> {record?.censusBlock ? record.censusBlock : "N/A"}</p>
                </div>
            </div>
        </section>
    )
}

export default LegalDescription