import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
// import styles from "../styles.module.css";
import expandedRecordIcon from "../images/expandedSVG.svg";
import useStore from "../../Store/Store"; // Import the store
import ExpandedRecordDr6 from "../../ExpandedRecordDr6/index"; // Import your modal component
import { useMutation, useQueryCache } from 'react-query';
import { postProperty } from "../../../Api/index";

function SearchResults() {
  const { dataResults, isQueryLoading } = useStore();
  const dataRecords = dataResults?.data?.records;
  // State to manage modal visibility and data
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const queryCache = useQueryCache();

  const [isLoadingRecord, setLoadingRecord] = useState(false); // New state to indicate loading

  // Use useMutation for the POST request
  const [mutate] = useMutation(postProperty, {
    onSuccess: (data) => {
      const newRecord = data?.data?.records[0];
      if (newRecord) {
        const cacheKey = `recordByID-${newRecord.keys[0]}`;
        queryCache.setQueryData(cacheKey, newRecord);
        setSelectedRecord(newRecord);
        setModalOpen(true);
        setLoadingRecord(false); // Set loading false on successful data fetch
      }
    },
    onError: () => {
      setLoadingRecord(false); // Set loading false on error
    }
  });

  const handleRecordClick = (record) => {
    const cacheKey = `recordByID-${record.keys[0]}`;
    const cachedData = queryCache.getQueryData(cacheKey);

    setModalOpen(true);
    setSelectedRecord(cachedData || null);
    setLoadingRecord(!cachedData); // Start loading unless cached data is available

    if (!cachedData && !isQueryLoading) {
      mutate({ query: `keys:"${record?.keys[0]}"` });
    } else if (cachedData) {
      setLoadingRecord(false); // Set loading false immediately if using cached data
    }
  };

  useEffect(() => {
    // Remove all queries that start with 'recordByID'
    if (queryCache) {
      queryCache.getQueries().forEach(query => {
        // Ensure queryKey[0] exists and is a string before calling startsWith
        if (typeof query?.queryKey[0] === 'string' && query.queryKey[0].startsWith('recordByID')) {
          queryCache.removeQueries(query.queryKey);
        }
      });
    }
  }, [dataResults, queryCache]);
  return (
    <section className={styles.searchResultsContainerWithScroll}>
      {/* Repeat this block for each listing */}
      {dataRecords?.map((record, index) => (
        <div
          onClick={() => handleRecordClick(record)}
          key={index}
          className={styles.searchResultsContainer}
          style={{
            opacity: isQueryLoading ? 0.5 : 1, // Change opacity to indicate disabled state
            cursor: isQueryLoading ? "not-allowed" : "pointer", // Change cursor to not-allowed when loading
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer", marginBottom: "12px", alignItems: "center", }}>
            <div style={{ display: "flex" }}>
              <span className={styles.testItem} style={{ marginRight: "8px", color: "#8122B4", fontWeight: 500, fontSize: "12px" }}>
                {record.address || "N/A"}
              </span>
              <span style={{ fontSize: "12px", fontWeight: "300" }} className={styles.testItem}>
                {record.city}
                {record.city && record.province ? ", " : ""}
                {record.province}
                {(record.city || record.province) && record.postalCode
                  ? " "
                  : ""}
                {record.postalCode}
              </span>
            </div>
            <div>
              <img style={{ cursor: "pointer", height: "14px", width: "14px" }} src={expandedRecordIcon} alt="ExpandIcon" />
            </div>
          </div>

          {/* Applying right margin to each detail */}

          <div style={{ display: "flex", flexWrap: "wrap", marginBottom: "14px", rowGap: "14px" }}>
            <div style={{ width: "146px" }} className={styles.listingItem}>
              <span style={{ display: "block", color: "#777777", fontSize: "12px", cursor: "pointer", }}>
                Most recent list price

              </span>
              <span style={{ fontSize: "12px", cursor: "pointer" }}>
                {record.mostRecentPriceAmount
                  ? `$${record.mostRecentPriceAmount.toLocaleString("en-US")}`
                  : "N/A"}
              </span>
            </div>
            {/* new */}

            {/* new */}
            <div style={{ width: "100px" }} className={styles.listingItem}>
              <span style={{ display: "block", fontSize: "12px", color: "#777777", cursor: "pointer", }}>
                Most recent status
              </span>
              <span style={{ cursor: "pointer" }}>
                {record.mostRecentStatus ? record.mostRecentStatus : "N/A"}
              </span>
            </div>
            <div style={{ width: "100px" }} className={styles.listingItem}>
              <span style={{ display: "block", color: "#777777", fontSize: "12px", cursor: "pointer", }}>
                Property Type
              </span>
              <span style={{ cursor: "pointer" }}>
                {record.propertyType ? record.propertyType : "N/A"}
              </span>
            </div>
            <div style={{ width: "85px" }} className={styles.listingItem}>
              <span style={{ display: "block", fontSize: "12px", color: "#777777", cursor: "pointer", }}>
                Owner
              </span>
              <span style={{ cursor: "pointer" }}>
                {record.currentOwnerType ? record.currentOwnerType : "N/A"}
              </span>
            </div>

            <div style={{ width: "57px", marginRight: "17px" }} className={styles.listingItem}>
              <span style={{ display: "block", fontSize: "12px", color: "#777777", cursor: "pointer", }}>
                Agent
              </span>
              <span style={{ cursor: "pointer" }}>
                {record.mostRecentBrokerAgent
                  ? record.mostRecentBrokerAgent
                  : "N/A"}
              </span>
            </div>

            <div style={{ width: "57px", marginRight: "32px" }} className={styles.listingItem}>
              <span style={{ display: "block", fontSize: "12px", color: "#777777", cursor: "pointer", }}>
                Floor Size
              </span>
              <span style={{ cursor: "pointer" }}>
                {record.floorSizeValue && record.floorSizeUnit
                  ? `${record.floorSizeValue} ${record.floorSizeUnit}`
                  : "N/A"}
              </span>
            </div>

            <div style={{ width: "57px" }} className={styles.listingItem}>
              <span style={{ display: "block", fontSize: "12px", color: "#777777", cursor: "pointer", }}>
                Lot Size
              </span>
              <span style={{ cursor: "pointer" }}>
                {record.lotSizeValue && record.lotSizeUnit
                  ? `${record.lotSizeValue} ${record.lotSizeUnit}`
                  : "N/A"}
              </span>
            </div>
            {/* style={{ width: "127px" }} */}
            <div style={{ display: "flex", alignItems: "center" }} className={styles.listingItem}>
              <span style={{ cursor: "pointer" }}>{dataResults?.data?.records?.length} fields available</span>
            </div>
            <div style={{ width: "106px", marginRight: "0px", display: "flex", alignItems: "center" }} className={styles.listingItem}>
              <span style={{ cursor: "pointer" }}>
                {record.imageURLs
                  ? `${record?.imageURLs?.length} images available `
                  : "No Images Found"}
              </span>
            </div>
          </div>
          {/*  */}
        </div>
      ))
      }

      {
        isModalOpen && (
          <ExpandedRecordDr6
            recordData={selectedRecord}
            isLoadingRecord={isLoadingRecord}
            onClose={() => {
              setModalOpen(false);
            }}
          />
        )
      }
      {/* End of listing block */}
    </section >
  );
}
export default SearchResults;
