import React, { useState, useEffect } from 'react';
import styles from "./styles.module.css"; // Assuming you have a CSS module with the required styles
import rectangleXmark from "./images/rectangle-xmark.svg";
import copyIcon from "./images/copyIcon.svg";
import SnackbarComponent from "../Snackbar/index";
import { CopyToClipboard } from "react-copy-to-clipboard";

function ModalApiCall({ onClose, queryStore, isQueryLoading }) {

    const [snackMessage, setSnackMessage] = useState({ type: "error" });
    const [isVisible, setIsVisible] = useState(false);
    const query = queryStore?.query

    // Function to handle backdrop click
    const handleBackdropClick = (event) => {
        // Check if the clicked element is the backdrop itself, not its children
        if (event.target === event.currentTarget) {
            setIsVisible(false);
            setTimeout(() => {
                onClose();
            }, 250);
        }
    };

    const handleClose = () => {
        // Start the slide-out effect
        setIsVisible(false);
        // Use a timeout to match the CSS transition duration
        setTimeout(() => {
            onClose();
        }, 250);
    };

    useEffect(() => {
        // Trigger the slide-in effect when the component mounts
        const timeoutId = setTimeout(() => {
            setIsVisible(true);
        }, 1);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        if (isQueryLoading) {
            handleClose();
        }
    }, [isQueryLoading]);/* eslint-disable-line react-hooks/exhaustive-deps */

    function escapeQuotesInQuery(query) {
        // Regex for fields followed by :("...") or :("..." OR "...")
        const complexValueRegex = /(mostRecentStatus|propertyType|hvacTypes|exteriorFeatures|roofing|address|city|province|postalCode|country):\((".*?"\s*(OR\s*".*?")*)\)/g;
        // Regex for fields followed by :"..."
        const simpleValueRegex = /(mostRecentStatus|propertyType|hvacTypes|exteriorFeatures|roofing|address|city|province|postalCode|country|lotSizeUnit|floorSizeUnit):"(.*?)"/g;

        // First, replace complex values (with ORs and parentheses)
        let result = query.replace(complexValueRegex, (match) => {
            return match.replace(/"/g, '\\"');
        });

        // Then, replace simple values
        result = result.replace(simpleValueRegex, (match) => {
            return match.replace(/"/g, '\\"');
        });

        return result;
    }

    const escapedQuery = escapeQuotesInQuery(query);

    const apiCallText =
        `{"query": "${escapedQuery}" }`;

    return (
        <div className={styles.modalBackdrop} onClick={handleBackdropClick}>
            <div className={styles.modalContent}
                style={{ transform: isVisible ? "translateX(0)" : "translateX(100%)" }}
                onClick={e => e.stopPropagation()}>
                <div className={styles.modalHeader}>
                    <h2 className={styles.ApiCallTitle}>View API Call</h2>
                    {/* <div style={{ display: "flex" }}> */}
                    <div className={styles.closeButtonContainer}>
                        <span className={styles.closeButtonTitle} onClick={handleClose}>Close</span>
                        <img
                            onClick={handleClose}
                            src={rectangleXmark}
                            alt="rectangleXmarkIcon"
                            className={styles.rectangleIcon}
                        />
                    </div>

                </div>
                <hr style={{ border: "1px solid #E5E5E5", margin: "0px -40px" }} />

                <div className={styles.apiCallContainer}>
                    {/* {queryStore?.query} */}
                    <span className={styles.ApiCallSmallTitle}>API CALL</span>
                    <div className={styles.containerQuery}>
                        <span className={styles.apiCallTextBracket}>{"{"}</span>
                        <span className={styles.apiCallKey}>"query"</span>
                        <span className={styles.apiCallText}>: </span>
                        <span className={styles.apiCallValue}>"{escapedQuery}"</span>
                        <span className={styles.apiCallTextBracket}>{"}"}</span>
                    </div>
                </div>

                <div className={styles.copyContainer}>
                    <CopyToClipboard
                        text={apiCallText}
                        onCopy={() => setSnackMessage({ type: 'success', myMessage: 'Query copied', update: Date.now() })}>
                        <span className={styles.copyButton}>Copy</span>
                    </CopyToClipboard>
                    <CopyToClipboard
                        text={apiCallText}
                        onCopy={() => setSnackMessage({ type: 'success', myMessage: 'Query copied', update: Date.now() })}>
                        <img src={copyIcon} alt="copyIcon" className={styles.copyIcon} />
                    </CopyToClipboard>

                </div>

                <hr style={{ border: "1px solid #E5E5E5", margin: "0px -40px" }} />

                <div className={styles.documentationContainer}>
                    <h3 className={styles.documentationTitle}>Documentation</h3>
                    <ul className={styles.listDocumentation}>
                        <li className={styles.liList}>
                            <a href="https://docs.datafiniti.co/docs/getting-started-with-property-data" className={styles.linkList} target="_blank" rel="noopener noreferrer">Property API starting guide</a>
                        </li>
                        <li className={styles.liList}>
                            <a href="https://docs.datafiniti.co/docs/property-data-schema" className={styles.linkList} target="_blank" rel="noopener noreferrer">Property data schema</a>
                        </li>
                        <li className={styles.liList}>
                            <a href="https://docs.datafiniti.co/docs/constructing-property-queries" className={styles.linkList} target="_blank" rel="noopener noreferrer">Constructing advanced queries</a>
                        </li>
                        <li className={styles.liList}>
                            <a href="https://docs.datafiniti.co/docs/use-cases-for-property-data" className={styles.linkList} target="_blank" rel="noopener noreferrer">Specific use case guides</a>
                        </li>
                    </ul>
                </div>
            </div>
            <SnackbarComponent snackData={snackMessage} />
        </div>
    );
}

export default ModalApiCall;
