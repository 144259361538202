// import React and components
import React, { useState, useEffect } from 'react'
// material Ui imports and Styles
import { Grid, Dialog, makeStyles } from '@material-ui/core'
import * as stylesJs from './styles.js'
import './styles.css'
import DialogContainer from './DialogContainer.js'
import { DialogTitle, DialogContent } from './utils'

export default function StartDownload({ dialog, queryData, downloadData, dataSelector }) {
  // state of the dialog, if it is open or not
  const [open, setOpen] = useState(false)
  // information coming from the request
  const data = queryData?.data
  const downloadQuery = queryData?.downloadValue
  // handle close event on click
  const handleClose = () => { setOpen(false) }
  /* usestyles and classes are used to style the app */
  const useStyles = makeStyles(stylesJs)
  const classesJs = useStyles()
  // update number coming from Matching records
  const handle = dialog?.update
  // use effect to check when to open the dialog
  useEffect(() => { if (handle) { setOpen(true) } }, [dialog]) /* eslint-disable-line react-hooks/exhaustive-deps */
  // GUI Dialog

  return (
    <Grid>
      <Dialog maxWidth='lg' onClose={handleClose} open={open}>
        <DialogTitle onClose={handleClose}>Download Records</DialogTitle>
        <DialogContent className={classesJs.dialogContent}>
          {/* GUI Dialog Begins */}
          <DialogContainer data={data} handleClose={handleClose} downloadData={downloadData} downloadQuery={downloadQuery} dataSelector={dataSelector} />
          {/* GUI Dialog Ends */}
        </DialogContent>
      </Dialog>
    </Grid>
  )
}
