// QA PLANS


// // QA Business
// export const businessPlans = [
//     { id: 'business-1k-trial', name: "Trial", records: '1000 records', price: 'Free', yearlyPrice: 'Free', yearlyId: 'business-1k-trial-annual' },
//     {
//         id: 'business-1k-59', name: "1K", records: '1,000 records per month', price: '$59', yearlyPrice: '$637', yearlyId: 'business_test1k_annual'
//     },
//     { id: 'business-10k-159', name: "10K", records: '10,000 records per month', price: '$159', yearlyPrice: '$1717', yearlyId: 'business-test10k-annual' },
//     { id: 'business-100k-449', name: "100K", records: '100,000 records per month', price: '$499', yearlyPrice: '$4849', yearlyId: 'business-test100k-annual' },
//     { id: 'business-1m-1999', name: "1M", records: '1,000,000 records per month', price: '$1999', yearlyPrice: '$21589', yearlyId: 'business-test1m-annual' },
//     { id: 'business-unlimited-monthly', name: "Unlimited", records: 'Unlimited records per month', price: '$5000', yearlyPrice: '$60000', yearlyId: 'business-testunlimitedbusiness-60k' },
// ];


// // QA Property
// export const propertyPlans = [
//     { id: 'property-1k-trial', name: "Trial", records: '1,000 records', price: 'Free', yearlyPrice: 'Free', yearlyId: 'property-1k-trial-yearly' },
//     { id: 'property-1k-119', name: "1K", records: '1,000 records per month', price: '$119', yearlyPrice: '$1285', yearlyId: 'property-test1k-annual' },
//     { id: 'property-10k-349', name: "10K", records: '10,000 records per month', price: '$349', yearlyPrice: '$3769', yearlyId: 'property-test10k-annual' },
//     { id: 'property-100k-899', name: "100K", records: '100,000 records per month', price: '$899', yearlyPrice: '$9709', yearlyId: 'property-test100k-annual' },
//     { id: 'property-1m-3999', name: "1M", records: '1,000,000 records per month', price: '$3999', yearlyPrice: '$43189', yearlyId: 'property-test1m-annual' },
//     { id: 'property-unlimited-monthly', name: "Unlimited", records: 'Unlimited records per month', price: '$5000', yearlyPrice: '$60000', yearlyId: 'property-testunlimitedproperty-60k' }
// ];



// // QA People
// export const peoplePlans = [
//     { id: 'people-100-trial', name: "Trial", records: '100 records', price: 'Free', yearlyPrice: 'Free', yearlyId: 'people-100-trial-annual' },
//     { id: 'people-100-19', name: "100", records: '100 records per month', price: '$19', yearlyPrice: '$205', yearlyId: 'people-test100-annual' },
//     { id: 'people-1k-99', name: "1k", records: '1,000 records per month', price: '$99', yearlyPrice: '$1069', yearlyId: 'people-test1k-annual' },
//     { id: 'people-5k-449', name: "5k", records: '5,000 records per month', price: '$499', yearlyPrice: '$4849', yearlyId: 'people-test5k-annual' },
//     { id: 'people-10k-799', name: "10k", records: '10,000 records per month', price: '$799', yearlyPrice: '$8629', yearlyId: 'people-test10k-annual' },
//     { id: 'people_unlimited_monthly', name: "Unlimited", records: 'Unlimited records per month', price: '$5000', yearlyPrice: '$60000', yearlyId: 'people-testunlimitedpeople-60k' },
// ];



// // QA product
// export const productPlans = [
//     { id: 'product-1k-trial', name: "Trial", records: '1000 records', price: 'Free', yearlyPrice: 'Free', yearlyId: 'product-1k-trial-annual' },
//     { id: 'product-5k-99', name: "5K", records: '5,000 records per month', price: '$99', yearlyPrice: '$1069', yearlyId: 'product-test5k-annual' },
//     { id: 'product-20k-259', name: "20K", records: '20,000 records per month', price: '$259', yearlyPrice: '$2797', yearlyId: 'product-test20k-annual' },
//     { id: 'product-50k-499', name: "50K", records: '50,000 records per month', price: '$499', yearlyPrice: '$5389', yearlyId: 'product-test50k-annual' },
//     { id: 'product-200k-999', name: "200K", records: '200,000 records per month', price: '$999', yearlyPrice: '$10789', yearlyId: 'product-test200k-annual' },
//     { id: 'product-unlimited-monthly', name: "Unlimited", records: 'Unlimited records per month', price: '$5000', yearlyPrice: '$60000', yearlyId: 'product-testunlimitedproduct-60k' },
// ];

// // QA PLANS



// PRODUCTION PLANS

// PRODUCTION
export const businessPlans = [
    { id: 'business-1k-trial', name: "Trial", records: '1000 records', price: 'Free', yearlyPrice: 'Free', yearlyId: 'business-1k-trial-annual' },
    {
        id: 'business-1k-59', name: "1K", records: '1,000 records per month', price: '$59', yearlyPrice: '$637', yearlyId: 'business_1k_annual'
    },
    { id: 'business-10k-159', name: "10K", records: '10,000 records per month', price: '$159', yearlyPrice: '$1717', yearlyId: 'business-10k-annual' },
    { id: 'business-100k-449', name: "100K", records: '100,000 records per month', price: '$499', yearlyPrice: '$4849', yearlyId: 'business-100k-annual' },
    { id: 'business-1m-1999', name: "1M", records: '1,000,000 records per month', price: '$1999', yearlyPrice: '$21589', yearlyId: 'business-1m-annual' },
    { id: 'business-unlimited-monthly', name: "Unlimited", records: 'Unlimited records per month', price: '$5000', yearlyPrice: '$60000', yearlyId: 'business-unlimitedbusiness-60k' },
];



// Production
export const propertyPlans = [
    { id: 'property-1k-trial', name: "Trial", records: '1,000 records', price: 'Free', yearlyPrice: 'Free', yearlyId: 'property-1k-trial-yearly' },
    { id: 'property-1k-119', name: "1K", records: '1,000 records per month', price: '$119', yearlyPrice: '$1285', yearlyId: 'property-1k-annual' },
    { id: 'property-10k-349', name: "10K", records: '10,000 records per month', price: '$349', yearlyPrice: '$3769', yearlyId: 'property-10k-annual' },
    { id: 'property-100k-899', name: "100K", records: '100,000 records per month', price: '$899', yearlyPrice: '$9709', yearlyId: 'property-100k-annual' },
    { id: 'property-1m-3999', name: "1M", records: '1,000,000 records per month', price: '$3999', yearlyPrice: '$43189', yearlyId: 'property-1m-annual' },
    { id: 'property-unlimited-monthly', name: "Unlimited", records: 'Unlimited records per month', price: '$5000', yearlyPrice: '$60000', yearlyId: 'property-unlimitedproperty-60k' }
];





// Production
export const productPlans = [
    { id: 'product-1k-trial', name: "Trial", records: '1000 records', price: 'Free', yearlyPrice: 'Free', yearlyId: 'product-1k-trial-annual' },
    { id: 'product-5k-99', name: "5K", records: '5,000 records per month', price: '$99', yearlyPrice: '$1069', yearlyId: 'product-5k-annual' },
    { id: 'product-20k-259', name: "20K", records: '20,000 records per month', price: '$259', yearlyPrice: '$2797', yearlyId: 'product-20k-annual' },
    { id: 'product-50k-499', name: "50K", records: '50,000 records per month', price: '$499', yearlyPrice: '$5389', yearlyId: 'product-50k-annual' },
    { id: 'product-200k-999', name: "200K", records: '200,000 records per month', price: '$999', yearlyPrice: '$10789', yearlyId: 'product-200k-annual' },
    { id: 'product-unlimited-monthly', name: "Unlimited", records: 'Unlimited records per month', price: '$5000', yearlyPrice: '$60000', yearlyId: 'product-unlimitedproduct-60k' },
];


// Production
export const peoplePlans = [
    { id: 'people-100-trial', name: "Trial", records: '100 records', price: 'Free', yearlyPrice: 'Free', yearlyId: 'people-100-annual' },
    { id: 'people-100-19', name: "100", records: '100 records per month', price: '$19', yearlyPrice: '$205', yearlyId: 'people-100-annual' },
    { id: 'people-1k-99', name: "1k", records: '1,000 records per month', price: '$99', yearlyPrice: '$1,069', yearlyId: 'people-1k-annual' },
    { id: 'people-5k-449', name: "5k", records: '5,000 records per month', price: '$499', yearlyPrice: '$4,849', yearlyId: 'people-5k-annual' },
    { id: 'people-10k-799', name: "10k", records: '10,000 records per month', price: '$799', yearlyPrice: '$8,629', yearlyId: 'people-10k-annual' },
    { id: 'people-unlimited-monthly', name: "Unlimited", records: 'Unlimited records per month', price: '$5000', yearlyPrice: '$60,000', yearlyId: 'people-unlimitedpeople-60k' },
];

// Production Plans