import React, { useState } from 'react'
import copyIcon from "../images/copyIconExpandedRecords.svg";
import styles from "./styles.module.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SnackbarComponent from '../../Snackbar/index'

function Images({ record }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [snackMessage, setSnackMessage] = useState({ type: 'error' })


    const renderImages = () => {
        const imageURLs = record?.imageURLs;
        if (!imageURLs || imageURLs.length === 0) {
            return <span className={styles.noImages}>N/A</span>;
        }

        const endIndex = isExpanded ? imageURLs.length : 3;
        return imageURLs.slice(0, endIndex).map((imageName, index) => {
            return (
                <CopyToClipboard key={index} text={imageName} onCopy={() => setSnackMessage({ type: 'success', myMessage: 'Image copied', update: Date.now() })} className={styles.imageItem}>
                    <div className={styles.imageItem}>
                        <span className={styles.aImages}>{imageName}</span>
                        <img alt="copy icon" className={styles.copyIcon} src={copyIcon} />
                    </div>
                </CopyToClipboard>
            );
        });
    };

    return (
        <section className={styles.containerImages}>
            <h2 className={styles.imageTitle}>Images</h2>
            {renderImages()}
            {record?.imageURLs && record.imageURLs.length > 3 && (
                <button className={styles.expandButton} onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? 'Show less' : `Expand All (${record.imageURLs.length})`}
                </button>
            )}
            <SnackbarComponent snackData={snackMessage} />
        </section>

    );
}

export default Images;
