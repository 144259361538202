import React, { useContext, useState } from "react";
import { NavigationContext } from "../NavigationContext";
import cartShoppingSolid from "./navbarImages/cartShoppingSolid.svg";
import cartShoppingRegular from "./navbarImages/cartshoppingRegular.svg";
import userLogo from "./navbarImages/user.svg";
import houseSolid from "./navbarImages/houseSolid.svg";
import houseRegular from "./navbarImages/houseRegular.svg";
import peopleSolid from "./navbarImages/people-simple-solid.svg";
import peopleRegular from "./navbarImages/people-simple-regular.svg";
import buildingSolid from "./navbarImages/buildingSolid.svg";
import buildingRegular from "./navbarImages/buildingRegular.svg";
import downloadSolid from "./navbarImages/downloadSolid.svg";
import downloadRegular from "./navbarImages/downloadRegular.svg";
import questionMark from "./navbarImages/location-question.svg";
import settingsMarkRegular from "./navbarImages/sliders-regular.svg";
import settingsMarkSolid from "./navbarImages/sliders-solid.svg";

import {
  Toolbar,
  Typography,
  AppBar,
  Menu,
  MenuItem,
  Button,
  IconButton,
  Drawer,
  useMediaQuery,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import customImage from "../img/datafiniti-logo-black.png";
import MenuIcon from "@material-ui/icons/Menu";
import { useQuery } from "react-query";
import { getAccount } from "../../Api/index";
import DrawerContent from "./drawer"; // Import the new component
import useStyles from "./styles"; // Adjust the path as necessary

export default function Navbar() {
  const { navigationValue, setNavigationValue } = useContext(NavigationContext);
  const { data, isLoading } = useQuery("navBarAccount", getAccount);
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { first_name, last_name } = data?.data || {};

  const isMobile = useMediaQuery("(max-width:1425px)");

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    const { localStorage, location } = window;

    // Iterate over the keys in localStorage
    Object.keys(localStorage).forEach(key => {
      // If the key starts with "dfp/" but is not "dfp/email", remove it
      if (key.startsWith("dfp/") && key !== "dfp/email") {
        localStorage.removeItem(key);
      }
    });

    // Redirect to the login page
    location.replace("/home");
  };

  const navigateTo = (path, navigationValue) => {
    history.push(path);
    setNavigationValue(navigationValue);
  };

  const drawer = (
    <DrawerContent
      navigateTo={navigateTo}
      handleDrawerToggle={handleDrawerToggle}
    />
  );

  return (
    <>
      <AppBar position="static" color="inherit" elevation={0}>
        <Toolbar disableGutters={true} className={classes.Toolbar}>
          {isMobile && (
            <>
              <IconButton
                className={classes.menuItem}
                edge="start"
                color="inherit"
                aria-label="menu"
                style={{ marginLeft: "24px", padding: 0, right: "5px" }}
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <img
                src={customImage}
                alt="Custom"
                className={classes.imagePhone}
                onClick={() => window.open("https://www.datafiniti.co/")}
              />
              <Button className={classes.Button} onClick={handleMenu}>
                <img
                  src={userLogo}
                  alt="userLogo"
                  style={{ width: "14px", height: "14px" }}
                />
                <Typography
                  className={classes.Typography}
                  style={{ marginLeft: "5px" }}
                >
                  {isLoading
                    ? "Loading"
                    : `${first_name || ""} ${last_name || ""}`.trim()}
                </Typography>
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                // anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "bottom", horizontal: "left" }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem className={classes.menuItem} onClick={logout}>
                  Log out
                </MenuItem>
              </Menu>
            </>
          )}
          {!isMobile && (
            // CONTAINER FOR BOTH LEFT AND RIGHT BUTTONS
            <div className={classes.buttonsContainer}>
              {/* // LEFT BUTTONS */}
              <div className={classes.leftButtons}>
                <img
                  src={customImage}
                  alt="Custom"
                  className={classes.imageDesktop}
                  onClick={() => window.open("https://www.datafiniti.co/")}
                />

                <Button
                  className={classes.Button}
                  onClick={() => navigateTo("/property-data", "property")}
                >
                  {navigationValue === "property" ||
                    history.location.pathname === "/property-data" ? (
                    <img
                      src={houseSolid}
                      alt="propertyIcon"
                      style={{ width: "14px", height: "14px" }}
                    />
                  ) : (
                    <img
                      src={houseRegular}
                      alt="propertyIcon"
                      style={{ width: "14px", height: "14px" }}
                    />
                  )}
                  <Typography className={classes.Typography}>
                    Property Data
                  </Typography>
                </Button>

                <Button
                  className={classes.Button}
                  onClick={() => navigateTo("/people-data", "people")}
                >
                  {navigationValue === "people" ||
                    history.location.pathname === "/people-data" ? (
                    <img
                      src={peopleSolid}
                      alt="peopleIcon"
                      style={{ width: "14px", height: "14px" }}
                    />
                  ) : (
                    <img
                      src={peopleRegular}
                      alt="peopleIcon"
                      style={{ width: "14px", height: "14px" }}
                    />
                  )}
                  <Typography className={classes.Typography}>
                    People Data
                  </Typography>
                </Button>

                <Button
                  className={classes.Button}
                  onClick={() => navigateTo("/product-data", "product")}
                >
                  {navigationValue === "product" ||
                    history.location.pathname === "/product-data" ? (
                    <img
                      src={cartShoppingSolid}
                      alt="productIcon"
                      style={{ width: "14px", height: "14px" }}
                    />
                  ) : (
                    <img
                      src={cartShoppingRegular}
                      alt="productIcon"
                      style={{ width: "14px", height: "14px" }}
                    />
                  )}
                  <Typography className={classes.Typography}>
                    Product Data
                  </Typography>
                </Button>

                <Button
                  className={classes.Button}
                  onClick={() => navigateTo("/business-data", "business")}
                >
                  {navigationValue === "business" ||
                    history.location.pathname === "/business-data" ? (
                    <img
                      src={buildingSolid}
                      alt="businessLogo"
                      style={{ width: "14px", height: "14px" }}
                    />
                  ) : (
                    <img
                      src={buildingRegular}
                      alt="businessLogo"
                      style={{ width: "14px", height: "14px" }}
                    />
                  )}

                  <Typography className={classes.Typography}>
                    Business Data
                  </Typography>
                </Button>
              </div>

              {/* RIGHT BUTTONS */}
              <div className={classes.rightButtons}>
                <Button
                  className={classes.Button}
                  onClick={() => navigateTo("/download", "download")}
                >
                  {navigationValue === "download" ||
                    history.location.pathname === "/download" ? (
                    <img
                      src={downloadSolid}
                      alt="downloadLogo"
                      style={{ width: "14px", height: "14px" }}
                    />
                  ) : (
                    <img
                      src={downloadRegular}
                      alt="downloadLogo"
                      style={{ width: "14px", height: "14px" }}
                    />
                  )}

                  <Typography className={classes.Typography}>
                    Downloads
                  </Typography>
                </Button>

                <Button
                  className={classes.Button}
                  onClick={() => navigateTo("/settings", "settings")}
                >
                  {navigationValue === "settings" ||
                    history.location.pathname === "/settings" ? (
                    <img
                      src={settingsMarkSolid}
                      alt="downloadLogo"
                      style={{ width: "14px", height: "14px" }}
                    />
                  ) : (
                    <img
                      src={settingsMarkRegular}
                      alt="downloadLogo"
                      style={{ width: "14px", height: "14px" }}
                    />
                  )}

                  <Typography className={classes.Typography}>
                    Settings
                  </Typography>
                </Button>
                <Button
                  className={classes.Button}
                  onClick={() =>
                    window.open(
                      "https://docs.datafiniti.co/"
                    )
                  }
                >
                  <img
                    src={questionMark}
                    alt="supportLogo"
                    style={{ width: "14px", height: "14px" }}
                  />
                  <Typography className={classes.Typography}>
                    Documentation
                  </Typography>
                </Button>
                {/* <Button
                  className={classes.Button}
                  onClick={() =>
                    window.open(
                      "https://docs.datafiniti.co/docs/api-introduction"
                    )
                  }
                >
                  <img
                    src={codeIcon}
                    alt="codeLogo"
                    style={{ width: "14px", height: "14px" }}
                  />
                  <Typography className={classes.Typography}>
                    Developer Docs
                  </Typography>
                </Button> */}

                <Button
                  className={classes.LastButtonRight}
                  onClick={handleMenu}
                  open={open}
                  onClose={handleClose}
                >
                  <img
                    src={userLogo}
                    alt="userLogo"
                    style={{ width: "14px", height: "14px" }}
                  />
                  <Typography
                    className={classes.Typography}
                    style={{ marginLeft: "5px" }}
                  >
                    {isLoading
                      ? "Loading"
                      : `${first_name || ""} ${last_name || ""}`.trim()}
                  </Typography>
                </Button>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  // anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  transformOrigin={{ vertical: "bottom", horizontal: "left" }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem className={classes.menuItem} onClick={logout}>
                    Log out
                  </MenuItem>
                </Menu>
              </div>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>
    </>
  );
}
