/* global localStorage */
/* React import */
import React from 'react';
/* Router imports */
import { Route, Redirect } from 'react-router-dom';

/* PrivateRoute */
const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const hasSession = !!localStorage.getItem('dfp/session');
        const isRootPath = props.location.pathname === '/';

        if (hasSession) {
          // If it's the root path and user is authenticated, redirect to dashboard
          if (isRootPath) {
            return <Redirect to="/property-data" />;

          }
          // Otherwise, render the requested component
          return <Component {...props} />;
        } else {
          // If user is not authenticated, redirect to login
          return <Redirect to={{ pathname: '/home' }} />;
        }
      }}
    />
  );
};


export default PrivateRoute;