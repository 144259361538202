import React from "react";
import styles from "../modal.module.css";
import {
  mostCommonFieldsList,
  allTheOtherFields,
} from "./RequiredFieldsValues";

function RequiredFields({ requiredFieldsFilter, handleChangeRequiredFields }) {
  return (
    <section className={styles.requiredFieldsSection}>
      <h1 className={styles.requiredFieldsTitle}>Required Fields</h1>
      <p className={styles.mostRequiredFieldsTitle}>
        Select fields that must be filled out in each record.
      </p>

      <div style={{ marginBottom: "14px" }}>
        <h2 className={styles.requiredFieldsh2s}>Common fields</h2>
        <div className={styles.requiredFieldsRow}>
          {mostCommonFieldsList.map((field, index) => (
            <div key={index} className={styles.checkboxContainer}>
              <input
                type="checkbox"
                id={field.value}
                name={field.value}
                className={styles.customCheckbox}
                checked={requiredFieldsFilter.includes(field.value)}
                onChange={() => handleChangeRequiredFields(field.value)}
              />
              <label htmlFor={field.value} className={styles.customLabel}>
                {field.label}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div>
        <h2 className={styles.requiredFieldsh2s}>All other fields</h2>
        <div className={styles.requiredFieldsRow}>
          {allTheOtherFields.map((field, index) => (
            <div key={index} className={styles.checkboxContainer}>
              <input
                type="checkbox"
                id={field.value}
                name={field.value}
                className={styles.customCheckbox}
                checked={requiredFieldsFilter.includes(field.value)}
                onChange={() => handleChangeRequiredFields(field.value)}
              />
              <label htmlFor={field.value} className={styles.customLabel}>
                {field.label}
              </label>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default RequiredFields;
