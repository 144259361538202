const buildQuery = (propertyType, mostRecentStatus, allFilters) => {

  let queryParts = [];

  // Handling allFilters first
  if (allFilters) {

    if (allFilters.streetAddress && allFilters.streetAddress.length > 0) {
      const streetAddressQuery = `address:(${allFilters.streetAddress.map(addr => `"${addr}"`).join(" OR ")})`;
      queryParts.push(streetAddressQuery);
    }
    if (allFilters.city && allFilters.city.length > 0) {
      const cityQuery = `city:(${allFilters.city.map(city => `"${city}"`).join(" OR ")})`;
      queryParts.push(cityQuery);
    }
    if (allFilters.stateProvince && allFilters.stateProvince.length > 0) {
      const stateProvinceQuery = `province:(${allFilters.stateProvince.map(state => `"${state}"`).join(" OR ")})`;
      queryParts.push(stateProvinceQuery);
    }
    // Handling postalCode as an array
    if (allFilters.postalCode && allFilters.postalCode.length > 0) {
      const postalCodeQuery = `postalCode:(${allFilters.postalCode.map(code => `"${code}"`).join(" OR ")})`;
      queryParts.push(postalCodeQuery);
    }
    if (allFilters.country) {
      queryParts.push(`country:"${allFilters.country}"`);
    }
    if (allFilters.price) {
      const { minValue, maxValue, currency } = allFilters.price;
      let priceQuery = "";

      if (minValue || maxValue) {
        // Construct the range part of the query
        const minPart = minValue ? minValue : "*";
        const maxPart = maxValue ? maxValue : "*";
        priceQuery = `mostRecentPriceAmount:[${minPart} TO ${maxPart}]`;

        // Add currency to the query if specified
        if (currency) {
          priceQuery += ` AND mostRecentPriceCurrency:${currency}`;
        }
        queryParts.push(`${priceQuery}`);
      }
    }

    if (allFilters.rentalPrice) {
      const { minValue, maxValue, currency } = allFilters.rentalPrice;
      let rentalPriceQuery = "";

      if (minValue || maxValue) {
        // Construct the range part of the query
        const minPart = minValue ? minValue : "*";
        const maxPart = maxValue ? maxValue : "*";
        rentalPriceQuery = `mostRecentRentalPriceAmount:[${minPart} TO ${maxPart}]`;

        // Add currency to the query if specified
        if (currency) {
          rentalPriceQuery += ` AND mostRecentPriceCurrency:${currency}`;
        }
        queryParts.push(`${rentalPriceQuery}`);
      }
    }

    // Amenity to Query Field Mapping
    const amenityToQueryField = {
      beds: "numBedroom",
      bath: "numBathroom",
      floors: "numFloor",
      parkingSpaces: "numParkingSpaces",
      rooms: "numRoom",
      units: "numUnit",
      // Add other mappings as needed
    };

    // Handling amenities
    if (allFilters.amenities) {
      Object.entries(allFilters.amenities).forEach(([amenity, range]) => {
        const queryField = amenityToQueryField[amenity] || amenity;
        if (range.min || range.max) {
          const minPart = range.min ? range.min : "*";
          const maxPart = range.max ? range.max : "*";
          let amenityQuery = `${queryField}:[${minPart} TO ${maxPart}]`;
          queryParts.push(amenityQuery);
        }
      });
    }

    if (allFilters.amenities && allFilters.amenities.HVAC.length > 0) {
      // Constructing the HVAC part of the query
      const hvacQuery = `hvacTypes:(${allFilters.amenities.HVAC.map(
        (hvac) => `"${hvac}"`
      ).join(" OR ")})`;
      queryParts.push(hvacQuery);
    }

    if (allFilters.sizeUnits) {
      const { floorSize, lotSize } = allFilters.sizeUnits;

      // Handle floorSize
      if (floorSize && (floorSize.min || floorSize.max)) {
        const minPart = floorSize.min ? floorSize.min : "*";
        const maxPart = floorSize.max ? floorSize.max : "*";
        const unitPart = floorSize.unit ? `"${floorSize.unit}"` : '"sq ft"'; // default unit with quotes
        let floorSizeQuery = `floorSizeValue:[${minPart} TO ${maxPart}] AND floorSizeUnit:${unitPart}`;
        queryParts.push(floorSizeQuery);
      }

      // Handle lotSize
      if (lotSize && (lotSize.min || lotSize.max)) {
        const minPart = lotSize.min ? lotSize.min : "*";
        const maxPart = lotSize.max ? lotSize.max : "*";
        const unitPart = lotSize.unit ? `"${lotSize.unit}"` : '"sq ft"'; // default unit with quotes
        let lotSizeQuery = `lotSizeValue:[${minPart} TO ${maxPart}] AND lotSizeUnit:${unitPart}`;
        queryParts.push(lotSizeQuery);
      }
    }

    // Handling Exterior
    if (
      allFilters.amenities &&
      allFilters.amenities.exterior &&
      allFilters.amenities.exterior.length > 0
    ) {
      const exteriorQuery = `exteriorFeatures:(${allFilters.amenities.exterior
        .map((ext) => `"${ext}"`)
        .join(" OR ")})`;
      queryParts.push(exteriorQuery);
    }

    // Handling Roofing
    if (
      allFilters.amenities &&
      allFilters.amenities.roofing &&
      allFilters.amenities.roofing.length > 0
    ) {
      const roofingQuery = `roofing:(${allFilters.amenities.roofing
        .map((roof) => `"${roof}"`)
        .join(" OR ")})`;
      queryParts.push(roofingQuery);
    }
  }

  // Handling required fields
  if (allFilters?.requiredFields && allFilters?.requiredFields.length > 0) {
    // For each required field, add a query part
    allFilters.requiredFields.forEach(field => {
      queryParts.push(`${field}:*`);
    });
  }

  if (allFilters?.mostRecentStatusDate) {
    const { startDate, endDate } = allFilters.mostRecentStatusDate;
    let dateQuery = 'mostRecentStatusDate:';

    if (startDate || endDate) {
      const start = startDate ? startDate : '*';
      const end = endDate ? endDate : '*';
      dateQuery += `[${start} TO ${end}]`;
      queryParts.push(dateQuery);
    }
  }

  if (allFilters?.mostRecentStatusFirstDateSeen) {
    const { startDate, endDate } = allFilters.mostRecentStatusFirstDateSeen;
    let dateQuery = 'mostRecentStatusFirstDateSeen:';

    if (startDate || endDate) {
      const start = startDate ? startDate : '*';
      const end = endDate ? endDate : '*';
      dateQuery += `[${start} TO ${end}]`;
      queryParts.push(dateQuery);
    }
  }
  // Handling mostRecentPriceDate
  if (allFilters?.mostRecentPriceDate) {
    const { startDate, endDate } = allFilters.mostRecentPriceDate;
    let dateQuery = 'mostRecentPriceDate:';

    if (startDate || endDate) {
      const start = startDate ? startDate : '*';
      const end = endDate ? endDate : '*';
      dateQuery += `[${start} TO ${end}]`;
      queryParts.push(dateQuery);
    }
  }

  if (allFilters?.recordRecency) {
    const { dateAdded, dateUpdated } = allFilters.recordRecency;

    if (dateAdded) {
      const dateAddedQuery = `dateAdded:[${dateAdded} TO *]`;
      queryParts.push(dateAddedQuery);
    }

    if (dateUpdated) {
      const dateUpdatedQuery = `dateUpdated:[${dateUpdated} TO *]`;
      queryParts.push(dateUpdatedQuery);
    }
  }

  // Handling propertyType
  if (propertyType && propertyType.some((pt) => pt !== "Any")) {
    const filteredPropertyTypes = propertyType.filter((pt) => pt !== "Any");
    let propertyTypeQuery =
      filteredPropertyTypes.length > 1
        ? `propertyType:(${filteredPropertyTypes
          .map((pt) => `"${pt}"`)
          .join(" OR ")})`
        : `propertyType:"${filteredPropertyTypes[0]}"`;
    queryParts.push(propertyTypeQuery);
  }

  // Handling mostRecentStatus
  if (mostRecentStatus && mostRecentStatus?.some((ms) => ms !== "Any")) {
    const filteredStatuses = mostRecentStatus.filter((ms) => ms !== "Any");
    let mostRecentStatusQuery =
      filteredStatuses.length > 1
        ? `mostRecentStatus:(${filteredStatuses
          .map((ms) => `"${ms}"`)
          .join(" OR ")})`
        : `mostRecentStatus:"${filteredStatuses[0]}"`;
    queryParts.push(mostRecentStatusQuery);
  }

  // Return the combined query or default if no parameters are set
  return queryParts.length > 0 ? queryParts.join(" AND ") : "keys:*";
};

export default buildQuery;