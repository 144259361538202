export const businessArray = [
  {
    name: 'address',
    type: 'text'
  },
  {
    name: 'categories',
    type: 'text'
  },
  {
    name: 'city',
    type: 'text'
  },
  {
    name: 'claimed',
    type: 'text'
  },
  {
    name: 'country',
    type: 'country'
  },
  {
    name: 'cuisines',
    type: 'text'
  },
  {
    name: 'dateAdded',
    type: 'date'
  },
  {
    name: 'dateUpdated',
    type: 'date'
  },
  {
    name: 'descriptions',
    type: 'nested'
  },
  {
    name: 'domains',
    type: 'text'
  },
  {
    name: 'emails',
    type: 'text'
  },
  {
    name: 'facebookPageURL',
    type: 'text'
  },
  {
    name: 'faxes',
    type: 'text'
  },
  {
    name: 'features',
    type: 'nested'
  },
  {
    name: 'geoLocation',
    type: 'text'
  },
  {
    name: 'hours',
    type: 'nested'
  },
  {
    name: 'imageURLs',
    type: 'text'
  },
  {
    name: 'isClosed',
    type: 'text'
  },
  {
    name: 'keys',
    type: 'text'
  },
  {
    name: 'languagesSpoken',
    type: 'text'
  },
  {
    name: 'latitude',
    type: 'text'
  }, {
    name: 'longitude',
    type: 'text'
  }, {
    name: 'menuPageURL',
    type: 'text'
  }, {
    name: 'menus',
    type: 'nested'
  }, {
    name: 'name',
    type: 'text'
  }, {
    name: 'neighborhoods',
    type: 'text'
  }, {
    name: 'numEmployeesMin',
    type: 'text'
  }, {
    name: 'numEmployeesMax',
    type: 'text'
  },
  {
    name: 'numRoom',
    type: 'text'
  },
  {
    name: 'paymentTypes',
    type: 'text'
  },
  {
    name: 'people',
    type: 'nested'
  },
  {
    name: 'phones',
    type: 'text'
  },
  {
    name: 'postalCode',
    type: 'text'
  },
  {
    name: 'priceRangeCurrency',
    type: 'text'
  },
  {
    name: 'priceRangeMin',
    type: 'text'
  },
  {
    name: 'priceRangeMax',
    type: 'text'
  },
  {
    name: 'primaryCategories',
    type: 'primaryBusiness'
  },
  {
    name: 'productsOrServices',
    type: 'text'
  },
  {
    name: 'province',
    type: 'state'
  },
  {
    name: 'revenueCurrency',
    type: 'text'
  },
  {
    name: 'revenueMin',
    type: 'text'
  },
  {
    name: 'revenueMax',
    type: 'text'
  },
  {
    name: 'reviews',
    type: 'nested'
  },
  {
    name: 'rooms',
    type: 'nested'
  },
  {
    name: 'sic',
    type: 'text'
  },
  {
    name: 'sourceURLs',
    type: 'text'
  },
  {
    name: 'twitter',
    type: 'text'
  },
  {
    name: 'websites',
    type: 'text'
  },
  {
    name: 'yearIncorporated',
    type: 'text'
  },
  {
    name: 'yearOpened',
    type: 'text'
  }
]

export const primaryBusiness = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'Accommodation & Food Services',
    value: 'Accommodation & Food Services'
  },
  {
    name: 'Administrative Support Waste Management & Remediation',
    value: 'Administrative Support Waste Management & Remediation'
  },
  {
    name: 'Agriculture',
    value: 'Agriculture'
  },
  {
    name: 'Arts Entertainment & Recreation',
    value: 'Arts Entertainment & Recreation'
  },
  {
    name: 'Construction',
    value: 'Construction'
  },
  {
    name: 'Educational Services',
    value: 'Educational Services'
  },
  {
    name: 'Finance & Insurance',
    value: 'Finance & Insurance'
  },
  {
    name: 'Healthcare',
    value: 'Healthcare'
  },
  {
    name: 'Information',
    value: 'Information'
  },
  {
    name: 'Management of Companies & Enterprises',
    value: 'Management of Companies & Enterprises'
  },
  {
    name: 'Manufacturing',
    value: 'Manufacturing'
  },
  {
    name: 'Mining Quarrying & Oil & Gas Extraction',
    value: 'Mining Quarrying & Oil & Gas Extraction'
  },
  {
    name: 'Other Services',
    value: 'Other Services'
  },
  {
    name: 'Professional Scientific & Technical Services',
    value: 'Professional Scientific & Technical Services'
  },
  {
    name: 'Public Administration',
    value: 'Public Administration'
  },
  {
    name: 'Real Estate Rental & Leasing',
    value: 'Real Estate Rental & Leasing'
  },
  {
    name: 'Retail',
    value: 'Retail'
  },
  {
    name: 'Transportation & Warehousing',
    value: 'Transportation & Warehousing'
  },
  {
    name: 'Utilities',
    value: 'Utilities'
  }
]

export const hoursList = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'day',
    value: 'day',
    type: 'text'
  },
  {
    name: 'dept',
    value: 'dept',
    type: 'text'
  },
  {
    name: 'meal',
    value: 'meal',
    type: 'text'
  },
  {
    name: 'hour',
    value: 'hour',
    type: 'text'
  }
]
export const menuList = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'amountMin',
    value: 'amountMin',
    type: 'text'
  },
  {
    name: 'amountMax',
    value: 'amountMax',
    type: 'text'
  },
  {
    name: 'category',
    value: 'category',
    type: 'text'
  },
  {
    name: 'currency',
    value: 'currency',
    type: 'text'
  },
  {
    name: 'dateSeen',
    value: 'dateSeen',
    type: 'date'
  },
  {
    name: 'name',
    value: 'name',
    type: 'text'
  },
  {
    name: 'description',
    value: 'description',
    type: 'text'
  },
  {
    name: 'sourceURLs',
    value: 'sourceURLs',
    type: 'text'
  }
]

export const peopleList = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'dateSeen',
    value: 'dateSeen',
    type: 'date'
  },
  {
    name: 'email',
    value: 'email',
    type: 'text'
  },
  {
    name: 'name',
    value: 'name',
    type: 'text'
  },
  {
    name: 'title',
    value: 'title',
    type: 'text'
  }
]

export const reviewsListBusiness = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'date',
    value: 'date',
    type: 'date'
  },
  {
    name: 'dateSeen',
    value: 'dateSeen',
    type: 'date'
  },
  {
    name: 'category',
    value: 'category',
    type: 'text'
  },
  {
    name: 'doRecommend',
    value: 'doRecommend',
    type: 'text'
  },
  {
    name: 'id',
    value: 'id',
    type: 'text'
  },
  {
    name: 'rating',
    value: 'rating',
    type: 'text'
  },
  {
    name: 'sourceURLs',
    value: 'sourceURLs',
    type: 'text'
  },
  {
    name: 'title',
    value: 'title',
    type: 'text'
  },
  {
    name: 'username',
    value: 'username',
    type: 'text'
  }
]

export const roomsList = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'amountMin',
    value: 'amountMin',
    type: 'text'
  },
  {
    name: 'amountMax',
    value: 'amountMax',
    type: 'text'
  },
  {
    name: 'capacity',
    value: 'capacity',
    type: 'text'
  },
  {
    name: 'currency',
    value: 'currency',
    type: 'text'
  },
  {
    name: 'dateSeen',
    value: 'dateSeen',
    type: 'date'
  },
  {
    name: 'roomType',
    value: 'roomType',
    type: 'text'
  }
]
