// Api data styles
// Text Field Container Styles
export const textFieldContainer = {
  backgroundColor: '#FFFFFF',
  height: '13rem',
  width: '100%'
}
// input styles
export const inputApiSearch = {
  border: 'none',
  width: '80%',
  marginLeft: '1rem',
  marginTop: '1rem',
  fontFamily: 'roboto',
  fontSize: '20px',
  resize: 'none',
  outline: 'none',
  '@media (max-width: 1250px)': { width: '70%', },
  '@media (max-width: 1050px)': { width: '50%', },
  '@media (max-width: 320px)': { fontSize: '17px' }
}
// button styles
export const buttonSearch = {
  marginTop: '13px',
  backgroundColor: '#3F3B88',
  cursor: 'pointer',
  width: '140px',
  textTransform: 'none',
  fontSize: '18px',
  fontWeight: 500,
  fontFamily: 'Roboto',
  color: '#FFFFFF',
  marginRight: '16px',
  '@media (max-width: 769px)': { width: '110px', marginRight: '8px' }
}

