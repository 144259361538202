import React from 'react';
import styles from './styles.module.css';
import DatafinitiLogo from './images/datafiniti-logo-white.svg';
/* router utils imports */
import { useHistory } from "react-router-dom";

function Home() {
    /* This action is used to redirect */
    const history = useHistory();
    /* Onclick event to go to the SignUp page */
    const goToLogin = () => {
        const path = "/login";
        history.push(path);
    };
    const goToSignUp = () => {
        const path = "/sign-up";
        history.push(path);
    };

    return (
        <section className={styles.mainContainer}>
            <div className={styles.background}></div>
            <div className={styles.contentContainer}>
                <img src={DatafinitiLogo} className={styles.imageDatafiniti} alt="Datafiniti Logo" />
                <div className={styles.buttonContainer}>
                    <button className={styles.loginButton} onClick={goToLogin}>Login</button>
                    <button className={styles.signUpButton} onClick={goToSignUp}>Sign up</button>
                </div>
                <div className={styles.policyContainer}>
                    <a rel="noopener noreferrer" target="_blank" href="https://datafiniti.co/terms/" className={styles.policy}>
                        Terms of use
                    </a>
                    {" "}
                    <a rel="noopener noreferrer" target="_blank" href="https://datafiniti.co/privacy-policy/" className={styles.policy}>
                        Privacy policy
                    </a>
                </div>
            </div>
        </section>
    );
}

export default Home;
