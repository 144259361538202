import React, { useState } from "react";
import styles from "./styles.module.css";
import useStore from "../Store/Store";
import downloadPurple from "./images/DownloadSVG.svg";
import ExampleSearches from "./ExampleSearches/ExampleSearches";
import SearchResults from "./SearchResults/SearchResults";
import arrowLeft from "./images/arrowLeft.svg";
import arrowRight from "./images/arrowRight.svg";
import DownloadModal from '../DownloadDr6/DownloadModal'
import { useEffect } from "react";
import { getAccount } from "../../Api/index";
import { useQuery } from "react-query";

function SearchResult() {
  const { data: AccountData, isLoading, refetch } = useQuery("account", getAccount);

  const { dataResults, isQueryLoading, page, setPage, queryStore, } = useStore((state) => state);

  const [calculatedCredits, setCalculatedCredits] = useState(null);

  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const dataToDownload = dataResults?.data
  const queryToDownload = queryStore?.query
  const RECORDS_PER_PAGE = 50;
  const TOTAL_NUMBER_RECORDS = dataResults?.data?.num_found;
  const isPreviousDisabled = page <= 1;
  const totalNumberOfPages = Math.ceil(TOTAL_NUMBER_RECORDS / RECORDS_PER_PAGE);
  const isNextDisabled = page >= totalNumberOfPages;
  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(RECORDS_PER_PAGE);


  useEffect(() => {
    const processPlans = () => {
      if (!AccountData?.data?.plans) return null;

      AccountData.data.plans.forEach(plan => {
        if (plan.id && plan.id.includes("property")) {
          const credits = plan?.credits_allotted - AccountData?.data?.property_credits_used;
          setCalculatedCredits(credits);
          return; // Exit the loop after setting the value
        }
      });
    };

    processPlans();
  }, [AccountData, dataResults]);


  useEffect(() => {
    // Call refetch whenever dataResults changes
    refetch();
  }, [dataResults, refetch]);


  const toggleDownloadModal = () => {
    if (queryToDownload && !isQueryLoading) {
      setIsDownloadModalVisible(!isDownloadModalVisible);
    }
  };

  useEffect(() => {
    const newStartIndex = (page - 1) * RECORDS_PER_PAGE + 1;
    const newEndIndex = Math.min(
      newStartIndex + RECORDS_PER_PAGE - 1,
      TOTAL_NUMBER_RECORDS
    );

    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  }, [page, RECORDS_PER_PAGE, TOTAL_NUMBER_RECORDS]);

  const goToNextPage = () => {
    if (page < totalNumberOfPages) {
      setPage(page + 1);
    }
  };

  const goToPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const downloadStyle = isQueryLoading || !queryToDownload
    ? { cursor: "not-allowed", opacity: 0.5 }
    : { cursor: "pointer" };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <span className={styles.searchResultTitle}>Search results</span>

          <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", rowGap: "2.5px" }}>
            <div style={{ marginRight: "20px" }}>
              <span className={styles.totalMatchesLabel}>
                Total available matches:
              </span>
              <span className={styles.totalMatchesValue} htmlFor="">
                {isQueryLoading ? (
                  <span>Loading...</span>
                ) : dataResults?.data?.num_found ? (
                  new Intl.NumberFormat("en-US", {
                    style: "decimal",
                    useGrouping: true,
                  }).format(
                    dataResults?.data?.num_found
                  )
                ) : (
                  "N/A" // Display "N/A" if there's no data
                )}
              </span>
            </div>
            <div style={{ marginRight: "20px" }}>
              <span
                style={{ color: "#000000", fontSize: "12px", marginRight: "4px" }}>
                Available record credits:
              </span>
              <span
                style={{ color: "#065E88", fontSize: "14px", fontWeight: "bold", }}>
                {isLoading
                  ? "Loading..."
                  : AccountData?.data?.role === "admin"
                    ? "Admin"
                    : calculatedCredits}
              </span>
            </div>
            <div>
              <span
                onClick={queryToDownload ? toggleDownloadModal : undefined}
                className={styles.downloadLabel}
                style={downloadStyle}
              >
                Download
              </span>
              <img
                onClick={queryToDownload ? toggleDownloadModal : undefined}
                style={{ alignSelf: "flex-start", ...downloadStyle }}
                src={downloadPurple}
                alt="upload"
                className={styles.downloadIcon}
              />
            </div>
          </div>
        </div>
        {dataResults?.data ? (
          <div style={{ alignSelf: "flex-end", display: "flex", alignItems: "center", }}>
            <span style={{ fontSize: "12px" }}>Showing records:</span>
            <span style={{ fontSize: "12px", marginRight: "16px", marginLeft: "8px", }}>
              {startIndex} - {endIndex}
            </span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                onClick={
                  !(isPreviousDisabled || isQueryLoading)
                    ? goToPreviousPage
                    : undefined
                }
                src={arrowLeft}
                alt="Previous"
                style={{
                  marginRight: "8px",
                  height: "14px",
                  width: "14px",
                  cursor:
                    isPreviousDisabled || isQueryLoading
                      ? "not-allowed"
                      : "pointer",
                  opacity: isPreviousDisabled || isQueryLoading ? 0.5 : 1, // Adjust opacity based on disabled state or loading state
                }}
              />
              <img
                onClick={
                  !(isNextDisabled || isQueryLoading) ? goToNextPage : undefined
                }
                src={arrowRight}
                alt="Next Page"
                style={{
                  height: "14px",
                  width: "14px",
                  cursor:
                    isNextDisabled || isQueryLoading
                      ? "not-allowed"
                      : "pointer",
                  opacity: isNextDisabled || isQueryLoading ? 0.5 : 1, // Adjust opacity based on disabled state or loading state
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
      {dataResults?.data ? <SearchResults /> : <ExampleSearches />}
      {isDownloadModalVisible && (
        <DownloadModal
          onClose={toggleDownloadModal}
          totalRecords={TOTAL_NUMBER_RECORDS}
          dataToDownload={dataToDownload}
          queryToDownload={queryToDownload}
        />
      )}
    </div>
  );
}

export default SearchResult;
