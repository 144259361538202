import React, { useEffect } from "react";
import styles from "../../modal.module.css";
import caretDown from "../../../images/caretDownGeneric.svg";
import { countryOptions } from './utils/countries'
import useFilterStore from "../../../../Store/filterStore"; // Adjust the path as needed
import Province from "./province";
import City from "./city";
import Address from "./address";
import PostalCode from "./postalCode";

function LocationSection({
  localFilters,
  setLocalFilters,
  setLocationChanged
}) {
  const { allFilters } = useFilterStore();
  const { streetAddress, city, stateProvince, postalCode, country } =
    allFilters;

  // Update local state when global state changes
  useEffect(() => {
    setLocalFilters({
      streetAddress: streetAddress,
      city: city,
      stateProvince: stateProvince,
      postalCode: postalCode,
      country: country,
    });
  }, [
    streetAddress,
    city,
    stateProvince,
    postalCode,
    country,
    setLocalFilters,
  ]);


  // Handlers for each input
  const handleLocationFieldsChange = (e) => {
    const { id, value } = e.target;
    setLocationChanged(true);
    setLocalFilters((prevFilters) => ({
      ...prevFilters,
      [id]: value,
    }));
  };

  return (
    <section>
      <form className={styles.locationSection}>
        <span className={styles.sectionTitle}>Location</span>
        <div className={styles.inputGroup}>
          {/* Street Address */}
          <Address setLocalFilters={setLocalFilters} setLocationChanged={setLocationChanged} />
          {/* City */}
          <City setLocalFilters={setLocalFilters} setLocationChanged={setLocationChanged} />
          {/* State or Province */}
          <Province setLocalFilters={setLocalFilters} setLocationChanged={setLocationChanged} />

          {/* Postal Code */}
          <PostalCode setLocalFilters={setLocalFilters} setLocationChanged={setLocationChanged} />
          {/* Country */}
          <div className={styles.inputColumn} style={{ marginTop: "10px" }}>
            <span className={styles.labelLocation} htmlFor="country">
              Country
            </span>
            <div>
              <select
                className={styles.customSelectLocation}
                id="country"
                value={localFilters.country}
                onChange={handleLocationFieldsChange}
              >

                <option value="">Any</option>
                {countryOptions.map((countryOption, index) => (
                  <option
                    className={countryOption === "--" ? styles.separatorOption : styles.option}
                    key={index}
                    value={countryOption === "--" ? "" : countryOption}
                    disabled={countryOption === "--"}
                  >
                    {countryOption === "--" ? "-----" : countryOption}
                  </option>
                ))}
              </select>
              <img src={caretDown} alt="arrow" className={styles.customArrow} />
            </div>
          </div>
        </div>
      </form>
      <hr className={styles.hrStyle} />
    </section>
  );
}

export default LocationSection;
