/* global localStorage */
/* React import */
import React from 'react'
/* Router imports */
import { Route, Redirect } from 'react-router-dom'

/* PublicRoute */
const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const hasSession = !!localStorage.getItem('dfp/session');

        return !hasSession
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/home' }} />;
      }}
    />
  );
};


export default PublicRoute