import React from 'react'
import styles from "./styles.module.css";

function Exterior({ record }) {

    // Join the exterior construction array elements with a comma if it's an array
    const exteriorConstruction = Array.isArray(record?.exteriorConstruction)
        ? record.exteriorConstruction.join(', ')
        : 'N/A';

    // Assuming record?.exteriorFeatures and record?.roofing are handled similarly
    const exteriorFeatures = Array.isArray(record?.exteriorFeatures)
        ? record.exteriorFeatures.join(', ')
        : 'N/A';
    const roofing = Array.isArray(record?.roofing)
        ? record.roofing.join(', ')
        : 'N/A';

    return (
        <div>
            <span className={styles.exteriorTitle}>Exterior</span>
            <div className={styles.exteriorContainer}>
                <span className={styles.exteriorTitleValues}>Construction</span>
                <div className={styles.exteriorValuesContainer}>
                    {exteriorConstruction}
                </div>
            </div>
            <div className={styles.exteriorContainer}>
                <span className={styles.exteriorTitleValues}>Features</span>
                <div className={styles.exteriorValuesContainer}>
                    {exteriorFeatures}
                </div>
            </div>
            <div className={styles.exteriorContainer}>
                <span className={styles.exteriorTitleValues}>Roofing</span>
                <div className={styles.exteriorValuesContainer}>
                    {roofing}
                </div>
            </div>
        </div>
    )
}

export default Exterior