/* React import */
import React, { useState, useMemo, useEffect } from "react";
import { UserContext } from "./Frontend/UserContext";
/* Router import */
import { BrowserRouter as Router, Switch } from "react-router-dom";
/* Components and css import */
// import Login from "./Frontend/Login";
import Home from "./Frontend/HomeDR12";
import Login from "./Frontend/LoginDR12/Login.jsx";
import StepOne from "./Frontend/SignUpDr12/index.jsx";
import Dashboard from "./Frontend/DashboardMain/index";
import "./App.css";
import ForgotPassword from "./Frontend/ForgotPassword";
import resetpassword from "./Frontend/ResetPassword";
import { PrivateRoute, PublicRoute } from "./Frontend/Routes";
// get account and axios
import { getAccount } from "./Api";
import { useQuery } from "react-query";

export default function App() {
  let session = localStorage.getItem("dfp/session");
  let oldPlan = localStorage.getItem("dfp/plan");
  const { data: accountInfo } = useQuery("accountApp", getAccount, {
    enabled: session,
  });
  const accountPlan = accountInfo?.data?.plan_id;

  // user context for routes
  const [value, setValue] = useState(null);

  const providerValue = useMemo(() => ({ value, setValue }), [value, setValue]);
  useEffect(() => {
    // boot(); // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (session && accountPlan) {
      if (oldPlan !== accountPlan) {
        localStorage.setItem("dfp/plan", accountPlan);
      }
    }
    // eslint-disable-next-line
  }, [oldPlan]);

  return (
    /* Router public and private */
    <Router>
      {/* The switch looks through its children Routes and
            renders the first one that matches the current URL */}
      <UserContext.Provider value={providerValue}>
        <Switch>
          {/* Public Routes */}
          <PublicRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <PublicRoute exact path="/reset-password" component={resetpassword} />
          <PublicRoute path="/sign-up" component={StepOne} />
          {/* <PublicRoute path="/sign-up-account" component={StepTwo} /> */}
          {/* <PublicRoute path="/sign-up-plans" component={StepThreeSignUp} /> */}
          {/* <PublicRoute path="/sign-up-billing" component={StepFourSignUp} /> */}

          {/* <PublicRoute path="/signup" component={SignUpFormStep1} /> */}
          {/* <PublicRoute path="/signup-token" component={StepTwoSignUp} /> */}
          <PublicRoute path="/login" component={Login} />
          <PublicRoute path="/home" component={Home} />
          {/* Private Routes */}
          <PrivateRoute path="/" component={Dashboard} />
        </Switch>
      </UserContext.Provider>
    </Router>
  );
}
