import React from 'react'
import { Grid, IconButton, DialogContent, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Rating } from '@material-ui/lab'

export default function PropertyRecords({ open, handleClose, infoModel, mapView, priceState, previousPrice, nextPrice, descriptionState, previousDescription, nextDescription, reviewState, previousReview, nextReview, featureSliceState, nextFeature, previousFeature }) {
    const model = mapView ? infoModel : infoModel?.column
    // Google maps variables
    const zoom = 'zoom=13'
    const size = 'size=160x160'
    const maptype = 'maptype=roadmap'
    return (
        <Dialog className='dialog-material' open={open} maxWidth='xl' onClose={handleClose} arialabelledby='responsive-dialog-title'>
            <DialogContent className='dialog-material'>
                <Grid>
                    <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'><label className='title-business-modal'>Expanded Record</label>
                        <IconButton style={{ marginTop: '0px' }} onClick={handleClose}><CloseIcon /></IconButton>
                    </Grid>
                    <Grid style={{ marginTop: '9px' }} container direction='row' justifyContent='space-between' alignItems='flex-start'>
                        <div className='name-business-modal'>{!model?.address ? 'N/A' : model?.address}</div>
                        <div>
                            <div className='expanded-record-data' style={{ marginRight: '30px', display: 'inline' }}>Added:{' '}{moment(model?.dateAdded).format('MMM DD, YYYY')}</div>
                            <div className='expanded-record-data' style={{ display: 'inline' }}>Updated: {moment(model?.dateUpdated).format('MMM DD, YYYY')}</div>
                        </div>
                    </Grid>
                    <div className='flex-body-business'>
                        <div> <img height='160' width='160' src={model?.latitude && model?.longitude ? `https://maps.googleapis.com/maps/api/staticmap?center=${model?.latitude + ',' + model?.longitude}&${zoom}&${size}&${maptype}&markers=${model?.latitude + ',' + model?.longitude}&key=AIzaSyD0AKt7KKjLie_FUX7rH1HAFXD9svOE6Go` : model?.address && model?.city ? `https://maps.googleapis.com/maps/api/staticmap?center=${model?.address + ',' + model?.city + ',' + model?.province + ',' + model?.country + ',' + model?.postalCode}&${zoom}&${size}&${maptype}&markers=${model?.address + ',' + model?.city + ',' + model?.province + ',' + model?.country + ',' + model?.postalCode}&key=AIzaSyD0AKt7KKjLie_FUX7rH1HAFXD9svOE6Go` : require('../../img/map-not-available.png')} alt='' /></div>


                        <div className='flex-row-business'>
                            <div className='flex-column-business' style={{ minWidth: '190px', wordWrap: 'break-word', marginLeft: 25, marginRight: 60 }}>
                                <div className='expanded-record-title'>Address</div>
                                <div className='expanded-record-data'>{!model?.address ? 'N/A' : model?.address}</div>
                                <div className='expanded-record-data'>{!model?.city ? 'N/A' : model?.city} {!model?.province ? '' : `, ${model?.province}`}</div>
                                <div className='expanded-record-data'>{!model?.country ? 'N/A' : model?.country}</div>
                            </div>

                            <div className='flex-column-business' style={{ minWidth: 190, maxWidth: 190, wordWrap: 'break-word', marginRight: 60 }}>
                                <div className='expanded-record-title'>Most Recent Price</div>
                                <div className='expanded-record-data'>{model?.mostRecentPriceAmount ? `$${model?.mostRecentPriceAmount}` : 'N/A'}</div>
                                <div className='expanded-record-data'>{model?.mostRecentPriceDate ? moment(model?.mostRecentPriceDate).format('MMM DD, YYYY') : 'N/A'}</div>
                            </div>
                            <div className='flex-column-business' style={{ minWidth: 190, maxWidth: 190, marginRight: 60 }}>
                                <div className='expanded-record-title'>Type</div>
                                <div className='expanded-record-data'> {!model?.propertyType ? 'N/A' : model?.propertyType}</div>
                            </div>
                            <div className='flex-column-business' style={{ minWidth: 234, maxWidth: 234, marginRight: 20 }}>
                                <div ><label className='expanded-record-title' htmlFor="">Size</label>
                                    <div style={{ display: 'block' }}> {!model?.numBedroom ? 'N/A' : `${model?.numBedroom} beds`}</div>
                                    <div style={{ display: 'block' }}> {!model?.numBedroom ? 'N/A' : `${model?.numBathroom} baths`}</div>
                                    <div style={{ display: 'block' }}> {!model?.floorSizeValue || !model?.floorSizeUnit ? 'N/A' : `${model?.floorSizeValue} ${model?.floorSizeUnit} (floor)`}</div>
                                    <div style={{ display: 'block' }}> {!model?.lotSizeValue || !model?.lotSizeUnit ? 'N/A' : `${model?.lotSizeValue} ${model?.lotSizeUnit} (lot)`}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex-row-business' style={{ marginBottom: '30px', marginTop: '38px', flexWrap: 'wrap' }}>
                        <div className='flex-column-business' style={{ marginRight: '60px' }}>
                            <label className='expanded-record-title'>Price Story</label>
                            {!model?.prices ? 'N/A' : model?.prices.slice(priceState?.initialState, priceState?.finalState).map((price, index) => (
                                <div key={index}>
                                    <label style={{ minWidth: '130px', display: 'inline-block' }} className='date-price-product'>{price?.dateSeen[0] ? moment(price?.dateSeen[0]).format('MMM DD, YYYY') : ''} </label>
                                    <label className='expanded-record-data'>${price?.amountMax}</label>
                                </div>
                            ))}
                            {model?.prices ?
                                <Grid container justifyContent='flex-end' style={{ marginTop: '10px' }} alignItems='flex-end'>
                                    <FontAwesomeIcon icon={faAngleLeft} onClick={previousPrice} className={priceState?.initialState === 0 ? 'disabled-product-arrow-left' : 'enable-product-arrow-left'} />
                                    <FontAwesomeIcon style={{ marginLeft: '20px' }} icon={faAngleRight} onClick={nextPrice} className={!model?.prices ? 'disabled-product-arrow-right' : priceState?.finalState >= model?.prices?.length ? 'disabled-product-arrow-right' : 'enable-product-arrow-right'} />
                                </Grid>
                                : ''}
                        </div>
                        <div className='flex-column-business' style={{ marginRight: '60px' }}>
                            <label className='expanded-record-title'>Status History</label>
                            {!model?.prices ? 'N/A' : model?.prices.slice(priceState?.initialState, priceState?.finalState).map((price, index) => (
                                <div key={index}>
                                    <label style={{ minWidth: '130px', display: 'inline-block' }} className='date-price-product'>{price?.dateSeen[0] ? moment(price?.dateSeen[0]).format('MMM DD, YYYY') : ''} </label>
                                    <label className='expanded-record-data'>
                                        {!price?.isSold ? '' : price?.isSold === 'true' ? 'Sold' : 'For Sale'}
                                        {!price?.isSale || price?.isSold ? '' : price?.isSale === 'false' ? 'Sold' : 'For Sale'}
                                        {!price?.isSale && !price?.isSold ? "N/A" : ""}
                                    </label>
                                </div>
                            ))}
                            {model?.prices ?
                                <Grid container justifyContent='flex-end' style={{ marginTop: '10px' }} alignItems='flex-end'>
                                    <FontAwesomeIcon icon={faAngleLeft} onClick={previousPrice} className={priceState?.initialState === 0 ? 'disabled-product-arrow-left' : 'enable-product-arrow-left'} />
                                    <FontAwesomeIcon style={{ marginLeft: '20px' }} icon={faAngleRight} onClick={nextPrice} className={!model?.prices ? 'disabled-product-arrow-right' : priceState?.finalState >= model?.prices?.length ? 'disabled-product-arrow-right' : 'enable-product-arrow-right'} />
                                </Grid>
                                : ''}
                        </div>
                    </div>

                    <hr />
                    {/* className={stylesPeopleExpanded.expandedHr} */}


                    <div className='expanded-record-title' style={{ marginTop: '18px', marginBottom: '5px' }}>Descriptions</div>
                    <div className='expanded-record-data'>{!model?.descriptions ? 'N/A' : model?.descriptions[descriptionState]?.value}</div>
                    <div style={{ marginBottom: '8px', marginTop: '8px', fontStyle: 'italic' }}>{!model?.descriptions ? '' : moment(model?.descriptions[descriptionState]?.dateSeen).format('MMM DD, YYYY')}</div>
                    <div onClick={() => window.open(model?.descriptions[descriptionState]?.sourceURLs, '_blank')} style={{ marginBottom: '20px', color: '#065E88', cursor: 'pointer' }}> {!model?.descriptions ? '' : model?.descriptions[descriptionState]?.sourceURLs}</div>
                    {model?.descriptions ?
                        <div style={{ marginBottom: '20px' }} className='container-buttons'>
                            <div><FontAwesomeIcon icon={faAngleLeft} onClick={previousDescription} className={descriptionState === 0 ? 'disabled-arrow-left' : 'enable-arrow-left'} /> </div>
                            <div><FontAwesomeIcon icon={faAngleRight} onClick={nextDescription} className={!model?.descriptions ? 'disabled-arrow-right' : descriptionState === model?.descriptions?.length - 1 ? 'disabled-arrow-right' : 'enable-arrow-right'} /></div>
                        </div> : ''}

                    <hr />


                    <p className='expanded-record-title' style={{ marginTop: '18px', marginBottom: '6px' }}>Reviews</p>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {!model?.reviews ? <label>N/A</label>
                            : <Rating className='rating' style={{ marginRight: '30.2px' }} value={!model?.reviews ? 0 : model?.reviews[reviewState]?.rating} readOnly />}
                        <p className='expanded-record-data' style={{ marginTop: '-4px' }}>{!model?.reviews ? '' : model?.reviews[reviewState]?.title}</p>
                    </div>
                    <p className='expanded-record-data' style={{ marginTop: '0px' }}>{!model?.reviews ? '' : model?.reviews[reviewState]?.text}</p>
                    <Typography style={{ marginTop: '10px', display: 'inline', marginRight: '52px', fontStyle: 'italic' }}>{!model?.reviews ? '' : moment(model?.reviews[reviewState]?.date).format('MMM DD, YYYY')}</Typography>
                    <Typography style={{ marginTop: '10px', display: 'inline' }}>{!model?.reviews ? '' : 'by ' + model?.reviews[reviewState]?.username}</Typography>

                    <div style={{ marginBottom: '20px' }} className='container-buttons'>
                        <div><FontAwesomeIcon icon={faAngleLeft} onClick={previousReview} className={reviewState === 0 ? 'disabled-arrow-left' : 'enable-arrow-left'} /> </div>
                        <div><FontAwesomeIcon icon={faAngleRight} onClick={nextReview} className={!model?.reviews ? 'disabled-arrow-right' : reviewState === model?.reviews?.length - 1 ? 'disabled-arrow-right' : 'enable-arrow-right'} /> </div>
                    </div>
                    <hr />
                    <div style={{ marginBottom: '3px', marginTop: '18px' }} className='expanded-record-title'>Features</div>
                    <div>
                        {!model?.features ? 'N/A' : model?.features?.slice(featureSliceState?.initialState, featureSliceState?.finalState).map((feature, index) => (<div style={{ display: 'inline-block', marginBottom: '10px', marginRight: '50px' }} key={index}>
                            <label style={{ color: '#43425D', fontWeight: '700' }}>{feature?.key}: </label> <label style={{ color: '#43425D' }}>{feature?.value} </label>
                        </div>))}
                    </div>
                    <div style={{ marginBottom: '20px' }} className='container-buttons'>
                        <div><FontAwesomeIcon icon={faAngleLeft} onClick={previousFeature} className={featureSliceState?.initialState === 0 ? 'disabled-arrow-left' : 'enable-arrow-left'} /> </div>
                        <div><FontAwesomeIcon icon={faAngleRight} onClick={nextFeature} className={!model?.features ? 'disabled-arrow-right' : featureSliceState?.finalState >= model?.features?.length - 1 ? 'disabled-arrow-right' : 'enable-arrow-right'} /> </div>
                    </div>


                </Grid>
            </DialogContent >
        </Dialog >
    )
}

