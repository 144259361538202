import React from 'react';
import { getAccount, updateAccountPlan } from '../../../../../../Api';
import BoxComponent from '../BoxComponent/BoxComponent';

// const productPlansQA = [
//     { id: 'product-1k-trial', name: "Trial", records: '1000 records', price: 'Free', yearlyPrice: 'Free', yearlyId: 'product-1k-trial-annual' },
//     { id: 'product-5k-99', name: "5K", records: '5,000 records per month', price: '$99', yearlyPrice: '$1,069', yearlyId: 'product-test5k-annual' },
//     { id: 'product-20k-259', name: "20K", records: '20,000 records per month', price: '$259', yearlyPrice: '$2797', yearlyId: 'product-test20k-annual' },
//     { id: 'product-50k-499', name: "50K", records: '50,000 records per month', price: '$499', yearlyPrice: '$5,389', yearlyId: 'product-test50k-annual' },
//     { id: 'product-200k-999', name: "200K", records: '200,000 records per month', price: '$999', yearlyPrice: '$10,789', yearlyId: 'product-test200k-annual' },
//     { id: 'product-unlimited-monthly', name: "Unlimited", records: 'Unlimited records per month', price: '$5000', yearlyPrice: '$60,000', yearlyId: 'product-testunlimitedproduct-60k' },
// ];
const productPlansProduction = [
    { id: 'product-1k-trial', name: "Trial", records: '1000 records', price: 'Free', yearlyPrice: 'Free', yearlyId: 'product-1k-trial-annual' },
    { id: 'product-5k-99', name: "5K", records: '5,000 records per month', price: '$99', yearlyPrice: '$1,069', yearlyId: 'product-5k-annual' },
    { id: 'product-20k-259', name: "20K", records: '20,000 records per month', price: '$259', yearlyPrice: '$2797', yearlyId: 'product-20k-annual' },
    { id: 'product-50k-499', name: "50K", records: '50,000 records per month', price: '$499', yearlyPrice: '$5,389', yearlyId: 'product-50k-annual' },
    { id: 'product-200k-999', name: "200K", records: '200,000 records per month', price: '$999', yearlyPrice: '$10,789', yearlyId: 'product-200k-annual' },
    { id: 'product-unlimited-monthly', name: "Unlimited", records: 'Unlimited records per month', price: '$5000', yearlyPrice: '$60,000', yearlyId: 'product-unlimitedproduct-60k' },
];


function ProductData() {
    return (
        <BoxComponent
            getAccount={getAccount}
            updateAccountPlan={updateAccountPlan}
            title="Product data subscription"
            plans={productPlansProduction}
            creditsKey="product_credits_used"
            planTypeKey="product"
        />
    );
}

export default ProductData;
