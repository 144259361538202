// React, Local cCntext imports
import React, { useState, useContext, useEffect } from 'react'
import { LocalContext } from '../LocalContext'
import { censorJSON } from '../../DataCensoring/index'
// Material ui, Styles imports
import { Grid } from '@material-ui/core'
import * as stylesJsonView from './json.module.css'
// Json Pretty and ScrollBox imports
import JSONPretty from 'react-json-pretty'
// Mutation react query
import { useMutation } from 'react-query'
// Font Awesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import CircularProgress from '@mui/material/CircularProgress';

export default function JsonView({ apiData }) {
  // Context Value State
  const { value, setValue } = useContext(LocalContext)
  const [isLoadingJsonView, setIsLoadingJsonView] = useState(false)
  const contextDataValue = value?.data?.data
  // page State
  const pageValue = value?.page
  const [censorData, setCensorData] = useState(null)
  // Mutation Json View
  const [jsonViewPaginationSum] = useMutation(({ page, queryData }) => apiData(page, queryData), { onSuccess: handleSuccess, onError: handleError })
  function handleSuccess(data) {
    setIsLoadingJsonView(false)
    const newPage = pageValue + 1
    setValue({ ...value, data, page: newPage })
  }
  // const isLoadingJsonView
  function handleError() {

  }

  const [jsonViewPaginationSubtraction] = useMutation(({ page, queryData }) => apiData(page, queryData), { onSuccess: handleSuccessLess, onError: handleErrorLess })
  function handleSuccessLess(data) {
    setIsLoadingJsonView(false)
    const newPage = pageValue - 1
    setValue({ ...value, data, page: newPage })
  }
  // const isLoadingJsonView
  function handleErrorLess() {
    console.error(censorData)
  }

  // pagination next onClick
  const nextSearchOnClick = () => {
    setIsLoadingJsonView(true)
    const newPage = pageValue + 1
    const { queryData } = value
    jsonViewPaginationSum({ page: newPage, queryData })
  }
  // pagination previous onClick
  const prevSearchOnClick = () => {
    setIsLoadingJsonView(true)
    const newPage = pageValue - 1
    const { queryData } = value
    jsonViewPaginationSubtraction({ page: newPage, queryData })
  }
  // When the query data changes, the mutation changes
  useEffect(() => {
    if (contextDataValue?.records) {
      const censorData = contextDataValue?.records?.map(censorJSON)
      setCensorData(censorData)
    }
  }, [value])/* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (isLoadingJsonView === true) {
    return <div className={stylesJsonView.noJsonContainer} ><label style={{ marginRight: '3px' }} className={stylesJsonView.noJsonView}>Loading...</label><CircularProgress color="secondary" /></div>
  }
  if (!contextDataValue) {
    return <div className={stylesJsonView.noJsonContainer}><label className={stylesJsonView.noJsonView}>No Json View</label></div>
  }
  return (
    <div>
      <div className={stylesJsonView.jsonViewer}>
        <div style={{ paddingLeft: '20px' }}>
          <JSONPretty style={{ fontFamily: 'Roboto', fontSize: '14px' }} data={contextDataValue} />
        </div>
      </div>
      <Grid className={stylesJsonView.arrowsContainer}>
        {contextDataValue ? <FontAwesomeIcon onClick={prevSearchOnClick} icon={faAngleLeft} className={pageValue === 1 ? stylesJsonView.disabledArrowLeft : stylesJsonView.enableArrowLeft} /> : ''}

        {
          value?.dataSelector ?
            <FontAwesomeIcon onClick={nextSearchOnClick} icon={faAngleRight} className={contextDataValue?.total_cost !== 12 ? stylesJsonView.disabledArrowRight : stylesJsonView.enableArrowRight} />
            :
            contextDataValue ? <FontAwesomeIcon onClick={nextSearchOnClick} icon={faAngleRight} className={contextDataValue?.total_cost !== 10 ? stylesJsonView.disabledArrowRight : stylesJsonView.enableArrowRight} /> : ''}
      </Grid>
    </div>
  )
}
