import React from "react";
import useStore from "../../Store/Store";
import GenericDropdown from "../GenericDropDown";

export const mostRecentStatusOptions = [
  "Any",
  "For Sale",
  "Off Market",
  "Rental",
  "Pre-Foreclosure",
  "Pending",
  "Foreclosed",
  "Sold",
  "Auction",
  "Coming Soon",
  "Rent To Own",
];

function MostRecentStatus() {
  const { mostRecentStatus, setMostRecentStatus, isQueryLoading } = useStore();
  return (
    <GenericDropdown
      options={mostRecentStatusOptions}
      label="Most Recent Status"
      setter={setMostRecentStatus}
      selectedOptions={mostRecentStatus}
      isQueryLoading={isQueryLoading}
    />
  );
}

export default MostRecentStatus;
