/* React , Local Context and components imports */
import React, { useState, useEffect, useContext } from 'react'
import { LocalContext } from '../LocalContext'
import { trimCSV } from './utils'
// CSV String, Scroll box imports
import csvString from 'csv-string'
// Material ui, styles imports
import { Grid } from '@material-ui/core'
import CircularProgress from '@mui/material/CircularProgress';
import * as stylesCsv from './csv.module.css'
/* React query imports */
import { useMutation } from 'react-query'
// Font Awesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { censorCSV } from '../../DataCensoring/index'

export default function CSVViewer({ apiData }) {
  // Local Context value
  const { value, setValue } = useContext(LocalContext)
  const pageValue = value?.pageCSVView
  const [isLoading, setIsLoading] = useState(false)
  /// Csv Data state
  const [csvData, setCsvData] = useState()
  /// Headers CSV state
  const [headers, setHeaders] = useState()
  /// Mutation
  const [CSVonMount, { isLoading: loadingRequest }] = useMutation(({ page, queryData }) => apiData(page, queryData), { onSuccess: handleSuccess })
  // Handles the success or the error of the mutation
  function handleSuccess(data) {
    setValue({ ...value, CsvDataContext: data, CSVBoolen: true })
    // censor
    const recordsCensored = censorCSV(data?.data?.column_headers, data?.data?.records)
    const records = recordsCensored.join('\n')
    const parseData = trimCSV(csvString.parse(records))
    setCsvData(parseData)
    const headers = data?.data?.column_headers.split(',')
    setHeaders(headers)
    setIsLoading(false)
  }

  /// Mutation
  const [CSVPagination] = useMutation(({ page, queryData }) => apiData(page, queryData), { onSuccess: handleSuccessPagination })
  // Handles the success or the error of the mutation
  function handleSuccessPagination(data) {
    const DFLocalStorage = sessionStorage.getItem("DF_Pagination");
    if (DFLocalStorage === '+') {
      const newPage = pageValue + 1
      setValue({ ...value, CsvDataContext: data, CSVBoolen: true, pageCSVView: newPage })
    }
    if (DFLocalStorage === '-') {
      const newPage = pageValue - 1
      setValue({ ...value, CsvDataContext: data, CSVBoolen: true, pageCSVView: newPage })
    }
    // censor
    const recordsCensored = censorCSV(data?.data?.column_headers, data?.data?.records)
    const records = recordsCensored.join('\n')
    const parseData = trimCSV(csvString.parse(records))
    setCsvData(parseData)
    const headers = data?.data?.column_headers.split(',')
    setHeaders(headers)
    setIsLoading(false)
  }
  const CsvSecond = () => {
    const recordsCensored = censorCSV(value?.CsvDataContext?.data?.column_headers, value?.CsvDataContext?.data?.records)
    const records = recordsCensored.join('\n')
    const parseData = trimCSV(csvString.parse(records))
    setCsvData(parseData)
    const headers = value?.CsvDataContext?.data?.column_headers.split(',')
    setHeaders(headers)
  }

  useEffect(() => {
    if (value?.queryDataCsv?.query) {
      setIsLoading(true)
      const { queryDataCsv } = value
      const page = pageValue
      CSVonMount({ page, queryData: queryDataCsv })
    }
  }, [value?.queryDataCsv?.query])/* eslint-disable-line react-hooks/exhaustive-deps */
  // value?.queryDataCsv?.query
  useEffect(() => {
    if (value?.CSVBoolen === true) {
      CsvSecond()
    }
  }, [])/* eslint-disable-line react-hooks/exhaustive-deps */

  // pagination next onClick
  const nextSearchOnClick = () => {
    sessionStorage.setItem("DF_Pagination", '+');
    setIsLoading(true)
    const { queryDataCsv } = value
    const page = pageValue + 1
    CSVPagination({ page, queryData: queryDataCsv })
    window.scrollTo(0, 0)
  }
  // pagination previous onClick
  const prevSearchOnClick = () => {
    sessionStorage.setItem("DF_Pagination", '-');
    setIsLoading(true)
    const { queryDataCsv } = value
    const page = pageValue - 1
    CSVPagination({ page, queryData: queryDataCsv })
    window.scrollTo(0, 0)
  }
  // Generates the headers of the Table
  const generateHeaders = (element, index) => { return (<th key={index}>{element}</th>) }
  // Generates the Divisions of the Table
  const generateDivisions = (element, index) => { return <td key={index}>{element}</td> }
  // Generates the Rows of the Table
  const generateRows = (element, index) => { return (<tr key={index}>{element.map(generateDivisions)}</tr>) }
  // If there is no data will return No Csv View
  if (loadingRequest === true) return <div className={stylesCsv.noCsvContainer}><label style={{ marginRight: '3px' }} className={stylesCsv.noCsvView} >Loading...</label><CircularProgress color="secondary" /></div>

  if (value?.CsvDataContext?.data === undefined) return <div className={stylesCsv.noCsvContainer}><label className={stylesCsv.noCsvView}>No Csv View Available</label></div>

  if (isLoading === true) {
    return <div className={stylesCsv.noCsvContainer}><label style={{ marginRight: '3px' }} className={stylesCsv.noCsvView}>Loading...</label><CircularProgress color="secondary" /></div>
  }
  // CSV GUI
  return (
    <div>
      <div className={stylesCsv.csvViewer}>
        <table>
          <thead>
            <tr>
              {headers?.map(generateHeaders)}
            </tr>
          </thead>
          <tbody>
            {csvData?.map(generateRows)}
          </tbody>
        </table>
      </div>
      <Grid className={stylesCsv.arrowsContainer}>
        {value?.CsvDataContext?.data
          ? <FontAwesomeIcon onClick={prevSearchOnClick} icon={faAngleLeft} className={pageValue === 1 ? stylesCsv.disabledArrowLeft : stylesCsv.enableArrowLeft} />
          : ''}
        {value?.CsvDataContext?.data
          ? <FontAwesomeIcon onClick={nextSearchOnClick} icon={faAngleRight} className={value?.CsvDataContext?.data?.total_cost !== 10 ? stylesCsv.disabledArrowRight : stylesCsv.enableArrowRight} /> : ''}
      </Grid>
    </div>
  )
}
