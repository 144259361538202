import React from 'react';
import { getAccount, updateAccountPlan } from '../../../../../../Api';
import BoxComponent from '../BoxComponent/BoxComponent';

const peoplePlansProduction = [
    { id: 'people-100-trial', name: "Trial", records: '100 records', price: 'Free', yearlyPrice: 'Free', yearlyId: 'people-100-annual' },
    { id: 'people-100-19', name: "100", records: '100 records per month', price: '$19', yearlyPrice: '$205', yearlyId: 'people-100-annual' },
    { id: 'people-1k-99', name: "1k", records: '1,000 records per month', price: '$99', yearlyPrice: '$1,069', yearlyId: 'people-1k-annual' },
    { id: 'people-5k-449', name: "5k", records: '5,000 records per month', price: '$499', yearlyPrice: '$4,849', yearlyId: 'people-5k-annual' },
    { id: 'people-10k-799', name: "10k", records: '10,000 records per month', price: '$799', yearlyPrice: '$8,629', yearlyId: 'people-10k-annual' },
    { id: 'people-unlimited-monthly', name: "Unlimited", records: 'Unlimited records per month', price: '$5000', yearlyPrice: '$60,000', yearlyId: 'people-unlimitedpeople-60k' },
];

// QA
// const peoplePlansQA = [
//     { id: 'people-100-trial', name: "Trial", records: '100 records', price: 'Free', yearlyPrice: 'Free', yearlyId: 'people-100-trial-annual' },
//     { id: 'people-100-19', name: "100", records: '100 records per month', price: '$19', yearlyPrice: '$205', yearlyId: 'people-test100-annual' },
//     { id: 'people-1k-99', name: "1k", records: '1,000 records per month', price: '$99', yearlyPrice: '$1,069', yearlyId: 'people-test1k-annual' },
//     { id: 'people-5k-449', name: "5k", records: '5,000 records per month', price: '$499', yearlyPrice: '$4,849', yearlyId: 'people-test5k-annual' },
//     { id: 'people-10k-799', name: "10k", records: '10,000 records per month', price: '$799', yearlyPrice: '$8,629', yearlyId: 'people-test10k-annual' },
//     { id: 'people_unlimited_monthly', name: "Unlimited", records: 'Unlimited records per month', price: '$5000', yearlyPrice: '$60,000', yearlyId: 'people-testunlimitedpeople-60k' },
// ];


function PeopleData() {
    return (
        <BoxComponent
            getAccount={getAccount}
            updateAccountPlan={updateAccountPlan}
            title="People data subscription"
            plans={peoplePlansProduction}
            creditsKey="people_credits_used"
            planTypeKey="people"
        />
    );
}

export default PeopleData;
