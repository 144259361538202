import React, { useState, useEffect } from "react";
import styles from "../modal.module.css";
import caretDown from "../../images/caretDownGeneric.svg";
import LocationSection from "./LocationSection/LocationSection";
import PropertyType from "./PropertyType";
import MostRecentStatus from "./MostRecentStatus";
import useFilterStore from "../../../Store/filterStore"; // Adjust the path as needed
import useStore from "../../../Store/Store"; // Adjust the path as needed
import Amenities from "./Amenities";
import SizeUnitSection from "./SizeUnitSection";
import RequiredFieldsSection from "./RequiredFields";
import RecentStatusDate from "./RecentStatusDate/RecentStatusDate";
import MostRecentStatusFirstDateSeen from "./MostRecentStatusFirstDateSeen/MostRecentStatusFirstDateSeen";
import MostRecentPriceDate from "./MostRecentPriceDate/MostRecentPriceDate";
import RecordRecency from "./RecordRecency/RecordRecency";
import MostRecentRentalPrice from "./MostRecentRentalPrice/MostRecentRentalPrice";

function Modal({ toggleModal, suggestions, setSuggestions }) {

  const [isOpen, setIsOpen] = useState(false);

  const { setAllFilters, allFilters, resetFilters } = useFilterStore();

  const { propertyType, setPropertyType, mostRecentStatus, setMostRecentStatus, isQueryLoading, setAddress, queryStore, resetStore, firstRender } = useStore();
  const [localPropertyType, setLocalPropertyType] = useState(propertyType);

  const initialLocalFilters = { streetAddress: [], city: [], stateProvince: [], postalCode: [], country: "", };

  const [localMostRecentStatus, setLocalMostRecentStatus] =
    useState(mostRecentStatus);

  const [locationChanged, setLocationChanged] = useState(false);


  const [priceFilter, setPriceFilter] = useState(allFilters.price);
  const [priceRentalFilter, setRentalPriceFilter] = useState(allFilters.rentalPrice);
  const [mostRecentStatusDateFilter, setMostRecentStatusDateFilter] = useState(allFilters.mostRecentStatusDate);

  const [mostRecentStatusFirstDateSeenFilter, setMostRecentStatusFirstDateSeenFilter] = useState(allFilters.mostRecentStatusFirstDateSeen);

  const [mostRecentPriceDateFilter, setMostRecentPriceDateFilter] = useState(allFilters.mostRecentPriceDate);

  const [recordRecencyFilter, setRecordRecencyFilter] = useState(allFilters.recordRecency);


  const [localFilters, setLocalFilters] = useState(initialLocalFilters);

  const [amenitiesFilters, setAmenitiesFilters] = useState({
    beds: { min: "", max: "" },
    bath: { min: "", max: "" },
    floors: { min: "", max: "" },
    parkingSpaces: { min: "", max: "" },
    rooms: { min: "", max: "" },
    units: { min: "", max: "" },
    HVAC: [],
    exterior: [],
    roofing: [],
    // ... other amenities
  });

  const [sizeUnitFilter, setSizeUnitFilter] = useState(allFilters.sizeUnits);

  const [requiredFieldsFilter, setRequiredFieldsFilter] = useState(
    allFilters.requiredFields
  );

  const handleSizeUnitChange = (event, sizeType) => {
    const { name, value } = event.target;

    // Regular expression to allow only numeric input
    const re = /^[0-9\b]+$/;

    // Update the state only if the value is a number or an empty string (for backspace)
    if (re.test(value) || value === "") {
      setSizeUnitFilter((prevState) => {
        const updatedSizeType = {
          ...prevState[sizeType],
          [name]: value,
        };

        // Check if min and max are both empty after the update
        if (!updatedSizeType.min && !updatedSizeType.max) {
          updatedSizeType.unit = ""; // Set unit to "" if both min and max are empty
        } else if (!updatedSizeType.unit) {
          updatedSizeType.unit = "sq ft"; // Default unit if either min or max is present and unit is empty
        }

        return {
          ...prevState,
          [sizeType]: updatedSizeType,
        };
      });
    }
  };

  const handleUnitChange = (event, sizeType) => {
    const { name, value } = event.target;
    setSizeUnitFilter((prevState) => {
      const sizeData = prevState[sizeType];

      // If min or max is present, set the unit to the selected value or default to "sq ft"
      const unitValue = sizeData.min || sizeData.max ? value : "";

      return {
        ...prevState,
        [sizeType]: {
          ...sizeData,
          [name]: unitValue,
        },
      };
    });
  };

  const handlePriceValueChange = (e) => {
    const { name, value } = e.target;

    // Regular expression to allow only numeric input for minValue and maxValue
    const re = /^[0-9\b]+$/;

    if (re.test(value) || value === "") {
      setPriceFilter((prevState) => {
        const updatedPriceFilter = {
          ...prevState,
          [name]: value,
        };

        // Check if both minValue and maxValue are empty after the update
        if (!updatedPriceFilter.minValue && !updatedPriceFilter.maxValue) {
          updatedPriceFilter.currency = ""; // Set currency to "" if both minValue and maxValue are empty
        } else if (!updatedPriceFilter.currency) {
          updatedPriceFilter.currency = "USD"; // Default currency to "USD" if either minValue or maxValue is present and currency is empty
        }

        return updatedPriceFilter;
      });
    }
  };

  const handleChangeRequiredFields = (fieldValue) => {
    setRequiredFieldsFilter((prevSelectedFields) => {
      const newSelectedFields = prevSelectedFields.includes(fieldValue)
        ? prevSelectedFields.filter((value) => value !== fieldValue)
        : [...prevSelectedFields, fieldValue];

      // Update the store with the new list of required fields
      setRequiredFieldsFilter(newSelectedFields);
      return newSelectedFields;
    });
  };

  const handleCurrencyChange = (e) => {
    const { value } = e.target;
    setPriceFilter((prev) => {
      // Only set the currency if minValue or maxValue is present
      const updatedCurrency =
        prev.minValue || prev.maxValue ? value : prev.currency;

      return {
        ...prev,
        currency: updatedCurrency,
      };
    });
  };

  const handleCloseClick = (e) => {
    e.stopPropagation();
    if (e.target === e.currentTarget) {
      setIsOpen(false);
      // Wait for the animation to complete before closing the modal
      // setTimeout(toggleModal, 250);
      setTimeout(() => {
        toggleModal();
      }, 250);
    }
  };

  const handleApplyFilters = () => {
    // Only proceed if not already loading
    if (!isQueryLoading) {
      if (locationChanged) {
        setAddress("");  // Clear the address only if location inputs have changed
      }
      // Update the property type filter state
      setPropertyType(localPropertyType);

      // Update the most recent status filter state
      setMostRecentStatus(localMostRecentStatus);

      // Update all filters, including price filter state
      setAllFilters({
        ...localFilters,
        price: priceFilter,
        rentalPrice: priceRentalFilter,
        amenities: amenitiesFilters,
        sizeUnits: sizeUnitFilter,
        requiredFields: requiredFieldsFilter,
        mostRecentStatusDate: mostRecentStatusDateFilter,
        mostRecentStatusFirstDateSeen: mostRecentStatusFirstDateSeenFilter,
        mostRecentPriceDate: mostRecentPriceDateFilter,
        recordRecency: recordRecencyFilter
      });

      // Close the modal and reset the loading state after a 500ms delay
      setTimeout(() => {
        toggleModal();
      }, 250);
    }
  };

  const handleClearFilters = () => {
    if (queryStore) {
      // cleans all filters store
      resetFilters();
      resetStore()
      // Close the modal and reset the loading state after a 500ms delay
      setTimeout(() => {
        toggleModal();
      }, 250);
    }
  };

  useEffect(() => {
    if ((isQueryLoading && isOpen) || firstRender) {
      setIsOpen(false);
      setTimeout(() => {
        toggleModal();
      }, 250);
    }
  }, [isQueryLoading, isOpen, toggleModal, firstRender]);

  useEffect(() => {
    // Trigger the animation after the component mounts
    setTimeout(() => setIsOpen(true), 250); // Short delay to ensure CSS is applied
  }, []);

  return (
    <main className={styles.modalBackdrop} onClick={handleCloseClick}>
      <div
        className={`${styles.modalContent} ${isOpen ? styles.modalContentOpen : ""
          }`}
      >
        {/* header */}
        <section className={styles.filtersHeader}>
          <h1 className={styles.filtersTitle}>Filters</h1>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span onClick={queryStore === "" ? undefined : handleClearFilters} className={queryStore === "" ? styles.clearFiltersDisabled : styles.clearFilters}>Clear filters</span>
            <button
              onClick={handleApplyFilters}
              className={styles.applyFiltersBtn}
              disabled={isQueryLoading}
            >
              Apply filters
            </button>
          </div>
        </section>
        <hr className={styles.hrStyle} />
        {/* header ends */}

        {/* Location Section */}
        <LocationSection
          localFilters={localFilters}
          setLocalFilters={setLocalFilters}
          suggestions={suggestions}
          setSuggestions={setSuggestions}
          setLocationChanged={setLocationChanged}
        />

        <PropertyType
          localPropertyType={localPropertyType}
          setLocalPropertyType={setLocalPropertyType}
        />

        {/* Property Type Section ends */}

        {/* Price Section */}
        <section className={styles.priceSection}>
          <h2 className={styles.priceTitle}>Price</h2>
          <div style={{ display: "flex" }}>
            <div>
              <span className={styles.spanPrice}>Most recent list price</span>
              <div className={styles.inputPriceGroup}>
                <input
                  autoComplete="off"
                  className={styles.inputPrice}
                  placeholder="Min"
                  onChange={handlePriceValueChange}
                  value={priceFilter.minValue}
                  name="minValue"
                />

                {/* Max Value Input */}
                <input
                  autoComplete="off"
                  className={styles.inputPrice}
                  placeholder="Max"
                  onChange={handlePriceValueChange}
                  value={priceFilter.maxValue}
                  name="maxValue"
                />
                <div style={{ marginRight: '32px' }}>
                  <select
                    className={styles.selectPrice}
                    onChange={handleCurrencyChange}
                    value={priceFilter.currency}
                    name="currency"
                  >
                    <option value="USD">USD</option>
                    <option value="CAD">CAD</option>
                    {/* Add more currency options here */}
                  </select>
                  <img
                    src={caretDown}
                    alt="arrow"
                    className={styles.customArrowPrice}
                  />
                </div>
              </div>

              <div>
                <MostRecentRentalPrice priceRentalFilter={priceRentalFilter} setRentalPriceFilter={setRentalPriceFilter} />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <MostRecentPriceDate mostRecentPriceDateFilter={mostRecentPriceDateFilter} setMostRecentPriceDateFilter={setMostRecentPriceDateFilter} />
            </div>


          </div>

        </section>

        <hr className={styles.hrStyle} />

        <MostRecentStatus
          localMostRecentStatus={localMostRecentStatus}
          setLocalMostRecentStatus={setLocalMostRecentStatus}
        />

        <div style={{ display: "flex", marginLeft: "40px", marginTop: "18px", marginBottom: "24px" }}>
          <RecentStatusDate mostRecentStatusDateFilter={mostRecentStatusDateFilter} setMostRecentStatusDateFilter={setMostRecentStatusDateFilter} />

          <MostRecentStatusFirstDateSeen mostRecentStatusFirstDateSeenFilter={mostRecentStatusFirstDateSeenFilter} setMostRecentStatusFirstDateSeenFilter={setMostRecentStatusFirstDateSeenFilter} />
        </div>

        <hr className={styles.hrStyle} />

        {/* Most Recent Status Section ends */}

        <Amenities
          amenitiesFilters={amenitiesFilters}
          setAmenitiesFilters={setAmenitiesFilters}
        />
        <hr className={styles.hrStyle} />

        <SizeUnitSection
          sizeUnitFilter={sizeUnitFilter}
          setSizeUnitFilter={handleSizeUnitChange}
          handleUnitChange={handleUnitChange}
        />

        <hr className={styles.hrStyle} />

        <RecordRecency recordRecencyFilter={recordRecencyFilter} setRecordRecencyFilter={setRecordRecencyFilter} />

        <hr className={styles.hrStyle} />

        <RequiredFieldsSection
          requiredFieldsFilter={requiredFieldsFilter}
          handleChangeRequiredFields={handleChangeRequiredFields}
        />
      </div>
    </main>
  );
}

export default Modal;
