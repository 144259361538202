import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import BillingSettings from '../Subscription/index/SubscriptionSettings';
import PaymentSettings from '../Payment/PaymentSettings';
import { Link, Route, Switch, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { useStyles } from './styles';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    const classes = useStyles();

    return (
        <Tab
            disableFocusRipple
            disableRipple
            component={Link}
            className={classes.tab}
            {...props}
        />
    );
}

function IndexBilling() {
    const classes = useStyles();
    let { path, url } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    const [valueTabs, setValueTabs] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValueTabs(newValue);
    };

    useEffect(() => {
        const tabPaths = [`${url}/subscription`, `${url}/payment`];
        const currentPath = location.pathname;
        const currentTab = tabPaths.indexOf(currentPath);

        if (currentPath === `${url}`) {
            history.replace(`${url}/subscription`);
        } else if (currentTab !== -1) {
            setValueTabs(currentTab);
        }
    }, [location.pathname, url, history]);

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar}>
                <Tabs
                    className={classes.tabs}
                    value={valueTabs}
                    onChange={handleChange}
                    aria-label="nav tabs example"
                >
                    <LinkTab label="Subscription" to={`${url}/subscription`} {...a11yProps(0)} />
                    <LinkTab label="Payment Information" to={`${url}/payment`} {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <hr style={{ margin: "0px", border: "1px solid #E5E5E5" }} />
            <Switch>
                <Route path={`${path}/subscription`} component={BillingSettings} />
                <Route path={`${path}/payment`} component={PaymentSettings} />
            </Switch>
        </div>
    );
}

export default IndexBilling;
