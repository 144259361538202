import React from 'react'
import styles from "./styles.module.css";

function MostRecentData({ record }) {
    return (
        <section className={styles.containerMostRecentData}>
            <div className={styles.containerMostRecentStatus}>
                <span className={styles.spanTitle}>
                    Most recent status
                </span>
                <span className={styles.spanTypeValue}>
                    {" "}
                    {record?.mostRecentStatus ? record.mostRecentStatus : "N/A"}
                </span>
            </div>
            <div className={styles.containerMostPrice}>
                <span
                    className={styles.spanTitle}
                >
                    Most recent list price
                </span>

                <span className={styles.spanTypeValue}>
                    {record?.mostRecentPriceAmount
                        ? `$${record.mostRecentPriceAmount.toLocaleString()}`
                        : "N/A"}
                </span>

            </div>

            <div className={styles.containerMostRecentRentalPriceAmount}>
                <span
                    className={styles.spanTitle}
                >
                    Most recent rental price
                </span>

                <span className={styles.spanTypeValue}>
                    {record?.mostRecentRentalPriceAmount
                        ? `$${record.mostRecentRentalPriceAmount.toLocaleString()}`
                        : "N/A"}
                </span>

            </div>
            <div className={styles.containerMostRecentAgent}>
                <span className={styles.spanTitle}>
                    Most recent agent
                </span>
                <span className={styles.spanTypeValue}>
                    {record?.mostRecentBrokerAgent
                        ? record.mostRecentBrokerAgent
                        : "N/A"}
                </span>
            </div>
        </section>
    )
}

export default MostRecentData