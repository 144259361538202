// react and components imports
import React, { useState, useEffect } from 'react'
import SnackbarComponent from '../Snackbar/index'
// material ui imports
import { Button, Grid, Typography, Radio, RadioGroup, FormControlLabel, FormControl, makeStyles, TextField, Box, CircularProgress } from '@material-ui/core'
import * as stylesJs from './styles.js'
// useMutation from react query and use form react hooks form
import { useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import './styles.css'
import { getAccount, getViews, getDefaultViews } from '../../Api/index'
import { useQuery } from 'react-query'

export default function DialogContainer({ data, handleClose, downloadData, downloadQuery, dataSelector }) {
  const { data: accountDownload } = useQuery('accountDownload', getAccount)
  const { data: ViewsResponseData } = useQuery('getViews', getViews)
  const { data: ViewsResponseDataDefault } = useQuery('getDefaultViews', getDefaultViews)
  const [viewsUserChecked, setViewsUserChecked] = useState([])
  // loop through the array
  const [disabledBronzeUser] = useState(false)
  // radio state JSON or CSV
  const [formatRadioSelected] = useState('csv')
  const [viewSelected, setViewSelected] = useState('default')
  // number of records to download state
  // const [numRecords, setNumRecords] = useState('')
  const [calculateCredits, setCalculateCredits] = useState(0)
  // is the button valid or not state
  const [buttonValid, setButtonValid] = useState(true)
  // State of the snackMessage
  const [snackMessage, setSnackMessage] = useState({ type: 'error' })
  // state of the query data to be use insde the mutation(post)
  const [queryData, setQueryData] = useState({ query: downloadQuery, num_records: '', format: formatRadioSelected, view: viewSelected, download: true })
  // form state react hook form
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({ mode: "onSubmit" });
  // here
  /* usestyles and classes are used to style the app */
  const useStyles = makeStyles(stylesJs)
  const classesJs = useStyles()
  // react query mutate login
  const [startDownload, { isLoading }] = useMutation(({ queryData }) => downloadData(queryData),
    { onSuccess: handleSuccessDownload, onError: handleError })
  function handleSuccessDownload(data) {
    if (data?.status !== 400) {
      setButtonValid(false)
      setSnackMessage({ type: 'success', myMessage: 'The download was successful. You will be redirected.', update: Date.now() })
      setTimeout(() => { window.location.href = `/download` }, 3000)
    } else {
      setSnackMessage({ type: 'error', myMessage: data?.data?.errors[0], update: Date.now() })
    }
  }
  function handleError(error) { setSnackMessage({ type: 'error', myMessage: error, update: Date.now() }) }
  // onClick events to change the radio value
  const radioOnClickJson = () => { setQueryData({ ...queryData, format: 'json' }) }
  // const handleInputChange = ({ target }) => { setQueryData({ ...queryData, num_records: target.value }) }
  const radioOnClickCsv = () => { setQueryData({ ...queryData, format: 'csv' }) }
  // onChange query data
  const handleInputChange = (e) => {
    const value = Number(e.target.value); // Convert the input value to a number
    setQueryData({
      ...queryData,
      [e.target.name]: value,
    });
  }
  // onSubmit click event to mutate the data(post)
  const onSubmit = () => { startDownload({ queryData }) }
  // variables coming from the data
  const num_records = queryData?.num_records
  const roleOfUser = accountDownload?.data?.role
  /**
   * Calculates the extra cost (if any) a user will incur by starting this download
   */
  const calculateCreditsPrice = () => {
    if (roleOfUser === 'admin') {
      setCalculateCredits('0')
    }
    else {
      if (accountDownload && num_records) {
        const cost = 1
        const total = num_records * cost
        setCalculateCredits(total)
      }
    }
  }
  useEffect(() => {
    // extraCostDownload()
    calculateCreditsPrice()
  }, [roleOfUser, num_records])/* eslint-disable-line react-hooks/exhaustive-deps */
  const onChangeSelectView = (event) => {
    const value = event.target.value
    setQueryData({ ...queryData, view: value })
    setViewSelected(value)
  }

  useEffect(() => {
    if (ViewsResponseDataDefault?.data) {
      ViewsResponseDataDefault.data.records.forEach(element => {
        if (element.data_type === dataSelector && element.name !== 'default') {
          setViewsUserChecked(prev => prev.concat(element))
        }
      });
    }

    if (ViewsResponseData?.data) {
      ViewsResponseData.data.records.forEach(element => {
        if (element.data_type === dataSelector) {
          setViewsUserChecked(prev => prev.concat(element))
        }
      });
    }
  }, [ViewsResponseData?.data])/* eslint-disable-line react-hooks/exhaustive-deps */

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid style={{ marginBottom: '15px' }}>
          <Box className={classesJs.boxQuery}><Typography className={classesJs.dialogTextLeftSide}>Query:</Typography></Box>
          <Box className={classesJs.boxQueryData}><Typography className={classesJs.dialogTextRightSide}>{downloadQuery || 'No data'}</Typography></Box>
        </Grid>
        <Grid style={{ marginBottom: '15px' }}>
          <Box className={classesJs.boxApiCall}><Typography className={classesJs.dialogTextLeftSide}>API CALL:</Typography></Box>
          <Box className={classesJs.boxApiCallData}><Typography className={classesJs.dialogTextRightSide}>{downloadQuery || 'No data available'}</Typography></Box>
        </Grid>
        <Grid className={classesJs.gridMatchingRecords}>
          <Box className={classesJs.boxMatchingRecords}><Typography className={classesJs.dialogTextLeftSide}>Matching records:</Typography></Box>
          <Box className={classesJs.boxMatchingRecordsData}><Typography className={classesJs.dialogTextRightSide}>{data?.data?.num_found.toLocaleString() || 'No data available'}</Typography></Box>
        </Grid>
        <Grid style={{ marginBottom: '12.5px' }}><Box className={classesJs.fields}><Typography className={classesJs.dialogTextLeftSide}>Format:</Typography></Box>
          <Box className={classesJs.fields}>
            <FormControl style={{ marginTop: '-5px' }} component='fieldset'>
              <RadioGroup row aria-label='position' name='position' defaultValue='top'>
                <FormControlLabel value='top' control={<Radio onClick={radioOnClickCsv} style={{ color: '#3F3B88' }} />} label='CSV' />
                <FormControlLabel value='start' control={<Radio onClick={radioOnClickJson} style={{ color: '#3F3B88', marginRight: '-0.5px', fontSize: '40px' }} />} label='JSON' labelPlacement='end' />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
        <Grid className={classesJs.gridMatchingRecords}>
          <Box className={classesJs.boxIncludeFields}><Typography className={classesJs.dialogTextLeftSide}>Include fields:</Typography></Box>
          <select value={viewSelected} onChange={onChangeSelectView} style={{ width: '164px', fontSize: '16px', padding: '5px' }}>
            {viewsUserChecked?.map((view, i) => (
              <option key={i}>{view.name}</option>))}
            : <option>default</option>
          </select>
        </Grid>
        <Grid style={{ marginBottom: '15px' }}>
          <Box className={classesJs.boxRecordsDownload}>
            <Typography className={classesJs.dialogTextLeftSide}>#records to download:</Typography>
          </Box>
          <Box className={classesJs.boxRecordsDownloadData}>
            <TextField name='num_records' color='secondary' className={classesJs.cssTextField} error={!!errors.num_records}
              {...register("num_records", { required: 'num_records is required', pattern: { value: /^[0-9]+$/i, message: 'num_records invalid format' }, onChange: (e) => { handleInputChange(e) } })}
            />
            {errors.num_records?.type === 'required' && <p className={classesJs.cssTextFieldError}>Required</p>}
            {errors.num_records?.type === 'pattern' && <p className={classesJs.cssTextFieldError}>Only numbers allowed</p>}
          </Box>
        </Grid>
        <Grid style={{ marginBottom: '15px' }}>
          <Box className={classesJs.boxCreditsRequired}><Typography className={classesJs.dialogTextLeftSide}>Credits required:</Typography></Box>
          <Box className={classesJs.boxCreditsRequiredData}><Typography className={classesJs.dialogTextRightSide}>{calculateCredits}</Typography></Box>
        </Grid>
        <Button type='submit' disabled={!isValid || !buttonValid || isLoading || disabledBronzeUser === true} className={classesJs.buttonDownloads} variant='contained'>{isLoading === true ? <CircularProgress size='2.2rem' style={{ color: '#3F3B88' }} /> : 'Confirm Download'}</Button>
      </form>
      <SnackbarComponent snackData={snackMessage} />
    </>
  )
}
