import React, { useState, useCallback, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import useStore from "../Store/Store"; // Adjust the path as needed
import customMarkerIcon from "./img/location-dot-solid.svg"; // Adjust the path to your SVG file
import ExpandedRecordDr6 from "../ExpandedRecordDr6";
import { useMutation, useQueryCache } from 'react-query';
// import { postProperty } from "../../../Api/index";
import { postProperty } from "../../Api/index";
import styles from "./styles.module.css";

const containerStyle = {
  width: '100%',
  height: '100%'
};

const initialCenter = { lat: 39.8283, lng: -98.5795 }; // Central location of the U.S.
const initialZoom = 4;

function MapViewDr6() {
  const { dataResults, isQueryLoading } = useStore();
  const recordsData = dataResults?.data?.records;

  // State to manage modal visibility and data
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const queryCache = useQueryCache();

  // State to hold the map instance
  const [map, setMap] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyD0AKt7KKjLie_FUX7rH1HAFXD9svOE6Go", // Replace with your actual API key
  });

  // Use useMutation for the POST request
  const [mutate] = useMutation(postProperty, {
    onSuccess: (data) => {
      const newRecord = data?.data?.records[0];
      if (newRecord) {
        const cacheKey = `recordByID-${newRecord.keys[0]}`;
        queryCache.setQueryData(cacheKey, newRecord);
        setSelectedRecord(newRecord);
        setModalOpen(true);
      }
    },
  });


  const handleRecordClick = (record) => {
    if (!isQueryLoading) {
      const cacheKey = `recordByID-${record.keys[0]}`;
      const cachedData = queryCache.getQueryData(cacheKey);

      if (cachedData) {
        // If we have cached data, use it directly
        setSelectedRecord(cachedData);
        setModalOpen(true);
      } else {
        // If there's no cached data, perform the mutation
        mutate({ query: `keys:"${record?.keys[0]}"` });
      }
    }
  };

  useEffect(() => {
    // Remove all queries that start with 'recordByID'
    if (queryCache) {
      queryCache.getQueries().forEach(query => {
        // Ensure queryKey[0] exists and is a string before calling startsWith
        if (typeof query?.queryKey[0] === 'string' && query.queryKey[0].startsWith('recordByID')) {
          queryCache.removeQueries(query.queryKey);
        }
      });
    }
  }, [dataResults, queryCache]);

  // Callback for when the map has loaded
  const onLoad = useCallback(
    (mapInstance) => {
      setMap(mapInstance);
      // If we already have records data when the map loads, fit bounds immediately
      if (recordsData && recordsData.length > 0) {
        fitBoundsToMarkers(mapInstance, recordsData);
      }
    },
    [recordsData]
  );

  // Fills the bounds with records/latitude/longitude
  const fitBoundsToMarkers = (mapInstance, records) => {
    const bounds = new window.google.maps.LatLngBounds();

    records.forEach((record) => {
      if (record.latitude && record.longitude) {
        bounds.extend(
          new window.google.maps.LatLng(
            parseFloat(record.latitude),
            parseFloat(record.longitude)
          )
        );
      }
    });

    if (!bounds.isEmpty()) {
      mapInstance.fitBounds(bounds);
    }
  };
  useEffect(() => {
    if (map && recordsData && recordsData.length > 0) {
      fitBoundsToMarkers(map, recordsData);
    }
  }, [map, recordsData]);

  // Effect for when the map is unmounted
  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);


  if (!isLoaded) {
    return <div className={containerStyle}>Loading...</div>; // Loading indicator
  }


  return (
    <div className={styles.mapContainer}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={initialCenter}
        onLoad={onLoad}
        zoom={initialZoom}
        onUnmount={onUnmount}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
      >
        {recordsData?.map((record) => (
          <Marker
            onClick={() => handleRecordClick(record)}
            key={record.id}
            position={{
              lat: parseFloat(record?.latitude),
              lng: parseFloat(record?.longitude),
            }}
            icon={{
              url: customMarkerIcon,
              scaledSize: new window.google.maps.Size(15, 21), // Adjust the size as needed
            }}
          />
        ))}
        {/* Additional child components */}
      </GoogleMap>

      {isModalOpen && (
        <ExpandedRecordDr6
          recordData={selectedRecord}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  );
}

export default React.memo(MapViewDr6);
