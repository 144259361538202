/* global localStorage */
// React imports and styles
import React, { useContext, useState, useEffect } from 'react'
import { LocalContext } from '../LocalContext'
import styles from './styles.module.css'
import ExpandedRecord from '../ExpandedRecord/index'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// Google maps imports and react query
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api'
import { useMutation } from 'react-query'
// Container style MAP
const containerStyle = { width: '100%', height: '50vh' }
function MapView({ apiData, dataSelector }) {
  // States
  const [selectedMarker, setSelectedMarker] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [infoModel, setInfoModel] = useState()
  const [mapViewBoolean, setMapViewBoolean] = useState(false)
  const [recordsState, setRecordsState] = useState(null)
  const { value, setValue } = useContext(LocalContext)
  const [loadingMapView, setLoadingMapView] = useState(false)
  const pageValue =  value?.pageMapView
  const [map, setMap] = useState(null)
  const [zoom, setZoom] = useState(3)
  const [center, setCenter] = useState({ lat: 43.142, lng: -85.049 })
  const queryValue = value?.queryData
  // Mutation Map View
  const [mapViewSum] = useMutation(({ page, queryData }) => apiData(page, queryData),
    { onSuccess: handleSuccess, onError: handleError })
  // If the mutation is sucessful the data will be send to the states and the map will center itself
  function handleSuccess(data) {
    // setLoadingMapView(true)
    const newPage = pageValue + 1
    setValue({...value, data , pageMapView: newPage})
    resetZoom()
    setSelectedMarker(null)
    setRecordsState(data?.data)
    const recordsInside = data?.data?.records
    const arrayOfZoom = []
    // This loop is required to push the results to the arrayOfZoom
    recordsInside.forEach(item => {
      if (!item.latitude && !item.longitude) { arrayOfZoom.push('noLatLong') } else if (item.country === recordsInside[0].country && item.province === recordsInside[0].province && item.city.toLowerCase() === recordsInside[0].city.toLowerCase()) {
        arrayOfZoom.push('countryProvinceCity')
      } else if (item.country === recordsInside[0].country && item.province === recordsInside[0].province) {
        arrayOfZoom.push('countryProvince')
      } else if (item.country === recordsInside[0].country && item.country === 'US') {
        if (item.province === 'AK' && item.province === 'HI') {
          arrayOfZoom.push('AKorHI')
        }
        arrayOfZoom.push('onlyUSA')
      } else {
        arrayOfZoom.push('notEqual')
      }
    })
    const countryProvinceCity = arrayOfZoom.includes('countryProvinceCity')
    const countryProvince = arrayOfZoom.includes('countryProvince')
    const alaskaOrHawai = arrayOfZoom.includes('AKorHI')
    const onlyUSA = arrayOfZoom.includes('onlyUSA')
    const notEqual = arrayOfZoom.includes('notEqual')
    // When the loop is complete, it checks if the array contains certain conditions, if any of them do, the zoom will change.
    if (countryProvinceCity === true && countryProvince === false && onlyUSA === false && notEqual === false) {
      setZoom(9)
    } else if (countryProvince === true && onlyUSA === false && notEqual === false) {
      setZoom(6)
    } else if (onlyUSA === true && notEqual === false) {
      if (alaskaOrHawai) {
        setZoom(3)
      } else {
        setZoom(4)
      }
    } else if (notEqual === true) {
      setZoom(2)
    }
    // sets the center of the map
    if (data?.status === 200) {
      if (data?.data.records[0]?.latitude && data?.data.records[0]?.longitude) {
        setCenter({ lat: parseFloat(data?.data?.records[0]?.latitude), lng: parseFloat(data?.data?.records[0]?.longitude) })
      } else if (data?.data?.records[1]?.latitude && data?.data?.records[1]?.longitude) {
        setCenter({ lat: parseFloat(data?.data?.records[1]?.latitude), lng: parseFloat(data?.data?.records[1]?.longitude) })
      } else if (data?.data?.records[2]?.latitude && data?.data?.records[2]?.longitude) {
        setCenter({ lat: parseFloat(data?.data?.records[2]?.latitude), lng: parseFloat(data?.data?.records[2]?.longitude) })
      } else if (data?.data?.records[3]?.latitude && data?.data?.records[3]?.longitude) {
        setCenter({ lat: parseFloat(data?.data?.records[3]?.latitude), lng: parseFloat(data?.data?.records[3]?.longitude) })
      }
    }
    setLoadingMapView(false)
  }
  function handleError() { }
//Mutation
const [mapViewSubstration] = useMutation(({ page, queryData }) => apiData(page, queryData),
{ onSuccess: handleSuccessSubstration, onError: handleErrorSubstration })
// If the mutation is sucessful the data will be send to the states and the map will center itself
function handleSuccessSubstration(data) {
// setLoadingMapView(true)
const newPage = pageValue - 1
setValue({...value, data , pageMapView: newPage})
resetZoom()
setSelectedMarker(null)
setRecordsState(data?.data)
const recordsInside = data?.data?.records
const arrayOfZoom = []
// This loop is required to push the results to the arrayOfZoom
recordsInside.forEach(item => {
  if (!item.latitude && !item.longitude) { arrayOfZoom.push('noLatLong') } else if (item.country === recordsInside[0].country && item.province === recordsInside[0].province && item.city.toLowerCase() === recordsInside[0].city.toLowerCase()) {
    arrayOfZoom.push('countryProvinceCity')
  } else if (item.country === recordsInside[0].country && item.province === recordsInside[0].province) {
    arrayOfZoom.push('countryProvince')
  } else if (item.country === recordsInside[0].country && item.country === 'US') {
    if (item.province === 'AK' && item.province === 'HI') {
      arrayOfZoom.push('AKorHI')
    }
    arrayOfZoom.push('onlyUSA')
  } else {
    arrayOfZoom.push('notEqual')
  }
})
const countryProvinceCity = arrayOfZoom.includes('countryProvinceCity')
const countryProvince = arrayOfZoom.includes('countryProvince')
const alaskaOrHawai = arrayOfZoom.includes('AKorHI')
const onlyUSA = arrayOfZoom.includes('onlyUSA')
const notEqual = arrayOfZoom.includes('notEqual')
// When the loop is complete, it checks if the array contains certain conditions, if any of them do, the zoom will change.
if (countryProvinceCity === true && countryProvince === false && onlyUSA === false && notEqual === false) {
  setZoom(9)
} else if (countryProvince === true && onlyUSA === false && notEqual === false) {
  setZoom(6)
} else if (onlyUSA === true && notEqual === false) {
  if (alaskaOrHawai) {
    setZoom(3)
  } else {
    setZoom(4)
  }
} else if (notEqual === true) {
  setZoom(2)
}
// sets the center of the map
if (data?.status === 200) {
  if (data?.data.records[0]?.latitude && data?.data.records[0]?.longitude) {
    setCenter({ lat: parseFloat(data?.data?.records[0]?.latitude), lng: parseFloat(data?.data?.records[0]?.longitude) })
  } else if (data?.data?.records[1]?.latitude && data?.data?.records[1]?.longitude) {
    setCenter({ lat: parseFloat(data?.data?.records[1]?.latitude), lng: parseFloat(data?.data?.records[1]?.longitude) })
  } else if (data?.data?.records[2]?.latitude && data?.data?.records[2]?.longitude) {
    setCenter({ lat: parseFloat(data?.data?.records[2]?.latitude), lng: parseFloat(data?.data?.records[2]?.longitude) })
  } else if (data?.data?.records[3]?.latitude && data?.data?.records[3]?.longitude) {
    setCenter({ lat: parseFloat(data?.data?.records[3]?.latitude), lng: parseFloat(data?.data?.records[3]?.longitude) })
  }
}
setLoadingMapView(false)
}
function handleErrorSubstration() { }

  const mapOnMount = () => {
    setLoadingMapView(true)
    setRecordsState(value?.data?.data)
    const recordsInside = value?.data?.data?.records
    const arrayOfZoom = []
     // This loop is required to push the results to the arrayOfZoom
     recordsInside.forEach(item => {
      if (!item.latitude && !item.longitude) { arrayOfZoom.push('noLatLong') } else if (item.country === recordsInside[0].country && item.province === recordsInside[0].province && item.city.toLowerCase() === recordsInside[0].city.toLowerCase()) {
        arrayOfZoom.push('countryProvinceCity')
      } else if (item.country === recordsInside[0].country && item.province === recordsInside[0].province) {
        arrayOfZoom.push('countryProvince')
      } else if (item.country === recordsInside[0].country && item.country === 'US') {
        if (item.province === 'AK' && item.province === 'HI') {
          arrayOfZoom.push('AKorHI')
        }
        arrayOfZoom.push('onlyUSA')
      } else {
        arrayOfZoom.push('notEqual')
      }
    })
    const countryProvinceCity = arrayOfZoom.includes('countryProvinceCity')
    const countryProvince = arrayOfZoom.includes('countryProvince')
    const alaskaOrHawai = arrayOfZoom.includes('AKorHI')
    const onlyUSA = arrayOfZoom.includes('onlyUSA')
    const notEqual = arrayOfZoom.includes('notEqual')
    // When the loop is complete, it checks if the array contains certain conditions, if any of them do, the zoom will change.
    if (countryProvinceCity === true && countryProvince === false && onlyUSA === false && notEqual === false) {
      setZoom(9)
    } else if (countryProvince === true && onlyUSA === false && notEqual === false) {
      setZoom(6)
    } else if (onlyUSA === true && notEqual === false) {
      if (alaskaOrHawai) {
        setZoom(3)
      } else {
        setZoom(4)
      }
    } else if (notEqual === true) {
      setZoom(2)
    }
    // sets the center of the map
    if (value?.data?.status === 200) {
      if (value?.data?.data.records[0]?.latitude && value?.data?.data.records[0]?.longitude) {
        setCenter({ lat: parseFloat(value?.data?.data?.records[0]?.latitude), lng: parseFloat(value?.data?.data?.records[0]?.longitude) })
      } else if (value?.data?.data?.records[1]?.latitude && value?.data?.data?.records[1]?.longitude) {
        setCenter({ lat: parseFloat(value?.data?.data?.records[1]?.latitude), lng: parseFloat(value?.data?.data?.records[1]?.longitude) })
      } else if (value?.data?.data?.records[2]?.latitude && value?.data?.data?.records[2]?.longitude) {
        setCenter({ lat: parseFloat(value?.data?.data?.records[2]?.latitude), lng: parseFloat(value?.data?.data?.records[2]?.longitude) })
      } else if (value?.data?.data?.records[3]?.latitude && value?.data?.data?.records[3]?.longitude) {
        setCenter({ lat: parseFloat(value?.data?.data?.records[3]?.latitude), lng: parseFloat(value?.data?.data?.records[3]?.longitude) })
      }
    }
    setLoadingMapView(false)
  }

  // onClick to set the info the the modal and to open it
  const onClickOpenMark = (selectedMarker) => {
    setInfoModel(selectedMarker)
    setMapViewBoolean(true)
    setOpenModal(true)
  }
  // Sets the new zoom when the user clicks zoom in and zoom out
  const newZooms = () => {
    if (map?.zoom && map?.zoom !== zoom) {
      if (zoom < map?.zoom) {
        setZoom(zoom + 1)
      } else if (zoom > map?.zoom) {
        setZoom(zoom - 1)
      }
    }
  }
  // Resets the zoom when the user moves the user uses pagination or a new query happens
  const resetZoom = () => {
    if (zoom !== 3) {
      if (zoom < 3) {
        const newResetZoom = 3 - zoom
        setZoom(zoom + newResetZoom)
      } else if (zoom > 3) {
        const newResetZoom = zoom - 3
        setZoom(zoom - newResetZoom)
      }
    }
  }
  // Pagination next onClick
  const nextSearchOnClick = () => {
    setLoadingMapView(true)
    setSelectedMarker(null)
    const newPage = pageValue + 1
    // setPage(newPage)
    mapViewSum({ page: newPage, queryData: queryValue })
  }
  // Pagination previous onClick
  const prevSearchOnClick = () => {
    setLoadingMapView(true)
    setSelectedMarker(null)
    const newPage = pageValue - 1
    // setPage(newPage)
    mapViewSubstration({ page: newPage, queryData: queryValue })
  }
  // Sets into the LS the data selector
  const mapViewLocalStorage = localStorage.getItem('map view') === `${dataSelector}`
  //  Checks if the local storage includes the correct data selector if it does the query is executed it,
  useEffect(() => {
    localStorage.setItem('map view', dataSelector)
    if (!mapViewLocalStorage) {
      localStorage.setItem('map view', dataSelector)
    }
    else if (queryValue) {
       mapOnMount()
    }
  }, [queryValue])/* eslint-disable-line react-hooks/exhaustive-deps */
  // This useEffect is to open the modal when the user clicks the marker
  useEffect(() => { if (openModal === true) { setTimeout(() => { setOpenModal(false) }, 100) } }, [openModal])
  const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey: 'AIzaSyD0AKt7KKjLie_FUX7rH1HAFXD9svOE6Go' })
  //  onLoad and onUnmount are use to mount and dismount the map, so there is not type errors
  const onLoad = React.useCallback(function callback(map) { setMap(map) }, [])
  const onUnmount = React.useCallback(function callback(map) { setMap(null) }, [])
  return (
    <>
      <div style={{ backgroundColor: '#3F3B88' }}> {loadingMapView === true ? <label style={{ fontSize: '16px', fontFamily: 'Roboto', color: '#FFFFFF' }}>Loading...</label> : ''}</div>
      {/* && value?.data?.data === undefined  */}
      {/*  */}
      {value === null || undefined || ''
        ? <div className={styles.noMapContainer}><label className={styles.noMapView}>No Map available</label></div>
        : isLoaded ? <GoogleMap onZoomChanged={newZooms} onLoad={onLoad} onUnmount={onUnmount} mapContainerStyle={containerStyle} center={center} zoom={zoom} options={{ scrollwheel: false, fullscreenControl: false, streetViewControl: false, mapTypeControl: false, clickableIcons: false }}>
          {recordsState?.records?.map(mark => (
            <Marker
              key={mark.id} position={{ lat: parseFloat(mark?.latitude), lng: parseFloat(mark?.longitude) }} onClick={() => { setSelectedMarker(mark) }}
            />
          ))}
          {selectedMarker && (
            <InfoWindow options={{ disableAutoPan: true }} style={{ backgroundColor: 'red' }} position={{ lat: parseFloat(selectedMarker?.latitude), lng: parseFloat(selectedMarker?.longitude) }} onCloseClick={() => { setSelectedMarker(null) }}>
              <div>
                <h2 onClick={() => onClickOpenMark(selectedMarker)} style={{ fontSize: '14px', fontFamily: 'Roboto', cursor: 'pointer' }}>{dataSelector === 'business' ? selectedMarker?.name : selectedMarker?.address ? selectedMarker?.address : selectedMarker?.city}</h2>
              </div>
            </InfoWindow>)}
        </GoogleMap> : ''}
      <ExpandedRecord openModal={openModal} infoModel={infoModel} dataSelector={dataSelector} mapView={mapViewBoolean} />
      {/* </LoadScript> */}
      {value === null || undefined || '' ? '' : <div className={styles.arrowsContainer}><FontAwesomeIcon className={pageValue === 1 ? styles.disabledArrowLeft : styles.enableArrowLeft} onClick={() => prevSearchOnClick()} icon={faAngleLeft} /><FontAwesomeIcon className={value?.data?.data?.total_cost !== 10 ? styles.disabledArrowRight : styles.enableArrowRight} onClick={() => nextSearchOnClick()} icon={faAngleRight} /></div>}
    </>
  )
}
export default React.memo(MapView)






