/* END POINTS APP */
// const { REACT_APP_API_HOST, REACT_APP_STRIPE_SERVER, REACT_APP_TOKEN_SERVER } = require('./environment');


// DEV
// const REACT_APP_API_HOST = "http://api.qa.datafiniti.co";
// const REACT_APP_STRIPE_SERVER = "http://localhost:9000/editBilling"
// const REACT_APP_TOKEN_SERVER = "http://localhost:9000/newToken"

// QA
// const REACT_APP_API_HOST = "http://api.qa.datafiniti.co";
// const REACT_APP_STRIPE_SERVER = "http://qa.portal.datafiniti.co/editBilling"
// const REACT_APP_TOKEN_SERVER = "http://qa.portal.datafiniti.co/newToken"

// Production
const REACT_APP_API_HOST = "https://api.datafiniti.co/v4"
const REACT_APP_STRIPE_SERVER = "https://portal.datafiniti.co/editBilling"
const REACT_APP_TOKEN_SERVER = "https://portal.datafiniti.co/newToken"


export const editBillingURL = REACT_APP_STRIPE_SERVER;
export const newTokenURL = REACT_APP_TOKEN_SERVER;
export const endpoints = {
  // API back end DF
  auth: "/auth",
  autoComplete: "/properties/complete",
  sendResetPasswordEmail: "/reset-password",
  resetPassword: "/reset-password",
  ValidatePlan: "/plans",
  plans: "/plans",
  signup: "/signup",
  account: "/account",
  people: "/people/search",
  peoplePaginate: "/people/paginate",
  propertyData: "/properties/search",
  propertyDataPaginate: "/properties/paginate",
  businessData: "/businesses/search",
  businessDataPaginated: "/businesses/paginate",
  product: "/products/search",
  productPaginate: "/products/paginate",
  downloads: "/downloads",
  updateAccount: "/account",
  updateAccountPlan: "/account",
  postman: "/postman",
  views: "/views",
  propertyById: "/properties/:record_id",
  defaultViews: "default-views",
};
export const API_HOST = REACT_APP_API_HOST;
