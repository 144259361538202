import React from 'react';
import { getAccount, updateAccountPlan } from '../../../../../../Api';
import BoxComponent from '../BoxComponent/BoxComponent';


const businessPlansProduction = [
    { id: 'business-1k-trial', name: "Trial", records: '1000 records', price: 'Free', yearlyPrice: 'Free', yearlyId: 'business-1k-trial-annual' },
    {
        id: 'business-1k-59', name: "1K", records: '1,000 records per month', price: '$59', yearlyPrice: '$637', yearlyId: 'business_1k_annual'
    },
    { id: 'business-10k-159', name: "10K", records: '10,000 records per month', price: '$159', yearlyPrice: '$1,717', yearlyId: 'business-10k-annual' },
    { id: 'business-100k-449', name: "100K", records: '100,000 records per month', price: '$499', yearlyPrice: '$4,849', yearlyId: 'business-100k-annual' },
    { id: 'business-1m-1999', name: "1M", records: '1,000,000 records per month', price: '$1999', yearlyPrice: '$21,589', yearlyId: 'business-1m-annual' },
    { id: 'business-unlimited-monthly', name: "Unlimited", records: 'Unlimited records per month', price: '$5000', yearlyPrice: '$60,000', yearlyId: 'business-unlimitedbusiness-60k' },
];

// const businessPlansQA = [
//     { id: 'business-1k-trial', name: "Trial", records: '1000 records', price: 'Free', yearlyPrice: 'Free', yearlyId: 'business-1k-trial-annual' },
//     {
//         id: 'business-1k-59', name: "1K", records: '1,000 records per month', price: '$59', yearlyPrice: '$637', yearlyId: 'business_test1k_annual'
//     },
//     { id: 'business-10k-159', name: "10K", records: '10,000 records per month', price: '$159', yearlyPrice: '$1,717', yearlyId: 'business-test10k-annual' },
//     { id: 'business-100k-449', name: "100K", records: '100,000 records per month', price: '$499', yearlyPrice: '$4,849', yearlyId: 'business-test100k-annual' },
//     { id: 'business-1m-1999', name: "1M", records: '1,000,000 records per month', price: '$1999', yearlyPrice: '$21,589', yearlyId: 'business-test1m-annual' },
//     { id: 'business-unlimited-monthly', name: "Unlimited", records: 'Unlimited records per month', price: '$5000', yearlyPrice: '$60,000', yearlyId: 'business-testunlimitedbusiness-60k' },
// ];


function BusinessData() {
    return (
        <BoxComponent
            getAccount={getAccount}
            updateAccountPlan={updateAccountPlan}
            title="Business data subscription"
            plans={businessPlansProduction}
            creditsKey="business_credits_used"
            planTypeKey="business"
        />
    );
}

export default BusinessData;
