import React, { useState } from 'react';
import styles from "./styles.module.css";

function Features({ record }) {

    const [isExpanded, setIsExpanded] = useState(false);
    // First, check if features are available and sort them if they are
    let sortedFeatures = record?.features ? [...record.features].sort((a, b) => a.key.localeCompare(b.key)) : [];

    // Determine which features to show based on the expanded state
    let featuresToShow = isExpanded ? sortedFeatures : sortedFeatures.slice(0, 10);


    return (
        <div className={styles.containerMainFeatures}>
            <h2 className={styles.titleFeatures}>
                Miscellaneous features
            </h2>
            {Array.isArray(featuresToShow) && featuresToShow.length > 0 ? featuresToShow.map((feature, index) => (
                <div className={styles.containerValuesFeatures} key={index}>
                    <span className={styles.keyValueFeatures}>
                        {feature.key}
                    </span>
                    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        {Array.isArray(feature.value) ? (

                            feature.value.map((val, idx) => (
                                <p className={styles.valueFeatures} key={idx}>
                                    {val}
                                </p>

                            ))
                        ) : (
                            <p className={styles.valueFeatures}>
                                N/A
                            </p>
                        )}
                    </div>
                </div>
            )) : (
                <p className={styles.valueFeatures}>No features available</p>
            )}
            {Array.isArray(record?.features) && record.features.length > 10 && (
                <button className={styles.expandButton} onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? 'Show Less' : `Expand All (${record.features.length})`}
                </button>
            )}
        </div>
    );
}


export default Features;
