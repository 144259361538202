import React from "react";
import styles from "../modal.module.css";
import caretDown from "../../images/caretDownGeneric.svg";

const unitOptions = [
  { label: "Square foot", value: "sq ft" },
  { label: "Acre", value: "ac" },
  { label: "Acres", value: "acs" },
  { label: "Square Meter", value: "sq m" },
  { label: "Square Yard", value: "sqyd" },
  // Add other units as needed
];

function SizeUnitSection({
  sizeUnitFilter,
  setSizeUnitFilter,
  handleUnitChange,
}) {
  return (
    <section className={styles.propertyTypeSection}>
      <h1 className={styles.titleSizeSection}>Size</h1>
      <div
        className={styles.specialAmenitiesRowContainer}
        style={{ gap: "24px" }}
      >
        {/* Floor Size Section */}
        <div className={styles.amenitiesColumn}>
          <label className={styles.labelsAmenities}>Floor Size</label>
          <div className={styles.amenitiesRow}>
            <input
              autoComplete="off"
              className={styles.inputAmenities}
              placeholder="Min"
              value={sizeUnitFilter.floorSize.min}
              name="min"
              onChange={(e) => setSizeUnitFilter(e, "floorSize")}
            />
            <input
              autoComplete="off"
              className={styles.inputAmenities}
              placeholder="Max"
              value={sizeUnitFilter.floorSize.max}
              name="max"
              onChange={(e) => setSizeUnitFilter(e, "floorSize")}
            />
            <div>
              <select
                className={styles.selectSize}
                value={sizeUnitFilter.floorSize.unit}
                name="unit"
                onChange={(e) => handleUnitChange(e, "floorSize")}
              >
                {unitOptions.map((option, idx) => (
                  <option key={idx} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <img
                src={caretDown}
                alt="arrow"
                className={styles.customArrowPrice}
              />
            </div>
          </div>
        </div>

        {/* Lot Size Section */}
        <div className={styles.amenitiesColumn}>
          <label className={styles.labelsAmenities}>Lot Size</label>
          <div className={styles.amenitiesRow}>
            <input
              autoComplete="off"
              className={styles.inputAmenities}
              placeholder="Min"
              value={sizeUnitFilter.lotSize.min}
              name="min"
              onChange={(e) => setSizeUnitFilter(e, "lotSize")}
            />
            <input
              autoComplete="off"
              className={styles.inputAmenities}
              placeholder="Max"
              value={sizeUnitFilter.lotSize.max}
              name="max"
              onChange={(e) => setSizeUnitFilter(e, "lotSize")}
            />
            <div>
              <select
                className={styles.selectSize}
                value={sizeUnitFilter.lotSize.unit}
                name="unit"
                onChange={(e) => handleUnitChange(e, "lotSize")}
              >
                {unitOptions.map((option, idx) => (
                  <option key={idx} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <img
                src={caretDown}
                alt="arrow"
                className={styles.customArrowPrice}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SizeUnitSection;
