import React, { useState, useEffect } from 'react';
import { authorize } from '../../Api';
import styles from './styles.module.css';
import DatafinitiLogo from '../HomeDR12/images/datafiniti-logo-white.svg';
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { CircularProgress } from '@material-ui/core';

function Login() {
    const [userData, setUserData] = useState({ email: "", password: "" });
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const [EmailState, setEmailState] = useState({ checked: false });
    const { checked } = EmailState;
    const history = useHistory();
    const [errorBackEnd, setErrorBackEnd] = useState();

    const goToForgetPassword = () => {
        const path = "/forgot-password";
        history.push(path);
    };

    const goToSignUp = () => {
        const path = "/sign-up";
        history.push(path);
    };

    const user = localStorage.getItem("dfp/email");
    const readLocalStorage = () => {
        if (user?.length) {
            setUserData({ email: user, password: "" });
            reset({ email: user, password: "" });
            setEmailState({ checked: true });
            window.localStorage.removeItem("dfp/email");
        }
    };

    const handleInputChange = ({ target }) => {
        setUserData({ ...userData, [target.name]: target.value });
    };

    const onChangeCheckbox = (event) => {
        const target = event.target;
        const value = target.name === "checked" ? target.checked : target.value;
        const name = target.name;
        setEmailState({ ...EmailState, [name]: value });
    };

    const [mutateLogin, { isLoading }] = useMutation(
        (userData) => authorize(userData),
        { onSuccess: handleSuccess, onError: handleError }
    );

    function handleSuccess(data) {
        if (data.status >= 400) {
            setErrorBackEnd(data.data.errors)
        } else {
            if (checked === true) {
                localStorage.setItem("dfp/email", userData.email);
            }
            localStorage.setItem("dfp/session", data?.data?.token);
            window.localStorage.removeItem("dfp/Gclid");
            window.localStorage.removeItem("dfp/webReferrer");
            window.localStorage.removeItem("dfp/dataType");
            history.push("/property-data");
        }
    }

    const onSubmitLogin = () => {
        if (isLoading === false) {
            mutateLogin(userData);
        }
    };

    function handleError(error) {
        setErrorBackEnd(error);
    }

    useEffect(() => {
        document.title = "Datafiniti Portal | Login";
        readLocalStorage();
    }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

    const getFirstErrorMessage = () => {
        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            const firstErrorKey = errorKeys[0];
            return errors[firstErrorKey].message;
        }
        return "";
    };

    return (
        <section className={styles.mainContainer}>
            <div className={styles.background}></div>
            <div className={styles.contentContainer}>
                <img src={DatafinitiLogo} alt="DatafinitiLogo" className={styles.logo} />
                <div className={styles.formContainer}>
                    <form className={styles.formLogin} onSubmit={handleSubmit(onSubmitLogin)}>
                        <div className={styles.inputContainer}>
                            <input
                                name="email"
                                placeholder="Email"
                                className={styles.inputField}
                                autoFocus
                                autoComplete="off"
                                {...register("email", {
                                    required: 'Email is a required field',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i,
                                        message: 'Invalid email format'
                                    },
                                    onChange: handleInputChange
                                })}
                                defaultValue={userData.email}
                            />
                            <input
                                name="password"
                                type="password"
                                placeholder="Password"
                                className={styles.inputField}
                                autoComplete="off"
                                {...register("password", {
                                    required: "Password is a required field",
                                    onChange: handleInputChange
                                })}
                            />
                        </div>

                        <div className={styles.checkboxContainer}>
                            <div style={{ display: "flex" }}>
                                <input
                                    type="checkbox"
                                    id="rememberMe"
                                    className={styles.checkbox}
                                    name="checked"
                                    onChange={onChangeCheckbox}
                                    checked={checked}
                                />
                                <label htmlFor="rememberMe" className={styles.checkboxLabel}>Remember me</label>
                            </div>

                            <div>
                                {getFirstErrorMessage() || errorBackEnd ?
                                    <span className={styles.errorMessage}>Invalid login. Please try again.</span> :
                                    ""}
                            </div>

                        </div>

                        <div className={styles.buttonContainer}>
                            <button
                                type="submit"
                                className={styles.loginButton}
                                disabled={isLoading}
                            >
                                {isLoading ? <CircularProgress className={styles.circularProgress} /> : "Login"}
                            </button>
                            <button
                                type="button"
                                onClick={goToSignUp}
                                className={styles.signupButton}
                            >
                                Sign up
                            </button>
                        </div>
                        <span onClick={goToForgetPassword} className={styles.forgotPassword}>Forgot Password</span>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default Login;
