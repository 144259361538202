import React, { useState, useEffect } from 'react'
import Rating from '@material-ui/lab/Rating'
import Card from '@material-ui/core/Card'
import { CardActionArea, Grid } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import * as stylesGridView from './GridView.module.css'
import ExpandedRecord from '../ExpandedRecord'
import './grid.css'
import Tooltip from '@material-ui/core/Tooltip';

export default function Cards({ dataRequest }) {
  const [openModal, setOpenModal] = useState(false)
  const [infoModel, setInfoModel] = useState({})
  const dataSelector = 'product'
  // styles
  const mediaQueryMedium = useMediaQuery('(max-width:1300px)')
  const smallQueryMedium = useMediaQuery('(max-width:360px)')
  useEffect(() => {
    if (openModal === true) { setTimeout(() => { setOpenModal(false) }, 50) }
  }, [openModal])
  return (
    <Grid container spacing={!smallQueryMedium ? 3 : 2}>
      {dataRequest?.data?.records?.map((column, i) => (
        <Grid key={i} item md={mediaQueryMedium === false ? 2 : 4} sm={6} xs={6}>
          <Tooltip title={column?.name ? column?.name : ''} placement='bottom'>
            <Card style={{ height: '100%', width: '100%' }} onClick={() => {
              setInfoModel({ column })
              setOpenModal(true)
            }}
            >
              <CardActionArea>
                <CardMedia component='img' image={!column?.imageURLs ? require('../img/noValidImageFound.jpeg') : column?.imageURLs[0]} />
                <CardContent>
                  <Typography align='center'>{column?.name?.length > 15 ? column?.name?.slice(0, 15) + '..' : column?.name}</Typography>
                  <Typography className={stylesGridView.titlePrices}>{!column?.prices ? 'No available' : '$' + column?.prices[0]?.amountMin}{!column?.prices ? '' : ' - $' + column?.prices[0]?.amountMax} </Typography>
                  <Grid container justifyContent='center' alignItems='center'>
                    <Rating className={stylesGridView.rating} name='read-only' value={!column?.reviews ? 0 : column?.reviews[0]?.rating} readOnly />
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          </Tooltip>
        </Grid>
      ))}
      <ExpandedRecord openModal={openModal} infoModel={infoModel} dataSelector={dataSelector} />
    </Grid>
  )
}
