import React, { useState } from 'react'
import styles from './styles.module.css'
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useQuery } from 'react-query'
import { getPostman } from '../../../Api'
import SnackbarComponent from '../../Snackbar/index'

function ApiSettings() {
    const user = localStorage.getItem('dfp/session')
    const [snackMessage, setSnackMessage] = useState({ type: 'error' })
    const { data: postmanDataCollection } = useQuery('postman', getPostman)


    const downloadAString = () => {
        const file = JSON.stringify(postmanDataCollection?.data, null, 3)
        const blob = new Blob([file], { type: 'text/plain;charset=utf-8' })
        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = 'datafiniti_postman_collection.json'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    return (
        <section style={{ marginTop: '18px' }}>
            <h1 className={styles.titleApiSettings}>API</h1>
            <span className={styles.tokenSpanApiSettings}>Token</span>
            <input className={styles.inputApiSettings} type="text" value={user || 'null'} />
            <div className={styles.containerButtonsApiSettings}>
                <CopyToClipboard
                    text={user}
                    onCopy={() => setSnackMessage({ type: 'success', myMessage: 'Copied token', update: Date.now() })}
                >
                    <button className={styles.buttonCopyApiSettings}>Copy token</button>
                </CopyToClipboard>
                <button onClick={downloadAString} className={styles.buttonCopyApiSettings}>Get Postman Collection</button>
            </div>
            <SnackbarComponent snackData={snackMessage} />
        </section>
    )
}

export default ApiSettings