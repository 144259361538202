import { withStyles } from '@material-ui/core/styles'
// import React and components
import React from 'react'
import { Typography, IconButton } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogContent from '@material-ui/core/DialogContent'

export const styles = (theme) => ({
  closeButton: { position: 'absolute', right: theme.spacing(1), top: theme.spacing(2.8), color: 'primary' },
  TitleDownload: {
    fontSize: '26px',
    fontFamily: 'Roboto',
    fontWeight: '600px',
    marginTop: '13px',
    '@media (max-width: 375px)': {
      fontSize: '23px'
    }
  }
})

export const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle {...other}>
      <Typography className={classes.TitleDownload}>{children}</Typography>
      {onClose ? (<IconButton aria-label='close' className={classes.closeButton} onClick={onClose}><CloseIcon className={classes.button} style={{ color: '#3F3B88', backgroundColor: 'transparent' }} /></IconButton>) : null}
    </MuiDialogTitle>
  )
})
export const DialogContent = withStyles((theme) => ({}))(MuiDialogContent)
