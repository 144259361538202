/* Material ui theme IMPORT */
import { createTheme } from "@material-ui/core/styles";

/* Custom Material theme  */
export const theme = createTheme({
  palette: {
    primary: {
      main: "#065E88",
    },
    secondary: {
      main: "#3F3B88",
    },
  },
  typography: {
    fontFamily: "Roboto",
  },
});
