import React, { useContext } from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { NavigationContext } from "../NavigationContext";
import cartShoppingSolid from "./navbarImages/cartShoppingSolid.svg";
import cartShoppingRegular from "./navbarImages/cartshoppingRegular.svg";
import houseSolid from "./navbarImages/houseSolid.svg";
import houseRegular from "./navbarImages/houseRegular.svg";
import peopleSolid from "./navbarImages/people-simple-solid.svg";
import peopleRegular from "./navbarImages/people-simple-regular.svg";
import buildingSolid from "./navbarImages/buildingSolid.svg";
import buildingRegular from "./navbarImages/buildingRegular.svg";
import downloadSolid from "./navbarImages/downloadSolid.svg";
import downloadRegular from "./navbarImages/downloadRegular.svg";
import questionMark from "./navbarImages/location-question.svg";
import settingsMarkRegular from "./navbarImages/sliders-regular.svg";
import settingsMarkSolid from "./navbarImages/sliders-solid.svg";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  list: {
    width: 250,
  },
  listItemIcon: {
    backgroundColor: "transparent", // Example: Setting transparent background
    color: "inherit", // Inherit the color from parent or set a specific color
  },

  // Adjust the icon size if necessary
  icon: {
    fontSize: "14px", // Adjust the size as per your design
  },
  businessIcon: {
    paddingLeft: "2px", // Add 1px padding to the left
  },
}));

const DrawerContent = ({ navigateTo, handleDrawerToggle }) => {
  const classes = useStyles();
  const { navigationValue } = useContext(NavigationContext);

  return (
    <div
      className={classes.list}
      role="presentation"
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
    >
      <List disablePadding>
        <ListItem
          selected={navigationValue === "property"}
          alignItems="center"
          button
          onClick={() => navigateTo("/property-data", "property")}
        >
          <ListItemIcon className={classes.listItemIcon}>
            {navigationValue === "property" ? (
              <img
                src={houseSolid}
                alt="propertyIcon"
                style={{ width: "14px", height: "14px" }}
              />
            ) : (
              <img
                src={houseRegular}
                alt="propertyIcon"
                style={{ width: "14px", height: "14px" }}
              />
            )}
          </ListItemIcon>
          <ListItemText primary="Property Data" />
        </ListItem>

        <ListItem
          button
          selected={navigationValue === "people"}
          onClick={() => navigateTo("/people-data", "people")}
        >
          <ListItemIcon className={classes.listItemIcon}>
            {navigationValue === "people" ? (
              <img
                src={peopleSolid}
                alt="peopleIcon"
                style={{ width: "17px", height: "17px" }}
              />
            ) : (
              <img
                src={peopleRegular}
                alt="peopleIcon"
                style={{ width: "17px", height: "17px" }}
              />
            )}
          </ListItemIcon>
          <ListItemText primary="People Data" />
        </ListItem>

        <ListItem
          button
          selected={navigationValue === "product"}
          onClick={() => navigateTo("/product-data", "product")}
        >
          <ListItemIcon className={classes.listItemIcon}>
            {navigationValue === "product" ? (
              <img
                src={cartShoppingSolid}
                alt="productIcon"
                style={{ width: "14px", height: "14px" }}
              />
            ) : (
              <img
                src={cartShoppingRegular}
                alt="productIcon"
                style={{ width: "14px", height: "14px" }}
              />
            )}
          </ListItemIcon>
          <ListItemText primary="Product Data" />
        </ListItem>

        <ListItem
          selected={navigationValue === "business"}
          button
          onClick={() => navigateTo("/business-data", "business")}
        >
          <ListItemIcon className={classes.listItemIcon}>
            {navigationValue === "business" ? (
              <img
                src={buildingSolid}
                alt="businessLogo"
                style={{ width: "14px", height: "14px" }}
              />
            ) : (
              <img
                src={buildingRegular}
                alt="businessLogo"
                style={{ width: "14px", height: "14px" }}
              />
            )}
          </ListItemIcon>
          <ListItemText primary="Business Data" />
        </ListItem>

        <ListItem
          button
          selected={navigationValue === "download"}
          onClick={() => navigateTo("/download", "download")}
        >
          <ListItemIcon className={classes.listItemIcon}>
            {navigationValue === "download" ? (
              <img
                src={downloadSolid}
                alt="downloadLogo"
                style={{ width: "14px", height: "14px" }}
              />
            ) : (
              <img
                src={downloadRegular}
                alt="downloadLogo"
                style={{ width: "14px", height: "14px" }}
              />
            )}
          </ListItemIcon>
          <ListItemText primary="Downloads" />
        </ListItem>

        <ListItem
          button
          selected={navigationValue === "settings"}
          onClick={() => navigateTo("/settings", "settings")}
        >
          <ListItemIcon className={classes.listItemIcon}>
            {navigationValue === "settings" ? (
              <img
                src={settingsMarkSolid}
                alt="settings"
                style={{ width: "14px", height: "14px" }}
              />
            ) : (
              <img
                src={settingsMarkRegular}
                alt="settings"
                style={{ width: "14px", height: "14px" }}
              />
            )}
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>

        <ListItem
          button
          onClick={() =>
            window.open("https://docs.datafiniti.co/docs/portal-introduction")
          }
        >
          <ListItemIcon className={classes.listItemIcon}>
            <img
              src={questionMark}
              alt="supportLogo"
              style={{ width: "14px", height: "14px" }}
            />
          </ListItemIcon>
          <ListItemText primary="Support Docs" />
        </ListItem>

        {/* <ListItem
          button
          onClick={() =>
            window.open(
              "https://docs.datafiniti.co/docs/api-introduction"
            )
          }
        >
          <ListItemIcon className={classes.listItemIcon}>
            <img
              src={codeIcon}
              alt="codeLogo"
              style={{ width: "14px", height: "14px" }}
            />
          </ListItemIcon>
          <ListItemText primary=" Developer Docs" />
        </ListItem> */}
        {/* Repeat for other items */}
      </List>
    </div>
  );
};

export default DrawerContent;
