// React, Local Context imports
import React, { useContext, useState } from 'react'
import { LocalContext } from '../LocalContext'
// Material ui  and styles imports
import { Typography, Button, Grid } from '@material-ui/core'
import * as styles from './styles'
import { makeStyles } from '@material-ui/core/styles'
import StartDownloads from '../StartDownload/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

export default function MatchingRecords({ downloadData, dataSelector }) {
  // styles classes
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  // Value Local Context State
  const { value } = useContext(LocalContext)
  const isButtonValid = value?.data?.data?.num_found
  const [dialog, setDialog] = useState({ dialogState: '', update: '' })
  const openDialog = () => { setDialog({ dialogState: true, update: Date.now() }) }
  return (
    <>
      <Typography className={classes.matchingTitle}>Matching Records</Typography>
      <Grid container alignItems='center' justifyContent='space-between' className={classes.matchingContainer}>
        <Typography className={classes.numberApiSearch}>{value?.isLoading ? 'Loading...' : value?.data?.data?.num_found ? value?.data?.data?.num_found.toLocaleString() : '0'}
        </Typography>
        <Button disabled={!isButtonValid} className={classes.buttonDownload} onClick={openDialog} variant='contained'>Download<FontAwesomeIcon style={{ marginLeft: '5%', marginBottom: '2%' }} icon={faDownload} /></Button>
      </Grid>
      <StartDownloads dialog={dialog} queryData={value} downloadData={downloadData} dataSelector={dataSelector} />
    </>
  )
}
