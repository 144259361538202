// react imports, components, and styles
import React, { useContext, useEffect, useState } from 'react'
import ApiSearch from '../ApiSearch/index'
import { postBusinessPaginated, postBusiness } from '../../Api/index'
import RecordsPreview from '../RecordsPreview'
import { LocalContext } from '../LocalContext'
import MatchingRecords from '../MatchingRecords'
// material ui and styles imports
import * as styles from './styles'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, Grid, Box } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
// import ArticleRoundedIcon from '@material-ui/icons/ArticleRounded';
import DescriptionIcon from '@material-ui/icons/Description';
import '../ApiSearch/DataStyles.css'
import FilterBuilder from '../FilterBuilder'

// Function to create the tab panel
function TabPanel(props) {
  const { children, value, index } = props
  return (
    <Grid>
      {value === index && (<Grid><Typography component='span'>{children}</Typography></Grid>)}
    </Grid>
  )
}
// AntTabs styles
const AntTabs = withStyles({ indicator: { backgroundColor: '#18D4D4' } })(Tabs)
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontSize: '15px',
    opacity: 0.5,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: '1%',
    fontFamily: ['Roboto'].join(','),
    '&:focus': {},
  },
  selected: { opacity: '0.5 !important' },
}))((props) => <Tab disableRipple {...props} />)

export default function Business({ containerState, setContainerState }) {
  const phoneScreen = useMediaQuery('(min-width:769px)');
  const smallPhoneScreen = useMediaQuery('(min-width:280px)');
  const dataSelector = 'business'
  // Value State local context
  const { setValue } = useContext(LocalContext)
  // styles material ui
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  // value tabs
  const [valueTabs, setValueTabs] = useState(0)
  // sets the value of the context to null when the component mounts
  useEffect(() => {
    localStorage.setItem("dfp/dataTypeNavigation", "business");
    document.title = "Datafiniti Portal | Business"
    setValue(null)
    window.scrollTo(0, 0)
    return () => {
      setValue(null)
    }
  }, []) /* eslint-disable-line react-hooks/exhaustive-deps */

  const clickChangeApi = () => { setValueTabs(1) }
  const clickChangeFilter = () => { setValueTabs(0) }
  // Business Data Gui
  return (
    <Box className={classes.containerBusinessData}>
      <Typography className={classes.searchTitle}>Search</Typography>
      <AppBar position='static' className={classes.appBarSearchApi} elevation={0}>
        <AntTabs value={valueTabs} aria-label='simple tabs example' indicatorColor='primary'>
          <AntTab style={{ minWidth: phoneScreen === true ? '131px' : smallPhoneScreen === true ? '75px' : '100px' }}
            label='Filter Builder' onClick={clickChangeFilter} />
          <AntTab style={{ minWidth: phoneScreen === true ? '131px' : smallPhoneScreen === true ? '75px' : '100px' }} label='API' onClick={clickChangeApi} />
          <AntTab style={{ minWidth: phoneScreen === true ? '131px' : smallPhoneScreen === true ? '75px' : '100px' }} icon={<HelpOutlineIcon />} onClick={() => window.open('https://docs.datafiniti.co/docs/business-data-portal')} />
          <AntTab style={{ minWidth: phoneScreen === true ? '131px' : smallPhoneScreen === true ? '75px' : '100px' }} icon={<DescriptionIcon />} onClick={() => window.open('https://docs.datafiniti.co/docs/business-data-schema')} />
        </AntTabs>
      </AppBar>
      <TabPanel value={valueTabs} index={0}>
        <FilterBuilder dataSelector={dataSelector} apiData={postBusinessPaginated} containerState={containerState} setContainerState={setContainerState} />
      </TabPanel>
      <TabPanel value={valueTabs} index={1}>
        <ApiSearch apiData={postBusinessPaginated} />
      </TabPanel>
      <MatchingRecords downloadData={postBusiness} dataSelector={dataSelector} />
      <RecordsPreview apiData={postBusinessPaginated} dataSelector={dataSelector} />
    </Box>
  )
}
