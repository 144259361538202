/* global localStorage */
import axios from "axios";
import { API_HOST, endpoints, editBillingURL, newTokenURL } from "./config";
// const environment = process.env.NODE_ENV;
/**
 * Function that creates a standardized Axios request handler
 * with all the required headers and parameters
 *
 * @returns axios request
 */
function getClient() {
  const token = localStorage.getItem("dfp/session");
  const headers = {};
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const client = axios.create({
    baseURL: API_HOST,
    validateStatus: false,
    headers,
  });
  return client;
}


export function newGetClient() {
  const newToken = localStorage.getItem("dfp/TokenSearch");
  const headers = {};
  if (newToken) {
    headers.Authorization = `Bearer ${newToken}`;
  }
  const client = axios.create({
    baseURL: API_HOST,
    validateStatus: false,
    headers,
  });
  return client;
}

/**
 * Post edit billing
 */
export async function postEditBilling(userData) {
  const res = await axios.post(editBillingURL, userData);
  return res;
}
/**
 * Post New Token
 */
export async function postNewToken(email) {
  const res = await axios.post(newTokenURL, email);
  return res;
}

/**
 * Login endpoint, it requires user data,
 * an email and a password
 * @param {Object} userData { email, password }
 */
export async function authorize(userData) {
  const res = await getClient().post(endpoints.auth, userData);
  return res;
}
/**
 * Initiate Password Reset for the email provided
 * @param {String} email
 */
export async function sendPasswordReset(email) {
  const res = await getClient().post(endpoints.sendResetPasswordEmail, email);
  return res;
}

/**
 * Resets the user's password for new provided value
 * @param {String} token API generated, obtained from url string query parameters
 * @param {String} password user new set password
 */
export async function resetPassword(token, password) {
  const res = await getClient().put(
    `${endpoints.resetPassword}/${token}`,
    password
  );
  return res;
}
/**
 * Sends an email to the user with a link to verify the user's email
 * @param {String} email user provided email
 */
export async function sendEmailVerification(email) {
  const res = await getClient().post(endpoints.signup, { email });
  return res;
}
/**
 * Validate the Plan
 */
export async function ValidatePlan(plan_id) {
  const res = await getClient().get(`${endpoints.ValidatePlan}/${plan_id}`);
  return res;
}
export async function getPlan() {
  const res = await getClient().get(endpoints.getPlan);
  return res;
}
export async function getViews() {
  const res = await getClient().get(`${endpoints.views}?page=1&limit=50`);
  return res;
}
export async function getDefaultViews() {
  const res = await getClient().get(endpoints.defaultViews);
  return res;
}

// export async function getPlan() {
//   const res = await getClient().get(endpoints.getPlan);
//   return res;
// }
/**
 * Create logged in user's account information
 */
export async function createAccount(jwt, userData) {
  const res = await getClient().post(`${endpoints.signup}/${jwt}`, userData);
  return res;
}

/**
 * Get logged in user's account information
 */
export async function getAccount() {
  const res = await getClient().get(endpoints.account);
  return res;
}
/**
 * Post products search paginated
 */
export async function postProductSearch(page, queryData) {
  const res = await getClient().post(
    `${endpoints.productPaginate}?page=${page}&limit=12`,
    queryData
  );
  return res;
}
export async function postProductLimit(page, limit, queryData) {
  const res = await getClient().post(
    `${endpoints.productPaginate}?page=${page}&limit=${limit}`,
    queryData
  );
  return res;
}
/**
 * Post products search
 */
export async function postProduct(queryData) {
  const res = await getClient().post(endpoints.product, queryData);
  return res;
}
/**
 * Post Business search
 */
export async function postBusiness(queryData) {
  const res = await getClient().post(endpoints.businessData, queryData);
  return res;
}
/**
 * Post Business Paginated
 */
export async function postBusinessPaginated(page, queryData) {
  const res = await getClient().post(
    `${endpoints.businessDataPaginated}?page=${page}`,
    queryData
  );
  return res;
}
/**
 * Post Property search
 */
export async function postProperty(queryData) {
  queryData.view = "property_sourceURLs";
  const res = await getClient().post(endpoints.propertyData, queryData);
  return res;
}

/**
 * Post Property search paginated
 */
export async function postPropertyPaginatedDr6(page, queryData) {
  queryData.view = "property_preview";
  const res = await newGetClient().post(
    `${endpoints.propertyDataPaginate}?page=${page}&limit=50`,
    queryData
  );
  return res;
}

export async function getPropertyById(id) {
  const url = endpoints.propertyById.replace(':record_id', id);
  const res = await getClient().get(url);
  return res;
}

/**
 * Post People search
 */
export async function postPeople(queryData) {
  const res = await getClient().post(endpoints.people, queryData);
  return res;
}
/**
 * Post Property search paginated
 */
export async function postPeoplePaginated(page, queryData) {
  const res = await getClient().post(
    `${endpoints.peoplePaginate}?page=${page}`,
    queryData
  );
  return res;
}

/**
 * get downloads
 */
export async function getDownloads(page, limit) {
  const res = await getClient().get(
    `${endpoints.downloads}?page=${page}&limit=${limit}&sort[date_started]=desc`
  );
  return res;
}
/**
 * get downloads by ID
 */
export async function getDownloadsById(id) {
  const res = await getClient().get(`${endpoints.downloads}/${id}`);
  return res;
}
/**
 * update the account of the user
 */
export async function updateAccount(queryData) {
  const res = await getClient().put(endpoints.updateAccount, queryData);
  return res;
}
/**
 * update the account of the user
 */
export async function updateAccountPlan(planState) {
  const res = await getClient().put(endpoints.updateAccountPlan, planState);
  return res;
}
/**
 * update the billing plan(stripe token) of the user
 */
export async function updateBillingPlan(token) {
  const res = await getClient().put(endpoints.updateAccountPlan, token);
  return res;
}

/**
 * Get postman collection
 */
export async function getPostman() {
  const res = await getClient().get(endpoints.postman);
  return res;
}

/**
 * Post Property Auto Complete
 */
export async function autoComplete(queryData) {
  const res = await getClient().post(`${endpoints.autoComplete}`, queryData);
  return res;
}
