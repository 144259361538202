/* Reset password styles */
export const app = {
  position: 'absolute',
  top: 0,
  width: '100%',
  padding: '3rem 1.5rem',
  background: 'linear-gradient(45deg, #553258 0%, #065E88 100%)',
  '@media (max-width: 1280px)': { padding: '0rem 0rem', height: '100%' },
  '@media (min-height: 860px)': { bottom: 0 }
}
// Reset Container
export const resetContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '43rem',
  maxWidth: '43rem',
  background: 'white',
  textAlign: 'center',
}
// Image Datafiniti
export const image = {
  width: '70%',
  marginTop: '40px',
  marginBottom: '40px',
  '@media (max-width: 640px)': { width: '85%' }
}
// resetPassword Title
export const resetPassword = {
  marginBottom: '40px',
}
// Field password resetpassword
export const fieldPassword = {
  width: '15rem',
  marginBottom: '7.5px',
  '@media (max-width: 360px)': { width: '82.5%' },
}
// Field check password 
export const confirmPassword = {
  width: '15rem',
  marginBottom: '40px',
  '@media (max-width: 360px)': { width: '82.5%' },
}
// Change Button Password resetpassword
export const changeButtonPassword = {
  cursor: 'pointer',
  marginTop: '40px',
  marginBottom: '40px',
  width: '15rem',
  '@media (max-width: 360px)': { width: '82.5%' }
}
// Back to login link
export const backToLogin = {
  cursor: 'pointer',
  marginBottom: '40px',
}
export const error = {
  color: 'red',
  fontFamily: 'Roboto',
  marginTop: '-0.3rem',
  fontSize: '13px',
}
/* ENDS reset password styles */
