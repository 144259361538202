export function processAddress(record) {
    let fullAddress = record.highlight['address'].toLowerCase();

    if (record.source) {
        if (record.source.city) {
            fullAddress += `, ${record.source.city}`;
        }
        if (record.source.province) {
            fullAddress += `, ${record.source.province}`;
        }
        if (record.source.postalCode) {
            fullAddress += `, ${record.source.postalCode}`;
        }
    }

    return fullAddress;
}
export function processPostalCode(record) {
    let fullPostalCode = record.highlight['postalCode'];

    if (record.source && record.source.province) {
        fullPostalCode += `, ${record.source.province}`;
    }

    return fullPostalCode;
}

export function processCity(record) {

    let fullCity = record.highlight['city'];

    if (record.source && record.source.province) {
        fullCity += `, ${record.source.province}`;
    }

    return fullCity;
}
export function processProvince(record) {

    let fullProvince = record.highlight['province'];

    if (record.source && record.source.country) {
        fullProvince += `, ${record.source.country}`;
    }

    return fullProvince;
}

export function processCountry(record) {

    let fullProvince = record.highlight['country'];
    return fullProvince;
}

export function processRecords(records) {
    const newHighLightState = { address: [], city: [], country: [], postalCode: [], province: [] };

    records.forEach(record => {
        for (const key in newHighLightState) {
            if (record.highlight && record.highlight[key]) {
                if (key === 'address') {
                    const fullAddress = processAddress(record);
                    newHighLightState[key].push(fullAddress);
                }
                else if (key === 'postalCode') {
                    const fullPostalCode = processPostalCode(record);
                    newHighLightState[key].push(fullPostalCode);
                }
                else if (key === 'city') {
                    const fullCity = processCity(record);
                    newHighLightState[key].push(fullCity);
                }
                else if (key === 'province') {
                    const fullCity = processProvince(record);
                    newHighLightState[key].push(fullCity);
                }
                else if (key === 'country') {
                    const fullCity = processCountry(record);
                    newHighLightState[key].push(fullCity);
                } else {
                    // Placeholder for potentially more complex logic for other keys
                    newHighLightState[key].push(record.highlight[key]);
                }
            }
        }
    });

    // Remove duplicates
    for (const key in newHighLightState) {
        newHighLightState[key] = [...new Set(newHighLightState[key])];
    }

    return newHighLightState;
}



export function handleSelection(newValue, setAllFilters) {
    let streetAddress, city, stateProvince, postalCode, country;

    let parts = newValue.includes(':') ? newValue.split(':').map(item => item.trim()) : ['', newValue.trim()];
    let type = parts[0];
    let value = parts[1] || '';

    const addressWithoutTags = value.replace(/<\/?strong>/g, '');

    const components = addressWithoutTags.split(/,\s*/);

    switch (type) {
        case 'Address':
            [streetAddress, city, stateProvince, postalCode] = components;
            setAllFilters({
                streetAddress: streetAddress ? [streetAddress] : [],
                city: city ? [city] : [],
                stateProvince: stateProvince ? [stateProvince] : [],
                postalCode: postalCode ? [postalCode] : []
            });
            break;
        case 'City':
            [city, stateProvince] = components;
            setAllFilters({
                city: city ? [city] : [],
                stateProvince: stateProvince ? [stateProvince] : [],
            });
            break;
        case 'Postal Code':
            [postalCode, stateProvince] = components;
            setAllFilters({
                postalCode: postalCode ? [postalCode] : [],
                stateProvince: stateProvince ? [stateProvince] : [],
            });
            break;
        case 'Province':
            [stateProvince, country] = components;
            setAllFilters({
                stateProvince: stateProvince ? [stateProvince] : [],
                country: country ? [country] : [],
            });
            break;
        case 'Country':
            [country] = components;
            setAllFilters({
                country: country ? [country] : [],
            });
            break;
        default:
            break;
    }
}
