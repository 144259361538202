import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import Step4 from './Step4/Step4';
import styles from './styles.module.css';

function SignUp() {
    const [currentStep, setCurrentStep] = useState(1);
    const [nonTrialSelected, setNonTrialSelected] = useState(false); // New state for tracking non-trial plan selection
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const planId = searchParams.get('plan_id');
    const dataType = searchParams.get('data_type');
    const googleID = searchParams.get('gclid');

    const handleNextStep = () => {
        setCurrentStep((prevStep) => (prevStep < 4 ? prevStep + 1 : prevStep));
    };

    const clearDfpItemsFromLocalStorage = () => {
        Object.keys(localStorage).forEach((key) => {
            if (key.startsWith('dfp/')) {
                localStorage.removeItem(key);
            }
        });
    };

    useEffect(() => {
        document.title = "Datafiniti Portal | Sign up";
        if (token) {
            localStorage.setItem('dfp/signup_token', token);
            setCurrentStep(2);
        } else if (planId && dataType) {
            const step2Data = {
                plan_id: planId,
                data_type: dataType,
            };

            // Extract gclid and add to step2Data if it exists

            if (googleID) {
                step2Data.gclid = googleID;
            }

            localStorage.setItem("dfp/planData", JSON.stringify(step2Data));
        } else {
            clearDfpItemsFromLocalStorage();
        }

        const savedData = JSON.parse(localStorage.getItem('dfp/step2_data'));
        if (savedData?.plan_ids && token) {
            setCurrentStep(4);
        } else if (savedData && !savedData.plan_ids && token) {
            setCurrentStep(3);
        }
    }, [location, token, planId, dataType, googleID]);


    return (
        <div className={styles.mainContainer}>
            <div className={styles.background}></div>
            <div className={styles.contentContainer}>
                <div className={`${styles.stepsContainer} ${styles[`step${currentStep}`]}`}>
                    <div className={`${styles.step} ${currentStep === 1 ? styles.active : styles.inactive}`}>
                        <Step1 isActive={currentStep === 1} />
                    </div>
                    <div className={`${styles.step} ${currentStep === 2 ? styles.active : styles.inactive}`}>
                        <Step2 isActive={currentStep === 2} onNext={handleNextStep} />
                    </div>
                    <div className={`${styles.step} ${currentStep === 3 ? styles.active : styles.inactive}`}>
                        <Step3 isActive={currentStep === 3} onNext={handleNextStep} token={token} clearDfpItemsFromLocalStorage={clearDfpItemsFromLocalStorage} setNonTrialSelected={setNonTrialSelected} />
                    </div>
                    <div className={`${styles.step} ${currentStep === 4 ? styles.active : styles.inactive}`} style={{ opacity: nonTrialSelected ? 0.5 : 0 }}>
                        <Step4 isActive={currentStep === 4} onNext={handleNextStep} token={token} clearDfpItemsFromLocalStorage={clearDfpItemsFromLocalStorage} setCurrentStep={setCurrentStep} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp;