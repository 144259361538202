export const propertyArray = [
  {
    name: 'address',
    type: 'text'
  },
  {
    name: 'brokers',
    type: 'nested'
  },
  {
    name: 'buildingName',
    type: 'text'
  },
  {
    name: 'city',
    type: 'text'
  },
  {
    name: 'country',
    type: 'country'
  },
  {
    name: 'dateAdded',
    type: 'date'
  },
  {
    name: 'dateUpdated',
    type: 'date'
  },
  {
    name: 'deposits',
    type: 'nested'
  },
  {
    name: 'descriptions',
    type: 'nested'
  },
  {
    name: 'domains',
    type: 'text'
  },
  {
    name: 'features',
    type: 'nested'
  },
  {
    name: 'fees',
    type: 'nested'
  },
  {
    name: 'floorSizeValue',
    type: 'text'
  },
  {
    name: 'floorSizeUnit',
    type: 'text'
  },
  {
    name: 'geoLocation',
    type: 'text'
  },
  {
    name: 'imageURLs',
    type: 'text'
  },
  {
    name: 'keys',
    type: 'text'
  },
  {
    name: 'languagesSpoken',
    type: 'text'
  },
  {
    name: 'latitude',
    type: 'text'
  },
  {
    name: 'leasingTerms',
    type: 'nested'
  }, {
    name: 'listingName',
    type: 'nested'
  }, {
    name: 'longitude',
    type: 'text'
  }, {
    name: 'lotSizeValue',
    type: 'text'
  }, {
    name: 'lotSizeUnit',
    type: 'text'
  }, {
    name: 'managedBy',
    type: 'nested'
  }, {
    name: 'mlsNumber',
    type: 'text'
  }, {
    name: 'mostRecentStatus',
    type: 'statusValuesProperty'
  },
  {
    name: 'mostRecentStatusDate',
    type: 'date'
  },
  {
    name: 'mostRecentPriceAmount',
    type: 'text'
  },
  {
    name: 'mostRecentPriceCurrency',
    type: 'text'
  },
  {
    name: 'mostRecentPriceDomain',
    type: 'text'
  },
  {
    name: 'mostRecentPriceSourceURL',
    type: 'text'
  },
  {
    name: 'mostRecentPriceDate',
    type: 'date'
  },
  {
    name: 'neighborhoods',
    type: 'text'
  },
  {
    name: 'numBathroom',
    type: 'text'
  },
  {
    name: 'numBedroom',
    type: 'text'
  },
  {
    name: 'numFloor',
    type: 'text'
  },
  {
    name: 'numPeople',
    type: 'text'
  },
  {
    name: 'numRoom',
    type: 'text'
  },
  {
    name: 'numUnit',
    type: 'text'
  },
  {
    name: 'parking',
    type: 'text'
  },
  {
    name: 'paymentTypes',
    type: 'text'
  },
  {
    name: 'people',
    type: 'nested',
    unique: 'peopleProperty'
  },
  {
    name: 'petPolicy',
    type: 'text'
  },
  {
    name: 'phones',
    type: 'text'
  },
  {
    name: 'postalCode',
    type: 'text'
  },
  {
    name: 'prices',
    type: 'nested',
    unique: 'pricesProperty'
  },
  {
    name: 'propertyTaxes',
    type: 'nested'
  },
  {
    name: 'propertyType',
    type: 'propertyType'
  },
  {
    name: 'province',
    type: 'state'
  },
  {
    name: 'reviews',
    type: 'nested',
    unique: 'propertyReviews'
  },
  {
    name: 'rules',
    type: 'text'
  },
  {
    name: 'statuses',
    type: 'nested'
  },
  {
    name: 'taxID',
    type: 'text'
  },
  {
    name: 'websiteIDs',
    type: 'text'
  },
  {
    name: 'yearBuilt',
    type: 'text'
  }
]
export const brokersList = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'agent',
    type: 'text'
  },
  {
    name: 'company',
    type: 'text'
  },
  {
    name: 'dateSeen',
    type: 'date'
  },
  {
    name: 'emails',
    type: 'text'
  },
  {
    name: 'phones',
    type: 'text'
  },
  {
    name: 'websites',
    type: 'text'
  }
]
export const depositsList = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'amount',
    type: 'text'
  },
  {
    name: 'currency',
    type: 'text'
  },
  {
    name: 'dateSeen',
    type: 'date'
  }
]

export const descriptionsListProperty = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'dateSeen',
    type: 'text'
  },
  {
    name: 'value',
    type: 'text'
  }
]

export const feesListProperty = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'amountMax',
    type: 'text'
  },
  {
    name: 'amountMin',
    type: 'text'
  },
  {
    name: 'currency',
    type: 'text'
  },
  {
    name: 'dateSeen',
    type: 'text'
  },
  {
    name: 'type',
    type: 'text'
  }
]
export const leasingTermsListProperty = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'dateSeen',
    type: 'date'
  },
  {
    name: 'value',
    type: 'text'
  }
]
export const manageByListProperty = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'dateSeen',
    type: 'date'
  },
  {
    name: 'value',
    type: 'text'
  }
]
export const peopleListProperty = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'dateSeen',
    type: 'date'
  },
  {
    name: 'email',
    type: 'text'
  },
  {
    name: 'name',
    type: 'date'
  },
  {
    name: 'phone',
    type: 'date'
  },
  {
    name: 'title',
    type: 'date'
  }
]

export const pricesListProperty = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'amountMin',
    type: 'text'
  },
  {
    name: 'amountMax',
    type: 'text'
  },
  {
    name: 'availability',
    type: 'text'
  },
  {
    name: 'comment',
    type: 'text'
  },
  {
    name: 'currency',
    type: 'text'
  },
  {
    name: 'date',
    type: 'date'
  },
  {
    name: 'dateSeen',
    type: 'date'
  },
  {
    name: 'isSale',
    type: 'text'
  },
  {
    name: 'isSold',
    type: 'text'
  },
  {
    name: 'minStay',
    type: 'text'
  },
  {
    name: 'period',
    type: 'date'
  },
  {
    name: 'pricePerSquareFoot',
    type: 'text'
  }
]
export const propertyTaxesListProperty = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'amount',
    type: 'text'
  },
  {
    name: 'currency',
    type: 'text'
  },
  {
    name: 'dateSeen',
    type: 'date'
  }
]
export const reviewsListProperty = [
  {
    name: 'No value'
  },
  {
    name: 'date'
  },
  {
    name: 'dateSeen'
  },
  {
    name: 'doRecommend'
  },
  {
    name: 'rating'
  },
  {
    name: 'title'
  },
  {
    name: 'userCity'
  },
  {
    name: 'username'
  },
  {
    name: 'userProvince',
    type: 'text'
  }
]
export const statusesListProperty = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'date'
  },
  {
    name: 'dateSeen'
  },
  {
    name: 'isUnderContract'
  },
  {
    name: 'type'
  }

]
export const propertyType = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'Apartment',
    value: 'Apartment'
  },
  {
    name: 'Barn',
    value: 'Barn'
  },
  {
    name: 'Bed Breakfast',
    value: 'Bed Breakfast'
  },
  {
    name: 'Boat',
    value: 'Boat'
  },
  {
    name: 'Boat Slip',
    value: 'Boat Slip'
  },
  {
    name: 'Building',
    value: 'Building'
  },
  {
    name: 'Bungalow',
    value: 'Bungalow'
  },
  {
    name: 'Cabin',
    value: 'Cabin'
  },
  {
    name: 'Camper/RV',
    value: 'Camper/RV'
  },
  {
    name: 'Campground',
    value: 'Campground'
  },
  {
    name: 'Car Wash',
    value: 'Car Wash'
  },
  {
    name: 'Castle',
    value: 'Castle'
  },
  {
    name: 'Cave',
    value: 'Cave'
  },
  {
    name: 'Chalet',
    value: 'Chalet'
  },
  {
    name: 'Co-op',
    value: 'Co-op'
  },
  {
    name: 'Commercial',
    value: 'Commercial'
  },
  {
    name: 'Condo',
    value: 'Condo'
  },
  {
    name: 'Cottage',
    value: 'Cottage'
  },
  {
    name: 'Development Site',
    value: 'Development Site'
  }
  ,
  {
    name: 'Dorm',
    value: 'Dorm'
  },
  {
    name: 'Duplex',
    value: 'Duplex'
  },
  {
    name: 'Earth House',
    value: 'Earth House'
  },
  {
    name: 'Entire Floor',
    value: 'Entire Floor'
  },
  {
    name: 'Estate',
    value: 'Estate'
  },
  {
    name: 'Farm',
    value: 'Farm'
  },
  {
    name: 'Flat',
    value: 'Flat'
  },
  {
    name: 'Guesthouse',
    value: 'Guesthouse'
  },
  {
    name: 'Homestay',
    value: 'Homestay'
  },
  {
    name: 'Hospitality',
    value: 'Hospitality'
  },
  {
    name: 'Hostal',
    value: 'Hostal'
  },
  {
    name: 'Hotel',
    value: 'Hotel'
  },
  {
    name: 'Igloo',
    value: 'Igloo'
  },
  {
    name: 'Industrial',
    value: 'Industrial'
  },
  {
    name: 'Inn',
    value: 'Inn'
  },
  {
    name: 'Island',
    value: 'Island'
  },
  {
    name: 'Land',
    value: 'Land'
  },
  {
    name: 'Lodge',
    value: 'Lodge'
  },
  {
    name: 'Loft',
    value: 'Loft'
  },
  {
    name: 'Manufactured Home',
    value: 'Manufactured Home'
  },
  {
    name: 'Marina',
    value: 'Marina'
  },
  {
    name: 'Mas',
    value: 'Mas'
  },
  {
    name: 'Masía',
    value: 'Masía'
  },
  {
    name: 'Mobile Home',
    value: 'Mobile Home'
  },
  {
    name: 'Motel',
    value: 'Motel'
  },
  {
    name: 'Multi-Family Dwelling',
    value: 'Multi-Family Dwelling'
  },
  {
    name: 'Nature lodge',
    value: 'Nature lodge'
  },
  {
    name: 'Other',
    value: 'Other'
  },
  {
    name: 'Parking',
    value: 'Parking'
  },
  {
    name: 'Posada',
    value: 'Posada'
  },
  {
    name: 'Quadruplex',
    value: 'Quadruplex'
  },
  {
    name: 'Religious Facility',
    value: 'Religious Facility'
  },
  {
    name: 'Rental Unit',
    value: 'Rental Unit'
  },
  {
    name: 'Resort',
    value: 'Resort'
  },
  {
    name: 'Retail',
    value: 'Retail'
  },
  {
    name: 'Riad',
    value: 'Riad'
  },
  {
    name: 'Room',
    value: 'Room'
  },
  {
    name: 'School',
    value: 'School'
  },
  {
    name: 'Self-Storage',
    value: 'Self-Storage'
  },
  {
    name: 'Shopping Center',
    value: 'Shopping Center'
  },
  {
    name: 'Single Family Dwelling',
    value: 'Single Family Dwelling'
  },
  {
    name: 'Sports & Entertainment',
    value: 'Sports & Entertainment'
  },
  {
    name: 'Studio',
    value: 'Studio'
  },
  {
    name: 'Tent',
    value: 'Tent'
  },
  {
    name: 'Tipi',
    value: 'Tipi'
  },
  {
    name: 'Townhouse',
    value: 'Townhouse'
  },
  {
    name: 'Train',
    value: 'Train'
  },
  {
    name: 'Treehouse',
    value: 'Treehouse'
  },
  {
    name: 'Triplex',
    value: 'Triplex'
  },
  {
    name: 'Unit',
    value: 'Unit'
  },
  {
    name: 'Vacation Rental',
    value: 'Vacation Rental'
  },
  {
    name: 'Villa',
    value: 'Villa'
  },
  {
    name: 'Yurt',
    value: 'Yurt'
  }
]
export const statusValuesProperty = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'For Sale',
    value: 'For Sale'
  },
  {
    name: 'Off Market',
    value: 'Off Market'
  },
  {
    name: 'Pending',
    value: 'Pending'
  },
  {
    name: 'Rent To Own',
    value: 'Rent To Own'
  },
  {
    name: 'Rental',
    value: 'Rental'
  },
  {
    name: 'Short Term Rental',
    value: 'Short Term Rental'
  },
  {
    name: 'Sold',
    value: 'Sold'
  },
  {
    name: 'Delisted (Withdrawn)',
    value: 'Delisted (Withdrawn)'
  },
  {
    name: 'Delisted (Withdrawn - Terminated)',
    value: 'Delisted (Withdrawn - Terminated)'
  },
  {
    name: 'Delisted (Withdrawn (Conditional))',
    value: 'Delisted (Withdrawn (Conditional))'
  },
  {
    name: 'Delisted (Withdrawn - Right to Re-List)',
    value: 'Delisted (Withdrawn - Right to Re-List)'
  },
  {
    name: 'Delisted (Temporarily No Showings)',
    value: 'Delisted (Temporarily No Showings)'
  },
  {
    name: 'Delisted (Temporarily Off-Market)',
    value: 'Delisted (Temporarily Off-Market)'
  },
  {
    name: 'Delisted (Expired)',
    value: 'Delisted (Expired)'
  },
  {
    name: 'Delisted (Cancelled)',
    value: 'Delisted (Cancelled)'
  },
]