// Matching Records styles Begins
// Container style
export const containerMatchingRecords = {
  '@media (max-width: 600px)': { marginLeft: '0rem' }
}
// Title style
export const matchingTitle = {
  fontFamily: 'Roboto',
  fontSize: '24px',
  marginBottom: '0.85rem',
  fontWeight: '500',
  color: '#000000',
  marginTop: '1.375rem',
  '@media (max-width: 600px)': { marginLeft: '1rem' }
}
// Matching container style
export const matchingContainer = {
  backgroundColor: '#FFFFFF',
  height: '80px',
  width: '100%'
}
// numberApiSearch styles
export const numberApiSearch = {
  fontFamily: 'Roboto',
  fontSize: '26px',
  color: '#43425D',
  fontWeight: '500',
  marginLeft: '1rem',
  '@media (max-width: 360px)': { marginLeft: '0.5rem', fontSize: '22px' }
}
// Button Download styles
export const buttonDownload = {
  marginRight: '1rem',
  fontSize: '18px',
  textTransform: 'none',
  width: '140px',
  height: '40px',
  backgroundColor: '#3F3B88',
  color: '#FFFFFF',
  fontFamily: 'Roboto',
  '@media (max-width: 769px)': {
    width: '110px',
    marginRight: '8px',

  }
}
// recordsTitle styles
export const recordsTitle = {
  fontFamily: 'Roboto',
  fontSize: '26px',
  color: '#43425D',
  marginTop: '2.25rem',
  marginBottom: '1.25rem',
  marginLeft: '2.5rem',
  '@media (max-width: 600px)': { marginLeft: '1rem' }
}
// Matching Records styles Ends
