// mutations.js
import { useMutation } from 'react-query';
import { createAccount, authorize } from '../../../Api';
import { useState } from 'react';
import jwt_decode from 'jwt-decode';

const useAccountCreation = (history, clearDfpItemsFromLocalStorage) => { // Accept history and clearDfpItemsFromLocalStorage
    const [errorMessage, setErrorMessage] = useState(""); // New error message state
    const [successMessage, setSuccessMessage] = useState(""); // New success message state

    const handleSuccessLogin = (data) => {
        localStorage.setItem("dfp/session", data?.data?.token);
    };

    const handleError = (error) => {
        setErrorMessage(error.message); // Set the error message
        history.push("/login"); // Redirect to login page
        clearDfpItemsFromLocalStorage();
    };

    const [login] = useMutation((userData) => authorize(userData), {
        onSuccess: handleSuccessLogin,
        onError: handleError,
    });

    const handleSuccess = (data, userData, redirectDashboard) => {
        if (data.status >= 400) {
            setErrorMessage(data?.data?.errors[0] || "Something went wrong, try again."); // Set the error message
            setTimeout(() => {
                history.push("/login"); // Redirect to login page
                clearDfpItemsFromLocalStorage();
            }, 2000); // 2-second delay before redirection
        } else {
            setErrorMessage(""); // Clear the error message
            setSuccessMessage("Account created successfully! Redirecting to dashboard..."); // Set the success message
            // Decode the token to get the email (sub)
            const decodedToken = jwt_decode(userData.jwt);
            const email = decodedToken.sub;
            const userDataLogin = { email: email, password: userData?.userData?.password }
            login(userDataLogin);
            redirectDashboard()
        }
    };

    const [mutateAccount, { isLoading }] = useMutation(
        ({ jwt, userData, redirectDashboard }) => createAccount(jwt, userData),
        {
            onSuccess: (data, variables) => handleSuccess(data, { ...variables, jwt: variables.jwt }, variables.redirectDashboard),
            onError: handleError,
        }
    );

    return { mutateAccount, isLoading, errorMessage, successMessage }; // Return the error message and success message state
};

export default useAccountCreation;
