import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles({

    notchedOutline: {
        borderColor: '#ABABAB !important',
        borderWidth: "1px !important"
    },

    noOptions: {
        fontSize: '12px', // Smaller font size for "No options" text
        padding: "0px !important",
        margin: "0px !important"
    },

    loading: {
        fontSize: '12px', // Smaller font size for "Loading" text
        padding: "0px !important",
        margin: "0px !important"
    },

    paper: {
        maxHeight: 'auto',
        paddingRight: "2.5px"
        // overflowY: 'visible'
    },

    option: {
        fontSize: '14px', // Set the font size for dropdown options
        padding: "5px 0px 5px 10px",
        '&[data-focus="true"]': {
            color: '#8122B4', // Change hover text color
            backgroundColor: "transparent"
        },
        '&[aria-selected="true"]': {
            backgroundColor: "transparent",
            color: '#8122B4', // Optional: if you also want to change the font color
        },
    },
    // input auto complete
    inputRoot: {
        width: '300px', // Adjust to your desired width
        minHeight: "30px",
        padding: "2px !important",
        borderRadius: "4px !important ",
        margin: "0px",

        '& .MuiAutocomplete-endAdornment': {
            right: "0px !important"
        },

        '& .MuiOutlinedInput-root': {
            padding: "0px !important",
            '& .MuiAutocomplete-input': {
                fontSize: "12px",
                padding: "0px",
                borderRadius: 0,

            },
            "& .MuiAutocomplete-inputRoot": {
                padding: "0px !important"
            },
            '&.Mui-focused fieldset': {
                borderColor: 'red !important', // Change to match your desired color or remove the line for no border color change
            },

        },
        '& .MuiAutocomplete-inputFocused': {
            fontSize: "12px",
            height: "25px",
            padding: "0px !important",
            paddingLeft: "8px !important"
        },

    },

});

export const listSubheaderStyle = {
    margin: '0',
    padding: '0',
    paddingLeft: "10px",
    fontWeight: "700",
    fontSize: '12px',
};
export const listStyle = {
    margin: '0',
    padding: '0',
    fontSize: '12px',
};




