export const peopleArray = [
  {
    name: 'address',
    type: 'text'
  },
  {
    name: 'birthDate',
    type: 'date'
  },
  {
    name: 'birthYear',
    type: 'text'
  },
  {
    name: 'businessCategories',
    type: 'businessCategories'
  },
  {
    name: 'businessName',
    type: 'text'
  },
  {
    name: 'city',
    type: 'text'
  },
  {
    name: 'country',
    type: 'country'
  },
  {
    name: 'dateAdded',
    type: 'date'
  },
  {
    name: 'dateUpdated',
    type: 'date'
  },
  {
    name: 'domains',
    type: 'text'
  },
  {
    name: 'emails',
    type: 'text'
  },
  {
    name: 'firstName',
    type: 'text'
  },
  {
    name: 'gender',
    type: 'text'
  },
  {
    name: 'interests',
    type: 'text'
  },
  {
    name: 'jobFunction',
    type: 'jobFunction'
  },
  {
    name: 'jobLevel',
    type: 'jobLevel'
  },
  {
    name: 'jobSkills',
    type: 'text'
  },
  {
    name: 'jobTitle',
    type: 'text'
  }, {
    name: 'keys',
    type: 'text'
  }, {
    name: 'lastName',
    type: 'text'
  }, {
    name: 'linkedinURL',
    type: 'text'
  }, {
    name: 'numEmployeesMin',
    type: 'text'
  }, {
    name: 'numEmployeesMax',
    type: 'text'
  }, {
    name: 'phones',
    type: 'text'
  }, {
    name: 'postalCode',
    type: 'text'
  },
  {
    name: 'personalEmails',
    type: 'text'
  },
  {
    name: 'primaryEmail',
    type: 'text'
  },
  {
    name: 'professionalEmails',
    type: 'text'
  },
  {
    name: 'province',
    type: 'state'
  },
  {
    name: 'sourceURLs',
    type: 'text'
  }
]

export const jobLevelValues = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    value: 'director',
    name: 'Director'
  },
  {
    value: 'entry',
    name: 'Entry'
  },
  {
    value: 'manager',
    name: 'Manager'
  },
  {
    value: 'owner',
    name: 'Owner'
  },
  {
    value: 'partner',
    name: 'Partner'
  },
  {
    value: 'senior',
    name: 'Senior'
  },
  {
    value: 'training',
    name: 'Training'
  },
  {
    value: 'unpaid',
    name: 'Unpaid'
  },
  {
    value: 'vp',
    name: 'Vp'
  }
]

export const jobFunctionsValues = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    value: 'customer_service',
    name: 'Customer service'
  },
  {
    value: 'design',
    name: 'Design'
  },
  {
    value: 'education',
    name: 'Education'
  },
  {
    value: 'engineering',
    name: 'Engineering'
  },
  {
    value: 'finance',
    name: 'Finance'
  },
  {
    value: 'health',
    name: 'Health'
  },
  {
    value: 'human_resources',
    name: 'Human Resources'
  },
  {
    value: 'legal',
    name: 'Legal'
  },
  {
    value: 'marketing',
    name: 'Marketing'
  },
  {
    value: 'media',
    name: 'Media'
  },
  {
    value: 'operations',
    name: 'Operations'
  },
  {
    value: 'real_estate',
    name: 'Real estate'
  },
  {
    value: 'sales',
    name: 'Sales'
  },
  {
    value: 'trades',
    name: 'Trades'
  }
]

export const statesValuesUS = [
  {
    name: 'no value',
    value: 'no value'
  },
  {
    name: 'Alabama',
    value: 'AL'
  },
  {
    name: 'Alaska',
    value: 'AK'
  },
  {
    name: 'American Samoa',
    value: 'AS'
  },
  {
    name: 'Arizona',
    value: 'AZ'
  },
  {
    name: 'Arkansas',
    value: 'AR'
  },
  {
    name: 'California',
    value: 'CA'
  },
  {
    name: 'Colorado',
    value: 'CO'
  },
  {
    name: 'Connecticut',
    value: 'CT'
  },
  {
    name: 'Delaware',
    value: 'DE'
  },
  {
    name: 'District Of Columbia',
    value: 'DC'
  },
  {
    name: 'Federated States Of Micronesia',
    value: 'FM'
  },
  {
    name: 'Florida',
    value: 'FL'
  },
  {
    name: 'Georgia',
    value: 'GA'
  },
  {
    name: 'Guam',
    value: 'GU'
  },
  {
    name: 'Hawaii',
    value: 'HI'
  },
  {
    name: 'Idaho',
    value: 'ID'
  },
  {
    name: 'Illinois',
    value: 'IL'
  },
  {
    name: 'Indiana',
    value: 'IN'
  },
  {
    name: 'Iowa',
    value: 'IA'
  },
  {
    name: 'Kansas',
    value: 'KS'
  },
  {
    name: 'Kentucky',
    value: 'KY'
  },
  {
    name: 'Louisiana',
    value: 'LA'
  },
  {
    name: 'Maine',
    value: 'ME'
  },
  {
    name: 'Marshall Islands',
    value: 'MH'
  },
  {
    name: 'Maryland',
    value: 'MD'
  },
  {
    name: 'Massachusetts',
    value: 'MA'
  },
  {
    name: 'Michigan',
    value: 'MI'
  },
  {
    name: 'Minnesota',
    value: 'MN'
  },
  {
    name: 'Mississippi',
    value: 'MS'
  },
  {
    name: 'Missouri',
    value: 'MO'
  },
  {
    name: 'Montana',
    value: 'MT'
  },
  {
    name: 'Nebraska',
    value: 'NE'
  },
  {
    name: 'Nevada',
    value: 'NV'
  },
  {
    name: 'New Hampshire',
    value: 'NH'
  },
  {
    name: 'New Jersey',
    value: 'NJ'
  },
  {
    name: 'New Mexico',
    value: 'NM'
  },
  {
    name: 'New York',
    value: 'NY'
  },
  {
    name: 'North Carolina',
    value: 'NC'
  },
  {
    name: 'North Dakota',
    value: 'ND'
  },
  {
    name: 'Northern Mariana Islands',
    value: 'MP'
  },
  {
    name: 'Ohio',
    value: 'OH'
  },
  {
    name: 'Oklahoma',
    value: 'OK'
  },
  {
    name: 'Oregon',
    value: 'OR'
  },
  {
    name: 'Palau',
    value: 'PW'
  },
  {
    name: 'Pennsylvania',
    value: 'PA'
  },
  {
    name: 'Puerto Rico',
    value: 'PR'
  },
  {
    name: 'Rhode Island',
    value: 'RI'
  },
  {
    name: 'South Carolina',
    value: 'SC'
  },
  {
    name: 'South Dakota',
    value: 'SD'
  },
  {
    name: 'Tennessee',
    value: 'TN'
  },
  {
    name: 'Texas',
    value: 'TX'
  },
  {
    name: 'Utah',
    value: 'UT'
  },
  {
    name: 'Vermont',
    value: 'VT'
  },
  {
    name: 'Virgin Islands',
    value: 'VI'
  },
  {
    name: 'Virginia',
    value: 'VA'
  },
  {
    name: 'Washington',
    value: 'WA'
  },
  {
    name: 'West Virginia',
    value: 'WV'
  },
  {
    name: 'Wisconsin',
    value: 'WI'
  },
  {
    name: 'Wyoming',
    value: 'WY'
  }
]

export const statesValuesCanada = [
  {
    name: "no value",
    value: "no value",
  },
  {
    name: "Alberta",
    value: "AB",
  },
  {
    name: "British Columbia",
    value: "BC",
  },
  {
    name: "Manitoba",
    value: "MB",
  },
  {
    name: "New Brunswick",
    value: "NB",
  },
  {
    name: "Newfoundland and Labrador",
    value: "NL",
  },
  {
    name: "Northwest Territories",
    value: "NT",
  },
  {
    name: "Nova Scotia",
    value: "NS",
  },
  {
    name: "Nunavut",
    value: "NU",
  },
  {
    name: "Ontario",
    value: "ON",
  },
  {
    name: "Prince Edward Island",
    value: "PE",
  },
  {
    name: "Quebec",
    value: "QC",
  },
  {
    name: "Saskatchewan",
    value: "SK",
  },
  {
    name: "Yukon Territory",
    value: "YT",
  },
];

export const businessCategories = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    value: 'accounting',
    name: 'Accounting'
  },
  {
    value: 'airlines/aviation',
    name: 'airlines/aviation'
  },
  {
    value: 'alternative dispute resolution',
    name: 'alternative dispute resolution'
  },
  {
    value: 'alternative medicine',
    name: 'alternative medicine'
  },
  {
    value: 'animation',
    name: 'animation'
  },
  {
    value: 'apparel & fashion',
    name: 'apparel & fashion'
  },
  {
    value: 'architecture & planning',
    name: 'architecture & planning'
  },
  {
    value: 'arts and crafts',
    name: 'arts and crafts'
  },
  {
    value: 'automotive',
    name: 'automotive'
  },
  {
    value: 'aviation & aerospace',
    name: 'aviation & aerospace'
  },
  {
    value: 'banking',
    name: 'banking'
  },
  {
    value: 'biotechnology',
    name: 'biotechnology'
  },
  {
    value: 'broadcast media',
    name: 'broadcast media'
  },
  {
    value: 'building materials',
    name: 'building materials'
  },
  {
    value: 'business supplies and equipment',
    name: 'business supplies and equipment'
  },
  {
    value: 'capital markets',
    name: 'capital markets'
  },
  {
    value: 'chemicals',
    name: 'chemicals'
  },
  {
    value: 'civic & social organization',
    name: 'civic & social organization'
  },
  {
    value: 'civil engineering',
    name: 'civil engineering'
  },
  {
    value: 'commercial real estate',
    name: 'commercial real estate'
  },
  {
    value: 'computer & network security',
    name: 'computer & network security'
  }
  ,
  {
    value: 'computer games',
    name: 'computer games'
  }
  ,
  {
    value: 'computer hardware',
    name: 'computer hardware'
  }
  ,
  {
    value: 'computer networking',
    name: 'computer networking'
  }
  ,
  {
    value: 'computer software',
    name: 'computer software'
  }
  ,
  {
    value: 'construction',
    name: 'construction'
  },
  {
    value: 'consumer electronics',
    name: 'consumer electronics'
  },
  {
    value: 'consumer goods',
    name: 'consumer goods'
  }
]

// computer games

// computer hardware

// computer networking

// computer software

// construction

// consumer electronics

// consumer goods

// consumer services

// cosmetics
// dairy
// defense & space
// design
// e-learning
// education management
// electrical/electronic manufacturing
// entertainment
// environmental services
// events services
// executive office
// facilities services
// farming
// financial services
// fine art
// fishery
// food & beverages
// food production
// fund-raising
// furniture
// gambling & casinos
// government administration
// government relations
// graphic design
// higher education
// hospital & health care
// hospitality
// human resources
// import and export
// individual & family services
// industrial automation
// information services
// information technology and services
// insurance
// international affairs
// international trade and development
// internet
// investment banking
// investment management
// judiciary
// law enforcement
// law practice
// legal services
// legislative office
// libraries
// logistics and supply chain
// luxury goods & jewelry
// machinery
// management consulting
// maritime
// market research
// marketing and advertising
// mechanical or industrial engineering
// media production
// medical devices
// medical practice
// mental health care
// military
// mining & metals
// motion pictures and film
// museums and institutions
// music
// nanotechnology
// newspapers
// non-profit organization management
// oil & energy
// online media
// outsourcing/offshoring
// package/freight delivery
// packaging and containers
// paper & forest products
// performing arts
// pharmaceuticals
// philanthropy
// photography
// plastics
// political organization
// primary/secondary education
// printing
// professional training & coaching
// program development
// public policy
// public relations and communications
// public safety
// publishing
// railroad manufacture
// ranching
// real estate
// recreational facilities and services
// religious institutions
// renewables & environment
// research
// restaurants
// retail
// security and investigations
// semiconductors
// shipbuilding
// sporting goods
// sports
// staffing and recruiting
// supermarkets
// telecommunications
// textiles
// think tanks
// tobacco
// translation and localization
// transportation/trucking/railroad
// utilities
// venture capital & private equity
// veterinary
// warehousing
// wholesale
// wine and spirits
// wireless
// writing and editing