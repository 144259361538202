import { create } from "zustand";
import { initialFilterStore } from './InitialValues'



const useFilterStore = create((set) => ({
  allFilters: {
    streetAddress: [],
    city: [],
    stateProvince: [],
    postalCode: [],
    country: "",
    price: {
      minValue: "",
      maxValue: "",
      currency: "",
    },
    rentalPrice: {
      minValue: "",
      maxValue: "",
      currency: "",
    },
    amenities: {
      // Add amenities filter
      beds: { min: "", max: "" },
      bath: { min: "", max: "" },
      floors: { min: "", max: "" },
      parkingSpaces: { min: "", max: "" },
      rooms: { min: "", max: "" },
      units: { min: "", max: "" },
      HVAC: [],
      exterior: [],
      roofing: [],
      // Add other amenities as needed
    },
    sizeUnits: {
      floorSize: {
        min: "",
        max: "",
        unit: "", // default unit for floor size
      },
      lotSize: {
        min: "",
        max: "",
        unit: "", // default unit for lot size
      },
    },
    requiredFields: [],

    mostRecentStatusDate: {
      startDate: "",
      endDate: "",
    },
    mostRecentStatusFirstDateSeen: {
      startDate: "",
      endDate: "",
    },
    mostRecentPriceDate: {
      startDate: "",
      endDate: "",
    },
    recordRecency: {
      dateAdded: "",
      dateUpdated: ""
    }
    // Add other filters here
  },

  // Specific setter for updating only the requiredFields array
  setRequiredFields: (requiredFields) => {
    set((state) => ({
      allFilters: {
        ...state.allFilters,
        requiredFields,
      },
    }));
  },

  resetAddressFilters: () => {
    set((state) => ({
      allFilters: {
        ...state.allFilters,
        streetAddress: [],
        city: [],
        stateProvince: [],
        postalCode: [],
        country: "",
      },
    }));
  },

  setMostRecentStatusDate: ({ startDate, endDate }) => {
    set((state) => ({
      allFilters: {
        ...state.allFilters,
        mostRecentStatusDate: {
          startDate: startDate,
          endDate: endDate,
        },
      },
    }));
  },

  setMostRecentStatusFirstDateSeen: ({ startDate, endDate }) => {
    set((state) => ({
      allFilters: {
        ...state.allFilters,
        mostRecentStatusFirstDateSeen: {
          startDate,
          endDate,
        },
      },
    }));
  },

  resetFilters: () => set({ allFilters: initialFilterStore }),

  setAllFilters: (newFilters) => {
    set((state) => ({
      allFilters: {
        ...state.allFilters,
        ...newFilters, // Uniformly updates all filters, including price
      },
    }));
  },
}));

export default useFilterStore;
