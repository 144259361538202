// dialog container styles
export const dialogTextLeftSide = {
  fontSize: '20px',
  display: 'inline',
  fontWeight: 500,
  fontFamily: 'Roboto',
  color: '#4D4F5C',
  '@media (max-width: 767px)': { display: 'block' }
}
export const dialogTextRightSide = {
  fontSize: '20px',
  display: 'inline',
  fontWeight: 400,
  fontFamily: 'Roboto',
  color: '#4D4F5C',
  '@media (max-width: 767px)': { display: 'block' }
}
export const buttonDownloads = {
  marginBottom: '20px',
  width: '215px',
  backgroundColor: '#3F3B88',
  color: 'white',
  marginLeft: '232px',
  height: '40px',
  textTransform: 'none',
  fontFamily: 'Roboto',
  fontSize: '18px',
  '@media (max-width: 766px)': { marginLeft: '0px' },
  '@media (max-width: 375px)': { marginLeft: '-9px' },
  '@media (max-width: 360px)': { width: '185px', marginLeft: '1.5px' },
  '@media (max-width: 280px)': { marginLeft: '-13px' }
}
export const cssTextField = {
  display: 'inline',
  marginLeft: '-5px',
  width: '250px',
  fontSize: '20px',
  fontFamily: 'Roboto'
}
export const cssTextFieldError = {
  color: 'red',
  paddingLeft: '0.5rem',
  fontSize: '15px',
  display: 'inline',
  '@media (max-width: 767px)': {
    display: 'block',
    marginTop: '-0.5px',
    paddingLeft: '0rem'
  }
}
// Containers inside Dialog
export const boxQuery = {
  display: 'inline',
  marginRight: '11.1rem',
  '@media (max-width: 767px)': {
    marginRight: '0rem',
    display: 'block'
  }
}
export const boxQueryData = {
  display: 'inline',
  '@media (max-width: 767px)': { display: 'block' }
}
export const boxApiCall = {
  display: 'inline',
  marginRight: '9rem',
  '@media (max-width: 767px)': {
    marginRight: '0rem',
    display: 'block'
  }
}
export const boxApiCallData = {
  display: 'inline',
  '@media (max-width: 767px)': { display: 'block' }
}
export const gridMatchingRecords = {
  marginBottom: '15px',
  '@media (max-width: 767px)': { marginBottom: '10px' }
}
export const boxMatchingRecords = {
  display: 'inline',
  paddingRight: '4.4rem',
  '@media (max-width: 767px)': {
    display: 'block',
    paddingRight: '0rem'
  }
}
export const boxMatchingRecordsData = {
  display: 'inline',
  '@media (max-width: 767px)': { display: 'block' }
}
export const fields = {
  paddingRight: '10.31rem',
  display: 'inline',
  '@media (max-width: 767px)': { display: 'block' }
}
export const boxIncludeFields = {
  display: 'inline',
  marginRight: '6.875rem',
  '@media (max-width: 767px)': {
    display: 'block',
    marginRight: '0rem'
  }
}
export const boxIncludeFieldsData = {
  display: 'inline',
  '@media (max-width: 767px)': { display: 'block' }
}
export const boxRecordsDownload = {
  display: 'inline',
  marginRight: '2.3rem',
  '@media (max-width: 767px)': {
    display: 'block',
    marginRight: '0rem'
  }
}
export const boxRecordsDownloadData = {
  display: 'inline',
  '@media (max-width: 767px)': { display: 'block' }
}
export const boxCreditsRequired = {
  marginRight: '5.1rem',
  display: 'inline',
  '@media (max-width: 767px)': {
    display: 'block',
    marginRight: '0rem'
  }
}
export const boxCreditsRequiredData = {
  display: 'inline',
  '@media (max-width: 767px)': { display: 'block' }
}
export const boxCostToDownload = {
  marginRight: '4.3rem',
  display: 'inline',
  '@media (max-width: 767px)': {
    display: 'block',
    marginRight: '0rem'
  }
}
export const boxCostToDownloadData = {
  display: 'inline',
  '@media (max-width: 767px)': { display: 'block' }
}