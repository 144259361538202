import React from 'react';
import { getAccount, updateAccountPlan } from '../../../../../../Api';
import BoxComponent from '../BoxComponent/BoxComponent';

// const propertyPlansQA = [
//     { id: 'property-1k-trial', name: "Trial", records: '1,000 records', price: 'Free', yearlyPrice: 'Free', yearlyId: 'property-1k-trial-yearly' },
//     { id: 'property-1k-119', name: "1K", records: '1,000 records per month', price: '$119', yearlyPrice: '$1285', yearlyId: 'property-test1k-annual' },
//     { id: 'property-10k-349', name: "10K", records: '10,000 records per month', price: '$349', yearlyPrice: '$3769', yearlyId: 'property-test10k-annual' },
//     { id: 'property-100k-899', name: "100K", records: '100,000 records per month', price: '$899', yearlyPrice: '$9,709', yearlyId: 'property-test100k-annual' },
//     { id: 'property-1m-3999', name: "1M", records: '1,000,000 records per month', price: '$3,999', yearlyPrice: '$43,189', yearlyId: 'property-test1m-annual' },
//     { id: 'property-unlimited-monthly', name: "Unlimited", records: 'Unlimited records per month', price: '$5000', yearlyPrice: '$60,000', yearlyId: 'property-testunlimitedproperty-60k' }
// ];

const propertyPlansProduction = [
    { id: 'property-1k-trial', name: "Trial", records: '1,000 records', price: 'Free', yearlyPrice: 'Free', yearlyId: 'property-1k-trial-yearly' },
    { id: 'property-1k-119', name: "1K", records: '1,000 records per month', price: '$119', yearlyPrice: '$1285', yearlyId: 'property-1k-annual' },
    { id: 'property-10k-349', name: "10K", records: '10,000 records per month', price: '$349', yearlyPrice: '$3769', yearlyId: 'property-10k-annual' },
    { id: 'property-100k-899', name: "100K", records: '100,000 records per month', price: '$899', yearlyPrice: '$9,709', yearlyId: 'property-100k-annual' },
    { id: 'property-1m-3999', name: "1M", records: '1,000,000 records per month', price: '$3,999', yearlyPrice: '$43,189', yearlyId: 'property-1m-annual' },
    { id: 'property-unlimited-monthly', name: "Unlimited", records: 'Unlimited records per month', price: '$5000', yearlyPrice: '$60,000', yearlyId: 'property-unlimitedproperty-60k' }
];



function PropertyData() {
    return (
        <BoxComponent
            getAccount={getAccount}
            updateAccountPlan={updateAccountPlan}
            title="Property data subscription"
            plans={propertyPlansProduction}
            creditsKey="property_credits_used"
            planTypeKey="property"
        />
    );
}

export default PropertyData;
