// navbarStyles.js
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  leftButtons: {
    display: "flex",
    alignItems: "center",
  },
  rightButtons: {
    display: "flex",
    alignItems: "center",
  },
  Typography: {
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: 0,
    textTransform: "none",
    marginLeft: "7px",
  },
  Button: {
    padding: "0px",
    marginRight: "24px",
    "&:hover": {
      backgroundColor: "transparent", // Set the background color to transparent on hover
      "& .MuiTouchRipple-root": {
        display: "none", // Hide the ripple effect
      },
    },
    "@media (max-width: 1400px)": {
      marginRight: "12px",
    },
  },

  LastButtonRight: {
    padding: "0px",
    marginRight: "38px",
    "&:hover": {
      backgroundColor: "transparent", // Set the background color to transparent on hover
      "& .MuiTouchRipple-root": {
        display: "none", // Hide the ripple effect
      },
    },
    "@media (max-width: 1400px)": {
      marginRight: "24px",
    },
  },

  menuItem: {
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    "& .MuiTouchRipple-root": {
      display: "none", // This line will disable the ripple effect
    },
  },
  imagePhone: {
    width: "154px",
    height: "23px",
    cursor: "pointer",
  },
  imageDesktop: {
    marginRight: "60px",
    width: "154px",
    height: "23px",
    cursor: "pointer",
    marginLeft: "40px",
    "@media (max-width: 1400px)": {
      marginRight: "30px",
      marginLeft: "20px",
    },
  },
  menuIconMobile: {
    marginLeft: "24px",
    padding: 0,
    right: "5px",
  },
  Toolbar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
    minHeight:"0px"
    // marginTop:"40px",
    // marginBottom:"38px"
  },
}));

export default useStyles;
