import React from "react";
import useStore from "../../Store/Store"; // Adjust the import path as needed
import GenericDropdown from "../GenericDropDown";

export const propertyTypeOptions = [
  "Any",
  "Single Family Home",
  "Commercial",
  "Mobile Home",
  "Multi Family Home",
  "Industrial",
  "Manufactured Home",
  "Apartment",
  "Retail",
  "Other",
  "Condo",
  "Land",
  "Townhome",
  "Farm",
  "Parking",
];

export const propertyTypeValueMap = {
  "Single Family Home": "Single Family Dwelling",
  "Multi Family Home": "Multi-Family Dwelling",
  "Townhome": "Townhouse",
  "Parking": "Parking",
  // Add other mappings as needed
};

function PropertyType() {
  const { propertyType, setPropertyType, isQueryLoading } = useStore();

  return (
    <GenericDropdown
      options={propertyTypeOptions}
      label="Property Type"
      setter={setPropertyType}
      selectedOptions={propertyType}
      valueMap={propertyTypeValueMap}
      isQueryLoading={isQueryLoading}
    />
  );
}

export default PropertyType;
