// react imports and components
import React, { useState, useEffect, useContext } from 'react'
import { useMutation } from 'react-query'
import Cards from './cards'
import ButtonsGrid from './Buttons'
// material ui and styles
import { Grid } from '@material-ui/core'
import * as stylesGridView from './GridView.module.css'
// React query imports
import { postProductLimit } from '../../Api/index'
import CircularProgress from '@mui/material/CircularProgress';
import { LocalContext } from '../LocalContext'

export default function GridView({ context }) {
  // page value
    // Local Context value
  const { value, setValue } = useContext(LocalContext)
  const [isLoadingGridView, setIsLoadingGridView] = useState(false)
  const pageValue =  value?.pageGridView
  const [secondTimePage, setSecondTimePage] = useState(1)
  // limit value
  const [limit] = useState(12)
  const [limitPage, setLimitPage] = useState(12)
  // mutation (post request)
  const [gridViewSum] = useMutation(({ page, limit, queryData }) => postProductLimit(page, limit, queryData),{ onSuccess: handleSuccess, onError: handleError})
  function handleSuccess(data) {
        const newPage = pageValue + 1
        setValue({...value,  GridViewDataPagination: data , pageGridView: newPage})
        setIsLoadingGridView(false)
    }
  function handleError() {}

  const [gridViewSubstration] = useMutation(({ page, limit, queryData }) => postProductLimit(page, limit, queryData),{ onSuccess: handleSuccessSubstration, onError: handleErrorSubstration})
  function handleSuccessSubstration(data) {
        const newPage = pageValue - 1
        setValue({...value,  GridViewDataPagination: data , pageGridView: newPage})
        setIsLoadingGridView(false)
    }
  function handleErrorSubstration() {}

  //   pagination next onClick
  const nextSearchOnClick = () => {
    setIsLoadingGridView(true)
    const newPage = pageValue + 1
    setSecondTimePage(newPage)
    const { queryData } = context
    gridViewSum({ page: newPage, limit, queryData })
    setLimitPage(limitPage + 12)
  }
  // pagination previous onClick
  const prevSearchOnClick = () => {
    setIsLoadingGridView(true)
    const newPage = pageValue - 1
    setSecondTimePage(newPage)
    const { queryData } = context
    gridViewSubstration({ page: newPage, limit, queryData })
    setLimitPage(limitPage - 12)
  }
  useEffect(() => {
    setSecondTimePage(1)
    setLimitPage(12)
    // when the components mounts, and the query changes, the mutation will execute
    // if (context?.dataSelector && context?.data) {
    //   const { queryData } = context
    //   gridView({ page, limit, queryData })
    // }
    window.scrollTo(0, 0)
  }, [context])/* eslint-disable-line react-hooks/exhaustive-deps */

  // if (value?.data?.data === undefined && isLoadingGridView === true) { return <div className={stylesGridView.noGridContainer}><label style={{ marginRight: '3px' }} className={stylesGridView.noGridView}>Loading...</label> <CircularProgress color="secondary" /></div> }
  if (isLoadingGridView === true) { return <div className={stylesGridView.noGridContainer}><label style={{ marginRight: '3px' }} className={stylesGridView.noGridView}>Loading...</label> <CircularProgress color="secondary" /></div> }

  if (value?.GridViewDataPagination?.data === undefined && isLoadingGridView === false) { return <div className={stylesGridView.noGridContainer}><label className={stylesGridView.noGridView}>No Grid View Available</label> </div> }

  return (
    <Grid style={{ backgroundColor: 'white' }}>
      <Cards dataRequest={value?.GridViewDataPagination} isLoading={isLoadingGridView} page={pageValue} />
      {/* <Cards dataRequest={data} isLoading={isLoading} /> */}
      <ButtonsGrid dataRequest={value?.GridViewDataPagination} page={secondTimePage} nextSearchOnClick={nextSearchOnClick} prevSearchOnClick={prevSearchOnClick} limitPage={limitPage} />
    </Grid>
  )
}
