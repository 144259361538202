import { create } from "zustand";
import { initialStore } from './InitialValues'

const useStore = create((set) => ({
  // variables
  page: 1,
  // numFoundResults: 0,
  isQueryLoading: false,
  firstRender: true,
  address: "",
  propertyType: ["Any"],
  mostRecentStatus: ["Any"],
  dataResults: [], // State to hold the data results
  queryStore: "", // Renamed state
  downloadData: {
    downloadQueryStore: "",
    download_id: ""
  },
  // new token
  newToken: "",

  // sets
  // token
  setNewToken: (newToken) => set(() => ({ newToken: newToken })),
  // Loading
  setIsQueryLoading: (loading) => set(() => ({ isQueryLoading: loading })),
  // Address
  setAddress: (newAddress) => set(() => ({ address: newAddress })),

  // Property
  setPropertyType: (newPropertyType) => set(() => ({ propertyType: newPropertyType })),

  setMostRecentStatus: (newStatus) => set(() => ({ mostRecentStatus: newStatus })),
  // Data results
  setDataResults: (newData) => set(() => ({ dataResults: newData })),
  // query
  setQueryStore: (newQuery) => set(() => ({ queryStore: newQuery })),
  // Update this setter to accept an object with query and id
  setDownloadQueryStore: ({ query, id }) => set((state) => ({
    downloadData: {
      ...state.downloadData,
      downloadQueryStore: query,
      download_id: id,
    },
  })),
  // page
  setPage: (newPage) => set(() => ({ page: newPage })),

  setFirstRender: (value) => set(() => ({ firstRender: value })),
  resetStore: () => set(() => ({ ...initialStore, firstRender: true })),
}));

export default useStore;
