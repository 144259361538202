import React, { useState } from "react";
import uploadIcon from "../images/uploadSVG.svg";
import styles from "./styles.module.css";
import useStore from "../../Store/Store"; // Import the store
import useFilterStore from "../../Store/filterStore"; // Import the store
import { useEffect } from "react";

function ExampleSearches() {
  const { setPropertyType, setMostRecentStatus, isQueryLoading } = useStore();

  const { setRequiredFields, setAllFilters, setMostRecentStatusFirstDateSeen } = useFilterStore();
  const [newQuery, setNewQuery] = useState()

  // RESIDENTIAL
  const onClickHomesForSale = () => {
    if (isQueryLoading) return;
    setNewQuery({
      propertyType: ["Single Family Dwelling"],
      mostRecentStatus: ["For Sale"],
      country: "US"
    })
  };
  const generateDateOffset = (days) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - days);
    return currentDate.toISOString().split('T')[0];
  };

  const onClickSoldLast30Days = () => {
    if (isQueryLoading) return;
    setNewQuery({
      propertyType: ["Single Family Dwelling"],
      mostRecentStatus: ["Sold"],
      mostRecentStatusFirstDateSeen: {
        startDate: generateDateOffset(30), // 30 days ago
        endDate: new Date().toISOString().split('T')[0], // Today's date
      },
      country: "US"
    })
  }

  const onClickHomesForRent = () => {
    if (isQueryLoading) return;
    setNewQuery({
      propertyType: ["Single Family Dwelling", "townhouse", "duplex", "triple", "Quadruplex"],
      mostRecentStatus: ["Rental"],
      country: "US"
    })
  }
  //COMMERCIAL
  const onClickCommercialForSale = () => {
    if (isQueryLoading) return;
    setNewQuery({
      propertyType: ["Commercial", "Shopping Center", "Retail"],
      mostRecentStatus: ["For Sale"],
      country: "US"
    })
  }
  const onClickCommercialSoldLast30Days = () => {
    if (isQueryLoading) return;
    setNewQuery({
      propertyType: ["Commercial", "Shopping Center", "Retail"],
      mostRecentStatus: ["For Sale"],
      mostRecentStatusFirstDateSeen: {
        startDate: generateDateOffset(30), // 30 days ago
        endDate: new Date().toISOString().split('T')[0], // Today's date
      },
      country: "US"
    })

  }
  const onClickCommercialRentOfficeSpace = () => {
    if (isQueryLoading) return;

    setNewQuery({
      propertyType: ["Office", "Retail"],
      mostRecentStatus: ["Rental"],
      country: "US"
    })
  }
  // Generic
  const onClickGenericHomeOwner = () => {
    if (isQueryLoading) return;

    setNewQuery({
      propertyType: ["Single Family Dwelling"],
      mostRecentStatus: ["Rental"],
      country: "US"
    })
  }

  const onClickGenericTransactions = () => {
    if (isQueryLoading) return;
    setNewQuery({
      propertyType: ["Single Family Dwelling"],
      transactions: ["transactions"]
    })
  }

  const updateFilters = () => {
    if (newQuery) {
      let filtersToUpdate = {};

      ['country', 'city', 'province', 'transactions', 'brokers'].forEach(key => {
        if (newQuery[key]) filtersToUpdate[key] = newQuery[key];
      });

      if (Object.keys(filtersToUpdate).length > 0) {
        if (filtersToUpdate.transactions || filtersToUpdate.brokers) {
          setRequiredFields(filtersToUpdate.transactions || filtersToUpdate.brokers);
        } else {
          setAllFilters(filtersToUpdate);
        }
      }

      if (newQuery.mostRecentStatusFirstDateSeen) {
        setMostRecentStatusFirstDateSeen(newQuery.mostRecentStatusFirstDateSeen);
      }

      if (newQuery.propertyType) {
        setPropertyType(newQuery.propertyType);
      }

      if (newQuery.mostRecentStatus) {
        setMostRecentStatus(newQuery.mostRecentStatus);
      }
    }
  };

  useEffect(updateFilters, [newQuery, setAllFilters, setPropertyType, setMostRecentStatus, setMostRecentStatusFirstDateSeen, setRequiredFields]);

  const disabledWrapperStyle = isQueryLoading
    ? { opacity: 0.5, } : {};
  const disabledCursorStyle = isQueryLoading
    ? { cursor: "not-allowed" }
    : { cursor: "pointer" };


  return (
    <section className={styles.containerExampleSearches}>
      <h1 className={styles.titleExampleSearches}>
        Example searches
      </h1>
      <p className={styles.pExampleSearches}>
        Select one of the options below to load in the given search parameters.
      </p>

      <div className={styles.containerResidential}>
        <h2 className={styles.titleResidential}>
          Residential
        </h2>
        <div className={styles.containerValuesResidential} style={{ ...disabledWrapperStyle }}>
          <div className={styles.containerHomesForSale} style={{ ...disabledCursorStyle }}
            onClick={isQueryLoading ? undefined : onClickHomesForSale}>
            <div style={{ display: "flex", justifyContent: "space-between", ...disabledCursorStyle }}>
              <span className={styles.spanStyle} style={{ ...disabledCursorStyle }}>
                Currently For Sale
              </span>
              <img src={uploadIcon} alt="upload"
                style={{ width: "12px", height: "13px", ...disabledCursorStyle, }} />
            </div>
            <p style={{ fontSize: "12px", ...disabledCursorStyle }}>
              Single Family Homes currently for sale in the US
            </p>
          </div>
          <div className={styles.containerHomesForSale} style={{ ...disabledCursorStyle }}
            onClick={isQueryLoading ? undefined : onClickSoldLast30Days}>
            <div style={{ display: "flex", justifyContent: "space-between", ...disabledCursorStyle, }}>
              <span style={{ fontSize: "12px", marginBottom: "8px", fontWeight: "500", ...disabledCursorStyle }}>
                Sold Last 30 Days
              </span>
              <img src={uploadIcon} alt="upload"
                style={{ width: "12px", height: "13px", ...disabledCursorStyle, }} />
            </div>
            <p style={{ fontSize: "12px", ...disabledCursorStyle }}>
              Single Family Homes sold in the US in the last 30 days
            </p>
          </div>
          {/*  */}
          <div className={styles.containerHomesForSale} style={{ ...disabledCursorStyle }}
            onClick={isQueryLoading ? undefined : onClickHomesForRent}>
            <div style={{ display: "flex", justifyContent: "space-between", ...disabledCursorStyle, }}>

              <span style={{ fontSize: "12px", marginBottom: "8px", fontWeight: "500", ...disabledCursorStyle }}>
                Currently For Rent
              </span>

              <img src={uploadIcon} alt="upload" style={{ width: "12px", height: "13px", ...disabledCursorStyle, }} />
            </div>

            <p style={{ fontSize: "12px", ...disabledCursorStyle }}>
              Residential property currently for rent in the US
            </p>
          </div>
        </div>

      </div>

      <div style={{ marginBottom: "30px" }}>
        <h3 style={{ margin: "0px 0px 12px 0px", fontSize: "14px", fontWeight: "500", }}>
          Commercial
        </h3>

        <div className={styles.containerValuesResidential} style={{ ...disabledWrapperStyle }}>
          <div className={styles.containerHomesForSale} style={{ ...disabledCursorStyle }}
            onClick={isQueryLoading ? undefined : onClickCommercialForSale}>
            <div style={{ display: "flex", justifyContent: "space-between", ...disabledCursorStyle, }}>

              <span style={{ fontSize: "12px", marginBottom: "8px", fontWeight: "500", ...disabledCursorStyle, }}>
                Currently For Sale
              </span>

              <img src={uploadIcon} alt="upload" style={{ width: "12px", height: "13px", ...disabledCursorStyle, }} />
            </div>

            <p style={{ fontSize: "12px", ...disabledCursorStyle }}>
              Commercial property currently for sale in the US
            </p>
          </div>

          <div className={styles.containerHomesForSale} style={{ ...disabledCursorStyle, }}
            onClick={isQueryLoading ? undefined : onClickCommercialSoldLast30Days}>
            <div style={{ display: "flex", justifyContent: "space-between", ...disabledCursorStyle, }}>

              <span style={{ fontSize: "12px", marginBottom: "8px", fontWeight: "500", ...disabledCursorStyle }}>
                Sold Last 30 Days
              </span>

              <img src={uploadIcon} alt="upload" style={{ width: "12px", height: "13px", ...disabledCursorStyle, }} />
            </div>
            <p style={{ fontSize: "12px", ...disabledCursorStyle }}>
              Commercial property sold in the US in the last 30 days
            </p>
          </div>

          <div className={styles.containerHomesForSale} style={{ ...disabledCursorStyle, }}
            onClick={isQueryLoading ? undefined : onClickCommercialRentOfficeSpace}>
            <div style={{ display: "flex", justifyContent: "space-between", ...disabledCursorStyle, }}>
              <span style={{ fontSize: "12px", marginBottom: "8px", fontWeight: "500", ...disabledCursorStyle }}>
                For Rent Office Space
              </span>
              <img src={uploadIcon} alt="upload" style={{ width: "12px", height: "13px", ...disabledCursorStyle, }} />
            </div>
            <p style={{ fontSize: "12px", ...disabledCursorStyle }}>
              Commercial Office spaces currently for rent in the US
            </p>
          </div>
        </div>
      </div>

      <section style={{ marginTop: "29px", marginBottom: "30px" }}>
        <h2 style={{ margin: "0px 0px 12px 0px", fontSize: "14px", fontWeight: "500", }}>
          Other Generic Use Cases
        </h2>
        <div style={{ ...disabledWrapperStyle }}
          className={styles.containerGenericCases}>

          <div className={styles.containerHomesForSale} style={{ ...disabledCursorStyle, }}
            onClick={isQueryLoading ? undefined : onClickGenericHomeOwner}>
            <div style={{ display: "flex", justifyContent: "space-between", ...disabledCursorStyle, }}>
              <span style={{ fontSize: "12px", marginBottom: "8px", fontWeight: "500", marginRight: "2.5px", ...disabledCursorStyle, }}>
                Home Owner Contact Information
              </span>
              <img src={uploadIcon} alt="upload" style={{ width: "12px", height: "13px", ...disabledCursorStyle, }} />
            </div>
            <p style={{ fontSize: "12px", ...disabledCursorStyle }}>
              Home owner contact data for residential homes in the US
            </p>
          </div>

          <div className={styles.containerHomesForSale} style={{ ...disabledCursorStyle, }}
            onClick={isQueryLoading ? undefined : onClickGenericTransactions}>
            <div style={{ display: "flex", justifyContent: "space-between", ...disabledCursorStyle, }}>
              <span style={{ fontSize: "12px", marginBottom: "8px", fontWeight: "500", marginRight: "2.5px", ...disabledCursorStyle, }}>
                Residential Transactions Data
              </span>
              <img src={uploadIcon} alt="upload" style={{ width: "12px", height: "13px", ...disabledCursorStyle, }} />
            </div>
            <p style={{ fontSize: "12px", ...disabledCursorStyle }}>
              Transactional data for residential homes in the US
            </p>
          </div>

        </div>
      </section>
    </section>
  )
};


export default ExampleSearches;
