
export const allTheOtherFields = [
  { label: "Appliances", value: "appliances" },
  { label: "Architectural Styles", value: "architecturalStyles" },
  { label: "Assessed Values", value: "assessedValues" },
  { label: "Building Name", value: "buildingName" },
  { label: "Categories", value: "categories" },
  { label: "CBSA Code", value: "cbsaCode" },
  { label: "CBSA Name", value: "cbsaName" },
  { label: "Census Block", value: "censusBlock" },
  { label: "Census Block Group", value: "censusBlockGroup" },
  { label: "Census Tract", value: "censusTract" },
  { label: "Congressional District House", value: "congressionalDistrictHouse" },
  { label: "City", value: "city" },
  { label: "Civil Division Code", value: "civilDivisionCode" },
  { label: "Civil Division Name", value: "civilDivisionName" },
  { label: "Country", value: "country" },
  { label: "County", value: "county" },
  { label: "County FIPS", value: "countyFIPS" },
  { label: "Current Owner Type", value: "currentOwnerType" },
  { label: "Date Added", value: "dateAdded" },
  { label: "Date Updated", value: "dateUpdated" },
  { label: "Deposits", value: "deposits" },
  { label: "Descriptions", value: "descriptions" },
  { label: "Domains", value: "domains" },
  { label: "Estimated Prices", value: "estimatedPrices" },
  { label: "Exterior Construction", value: "exteriorConstruction" },
  { label: "Exterior Features", value: "exteriorFeatures" },
  { label: "Features", value: "features" },
  { label: "Fees", value: "fees" },
  { label: "Floor Plans", value: "floorPlans" },
  { label: "Floor Size Value", value: "floorSizeValue" },
  { label: "Floor Size Unit", value: "floorSizeUnit" },
  { label: "Geo Location", value: "geoLocation" },
  { label: "Geo Quality", value: "geoQuality" },
  { label: "HVAC Types", value: "hvacTypes" },
  { label: "Instrument Number", value: "instrumentNumber" },
  { label: "Keys", value: "keys" },
  { label: "Languages Spoken", value: "languagesSpoken" },
  { label: "Latitude", value: "latitude" },
  { label: "Leasing Terms", value: "leasingTerms" },
  { label: "Legal Description", value: "legalDescription" },
  { label: "Legal Range", value: "legalRange" },
  { label: "Listing Name", value: "listingName" },
  { label: "Longitude", value: "longitude" },
  { label: "Lot Size Value", value: "lotSizeValue" },
  { label: "Lot Size Unit", value: "lotSizeUnit" },
  { label: "Managed By", value: "managedBy" },
  { label: "MLS Number", value: "mlsNumber" },
  { label: "Most Recent Broker Agent", value: "mostRecentBrokerAgent" },
  { label: "Most Recent Broker Company", value: "mostRecentBrokerCompany" },
  { label: "Most Recent Broker Date Seen", value: "mostRecentBrokerDateSeen" },
  { label: "Most Recent Broker Emails", value: "mostRecentBrokerEmails" },
  { label: "Most Recent Broker Phones", value: "mostRecentBrokerPhones" },
  { label: "Most Recent Price Amount", value: "mostRecentPriceAmount" },
  { label: "Most Recent Price Currency", value: "mostRecentPriceCurrency" },
  { label: "Most Recent Price Domain", value: "mostRecentPriceDomain" },
  { label: "Most Recent Price Date", value: "mostRecentPriceDate" },
  { label: "Most Recent Status", value: "mostRecentStatus" },
  { label: "Most Recent Status Date", value: "mostRecentStatusDate" },
  { label: "Most Recent Status First Date Seen", value: "mostRecentStatusFirstDateSeen" },
  { label: "MSA Code", value: "msaCode" },
  { label: "MSA Name", value: "msaName" },
  { label: "Neighborhoods", value: "neighborhoods" },
  { label: "Number of Bathrooms", value: "numBathroom" },
  { label: "Number of Bedrooms", value: "numBedroom" },
  { label: "Number of Floors", value: "numFloor" },
  { label: "Number of Parking Spaces", value: "numParkingSpaces" },
  { label: "Number of People", value: "numPeople" },
  { label: "Number of Rooms", value: "numRoom" },
  { label: "Number of Units", value: "numUnit" },
  { label: "Owner Occupied", value: "ownerOccupied" },
  { label: "Parking", value: "parking" },
  { label: "Parking Types", value: "parkingTypes" },
  { label: "Parcel Numbers", value: "parcelNumbers" },
  { label: "Payment Types", value: "paymentTypes" },
  { label: "People", value: "people" },
  { label: "Pet Policy", value: "petPolicy" },
  { label: "Phones", value: "phones" },
  { label: "Postal Code", value: "postalCode" },
  { label: "Prices", value: "prices" },
  { label: "Property Taxes", value: "propertyTaxes" },
  { label: "Property Type", value: "propertyType" },
  { label: "Province", value: "province" },
  { label: "Reviews", value: "reviews" },
  { label: "Roofing", value: "roofing" },
  { label: "Room Types", value: "roomTypes" },
  { label: "Rules", value: "rules" },
  { label: "Statuses", value: "statuses" },
  { label: "Subdivision", value: "subdivision" },
  { label: "Tax Exemptions", value: "taxExemptions" },
  { label: "Tax ID", value: "taxID" },
  { label: "Title", value: "title" },
  { label: "Topography Code", value: "topographyCode" },
  { label: "Trust Description", value: "trustDescription" },
  { label: "Website IDs", value: "websiteIDs" },
  { label: "Year Built", value: "yearBuilt" },
  { label: "Zoning", value: "zoning" }
];


export const mostCommonFieldsList = [
  { label: "Address", value: "address" },
  { label: "Brokers", value: "brokers" },
  { label: "Image URLs", value: "imageURLs" },
  { label: "Transactions", value: "transactions" }
];