import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import DatafinitiLogo from '../../HomeDR12/images/datafiniti-logo-white.svg';
import { businessPlans, propertyPlans, productPlans, peoplePlans } from './plans';
import CheckIcon from '@material-ui/icons/Check';
import useAccountCreation from '../mutation/mutation';
import { CircularProgress } from "@material-ui/core";
import { useHistory } from 'react-router-dom';

const Step3 = ({ onNext, isActive, token, clearDfpItemsFromLocalStorage, setNonTrialSelected }) => {
    const [selectedPlans, setSelectedPlans] = useState({});
    const [plansSaved, setPlansSaved] = useState(false);
    const history = useHistory();
    const { mutateAccount, isLoading, errorMessage, successMessage } = useAccountCreation(history, clearDfpItemsFromLocalStorage);
    const [accountCreated, isAccountCreated] = useState(false);

    const redirectDashboard = () => {
        isAccountCreated(true);
        setTimeout(() => {
            let basePath = "/property-data";
            history.push(basePath);
            localStorage.removeItem('dfp/step2_data');
            localStorage.removeItem("dfp/signup_token");
        }, 2000);
    };

    const checkNonTrialPlans = (plans) => {
        const nonTrialSelected = Object.values(plans).some(plan => !plan.includes('trial'));
        setNonTrialSelected(nonTrialSelected);
    };

    useEffect(() => {
        if (!token) {
            localStorage.removeItem('dfp/step2_data');
        } else {
            const savedData = JSON.parse(localStorage.getItem('dfp/step2_data'));
            if (savedData && savedData.plan_ids) {
                const plans = savedData.plan_ids.reduce((acc, plan) => {
                    const planType = plan.split('-')[0];
                    acc[planType] = plan;
                    return acc;
                }, {});
                setSelectedPlans(plans);
                setPlansSaved(true);
                checkNonTrialPlans(plans); // Check if non-trial plan is selected initially
            }

            if (isActive) {
                const planData = JSON.parse(localStorage.getItem('dfp/planData'));
                if (planData) {
                    setSelectedPlans(prevPlans => {
                        const updatedPlans = {
                            ...prevPlans,
                            [planData.data_type]: planData.plan_id
                        };
                        checkNonTrialPlans(updatedPlans); // Check if the selected plan is a trial
                        return updatedPlans;
                    });
                }
            }
        }
    }, [token, isActive]); /* eslint-disable-line react-hooks/exhaustive-deps */


    const handlePlanChange = (e, field) => {
        const value = e.target.value;

        setSelectedPlans((prevPlans) => {
            const updatedPlans = { ...prevPlans };

            if (value === "none") {
                delete updatedPlans[field];
            } else {
                updatedPlans[field] = value;
            }

            checkNonTrialPlans(updatedPlans); // Check if non-trial plan is selected

            return updatedPlans;
        });
        setPlansSaved(false); // Reset the saved state when changing plans
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const savedData = JSON.parse(localStorage.getItem('dfp/step2_data')) || {};
        savedData.plan_ids = Object.values(selectedPlans);
        localStorage.setItem('dfp/step2_data', JSON.stringify(savedData));
        setPlansSaved(true);

        const planData = JSON.parse(localStorage.getItem('dfp/planData')) || {};
        const googleID = planData.gclid;

        const userData = {
            first_name: savedData.firstName,
            last_name: savedData.lastName,
            email: savedData.email,
            organization: savedData.organization,
            password: savedData.password,
            plan_ids: Object.values(selectedPlans),
        };

        if (googleID) {
            userData.googleID = googleID;
        }

        const jwt = token;

        if (userData.plan_ids.every(plan => plan.includes('trial'))) {
            mutateAccount({ jwt, userData, redirectDashboard });
        } else {
            onNext();
        }
    };


    // Filter monthly and yearly plans for business
    const monthlyBusinessPlans = businessPlans.map(plan => ({
        ...plan,
        displayText: plan.id.includes('trial')
            ? `Trial - 1K records for 2 weeks (${plan.price})`
            : `${plan.name} - ${plan.records} (${plan.price} per month)`
    }));

    const yearlyBusinessPlans = businessPlans.filter(plan => plan.yearlyPrice !== 'Free' && plan.yearlyPrice !== undefined)
        .map(plan => ({
            ...plan,
            displayText: plan.id.includes('trial')
                ? `Trial - 1K records for 2 weeks (${plan.yearlyPrice})`
                : `${plan.name} - ${plan.records} (${plan.yearlyPrice} per year)`
        }));

    // Filter monthly and yearly plans for property
    const monthlyPropertyPlans = propertyPlans.map(plan => ({
        ...plan,
        displayText: plan.id.includes('trial')
            ? `Trial - 1K records for 2 weeks (${plan.price})`
            : `${plan.name} - ${plan.records} (${plan.price} per month)`
    }));

    const yearlyPropertyPlans = propertyPlans.filter(plan => plan.yearlyPrice !== 'Free' && plan.yearlyPrice !== undefined)
        .map(plan => ({
            ...plan,
            displayText: plan.id.includes('trial')
                ? `Trial - 1K records for 2 weeks (${plan.yearlyPrice})`
                : `${plan.name} - ${plan.records} (${plan.yearlyPrice} per year)`
        }));

    // Filter monthly and yearly plans for product
    const monthlyProductPlans = productPlans.map(plan => ({
        ...plan,
        displayText: plan.id.includes('trial')
            ? `Trial - 1K records for 2 weeks (${plan.price})`
            : `${plan.name} - ${plan.records} (${plan.price} per month)`
    }));

    const yearlyProductPlans = productPlans.filter(plan => plan.yearlyPrice !== 'Free' && plan.yearlyPrice !== undefined)
        .map(plan => ({
            ...plan,
            displayText: plan.id.includes('trial')
                ? `Trial - 1K records for 2 weeks (${plan.yearlyPrice})`
                : `${plan.name} - ${plan.records} (${plan.yearlyPrice} per year)`
        }));

    // Filter monthly and yearly plans for people
    const monthlyPeoplePlans = peoplePlans.map(plan => ({
        ...plan,
        displayText: plan.id.includes('trial')
            ? `Trial - 100 records for 2 weeks (${plan.price})`
            : `${plan.name} - ${plan.records} (${plan.price} per month)`
    }));

    const yearlyPeoplePlans = peoplePlans.filter(plan => plan.yearlyPrice !== 'Free' && plan.yearlyPrice !== undefined)
        .map(plan => ({
            ...plan,
            displayText: plan.id.includes('trial')
                ? `Trial - 1K records for 2 weeks (${plan.yearlyPrice})`
                : `${plan.name} - ${plan.records} (${plan.yearlyPrice} per year)`
        }));

    return (
        <div className={styles.container}>
            <img
                src={DatafinitiLogo}
                alt="DatafinitiLogo"
                className={styles.logo}
                style={{ opacity: isActive ? 1 : 0 }}
            />
            <div>
                {/* Display error message */}
                {errorMessage && (
                    <div className={styles.errorMessage}>
                        {errorMessage}
                    </div>
                )}
                {/* Display success message */}
                {successMessage && (
                    <div className={styles.successMessage}>
                        {successMessage}
                    </div>
                )}
                <h1 className={styles.heading}>Step 3: Subscription</h1>
                <span className={styles.subHeading}>Select a subscription.</span>
                <form className={styles.form} onSubmit={isLoading || errorMessage ? null : handleSubmit}>
                    <div className={styles.field}>
                        <span htmlFor="property" className={styles.span}>Property</span>
                        <select id="property" className={styles.select} disabled={!isActive} onChange={(e) => handlePlanChange(e, 'property')} value={selectedPlans.property || 'none'}>
                            <option value="none">None</option>
                            <optgroup label="-- Monthly --">
                                {monthlyPropertyPlans.map(plan => (
                                    <option key={plan.id} value={plan.id}>{plan.displayText}</option>
                                ))}
                            </optgroup>
                            <optgroup label="-- Yearly --">
                                {yearlyPropertyPlans.map(plan => (
                                    <option key={plan.yearlyId} value={plan.yearlyId}>{plan.displayText}</option>
                                ))}
                            </optgroup>
                        </select>
                    </div>
                    <div className={styles.field}>
                        <span htmlFor="people" className={styles.span}>People</span>
                        <select id="people" className={styles.select} disabled={!isActive} onChange={(e) => handlePlanChange(e, 'people')} value={selectedPlans.people || 'none'}>
                            <option value="none">None</option>
                            <optgroup label="-- Monthly --">
                                {monthlyPeoplePlans.map(plan => (
                                    <option key={plan.id} value={plan.id}>{plan.displayText}</option>
                                ))}
                            </optgroup>
                            <optgroup label="-- Yearly --">
                                {yearlyPeoplePlans.map(plan => (
                                    <option key={plan.yearlyId} value={plan.yearlyId}>{plan.displayText}</option>
                                ))}
                            </optgroup>
                        </select>
                    </div>
                    <div className={styles.field}>
                        <span htmlFor="product" className={styles.span}>Product</span>
                        <select id="product" className={styles.select} disabled={!isActive} onChange={(e) => handlePlanChange(e, 'product')} value={selectedPlans.product || 'none'}>
                            <option value="none">None</option>
                            <optgroup label="-- Monthly --">
                                {monthlyProductPlans.map(plan => (
                                    <option key={plan.id} value={plan.id}>{plan.displayText}</option>
                                ))}
                            </optgroup>
                            <optgroup label="-- Yearly --">
                                {yearlyProductPlans.map(plan => (
                                    <option key={plan.yearlyId} value={plan.yearlyId}>{plan.displayText}</option>
                                ))}
                            </optgroup>
                        </select>
                    </div>
                    <div className={styles.lastField}>
                        <span htmlFor="business" className={styles.span}>Business</span>
                        <select id="business" className={styles.select} disabled={!isActive} onChange={(e) => handlePlanChange(e, 'business')} value={selectedPlans.business || 'none'}>
                            <option value="none">None</option>
                            <optgroup label="-- Monthly --">
                                {monthlyBusinessPlans.map(plan => (
                                    <option key={plan.id} value={plan.id}>{plan.displayText}</option>
                                ))}
                            </optgroup>
                            <optgroup label="-- Yearly --">
                                {yearlyBusinessPlans.map(plan => (
                                    <option key={plan.yearlyId} value={plan.yearlyId}>{plan.displayText}</option>
                                ))}
                            </optgroup>
                        </select>
                    </div>
                    {isLoading || errorMessage || accountCreated ?

                        <button disabled className={styles.spinnerDisabledButton}> <CircularProgress className={styles.circularProgress} /></button> :

                        <button
                            type="submit"
                            className={`${styles.button} ${plansSaved ? styles.buttonSuccess : (isActive && Object.keys(selectedPlans).length > 0 ? styles.buttonEnabled : styles.buttonDisabled)}`}
                            disabled={!isActive || Object.keys(selectedPlans).length === 0}
                        >
                            {plansSaved ? <CheckIcon /> : 'Continue'}
                        </button>
                    }

                </form>
            </div>
        </div>
    );
};

export default Step3;
