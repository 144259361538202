import React, { useEffect } from "react";
import SearchFilters from "../SearchFilters";
import SearchResult from "../SearchResults";
import styles from "./styles.module.css";
import MapViewDr6 from "../mapViewDr6";

export default function PropertyDataDR6() {
  useEffect(() => {
    localStorage.setItem("dfp/dataTypeNavigation", "property");
  }, []);

  return (
    <div className={styles.propertyDataContainer}>
      <SearchFilters />
      <div className={styles.containerPropertyAndMap}>
        <div className={styles.searchResultContainerProperty}>
          <SearchResult />
        </div>
        <div className={styles.mapContainer}>
          <MapViewDr6 />
        </div>
      </div>
    </div>
  );
}
