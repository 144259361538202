export const initialFilterStore = {
    streetAddress: [],
    city: [],
    stateProvince: [],
    postalCode: [],
    country: "",
    price: { minValue: "", maxValue: "", currency: "" },
    rentalPrice: { minValue: "", maxValue: "", currency: "", },
    amenities: {
        beds: { min: "", max: "" },
        bath: { min: "", max: "" },
        floors: { min: "", max: "" },
        parkingSpaces: { min: "", max: "" },
        rooms: { min: "", max: "" },
        units: { min: "", max: "" },
        HVAC: [],
        exterior: [],
        roofing: [],
    },
    sizeUnits: {
        floorSize: { min: "", max: "", unit: "" },
        lotSize: { min: "", max: "", unit: "" },
    },
    requiredFields: [],
    mostRecentStatusDate: {
        startDate: "",
        endDate: "",
    },
    mostRecentStatusFirstDateSeen: {
        startDate: "",
        endDate: "",
    },
    mostRecentPriceDate: {
        startDate: "",
        endDate: "",
    },
    recordRecency: {
        dateAdded: "",
        dateUpdated: ""
    }
};

export const initialStore = {
    page: 1,
    isQueryLoading: false,
    address: "",
    propertyType: ["Any"],
    mostRecentStatus: ["Any"],
    dataResults: [],
    queryStore: "",
    downloadData: {
        downloadQueryStore: "",
        download_id: "",
    },
    newToken: "",
}