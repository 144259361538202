import React, { useState } from 'react';
import codeIcon from "../SearchFilters/images/code.svg";
import ModalApiCall from './modalApiCall';
import useStore from "../Store/Store";

// State to manage modal visibility

// Function to toggle modal visibility
function ViewApiCall() {

    const { queryStore, isQueryLoading } = useStore();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const toggleModal = () => {
        if (!isQueryLoading && queryStore) {
            setIsModalVisible(!isModalVisible);
        }
    };

    const isDisabled = !queryStore || isQueryLoading;

    return (
        <div style={{ display: 'flex', alignSelf: "flex-end", paddingBottom: "5px" }}>
            <span
                style={{
                    color: "#8122B4",
                    marginRight: "8px",
                    fontWeight: "400",
                    cursor: isDisabled ? "not-allowed" : "pointer",
                    opacity: isDisabled ? 0.5 : 1, // Reduce opacity if disabled
                }}
                onClick={isDisabled ? null : toggleModal} // Prevent function call if disabled
            >
                View API call
            </span>
            <img
                src={codeIcon}
                alt="codeIcon"
                style={{
                    width: "18px",
                    height: "14px",
                    alignSelf: "center",
                    cursor: isDisabled ? "not-allowed" : "pointer",
                    opacity: isDisabled ? 0.5 : 1, // Reduce opacity if disabled
                }}
                onClick={isDisabled ? null : toggleModal} // Prevent function call if disabled
            />

            {isModalVisible && <ModalApiCall queryStore={queryStore} onClose={() => setIsModalVisible(false)} isQueryLoading={isQueryLoading} />}
        </div>
    )
}

export default ViewApiCall