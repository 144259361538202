/* React and components imports */
import React, { useState, useEffect } from 'react'
import SnackbarComponent from '../Snackbar'
/* Api imports */
import { sendPasswordReset } from '../../Api'
/* Router imports */
import { useHistory } from 'react-router-dom'
/* Use form  import  */
import { useForm } from 'react-hook-form'
/* React query  import  */
import { useMutation } from 'react-query'
import styles from './styles.module.css';

export default function ForgotPassword() {
  /* Send email state(email) */
  const [email, setEmail] = useState('')
  /* component styles */
  /* State of the snackMessage */
  const [snackMessage, setSnackMessage] = useState({
    type: 'error'
  })
  /* state of the email form */
  // const { register, handleSubmit, reset, formState: { errors, isValid }, watch }
  const { register, handleSubmit, reset, formState: { errors } } = useForm(
  )
  /* history from the router and path */
  const history = useHistory()
  const path = '/login'
  /* function  use to redirect to login after 3 seconds */
  const backToLoginRedirect = () => {
    setTimeout(() => { history.push(path) }, 3000)
  }
  /* function  use to redirect to login  */
  const backToLogin = () => { history.push(path) }
  /* Mutate hook react query */
  const [mutate, { isLoading }] = useMutation(
    ({ email }) => sendPasswordReset(email),
    { onSuccess: handleSuccess, onError: handleError }
  )
  // if the mutation is sucessful, shows snackbar depending of the status
  function handleSuccess(data, Data) { // Data comes from react hook form
    /* Snackbar message if something goes wrong */
    if (data.status >= 400) {
      setSnackMessage({
        type: 'error',
        myMessage: 'Something went wrong, try again.',
        update: Date.now()
      })
    } else {
      /* Resets the state and form */
      reset();
      /* Sets the message to be send to the snackbar */
      setSnackMessage({
        type: 'success',
        myMessage: 'You will receive and email shortly with the instructions.',
        update: Date.now()
      })
      /* returns to the login  */
      backToLoginRedirect()
    }
  }
  // If something goes wrong with the mutation, shows snackbar error
  function handleError(error) {
    setSnackMessage({
      type: 'error', myMessage: error, update: Date.now()
    })
  }
  // onChange Send forgotpassword(email password)
  const handleInputChange = ({ target }) => {
    setEmail({ ...email, [target.name]: target.value })
  }
  // onSubmit send email reset
  const sendEmailReset = () => { mutate({ email }) }

  useEffect(() => {
    document.title = "Datafiniti Portal | Forgot Password"

  }, [email])


  return (
    /* GUI Forgot password */
    <section className={styles.mainContainer}>
      <div className={styles.background}></div>
      <div className={styles.contentContainer}>
        <div className={styles.containerInputs}>
          <img className={styles.imageForgotPassword} alt='images' src={require('../HomeDR12/images/datafiniti-logo-white.svg')} />
          <h1 className={styles.TitleForgotPassword}>Forgot password</h1>
          <form className={styles.formForgotPassword} onSubmit={handleSubmit(sendEmailReset)}>
            <div className={styles.containerFieldEmail}>
              <input className={styles.fieldEmail} label='Email' name='email' placeholder='Email' autoComplete='off' error={!!errors.email} {...register("email", { required: 'Email is required', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i, message: 'Invalid email format' }, onChange: (e) => { handleInputChange(e) } })} />
              {errors.email?.type === 'required' && <p className={styles.error}>Email is required</p>}
              {errors.email?.type === 'pattern' && <p className={styles.error}>invalid email</p>}
            </div>

            <div className={styles.buttonContainerForgotPassword}>
              <button className={styles.changeButtonEmail} type='submit' disabled={isLoading === true}>Send
              </button>
            </div>
          </form>
          <button className={styles.backToLoginForgotPassword} onClick={backToLogin}> Back to login</button>
        </div>

        {/* Sends the Message to the snackcomponent */}
        <SnackbarComponent snackData={snackMessage} />
      </div>
    </section>
  )
}
