// Product data styles
// Product Data Container
export const containerProductData = {
  marginTop: '1.5rem',
  marginLeft: '1.5rem',
  marginRight: '1.5rem',
  paddingBottom: '25px'
}
// Search Title style
export const searchTitle = {
  fontFamily: 'Roboto',
  fontSize: '24px',
  marginBottom: '0.85rem',
  fontWeight: '500',
  color: '#000000',
  '@media (max-width: 600px)': { marginLeft: '0rem' }
}
// AppBarSearchApi style
export const appBarSearchApi = {
  backgroundColor: '#FFFFFF',
  color: '#43425D',
  borderBottom: '1px solid #E9E9F0',
  borderRadius: '5px 5px 0px 0px'
}
// numberApiSearch styles
export const numberApiSearch = {
  fontFamily: 'Roboto',
  fontSize: '26px',
  color: '#43425D'
}
// buttonDownload styles
export const buttonDownload = {
  marginRight: '1%',
  width: '11.6rem'
}
export const recordsTitle = {
  fontFamily: 'Roboto',
  fontSize: '26px',
  color: '#43425D',
  marginTop: '1%',
  marginBottom: '1%'
}
