/* React and components imports  */
import React, { useState, useEffect } from 'react'
import SnackbarComponent from '../Snackbar/index'
/* Material ui imports */
import { Grid, Button, FormLabel, Link, TextField } from '@material-ui/core'
import * as styles from '../ResetPassword/styles'
import { makeStyles } from '@material-ui/core/styles'
/* import from the API */
import { resetPassword } from '../../Api'
/*  Router history import */
import { useLocation, useHistory } from 'react-router-dom'
/* React hook form */
import { useForm } from 'react-hook-form'
// React Query
import { useMutation } from 'react-query'

export default function Newpassword() {
  /* Styles to the page */
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  /* Password state */
  const [password, setPassword] = useState('')
  /* Reset password form state */
  // register, handleSubmit, reset, formState: { errors, isValid }, watch 
  const { register, handleSubmit, formState: { errors }, reset, watch } = useForm({ mode: 'onSubmit' })
  /* State of the snackMessage */
  const [snackMessage, setSnackMessage] = useState({ type: 'error' })
  /* History from router */
  const history = useHistory()
  const path = '/home'
  /* variable to get the token from the URL */
  const query = new URLSearchParams(useLocation().search)
  /* Obtains the token */
  const token = query.get('token')
  /* click event that redirects to the login after 3.5 seconds */
  const backToLogin = () => { setTimeout(() => { history.push(path) }, 3000) }
  /* redirects to the login page */
  const linkBackToLogin = () => { history.push(path) }
  /* onChange password */
  const handleInputChange = ({ target }) => {
    setPassword({ ...password, [target.name]: target.value })
  }
  /* Mutate react query */
  const [mutate, { isLoading }] = useMutation(
    ({ token, password }) => resetPassword(token, password),
    { onSuccess: handleSuccess, onError: handleError }
  )
  // if the mutate is sucessful, displays the snack bar, cleans form, and redirects to login
  function handleSuccess(data, Data) { // Data comes from react hook form
    if (data.status >= 400) {
      setSnackMessage({ type: 'error', myMessage: 'Something went wrong, try again.', update: Date.now() })
      reset(Data = '')
      backToLogin()
    } else {
      setSnackMessage({ type: 'success', myMessage: 'Password has been changed, you will be redirect to the login', update: Date.now() })
      reset()
      backToLogin()
    }
  }
  // if the mutate is error, displays the snack bar
  function handleError(error) {
    setSnackMessage({ type: 'error', myMessage: error, update: Date.now() })
  }
  // onClick event to activate the mutate
  const handleReset = () => { mutate({ token, password }) }

  useEffect(() => {
    document.title = "Datafiniti Portal | Reset Password"
  }, [])
  const passwordConfirm = watch('password', '');
  return (
    /* New password GUI */
    <Grid container direction='row' alignItems='center' justifyContent='space-evenly' className={classes.app}>
      <Grid container item className={classes.resetContainer} xs={10} sm={8}>
        <img className={classes.image} alt='images' src={require('../img/datafiniti-logo.png')} />
        <FormLabel className={classes.resetPassword}>Insert your new password</FormLabel>
        <form onSubmit={handleSubmit(handleReset)}>
          <Grid item xs={12} s={12} md={12} lg={12}>
            <TextField type='password' className={classes.fieldPassword} label='New Password' name='password'  {...register("password", { required: 'New password is required', minLength: { value: 8, message: 'Password must be at least 8 characters long' }, onChange: (e) => { handleInputChange(e) } })} error={!!errors.password} autoFocus autoComplete='off' />
            {errors.password?.type === 'required' && <p className={classes.error}>Password required</p>}
            {errors.password?.type === 'pattern' && <p className={classes.error}>Invalid Format</p>}
            {errors.password?.type === 'minLength' && <p className={classes.error}>Password is too short</p>}
          </Grid>
          <Grid item xs={12} s={12} md={12} lg={12}>
            <TextField className={classes.fieldPassword} name='ConfirmPassword' label='Confirm Password' autoComplete='off' type='password' error={!!errors.ConfirmPassword} {...register('ConfirmPassword', {
              required: 'Confirm password is required',
              validate: (value) =>
                value === passwordConfirm || 'The passwords do not match',
            })} />
            {errors.ConfirmPassword?.type === 'required' && <p className={classes.error}>Password is required</p>}
            {errors.ConfirmPassword?.type === 'validate' && <p className={classes.error}>Passwords need to match</p>}
            {errors.ConfirmPassword?.type === 'minLength' && <p className={classes.error}>Passwords too short</p>}
          </Grid>
          <Grid item xs={12} s={12} md={12} lg={12}>
            <Button className={classes.changeButtonPassword} type='submit' color='primary' variant='outlined' disabled={isLoading === true}> Change your password</Button>
            {/* || !formState.isValid */}
          </Grid>
        </form>
        <Link className={classes.backToLogin} color='primary' onClick={linkBackToLogin}>Back to login</Link>
        {/* Send the message to the snackbar component */}
        <SnackbarComponent snackData={snackMessage} />
      </Grid>
    </Grid>
  )
}
