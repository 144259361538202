import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles({

    focused: {
        // '& $notchedOutline': {
        //     borderColor: '#ABABAB !important', // Your desired color\
        //     borderWidth: "1px !important"
        // },
    },
    notchedOutline: {
        borderColor: '#ABABAB !important',
        borderWidth: "1px !important"
    },


    noOptions: {
        fontSize: '12px', // Smaller font size for "No options" text
        padding: "0px !important",
        margin: "0px !important"
    },

    loading: {
        fontSize: '12px', // Smaller font size for "Loading" text
        padding: "0px !important",
        margin: "0px !important"
    },

    option: {
        fontSize: '14px', // Set the font size for dropdown options
        padding: "5px 0px 5px 10px",
        '&[data-focus="true"]': {
            color: '#8122B4', // Change hover text color
            backgroundColor: "transparent"
        },
        '&[aria-selected="true"]': {
            backgroundColor: "transparent",
            color: '#8122B4', // Optional: if you also want to change the font color
            '&:hover': {
                color: '#FF0000', // Text color for hovered and selected items
                backgroundColor: 'transparent', // Background color for hovered and selected items
            }
        },
        '&[aria-selected="true"][data-focus="true"]': {
            color: '#FF0000'
        },
    },
    // input auto complete
    inputRoot: {
        width: '195px', // Adjust to your desired width
        minHeight: "30px",
        padding: "2px !important",
        borderRadius: "4px !important ",
        margin: "0px",

        '& .MuiAutocomplete-endAdornment': {
            right: "0px !important"
        },

        '& .MuiOutlinedInput-root': {
            padding: "0px !important",
            // border: "1px solid #ABABAB",
            '& .MuiAutocomplete-input': {
                fontSize: "12px",
                padding: "0px",
                borderRadius: 0,

            },
            "& .MuiAutocomplete-inputRoot": {
                padding: "0px !important"
            },
            '&.Mui-focused fieldset': {
                borderColor: 'grey', // Change to match your desired color or remove the line for no border color change
            },

        },
        '& .MuiAutocomplete-inputFocused': {
            fontSize: "12px",
            height: "25px",
            padding: "0px !important",
            paddingLeft: "8px !important"
        },
        '& .MuiChip-root': {
            height: '24px', // Smaller height for the chips
            fontSize: '12px',
            margin: "0",
            padding: "0px !important",
            backgroundColor: 'transparent', // Set background color to transparent
            color: '#8122B4', // Set the text color to black or any color you prefer
            '&:focus': {
                backgroundColor: 'transparent', // Ensure the background stays transparent even when focused
            }
        },
        '& .MuiChip-deleteIcon': {
            width: "16px",
            height: "17px",
            padding: "0px",
            margin: "0px 0px 0px 4px",
            color: '#8122B4'
        },
        '& .MuiChip-label': {
            padding: "0px 0px 0px 8px", // Remove padding from the label
        },

    },

});