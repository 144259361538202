import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useQuery, useMutation } from 'react-query'
import SnackbarComponent from '../../../../Snackbar'
import { getAccount, postEditBilling } from '../../../../../Api/index'
import ModalSettings from '../modal/ModalSettings'
import * as styles from './styles.module.css'
import CircularProgress from '@mui/material/CircularProgress';

function PaymentSettings() {
    const [cardState, setCardState] = useState(false)
    const [readyCard, setReadyCard] = useState(false)
    const [loadingStripe, setLoadingStripe] = useState(false)
    const [snackMessage, setSnackMessage] = useState({ type: 'error' })
    const [open, setOpen] = useState(false)
    const { data: costumerAccount } = useQuery('SecondFormAccount', getAccount)
    const elements = useElements()
    const stripe = useStripe()

    const handleClose = () => { setOpen(false) }
    const handleClickOpen = () => { setOpen(true) }

    const [formData, setFormData] = useState({ cardHolderName: '', address: '', city: '', state: '', postalCode: '' })

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onSubmit" });

    const onChangesFieldsCreditCard = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const [updateBilling, { isLoading }] = useMutation(
        ({ userData }) => postEditBilling(userData),
        { onSuccess: handleSuccess, onError: handleError })
    function handleSuccess(data) {
        const { data: dataPlan, status } = data
        if (status >= 400) {
            setSnackMessage({ type: 'error', myMessage: dataPlan.errors, update: Date.now() })
            setLoadingStripe(false)
        } else {
            handleClickOpen(true)
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        }
    }

    function handleError(error) {
        const getError = error.response.data.errors.code
        const detailedErrorMessage = getError ? `Error - ${getError}` : "Something has happened. Please contact customer support";
        setSnackMessage({ type: 'error', myMessage: detailedErrorMessage, update: Date.now() })
        setLoadingStripe(false)
    }

    const onClickUpdateBilling = async () => {
        setLoadingStripe(true)
        // Gets the card element stripe and create the token with the data inside the form
        if (cardState === true) {
            const cardElement = elements.getElement(CardElement)
            const { error, token } = await stripe.createToken(cardElement, { name: formData?.cardHolderName, address_line1: formData?.address, address_city: formData?.city, address_state: formData?.state, address_zip: formData?.postalCode })
            if (error) {
                setSnackMessage({ type: 'error', myMessage: error.message, update: Date.now() })
                setLoadingStripe(false)
                setTimeout(() => {
                    window.location.reload()
                }, 1500)
            } else {
                // customer ID
                const userData = {
                    source: token?.id,
                    customer: costumerAccount?.data?.customer_id
                }
                updateBilling({ userData })
            }
        }
    }
    return (
        <div className={styles.PaymentSettingsContainer}>
            <h1 className={styles.PaymentSettingsTitle}>Payment Information</h1>
            <form autoComplete="off" onSubmit={handleSubmit(onClickUpdateBilling)}>
                <label className={styles.CardHolderNameLabel}>Cardholder name</label>
                <input autoComplete="off" className={styles.inputCardHolderName} type='text' name='cardHolderName' {...register("cardHolderName", { required: 'Cardholder is required', onChange: (e) => { onChangesFieldsCreditCard(e) } })} />
                {errors.cardHolderName && errors.cardHolderName.type === 'required' && <span>Name is required</span>}

                <label className={styles.AddressLabel}>Address</label>
                <input autoComplete="off" className={styles.inputAddress} type='text' name='address' {...register("address", { required: 'Address is required', onChange: (e) => { onChangesFieldsCreditCard(e) } })} />
                {errors.address && errors.address.type === 'required' && <span>Address is required</span>}

                <label className={styles.CityLabel}>City</label>
                <input autoComplete="off" className={styles.inputCity} type='text' name='city' {...register("city", { required: 'City is required', onChange: (e) => { onChangesFieldsCreditCard(e) } })} />
                {errors.city && errors.city.type === 'required' && <span>City is required</span>}

                <label className={styles.StateLabel}>State</label>
                <input autoComplete="off" className={styles.inputState} type='text' name='state' {...register("state", { required: 'State is required', onChange: (e) => { onChangesFieldsCreditCard(e) } })} />
                {errors.state && errors.state.type === 'required' && <span>State is required</span>}

                <label className={styles.PostalCodeLabel}>Zip Code</label>
                <input autoComplete="off" className={styles.inputPostalCode} type='text' name='postalCode' {...register("postalCode", { required: 'Postal Code is required', onChange: (e) => { onChangesFieldsCreditCard(e) } })} />
                {errors.postalCode && errors.postalCode.type === 'required' && <span>Postal Code is required</span>}

                <div style={{ marginBottom: "12px" }}>
                    <CardElement onReady={function (element) { if (element) { setReadyCard(true) } else { setReadyCard(false) } }} onChange={function (event) { if (event.complete) { setCardState(true) } else { setCardState(false) } }} options={{ disableLink: true, hidePostalCode: true, style: { base: { fontFamily: 'Roboto', fontSize: '16px' } } }} />
                    {readyCard === false ? <LinearProgress style={{ marginTop: '5px', height: '5px' }} /> : ''}
                </div>

                {isLoading ?
                    <button className={styles.spinnerDisabled}>
                        <CircularProgress style={{ width: '25px', height: '30px', color: 'white' }} />
                    </button>
                    :
                    <button disabled={cardState === false || loadingStripe === true} className={styles.UpdateButtonPaymentSettings} type='submit'>Update</button>
                }
            </form>
            <ModalSettings open={open} onClose={handleClose} />
            <SnackbarComponent snackData={snackMessage} />
        </div>
    )
}

export default PaymentSettings
