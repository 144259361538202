import React, { useState, useEffect } from 'react'
import styles from "./styles.module.css";
import { usePaginatedQuery } from 'react-query'
import { getDownloads } from '../../Api'
import SingleDownload from './SingleDownload/SingleDownload';
import expandDownloadIcon from "../SearchResults/images/expandedSVG.svg"

function AllDownloads() {
    const [page] = useState(1)
    const [limit] = useState(10)
    const [downloadRecord, setDownloadRecord] = useState()
    const { resolvedData } = usePaginatedQuery([page, limit], getDownloads)

    const [isSingleDownloadVisible, setIsSingleDownloadVisible] = useState(false);


    useEffect(() => {
        document.title = "Datafiniti Portal | Settings"
        localStorage.setItem("dfp/dataTypeNavigation", "download");
        window.scrollTo(0, 0)
    }, []) /* eslint-disable-line react-hooks/exhaustive-deps */

    const toggleSingleDownload = (id) => {
        setDownloadRecord(id)
        setIsSingleDownloadVisible(true);

    };

    const parseQueryToFilters = (query) => {
        const filterPattern = /(\w+):((?:"[^"]+"|\([^)]+\)|\[[^\]]+\]|[^ }\]]+))/g;
        let match;
        const parsedFilters = [];

        while ((match = filterPattern.exec(query)) !== null) {
            let key = match[1];
            let value = match[2];

            if (value.startsWith('(') && value.endsWith(')')) {
                value = value.substring(1, value.length - 1)
                    .split(' OR ')
                    .map(segment => segment.replace(/^"|"$/g, ''))
                    .join(' OR ');
            } else {
                value = value.replace(/^"|"$/g, '');
            }

            parsedFilters.push({ key, value });
        }
        return parsedFilters;
    };

    const handleDownloadAll = (event, urls) => {
        event.stopPropagation();
        urls.forEach((url, index) => {
            const a = document.createElement('a');
            a.href = url.url;
            a.download = `download-${index}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    };

    return (
        <section className={styles.sectionContainerAllDownloads}>
            <h1 className={styles.allDownloadsTitle}>Downloads</h1>

            <div className={styles.containerAllDownloads}>
                {resolvedData?.data?.records.length > 0 ? (
                    resolvedData?.data?.records.map((record, index) => (

                        <article className={styles.articleDownloads} onClick={() => toggleSingleDownload(record.id)} key={index}>

                            <header style={{ display: "flex", marginBottom: "12px", justifyContent: "space-between" }}>
                                <h2 className={styles.allDownloadsHeaderTitle}>{record?.id}</h2>
                                <img style={{ cursor: "pointer", height: "13px" }} src={expandDownloadIcon} alt="Expand Icon" />
                            </header>

                            <div className={styles.allDownloadsBox}>

                                <div>
                                    <h3 className={styles.titleH3AllDownloads}>Date Started</h3>
                                    <span className={styles.spanAllDownloads}>
                                        {record?.date_started ? (
                                            new Intl.DateTimeFormat('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                                timeZoneName: 'short',
                                                hour12: false
                                            }).format(new Date(record.date_started))
                                        ) : (
                                            'N/A'
                                        )}
                                    </span>
                                </div>

                                <div>
                                    <h3 className={styles.titleH3AllDownloads}>Data type</h3>
                                    <span className={styles.spanAllDownloads}>  {record?.data_type ? record.data_type.charAt(0).toUpperCase() + record.data_type.slice(1).toLowerCase() : "N/A"}
                                    </span>

                                </div>
                                <div style={{ minHeight: "125px", overflow: "auto" }}>
                                    <h3 className={styles.titleH3AllDownloads}>Filters</h3>
                                    {
                                        record?.query ? (
                                            <>
                                                {parseQueryToFilters(record.query).slice(0, 5).map((filter, index) => (
                                                    <span style={{ marginBottom: "4px" }} key={`${filter.key}-${index}`} className={styles.spanAllDownloads}>{`${filter.key}: ${filter.value}`}</span>
                                                ))}
                                                {parseQueryToFilters(record.query).length > 5 && (
                                                    <span style={{ color: "#ABABAB" }} className={styles.spanAllDownloads}>+{parseQueryToFilters(record.query).length - 5} more</span>
                                                )}
                                            </>
                                        ) : <span className={styles.spanAllDownloads}>N/A</span>
                                    }
                                </div>

                                <div>
                                    <h3 className={styles.titleH3AllDownloads}>Records downloaded</h3>
                                    <span className={styles.spanAllDownloads}>{record?.num_downloaded || "N/A"}</span>

                                </div>

                                <div>
                                    <h3 className={styles.titleH3AllDownloads}>File format</h3>
                                    <span className={styles.spanAllDownloads}>{record?.format.toUpperCase() || "N/A"}</span>

                                </div>

                                <div>
                                    <h3 className={styles.titleH3AllDownloads}>Download</h3>
                                    <span onClick={(e) => handleDownloadAll(e, record?.results || [])} className={styles.spanDownloadFiles}>Download all files</span>
                                </div>
                            </div>
                        </article>

                    ))
                ) : (
                    <div>
                        <span>
                            No downloads available
                        </span>
                    </div>
                )}
                {/* } */}

            </div>
            {
                isSingleDownloadVisible && (
                    <SingleDownload
                        downloadId={downloadRecord}
                        onClose={() => setIsSingleDownloadVisible(false)}
                        parseQueryToFilters={parseQueryToFilters}
                    />
                )
            }
        </section >

    )
}

export default AllDownloads