import React from 'react'
// import rectangleXmark from "./images/rectangle-xmark.svg";
import rectangleXmark from "../images/rectangle-xmark.svg";
import bracketsCurly from "../images/brackets-curly-solid.svg";
import styles from "./styles.module.css";

function HeaderExpandedRecord({ record, handleClose, toggleJsonModal }) {

    const zoom = "zoom=13";
    const size = "size=160x160";
    const mapType = "mapType=roadmap";

    return (
        <section className={styles.containerHeader}>
            <div>
                <img height="114" width="114"
                    src={
                        record?.latitude && record?.longitude
                            ? `https://maps.googleapis.com/maps/api/staticmap?center=${record?.latitude + "," + record?.longitude
                            }&${zoom}&${size}&${mapType}&markers=${record?.latitude + "," + record?.longitude
                            }&key=AIzaSyD0AKt7KKjLie_FUX7rH1HAFXD9svOE6Go`
                            : record?.address && record?.city
                                ? `https://maps.googleapis.com/maps/api/staticmap?center=${record?.address +
                                "," +
                                record?.city +
                                "," +
                                record?.province +
                                "," +
                                record?.country +
                                "," +
                                record?.postalCode
                                }&${zoom}&${size}&${mapType}&markers=${record?.address +
                                "," +
                                record?.city +
                                "," +
                                record?.province +
                                "," +
                                record?.country +
                                "," +
                                record?.postalCode
                                }&key=AIzaSyD0AKt7KKjLie_FUX7rH1HAFXD9svOE6Go`
                                : require("../images/map-not-available.png")
                    }
                    alt="mapImage"
                />
            </div>
            <div className={styles.containerCloseIconJsonView}>
                <div className={styles.containerCloseIcon} onClick={handleClose}>
                    <span className={styles.buttonClose} onClick={handleClose}>
                        Close
                    </span>

                    <img alt="rectangleXmarkIcon"
                        onClick={handleClose}
                        src={rectangleXmark}
                        className={styles.rectangleIcon} />
                </div>

                <div className={styles.containerViewJson} onClick={toggleJsonModal}>
                    <span className={styles.buttonJsonView}>View JSON</span>
                    <img alt="bracketsCurly"
                        src={bracketsCurly}
                        style={{ width: "14px" }}
                    />
                </div>
            </div>
        </section>
    )
}

export default HeaderExpandedRecord