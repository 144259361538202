import React from 'react'
import PropertyData from '../PropertyData/PropertyData'
import PeopleData from '../PeopleData/PeopleData'
import BusinessData from '../BusinessData/BusinessData'
import ProductData from '../ProductData/ProductData'
import * as styles from './styles.module.css';

function SubscriptionSettings() {
    return (
        <section className={styles.SubscriptionSettingsContainer}>
            <PropertyData />
            <PeopleData />
            <BusinessData />
            <ProductData />
        </section>
    )
}

export default SubscriptionSettings