import React from 'react'
import styles from "./styles.module.css";

function AddressExpandedRecord({ record }) {

    return (
        <section>
            <div className={styles.fistSectionValuesContainer}>
                <span className={styles.spanAddress}>
                    {record?.address ? record.address : "N/A"}
                </span>

                <span className={styles.spanCityProvincePostalCode} >
                    {record?.city}
                    {record?.city && record?.province ? ", " : ""}
                    {record?.province}
                    {(record?.city || record?.province) && record?.postalCode
                        ? " "
                        : ""}
                    {record?.postalCode}
                </span>
            </div>

            <div className={styles.containerAddressDetails}>
                <div className={styles.containerPropertyType}>
                    <span className={styles.spanTitle}>
                        Property type
                    </span>
                    <span className={styles.spanTypeValue}>
                        {record?.propertyType ? record.propertyType : "N/A"}
                    </span>
                </div>
                <div className={styles.containerFloorSize}>
                    <span className={styles.spanTitle}>
                        Floor size
                    </span>
                    <span className={styles.spanTypeValue}>
                        {record?.floorSizeValue
                            ? `${record.floorSizeValue} ${record?.floorSizeUnit}`
                            : "N/A"}
                    </span>
                </div>
                <div className={styles.containerLotSize}>
                    <span className={styles.spanTitle}>
                        Lot size
                    </span>
                    <span className={styles.spanTypeValue}>
                        {record?.lotSizeValue ? `${record.lotSizeValue} ${record?.lotSizeUnit}` : "N/A"}

                    </span>
                </div>
                <div className={styles.containerBedNumber}>
                    <span className={styles.spanTitle}>
                        Bed
                    </span>
                    <span className={styles.spanTypeValue}>
                        {record?.numBedroom ? record.numBedroom : "N/A"}
                    </span>
                </div>
                <div className={styles.containerBathNumber}>
                    <span className={styles.spanTitle}>
                        Bath
                    </span>
                    <span className={styles.spanTypeValue}>
                        {record?.numBathroom ? record.numBathroom : "N/A"}
                    </span>
                </div>

                <div className={styles.containerFloorNumber}>
                    <span className={styles.spanTitle}>
                        Floors
                    </span>
                    <p className={styles.spanTypeValue}>
                        {record?.numFloor ? record.numFloor : "N/A"}
                    </p>
                </div>

                <div className={styles.containerRoomNumber}>
                    <span className={styles.spanTitle}>
                        Rooms
                    </span>
                    <p className={styles.spanTypeValue}>
                        {record?.numRoom ? record.numRoom : "N/A"}
                    </p>
                </div>
            </div>
        </section>
    )
}

export default AddressExpandedRecord