export const productArray = [
  {
    name: 'asins',
    type: 'text'
  },
  {
    name: 'brand',
    type: 'text'
  },
  {
    name: 'categories',
    type: 'text'
  },
  {
    name: 'colors',
    type: 'text'
  },
  {
    name: 'count',
    type: 'text'
  },
  {
    name: 'dateAdded',
    type: 'date'
  },
  {
    name: 'dateUpdated',
    type: 'date'
  },
  {
    name: 'descriptions',
    type: 'nested'
  },
  {
    name: 'dimension',
    type: 'text'
  },
  {
    name: 'domains',
    type: 'text'
  },
  {
    name: 'ean',
    type: 'text'
  },
  {
    name: 'ean13',
    type: 'text'
  },
  {
    name: 'ean8',
    type: 'text'
  },
  {
    name: 'features',
    type: 'nested'
  },
  {
    name: 'financingAndLeasing',
    type: 'nested'
  },
  {
    name: 'flavors',
    type: 'text'
  },
  {
    name: 'gtins',
    type: 'text'
  },
  {
    name: 'imageURLs',
    type: 'text'
  },
  {
    name: 'isbn',
    type: 'text'
  }, {
    name: 'keys',
    type: 'text'
  }, {
    name: 'manufacturer',
    type: 'text'
  }, {
    name: 'manufacturerNumber',
    type: 'text'
  }, {
    name: 'merchants',
    type: 'nested'
  }, {
    name: 'name',
    type: 'text'
  },
  {
    name: 'prices',
    type: 'nested'
  }, {
    name: 'primaryCategories',
    type: 'text'
  }, {
    name: 'primaryImageURLs',
    type: 'text'
  },
  {
    name: 'quantities',
    type: 'nested'
  },
  {
    name: 'reviews',
    type: 'nested'
  },
  {
    name: 'sdsURLs',
    type: 'text'
  },
  {
    name: 'secondaryCategories',
    type: 'text'
  },
  {
    name: 'sizes',
    type: 'text'
  },
  {
    name: 'skus',
    type: 'nested'
  },
  {
    name: 'sourceURLs',
    type: 'text'
  },
  {
    name: 'stockNum',
    type: 'text'
  },
  {
    name: 'taxonomy',
    type: 'taxonomyList'
  },
  {
    name: 'upc',
    type: 'text'
  },
  {
    name: 'upca',
    type: 'text'
  },
  {
    name: 'upce',
    type: 'text'
  },
  {
    name: 'vin',
    type: 'text'
  },
  {
    name: 'websiteIDs',
    type: 'text'
  },
  {
    name: 'weight',
    type: 'text'
  }
]
export const financingAndLeasingList = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'apr',
    value: 'apr',
    type: 'text'
  },
  {
    name: 'currency',
    value: 'currency',
    type: 'text'
  },
  {
    name: 'dateSeen',
    value: 'dateSeen',
    type: 'date'
  },
  {
    name: 'description',
    value: 'description',
    type: 'text'
  },
  {
    name: 'disclaimers',
    value: 'disclaimers',
    type: 'text'
  },
  {
    name: 'dueAtSigningMax',
    value: 'dueAtSigningMax',
    type: 'text'
  },
  {
    name: 'dueAtSigningMin',
    value: 'dueAtSigningMin',
    type: 'text'
  },
  {
    name: 'milesPerYear',
    value: 'milesPerYear',
    type: 'text'
  },
  {
    name: 'monthlyPaymentMax',
    value: 'monthlyPaymentMax',
    type: 'text'
  },
  {
    name: 'monthlyPaymentMin',
    value: 'monthlyPaymentMin',
    type: 'text'
  },
  {
    name: 'name',
    value: 'name',
    type: 'text'
  },
  {
    name: 'type',
    value: 'type',
    type: 'text'
  },
  {
    name: 'securityDepositMax',
    value: 'securityDepositMax',
    type: 'text'
  },
  {
    name: 'securityDepositMin',
    value: 'securityDepositMin',
    type: 'text'
  },
  {
    name: 'sourceURLs',
    value: 'sourceURLs',
    type: 'text'
  }
]
export const merchantsList = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'address',
    value: 'address',
    type: 'text'
  },
  {
    name: 'availability',
    value: 'availability',
    type: 'text'
  },
  {
    name: 'city',
    value: 'city',
    type: 'text'
  },
  {
    name: 'dateSeen',
    value: 'dateSeen',
    type: 'date'
  },
  {
    name: 'isPrivateSeller',
    value: 'isPrivateSeller',
    type: 'text'
  },
  {
    name: 'name',
    value: 'name',
    type: 'text'
  },
  {
    name: 'phone',
    value: 'phone',
    type: 'text'
  },
  {
    name: 'postalCode',
    value: 'postalCode',
    type: 'text'
  },
  {
    name: 'province',
    value: 'province',
    type: 'text'
  }
]
export const pricesListProduct = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'amountMin',
    value: 'amountMin',
    type: 'text'
  },
  {
    name: 'amountMax',
    value: 'amountMax',
    type: 'text'
  },
  {
    name: 'availability',
    value: 'availability',
    type: 'text'
  },
  {
    name: 'color',
    value: 'color',
    type: 'text'
  },
  {
    name: 'condition',
    value: 'condition',
    type: 'text'
  },
  {
    name: 'count',
    value: 'count',
    type: 'text'
  },
  {
    name: 'currency',
    value: 'currency',
    type: 'text'
  },
  {
    name: 'dateSeen',
    value: 'dateSeen',
    type: 'date'
  },
  {
    name: 'flavor',
    value: 'flavor',
    type: 'text'
  },
  {
    name: 'isSale',
    value: 'isSale',
    type: 'text'
  },
  {
    name: 'isSold',
    value: 'isSold',
    type: 'text'
  },
  {
    name: 'merchant',
    value: 'merchant',
    type: 'text'
  },
  {
    name: 'offer',
    value: 'offer',
    type: 'date'
  },
  {
    name: 'returnPolicy',
    value: 'returnPolicy',
    type: 'text'
  },
  {
    name: 'shipping',
    value: 'shipping',
    type: 'date'
  },
  {
    name: 'size',
    value: 'size',
    type: 'text'
  },
  {
    name: 'sourceURLs',
    value: 'sourceURLs',
    type: 'date'
  },
  {
    name: 'warranty',
    value: 'warranty',
    type: 'text'
  }
]

export const quantitiesListProduct = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'dateSeen',
    value: 'dateSeen',
    type: 'text'
  },
  {
    name: 'sourceURLs',
    value: 'sourceURLs',
    type: 'text'
  },
  {
    name: 'value',
    value: 'value',
    type: 'text'
  }
]

export const reviewsListProduct = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'date',
    value: 'date',
    type: 'date'
  },
  {
    name: 'dateSeen',
    value: 'dateSeen',
    type: 'date'
  },
  {
    name: 'didPurchase',
    value: 'didPurchase',
    type: 'text'
  },
  {
    name: 'doRecommend',
    value: 'doRecommend',
    type: 'text'
  },
  {
    name: 'id',
    value: 'id',
    type: 'text'
  },
  {
    name: 'numHelpful',
    value: 'numHelpful',
    type: 'text'
  },
  {
    name: 'rating',
    value: 'rating',
    type: 'text'
  },
  {
    name: 'sourceURLs',
    value: 'sourceURLs',
    type: 'date'
  },
  {
    name: 'title',
    value: 'title',
    type: 'text'
  },
  {
    name: 'userCity',
    value: 'userCity',
    type: 'text'
  },
  {
    name: 'username',
    value: 'username',
    type: 'text'
  },
  {
    name: 'userProvince',
    value: 'userProvince',
    type: 'text'
  }
]
export const skusProduct = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'sourceURLs',
    value: 'sourceURLs'
  },
  {
    name: 'value',
    value: 'value'
  }
]

export const taxonomyList = [
  {
    name: 'No value',
    value: 'No value'
  },
  {
    name: 'amazon devices & accessories',
    value: 'amazon devices & accessories'
  },
  {
    name: 'appliances',
    value: 'appliances'
  },
  {
    name: 'arts, crafts & sewing',
    value: 'arts, crafts & sewing'
  },
  {
    name: 'automotive',
    value: 'automotive'
  },
  {
    name: 'baby products',
    value: 'baby products'
  },
  {
    name: 'beauty & personal care',
    value: 'beauty & personal care'
  },
  {
    name: 'books',
    value: 'books'
  },
  {
    name: 'car & vehicle electronics',
    value: 'car & vehicle electronics'
  },
  {
    name: 'cds & vinyl',
    value: 'cds & vinyl'
  },
  {
    name: 'cell phones & accessories',
    value: 'cell phones & accessories'
  },
  {
    name: 'clothing, shoes & jewelry',
    value: 'clothing, shoes & jewelry'
  },
  {
    name: 'collectibles & fine art',
    value: 'collectibles & fine art'
  },
  {
    name: 'digital educational resources',
    value: 'digital educational resources'
  },
  {
    name: 'educational courses',
    value: 'educational courses'
  },
  {
    name: 'electronics',
    value: 'electronics'
  },
  {
    name: 'food service equipment & supplies',
    value: 'food service equipment & supplies'
  },
  {
    name: 'gift cards',
    value: 'gift cards'
  },
  {
    name: 'grills & outdoor cooking',
    value: 'grills & outdoor cooking'
  },
  {
    name: 'grocery & gourmet food',
    value: 'grocery & gourmet food'
  },
  {
    name: 'handmade products',
    value: 'handmade products'
  },
  {
    name: 'health & household',
    value: 'health & household'
  },
  {
    name: 'heavy duty & commercial vehicle equipment',
    value: 'heavy duty & commercial vehicle equipment'
  },
  {
    name: 'heavy duty & commercial vehicles parts',
    value: 'heavy duty & commercial vehicles parts'
  },
  {
    name: 'hobbies',
    value: 'hobbies'
  },
  {
    name: 'home & business services',
    value: 'home & business services'
  },
  {
    name: 'home & kitchen',
    value: 'home & kitchen'
  },
  {
    name: 'hunting & fishing',
    value: 'hunting & fishing'
  },
  {
    name: 'industrial & scientific',
    value: 'industrial & scientific'
  },
  {
    name: 'instrument accessories',
    value: 'instrument accessories'
  }
  ,
  {
    name: 'janitorial & sanitation supplies',
    value: 'janitorial & sanitation supplies'
  },
  {
    name: 'kindle ebooks',
    value: 'kindle ebooks'
  },
  {
    name: 'kindle store',
    value: 'kindle store'
  },
  {
    name: 'kitchen & dining',
    value: 'kitchen & dining'
  },
  {
    name: 'lab & scientific products',
    value: 'lab & scientific products'
  },
  {
    name: 'lights & lighting accessories',
    value: 'lights & lighting accessories'
  },
  {
    name: 'magazine subscriptions',
    value: 'magazine subscriptions'
  },
  {
    name: 'medical supplies & equipment',
    value: 'medical supplies & equipment'
  },
  {
    name: 'mobility & daily living aids',
    value: 'mobility & daily living aids'
  },
  {
    name: 'motorcycle & powersports',
    value: 'motorcycle & powersports'
  },
  {
    name: 'movies & tv',
    value: 'movies & tv'
  },
  {
    name: 'musical instruments',
    value: 'musical instruments'
  },
  {
    name: 'office electronics',
    value: 'office electronics'
  },
  {
    name: 'office products',
    value: 'office products'
  },
  // 
  {
    name: 'patio, lawn & garden',
    value: 'patio, lawn & garden'
  },
  {
    name: 'pet supplies',
    value: 'pet supplies'
  },
  {
    name: 'power & hand tools',
    value: 'power & hand tools'
  },
  {
    name: 'power tool parts & accessories',
    value: 'power tool parts & accessories'
  },
  {
    name: 'professional dental supplies',
    value: 'professional dental supplies'
  },
  {
    name: 'remote & app controlled vehicle parts',
    value: 'remote & app controlled vehicle parts'
  },
  {
    name: 'remote & app controlled vehicles & parts',
    value: 'remote & app controlled vehicles & parts'
  },
  {
    name: 'restaurant appliances & equipment',
    value: 'restaurant appliances & equipment'
  },
  {
    name: 'returns',
    value: 'returns'
  },
  {
    name: 'safety & security',
    value: 'safety & security'
  },
  {
    name: 'small appliance parts & accessories',
    value: 'small appliance parts & accessories'
  },
  {
    name: 'software',
    value: 'software'
  },
  {
    name: 'sports & fitness',
    value: 'sports & fitness'
  },
  {
    name: 'sports & outdoors',
    value: 'sports & outdoors'
  },
  {
    name: 'tools & home improvement',
    value: 'tools & home improvement'
  },
  {
    name: 'toys & games',
    value: 'toys & games'
  },
  {
    name: 'video games',
    value: 'video games'
  }
]