// react and components imports
import React, {useContext} from 'react'
// material ui and styles imports
import { Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import * as stylesGridView from './GridView.module.css'
import { LocalContext } from '../LocalContext'

export default function ButtonsGrid({ dataRequest, page, nextSearchOnClick, prevSearchOnClick, limitPage }) {
  const { value } = useContext(LocalContext)
  const pageValue =  value?.pageGridView
  // this determines the limit of the arrows
  const totalRecords = dataRequest?.data?.num_found
  return (
    <>
      <Grid className={stylesGridView.arrowsContainer}>
        {dataRequest?.data ? <FontAwesomeIcon onClick={prevSearchOnClick} icon={faAngleLeft} className={pageValue === 1 ? stylesGridView.disabledArrowLeft : stylesGridView.enableArrowLeft} /> : ''}

        {dataRequest?.data ? <FontAwesomeIcon onClick={nextSearchOnClick} icon={faAngleRight} className={totalRecords === limitPage || totalRecords <= limitPage ? stylesGridView.disabledArrowRight : stylesGridView.enableArrowRight} /> : ''}
      </Grid>
    </>
  )
}
