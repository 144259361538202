import React from 'react';
import styles from "./styles.module.css";

function Descriptions({ record }) {
    // Sort descriptions by dateSeen in descending order
    const sortedDescriptions = record?.descriptions?.sort((a, b) => {
        return new Date(b.dateSeen) - new Date(a.dateSeen);
    }) || [];

    return (
        <section className={styles.containerMainDescription}>
            <h2 className={styles.titleDescription}>
                Descriptions
            </h2>

            <div className={styles.containerDescription}>
                {sortedDescriptions.length > 0 ? (
                    sortedDescriptions.slice(0, 3).map((description, index) => (
                        <div className={styles.containerDescriptionValues} key={index}>
                            <p className={styles.descriptionValueP}>
                                {description.value}
                            </p>
                            <p className={styles.descriptionDateP}>
                                {description.dateSeen ? (
                                    new Intl.DateTimeFormat("en-US", {
                                        month: "long",
                                        day: "numeric",
                                        year: "numeric",
                                    }).format(new Date(description.dateSeen))
                                ) : "N/A"}
                            </p>
                        </div>
                    ))
                ) : (
                    <span className={styles.notFound}>N/A</span>
                )}
            </div>
        </section>
    );
}

export default Descriptions;
