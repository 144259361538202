/* React and components imports */
import React, { useState, useContext } from 'react'
/* Material ui imports */
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Link, Button } from '@material-ui/core'
import * as styles from './styles'
/* React query imports */
import { useQuery } from 'react-query'
import { getDownloads } from '../../Api'
import { columns } from '../Download/columnsDownload'
/* router utils imports */
import stylesTable from './stylesTable.module.css'
import { useHistory } from 'react-router-dom'
import { NavigationContext } from '../NavigationContext'
import CircularProgress from '@mui/material/CircularProgress';

export default function Downloads() {
  const { setNavigationValue } = useContext(NavigationContext)
  // page and limit state
  const [page] = useState(1)
  const [limit] = useState(6)
  // react query
  const { data, isLoading } = useQuery([page, limit], getDownloads)
  const records = data?.data?.records
  /* usestyles and classes are used to style the app */
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  /* This action is used to redirect */
  const history = useHistory()
  const goToDownloads = () => {
    history.push('/download')
    setNavigationValue('/download')
  }
  // Downloads GUI
  return (
    <div id={stylesTable.containerTableDashboard}>
      <Typography className={classes.activityData}>Activity</Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'white', alignItems: 'baseline' }}>
        <Typography style={{ backgroundColor: 'white' }} className={classes.titleDownload}>Downloads</Typography>
      </div>
      {(records?.length === 0 || records === undefined) && isLoading === false ? <div className={classes.noDownloadAvailableContainer}><label className={classes.noDownloadTitle}>No downloads available</label></div> :
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <table>
            <thead>
              <tr>
                {records?.length !== 0 && records !== undefined && isLoading === false ?
                  columns.map((column, i) => (
                    <th key={column.label} align={column.align}>{column.label}</th>
                  ))
                  : <th style={{ display: 'none' }}></th>}
              </tr>
            </thead>
            <tbody>
              {records?.map((row, i) => (
                <tr style={{ cursor: 'pointer' }} onClick={() => { window.location.href = `singleDownload?id=${row.id}` }} key={row.id}>
                  <td>{row.id}</td>
                  <td>{row.data_type}</td>
                  <td>{row.total_cost}</td>
                  <td>{row.date_started}</td>
                  <td>{row.status}</td>
                  <td>{row.query}</td>
                  <td><Link className={classes.view}>View</Link></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }
      {records?.length !== 0 && records !== undefined && isLoading === false ?
        <Grid className={stylesTable.containerButtonView} container justifyContent='flex-start' >
          <Button variant='text' onClick={goToDownloads} className={classes.buttonViewAllDownloads} style={{ color: '#3F3B88', marginLeft: '1rem' }}>View All</Button>
        </Grid> : ''}
      {isLoading === true ? <div className={stylesTable.loadingView}><label style={{ marginRight: "2.5px" }}> Loading...</label> <CircularProgress color="secondary" />
      </div> : ''
      }
    </div >
  )
}
