import React from 'react'
import styles from "./styles.module.css";

function DownloadFiles({ downloadByIdData }) {

    const formatStatus = (status) => {
        if (status === 'running') {
            return 'In Progress';
        }
        return status.charAt(0).toUpperCase() + status.slice(1);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const timeString = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' });
        const dateStringFormatted = date.toLocaleDateString('en-US', { day: '2-digit', month: 'long', year: 'numeric' });
        return `${timeString} on ${dateStringFormatted}`;
    };

    return (
        <section className={styles.downloadSectionContainer}>
            <h2 className={styles.downloadSectionTitle}>Download</h2>
            <div>
                <label className={styles.downloadFilesLabel}>Status</label>
                <span className={styles.downloadFilesSpan}>{formatStatus(downloadByIdData?.data?.status)}</span>
            </div>
            <div>
                <label className={styles.downloadFilesLabel}>ID</label>
                <span className={styles.downloadFilesSpan}>{downloadByIdData?.data?.id}</span>
            </div>
            <div>
                <label className={styles.downloadFilesLabel}>Records downloaded </label>
                <span className={styles.downloadFilesSpan}>{downloadByIdData?.data?.num_downloaded}</span>
            </div>
            <div>
                <label className={styles.downloadFilesLabel}>Date started </label>
                <span className={styles.downloadFilesSpan}>{formatDate(downloadByIdData?.data?.date_started)}</span>

            </div>
            <div style={{ display: "flex" }}>
                <label className={styles.downloadFilesLabel}>Result files</label>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {downloadByIdData?.data?.results?.length > 0 ? (
                        downloadByIdData.data.results.map((fileUrl, index) => (
                            <a
                                key={index}
                                href={fileUrl}
                                download
                                className={styles.downloadFilesLink}
                            >
                                File {index + 1}
                            </a>
                        ))
                    ) : (
                        <span className={styles.downloadFilesSpan}>No files ready yet.</span>
                    )}
                </div>
            </div>
        </section>
    )
}

export default DownloadFiles