// react imports and components also moment
import React from 'react'
import moment from 'moment'
// material ui and styles imports
import { Grid, IconButton, DialogContent, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import './businessRecords.css'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import { Rating } from '@material-ui/lab'
import Divider from '@material-ui/core/Divider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CloseIcon from '@material-ui/icons/Close'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

// all these props are common from the index(the parent)
export default function BusinessRecords({ open, handleClose, infoModel, descriptionState, nextDescription, previousDescription, reviewState, nextReview, previousReview, featureSliceState, nextFeature, previousFeature, sourceUrlSliceState, previousSourceUrl, nextSourceUrl, mapView }) {
  const model = mapView ? infoModel : infoModel?.column
  // Google maps variables
  const zoom = 'zoom=13'
  const size = 'size=160x160'
  const maptype = 'maptype=roadmap'
  return (
    <Dialog className='dialog-material' open={open} maxWidth='xl' onClose={handleClose} arialabelledby='responsive-dialog-title'>
      <DialogContent className='dialog-material'>
        <Grid>
          <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'><label className='title-business-modal'>Expanded Record</label>
            <IconButton style={{ marginTop: '0px' }} onClick={handleClose}><CloseIcon /></IconButton>
          </Grid>
          <Grid style={{ marginTop: '9px' }} container direction='row' justifyContent='space-between' alignItems='flex-start'>
            <div className='name-business-modal'>{!model?.name ? 'N/A' : model?.name}</div>
            <div>
              <div className='expanded-record-data' style={{ marginRight: '30px', display: 'inline' }}>Added:{' '}{moment(model?.dateAdded).format('MMM DD, YYYY')}</div>
              <div className='expanded-record-data' style={{ display: 'inline' }}>Updated: {moment(model?.dateUpdated).format('MMM DD, YYYY')}</div>
            </div>
          </Grid>
          <div className='flex-body-business'>
            <div> <img height='160' width='160' src={model?.latitude && model?.longitude ? `https://maps.googleapis.com/maps/api/staticmap?center=${model?.latitude + ',' + model?.longitude}&${zoom}&${size}&${maptype}&markers=${model?.latitude + ',' + model?.longitude}&key=AIzaSyD0AKt7KKjLie_FUX7rH1HAFXD9svOE6Go` : model?.address && model?.city ? `https://maps.googleapis.com/maps/api/staticmap?center=${model?.address + ',' + model?.city + ',' + model?.province + ',' + model?.country + ',' + model?.postalCode}&${zoom}&${size}&${maptype}&markers=${model?.address + ',' + model?.city + ',' + model?.province + ',' + model?.country + ',' + model?.postalCode}&key=AIzaSyD0AKt7KKjLie_FUX7rH1HAFXD9svOE6Go` : require('../../img/map-not-available.png')} alt='' /></div>
            <div className='flex-row-business'>
              <div className='flex-column-business' style={{ minWidth: '190px', wordWrap: 'break-word', marginLeft: 25, marginRight: 60 }}>
                <div className='expanded-record-title'>Address</div>
                <div className='expanded-record-data'>{!model?.address ? 'N/A' : model?.address}</div>
                <div className='expanded-record-data'>{!model?.city ? 'N/A' : model?.city} {!model?.postalCode ? '' : model?.postalCode}</div>
                <div className='expanded-record-data'>{!model?.country ? 'N/A' : model?.country}</div>
              </div>
              <div className='flex-column-business' style={{ minWidth: 190, maxWidth: 190, wordWrap: 'break-word', marginRight: 60 }}>
                <div className='expanded-record-title'>Phones #s</div>
                <div className='expanded-record-data'>{!model?.phones ? 'N/A' : model?.phones[0]}</div>
                <div className='expanded-record-data'>{!model?.phones ? '' : model?.phones[1] ? model?.phones[1] : 'N/A'}</div>
              </div>
              <div className='flex-column-business' style={{ minWidth: 190, maxWidth: 190, marginRight: 60 }}>
                <div className='expanded-record-title'>Emails</div>
                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} className='expanded-record-data'>{!model?.emails ? 'N/A' : model?.emails[0]}</div>
                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} className='expanded-record-data'>{!model?.emails ? '' : model?.emails[1] ? model?.emails[1] : ''}</div>
              </div>
              <div className='flex-column-business' style={{ minWidth: 234, maxWidth: 234, marginRight: 20 }}>
                <div className='expanded-record-title'>Websites</div>
                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={!model?.websites ? () => { } : () => window.open(model?.websites[0] ? model?.websites[0] : '', '_blank')} className={!model?.websites ? 'expanded-record-data' : 'web-site-links'}>{!model?.websites ? 'N/A' : model?.websites[0]}</div>
                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={!model?.websites ? () => { } : () => window.open(model?.websites[1] ? model?.websites[1] : '', '_blank')} className={!model?.websites ? '' : !model?.websites[1] ? 'expanded-record-data' : 'web-site-links'}>{!model?.websites ? '' : model?.websites[1] ? model?.websites[1] : ''}</div>
                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={!model?.websites ? () => { } : () => window.open(model?.websites[2] ? model?.websites[2] : '', '_blank')} className={!model?.websites ? '' : model?.websites[2] ? 'web-site-links' : 'expanded-record-data'}>{!model?.websites ? '' : model?.websites[2] ? model?.websites[2] : ''}</div>
              </div>
              <div className='flex-column-business' style={{ fontSize: '16px' }}>
                <div className='expanded-record-title'>Social Media</div>
                <div>
                  {!model?.facebookPageURL && !model?.twitter ? 'N/A' : ''}
                  {!model?.facebookPageURL ? '' : <FacebookIcon onClick={() => window.open(model?.facebookPageURL, '_blank')} style={{ marginRight: '10px', width: '30px', height: '30px', color: '#4267B2', cursor: 'pointer' }} />}
                  {!model?.twitter ? '' : <TwitterIcon onClick={() => window.open(`https://twitter.com/${model?.twitter}`, '_blank')} style={{ width: '30px', height: '30px', color: '#1DA1F2', cursor: 'pointer' }} />}
                </div>
              </div>
            </div>
          </div>
          <div className='flex-row-categories' style={{ marginBottom: '30px', marginTop: '38px', flexWrap: 'wrap' }}>
            <div style={{ width: '460px', maxWidth: '460px', marginRight: '18px', display: 'inline' }} className='flex-column-business'>
              <div className='expanded-record-title'>Primary Categories</div>
              {!model?.primaryCategories ? 'N/A' : model?.primaryCategories?.map((categorie, index) => (<p style={{ display: 'inline' }} key={index}>{index === model?.primaryCategories?.length - 1 ? categorie + '' : categorie + ', '}</p>))}
            </div>
            <div style={{ minWidth: '184px', maxWidth: '184px', marginRight: '23px' }} className='flex-column-business'>
              <div className='expanded-record-title'>Employees</div>
              <div className='expanded-record-data'>{!model?.numExployeesMin ? 'N/A' : model?.numExployeesMin && model?.numExployeesMan ? model?.numExployeesMin + '-' + model?.numExployeesMan : 'N/A'}</div>
            </div>
            <div style={{ minWidth: '190px', maxWidth: '190px', marginRight: '60px' }} className='flex-column-business'>
              <div className='expanded-record-title'>Year Incorporated</div>
              <div className='expanded-record-data'>{!model?.yearIncorporated ? 'N/A' : model?.yearIncorporated}</div>
            </div>
            <div style={{ minWidth: '199px', maxWidth: '199px', marginRight: '60px' }} className='flex-column-business'>
              <div className='expanded-record-title'>Claimed</div>
              <div className='expanded-record-data'>{!model?.claimed ? 'N/A' : model?.claimed[0] ? model?.claimed[0] : 'N/A'}</div>
              <div className='expanded-record-data'>{!model?.claimed ? '' : model?.claimed[1] ? model?.claimed[1] : ''}</div>
            </div>
            <div style={{ minWidth: '70px', maxWidth: '70px' }} className='flex-column-business'>
              <div className='expanded-record-title'>Is Closed</div>
              <div style={{ textTransform: 'capitalize' }} className='expanded-record-data'>{!model?.isClosed ? 'N/A' : model?.isClosed}</div>
            </div>
          </div>
          <div style={{ flexWrap: 'wrap' }} className='flex-body-business'>
            <div className='flex-column-business'>
              <div className='expanded-record-title'>Categories</div>
              <div style={{ minWidth: '460px', maxWidth: '460px', marginRight: '18px' }}> {!model?.categories ? 'N/A' : model?.categories ? model?.categories.map((categorie, index) => (<p style={{ display: 'inline' }} key={index}>{index === model?.categories?.length - 1 ? categorie + '' : categorie + ', '}</p>)) : 'N/A'}</div>
            </div>
            <div style={{ minWidth: 184, maxWidth: 184, marginRight: 23 }} className='flex-column-business'>
              <div className='expanded-record-title'>Revenue</div>
              <div className='expanded-record-data'> {!model?.revenueMin ? 'N/A' : model?.revenueMin && model?.revenueMax ? '$' + model?.revenueMin?.toLocaleString() + '- $' + model?.revenueMax?.toLocaleString() : 'N/A'}</div>
            </div>
            <div style={{}} className='flex-column-business'>
              <div className='expanded-record-title'>Year Opened</div>
              <div className='expanded-record-data'>{!model?.yearOpened ? 'N/A' : model?.yearOpened ? model?.yearOpened : ''}</div>
            </div>
          </div>
          <div style={{ marginTop: '14px', marginBottom: '24px' }} className='flex-column-business'>
            <div className='expanded-record-title'>SIC</div>
            <div className='expanded-record-data'>{!model?.sic ? 'N/A' : model?.sic ? model?.sic : ''}</div>
          </div>
          <Divider style={{ backgroundColor: '#43425D', opacity: 0.3, height: '2.2px' }} />
          <div className='expanded-record-title' style={{ marginTop: '18px', marginBottom: '5px' }}>Descriptions</div>
          <div className='expanded-record-data'>{!model?.descriptions ? 'N/A' : model?.descriptions[descriptionState]?.value}</div>
          <div style={{ marginBottom: '8px', marginTop: '8px', fontStyle: 'italic' }}>{!model?.descriptions ? '' : moment(model?.descriptions[descriptionState]?.dateSeen).format('MMM DD, YYYY')}</div>
          <div onClick={() => window.open(model?.descriptions[descriptionState]?.sourceURLs, '_blank')} style={{ marginBottom: '20px', color: '#065E88', cursor: 'pointer' }}> {!model?.descriptions ? '' : model?.descriptions[descriptionState]?.sourceURLs}</div>
          <div style={{ marginBottom: '20px' }} className='container-buttons'>
            <div><FontAwesomeIcon icon={faAngleLeft} onClick={previousDescription} className={descriptionState === 0 ? 'disabled-arrow-left' : 'enable-arrow-left'} /> </div>
            <div><FontAwesomeIcon icon={faAngleRight} onClick={nextDescription} className={!model?.descriptions ? 'disabled-arrow-right' : descriptionState === model?.descriptions?.length - 1 ? 'disabled-arrow-right' : 'enable-arrow-right'} /></div>
          </div>
          <Divider style={{ backgroundColor: '#43425D', opacity: 0.3, height: '2.2px' }} />
          <p className='expanded-record-title' style={{ marginTop: '18px', marginBottom: '6px' }}>Reviews</p>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {!model?.reviews ? <label>N/A</label>
              : <Rating className='rating' style={{ marginRight: '30.2px' }} value={!model?.reviews ? 0 : model?.reviews[reviewState]?.rating} readOnly />}
            <p className='expanded-record-data' style={{ marginTop: '-4px' }}>{!model?.reviews ? '' : model?.reviews[reviewState]?.title}</p>
          </div>
          <p className='expanded-record-data' style={{ marginTop: '0px' }}>{!model?.reviews ? '' : model?.reviews[reviewState]?.text}</p>
          <Typography style={{ marginTop: '10px', display: 'inline', marginRight: '52px', fontStyle: 'italic' }}>{!model?.reviews ? '' : moment(model?.reviews[reviewState]?.date).format('MMM DD, YYYY')}</Typography>
          <Typography style={{ marginTop: '10px', display: 'inline' }}>{!model?.reviews ? '' : 'by ' + model?.reviews[reviewState]?.username}</Typography>
          <Typography onClick={() => window.open(model?.reviews[reviewState]?.sourceURLs[0], '_blank')} style={{ marginBottom: '10px', color: '#065E88', cursor: 'pointer' }}>{!model?.reviews ? '' : model?.reviews[reviewState]?.sourceURLs[0]}</Typography>
          <div style={{ marginBottom: '20px' }} className='container-buttons'>
            <div><FontAwesomeIcon icon={faAngleLeft} onClick={previousReview} className={reviewState === 0 ? 'disabled-arrow-left' : 'enable-arrow-left'} /> </div>
            <div><FontAwesomeIcon icon={faAngleRight} onClick={nextReview} className={!model?.reviews ? 'disabled-arrow-right' : reviewState === model?.reviews?.length - 1 ? 'disabled-arrow-right' : 'enable-arrow-right'} /> </div>
          </div>
          <Divider style={{ backgroundColor: '#43425D', opacity: 0.3, height: '2.2px' }} />
          <div style={{ marginBottom: '3px', marginTop: '18px' }} className='expanded-record-title'>Features</div>
          <div>
            {!model?.features ? 'N/A' : model?.features?.slice(featureSliceState?.initialState, featureSliceState?.finalState).map((feature, index) => (<div style={{ display: 'inline-block', marginBottom: '10px', marginRight: '50px' }} key={index}>
              <label style={{ color: '#43425D', fontWeight: '700' }}>{feature?.key}: </label> <label style={{ color: '#43425D' }}>{feature?.value} </label>
            </div>))}
          </div>
          <div style={{ marginBottom: '20px' }} className='container-buttons'>
            <div><FontAwesomeIcon icon={faAngleLeft} onClick={previousFeature} className={featureSliceState?.initialState === 0 ? 'disabled-arrow-left' : 'enable-arrow-left'} /> </div>
            <div><FontAwesomeIcon icon={faAngleRight} onClick={nextFeature} className={!model?.features ? 'disabled-arrow-right' : featureSliceState?.finalState >= model?.features?.length - 1 ? 'disabled-arrow-right' : 'enable-arrow-right'} /> </div>
          </div>
          <Divider style={{ backgroundColor: '#43425D', opacity: 0.3, height: '2.2px' }} />
          <p className='expanded-record-title' style={{ marginTop: '18px', marginBottom: '6px' }}>Source URLs</p>
          <div>{!model?.sourceURLs ? 'N/A' : model?.sourceURLs.slice(sourceUrlSliceState?.initialState, sourceUrlSliceState?.finalState).map((source, index) => (<p onClick={() => window.open(source, '_blank')} style={{ display: 'block', margin: '0px 0px 0px 0px', color: '#065E88', cursor: 'pointer' }} key={index}>{source}</p>))}</div>
          <div className='container-buttons'>
            <div><FontAwesomeIcon icon={faAngleLeft} onClick={previousSourceUrl} className={sourceUrlSliceState?.initialState === 0 ? 'disabled-arrow-left' : 'enable-arrow-left'} /> </div>
            <div><FontAwesomeIcon icon={faAngleRight} onClick={nextSourceUrl} className={!model?.features ? 'disabled-arrow-right' : sourceUrlSliceState?.finalState >= model?.sourceURLs?.length ? 'disabled-arrow-right' : 'enable-arrow-right'} /> </div>
          </div>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
