/* React  and components imports */
import React, { useState, useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
/* Styles and Icons imports */
import { makeStyles } from '@material-ui/core/styles'
import ErrorIcon from '@material-ui/icons/Error'
import * as styles from './stylesSnack'
import clsx from 'clsx'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import SnackbarContent from '@material-ui/core/SnackbarContent'

/* Snackbar components gets data from others components */
export default function SnackbarComponent({ snackData }) {
  /* Icons */
  const variantIcon = { error: ErrorIcon, success: CheckCircleIcon, info: CheckCircleIcon }
  /* Styles and classes  */
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  /* icon error  */
  const Icon = variantIcon[snackData?.type]
  /* Snackbar State */
  const [open, setOpen] = useState(false)
  /* Event to handle the open state */
  const handle = snackData?.update
  const handleClick = () => { if (handle) { setOpen(true) } }
  /* Every time the snackData changes, the state changes */
  useEffect(() => { handleClick() }, [snackData]) // eslint-disable-line react-hooks/exhaustive-deps
  /* Close Snack event */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: snackData?.verticalPos ? snackData?.verticalPos : 'bottom',
        horizontal: snackData?.horizontalPos ? snackData?.horizontalPos : 'center'
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={clsx(classes[snackData?.type])}
        message={
          <span className={classes?.message}>
            <Icon className={clsx(classes?.icon, classes?.iconVariant)}>
              {snackData?.type}
            </Icon>
            {snackData?.myMessage}
          </span>
        }
        action={
          <IconButton size='small' onClick={handleClose}>
            <CloseIcon className={classes?.icon} />
          </IconButton>
        }
      />
    </Snackbar>
  )
}
