// React, Local Context imports
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { LocalContext } from "../LocalContext";
import { NavigationContext } from "../NavigationContext";
// Component imports
import Business from "../BusinessData";
import PeopleData from "../PeopleData";
import ProductData from "../ProductData";
import DownloadDR6 from "../AllDownloadsDR6/AllDownloads";
import Navbar from "../Navbar";
import Settings from "../SettingsDR6/Index/IndexSettings";
import { postNewToken } from "../../Api/index";
import { useMutation } from "react-query";
// React router imports
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { getAccount } from "../../Api";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import useStore from "../Store/Store";

import PropertyDataDR6 from "../PropertyDataDr6";

// Styles dashboard
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F0F0F7",
    height: "100vh",
  },
  rootProperty: {
    height: "100vh",
    width: "100%",
    margin: "0",
    padding: "0",
    backgroundColor: "white",
  },
}));

function Dashboard() {
  const location = useLocation();
  const { setNewToken } = useStore();
  const history = useHistory();
  const { data: accountInfo } = useQuery("accountApp", getAccount);
  // Local Context Value
  const [value, setValue] = useState("");
  // Drawer Context Value
  const [navigationValue, setNavigationValue] = useState(NavigationContext);
  const emailData = accountInfo?.data?.email
  // Styles Classes
  const classes = useStyles();
  // State Mobile Drawer
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // container state
  const [containerState, setContainerState] = useState(true);
  // Functions to set the mobile drawer open
  const openDrawerMobile = () => {
    setMobileOpen(!mobileOpen);
  };

  const [newTokenPost] = useMutation(
    (email) => postNewToken(email),
    {
      onMutate: () => {
      },
      onSuccess: (data) => {
        if (data?.data?.errors || data.status >= 400) {
        } else if (!data?.data?.errors) {
          const newTokenAPIValue = data.data.newToken
          setNewToken(newTokenAPIValue)
          localStorage.setItem("dfp/TokenSearch", newTokenAPIValue);
        }
      },
      onError: (error) => { },
    });

  useEffect(() => {
    // Check if the token exists
    const session = localStorage.getItem("dfp/session");
    if (session) {
      newTokenPost({ token: session })
    } else {
      // Handle the absence of a token, maybe redirect to login
    }

  }, [newTokenPost, emailData])

  // onClick Change tabs

  useEffect(() => {
    if (accountInfo?.status === 401) {
      localStorage.clear()
      // localStorage.removeItem("dfp/session");
      // localStorage.removeItem("dfp/plan");
      // localStorage.removeItem("map view");
      history.push("/home");
    }
    localStorage.removeItem("dfp/user-sign-up");
    // eslint-disable-next-line
  }, [accountInfo]);


  const dataTypeNavigation = localStorage.getItem("dfp/dataTypeNavigation");

  useEffect(() => {

    // If the item exists, update the navigation value
    if (dataTypeNavigation) {
      setNavigationValue(dataTypeNavigation);
    }
  }, [dataTypeNavigation, location]);

  return (
    <BrowserRouter>
      <NavigationContext.Provider
        value={{ navigationValue, setNavigationValue }}
      >
        <div
          className={
            navigationValue === "property" || navigationValue === "download" || navigationValue === "settings" ? classes.rootProperty : classes.root
          }
        >
          <Navbar handleDrawerOpen={openDrawerMobile} />
          <LocalContext.Provider value={{ value, setValue }}>
            <Switch>
              <Route
                path="/people-data"
                render={() => (
                  <PeopleData
                    containerState={containerState}
                    setContainerState={setContainerState}
                  />
                )}
              />

              <Route
                path="/product-data"
                render={() => (
                  <ProductData
                    containerState={containerState}
                    setContainerState={setContainerState}
                  />
                )}
              />
              <Route
                path="/business-data"
                render={() => (
                  <Business
                    containerState={containerState}
                    setContainerState={setContainerState}
                  />
                )}
              />
              <Route path="/download" render={() => <DownloadDR6 />} />
              <Route path='/settings' render={() => <Settings />} />
              <Route path='/' render={() => <PropertyDataDR6 />} />
              <Route
                path="/property-data"
                render={() => <PropertyDataDR6 />}
              />
            </Switch>
          </LocalContext.Provider>
        </div>
      </NavigationContext.Provider>
    </BrowserRouter>
  );
}
// .propTypes = { window: PropTypes.func };
export default Dashboard;
