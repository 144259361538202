import React, { useState, useEffect, useContext } from 'react'
import { useMutation } from 'react-query'
import { Grid } from '@material-ui/core'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { columnsPeopleView } from './ColumnsPeopleView'
import styles from './styles.module.css'
import ExpandedRecord from '../../ExpandedRecord'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircularProgress from '@mui/material/CircularProgress';
import { LocalContext } from '../../LocalContext'

export default function TablePeople({ dataQuery, apiData, dataSelector }) {
    // Page State
    const { value, setValue } = useContext(LocalContext)
    const [isLoadingTable, setIsLoadingTable] = useState(false)
    // page State
    const pageValue =  value?.pageTableView
    const [openModal, setOpenModal] = useState(false)
    const [infoModel, setInfoModel] = useState({})

    const [peopleViewSum] = useMutation(({ page, queryData }) => apiData(page, queryData), { onSuccess: handleSuccessSum, onError: handleErrorSum })
    function handleSuccessSum(data) {
        const newPage = pageValue + 1
        setValue({...value, data, pageTableView: newPage })
        setIsLoadingTable(false)
     } function handleErrorSum() { }
     
     const [peopleViewSubstration] = useMutation(({ page, queryData }) => apiData(page, queryData), { onSuccess: handleSuccessSubstration, onError: handleErrorSubstration })
     function handleSuccessSubstration(data) {
        const newPage = pageValue - 1
        setValue({...value, data, pageTableView: newPage })
        setIsLoadingTable(false)
      } function handleErrorSubstration() { }
      
    // //  pagination next onClick
    const nextSearchOnClick = () => {
        setIsLoadingTable(true)
        const newPage = pageValue + 1
        const { queryData } = dataQuery
        peopleViewSum({ page: newPage, queryData })
        window.scrollTo(0, 555)
    }
    // // pagination previous onClick
    const prevSearchOnClick = () => {
        setIsLoadingTable(true)
        const newPage = pageValue - 1
        const { queryData } = dataQuery
        peopleViewSubstration({ page: newPage, queryData })
        window.scrollTo(0, 555)
    }

    useEffect(() => {
        if (openModal === true) { setTimeout(() => { setOpenModal(false) }, 50) }
    }, [openModal])

    if (value?.data?.data === undefined && isLoadingTable === false) return <div className={styles.noPeopleTableContainer}><label className={styles.noPeopleDataView}>No Table View Available</label></div>
    if (isLoadingTable === true) return <div className={styles.noPeopleTableContainer} ><label className={styles.noPeopleDataView} style={{ marginRight: '3px' }}>Loading...</label> <CircularProgress color="secondary" /></div>
    return (
        <Grid>
            <div style={{ height: '50vh', width: '100%', overflowX: 'auto' }}>
                {value?.data?.data?.records?.map((column, i) => (
                    <table className={styles.tablePeople} key={i}>
                        <tbody>
                            <tr>
                                <td>
                                    <table key={i} className={styles.contentPeopleTable}>
                                        <tbody>
                                            <tr>
                                                <th
                                                    onClick={() => {
                                                        setInfoModel({ column })
                                                        setOpenModal(true)
                                                    }} style={{ cursor: 'pointer' }} className={styles.nameItem}
                                                >{column?.firstName.charAt(0).toUpperCase() + column?.firstName.substring(1)  || 'N/A'} {column?.lastName.charAt(0).toUpperCase() + column?.lastName.substring(1) || 'N/A'}
                                                </th><th /><th /><th /><th />
                                                <th style={{ fontWeight: 300 }}><p>Updated: {column?.dateUpdated ? moment(column?.dateUpdated).format('MMM DD, YYYY') : 'N/A'}</p></th>
                                                <th style={{ color: '#065E88', cursor: 'pointer' }}>
                                                    <p onClick={() => {
                                                        setInfoModel({ column })
                                                        setOpenModal(true)
                                                    }}
                                                    >See full record
                                                    </p>
                                                </th>
                                            </tr>
                                            <tr>
                                                {columnsPeopleView.map((columnTable) => (
                                                    <th key={columnTable.id}>{columnTable.label}</th>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>Job title:  {column?.jobTitle || 'N/A'}</td>
                                                <td>Email:  {column?.primaryEmail ?  column?.primaryEmail :  column?.professionalEmails ? column?.professionalEmails[0] : column?.emails ?  column?.emails[0] : "N/A"}</td>
                                                <td>{column?.businessName || 'N/A'}</td>
                                                <td>{!column?.businessCategories ? 'N/A' : column.businessCategories[0]}</td>
                                                <td>Gender: {column?.gender || 'N/A'}</td>
                                                <td >{!column?.jobSkills ? 'N/A' : column.jobSkills[0]} </td>
                                                <td>{!column?.interests ? 'N/A' : column.interests[0]} </td>
                                            </tr>
                                            <tr>
                                                <td>Job level: {column?.jobLevel || 'N/A'}</td>
                                                <td >Phone: {!column?.phones ? 'N/A' : column.phones[0]} </td>
                                                <td>Location: {column?.address || 'N/A'}</td>
                                                <td>{!column?.businessCategories ? '' : !column?.businessCategories[1] ? "N/A" : column.businessCategories[1]}</td>
                                                <td>Date of birth: {!column.birthDate ? 'N/A' : column.birthDate}</td>
                                                <td >{!column?.jobSkills ? 'N/A' : !column.jobSkills[1] ? "" : column.jobSkills[1]} </td>
                                                <td>{!column?.interests ? 'N/A' : !column.interests[1] ? '' : column.interests[1]} </td>
                                            </tr>
                                            <tr>
                                                <td>Job function: {column?.jobFunction || 'N/A'}</td>
                                                <td>Social: {column?.linkedinURL || 'N/A'}</td>
                                                <td># Employees {column.numEmployeesMin || 'N/A'} - {column.numEmployeesMax || 'N/A'}</td>
                                                <td>{!column?.businessCategories ? '' : !column?.businessCategories[2] ? "N/A" : column.businessCategories[2]}</td>
                                                <td></td>
                                                <td >{!column?.jobSkills ? 'N/A' : !column.jobSkills[2] ? "" : column.jobSkills[2]} </td>
                                                <td>{!column?.interests ? 'N/A' : !column.interests[2] ? '' : column.interests[2]} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                ))}
            </div>
            <Grid className={styles.arrowsContainer}>
                {value?.data?.data ? <FontAwesomeIcon onClick={prevSearchOnClick} icon={faAngleLeft} className={pageValue === 1 ? styles.disabledArrowLeft : styles.enableArrowLeft} /> : ''}
                {value?.data?.data ? <FontAwesomeIcon onClick={nextSearchOnClick} icon={faAngleRight} className={value?.data?.data?.total_cost !== 10 ? styles.disabledArrowRight : styles.enableArrowRight} /> : ''}
            </Grid>
            <ExpandedRecord openModal={openModal} infoModel={infoModel} dataSelector={dataSelector} />
        </Grid>
    )
}
