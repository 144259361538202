// Records Preview styles Begins
// Container Records Preview styles
export const containerRecordsPreview = {
  '@media (max-width: 600px)': { marginLeft: '0rem' }
}
// Json bar style
export const jsonBar = {
  backgroundColor: '#FFFFFF',
  color: '#43425D',
  width: '100%',
  borderBottom: '0.5px solid #E9E9F0'
}
// Records Title style
export const recordsTitle = {
  fontFamily: 'Roboto',
  fontSize: '24px',
  fontWeight: '500',
  color: '#000000',
  marginTop: '1.375rem',
  marginBottom: '1.25rem',
  '@media (max-width: 600px)': { marginLeft: '1rem' }
}
// Records Preview styles Ends
