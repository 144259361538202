import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Profile from '../Profile/ProfileSettings'; // Make sure the path is correct
import { LocalContext } from '../../LocalContext';
import ApiSettings from '../API/ApiSettings';
import Billing from '../Billing/IndexBilling/index/IndexBilling';
import { useStyles } from './styles';
import { Link, Route, Switch, useRouteMatch, useHistory, useLocation } from 'react-router-dom';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    const classes = useStyles();

    return (
        <Tab
            disableFocusRipple
            disableRipple
            component={Link}
            className={classes.tab}
            {...props}
        />
    );
}

function IndexSettings() {
    const classes = useStyles();
    const { setValue } = useContext(LocalContext);
    const [valueTabs, setValueTabs] = React.useState(0);
    let { path, url } = useRouteMatch();
    const history = useHistory();
    const location = useLocation();

    const handleChange = (event, newValue) => {
        setValueTabs(newValue);
    };

    useEffect(() => {
        document.title = "Datafiniti Portal | Settings";
        localStorage.setItem("dfp/dataTypeNavigation", "settings");
        setValue(null);
        window.scrollTo(0, 0);
        return () => {
            setValue(null);
        };
    }, [setValue]);

    useEffect(() => {
        const tabPaths = [`${url}/profile`, `${url}/api`, `${url}/billing`];
        const currentPath = location.pathname;
        const currentTab = tabPaths.indexOf(currentPath);

        if (currentPath === `${url}`) {
            history.replace(`${url}/profile`);
        } else if (currentTab !== -1) {
            setValueTabs(currentTab);
        }
    }, [location.pathname, url, history]);

    useEffect(() => {
        history.replace(`${url}/profile`);
    }, [url, history])


    return (
        <div className={classes.root}>
            <h1 style={{ margin: 0, fontSize: "14px", fontWeight: "500", marginBottom: "18px" }}>Account</h1>
            <AppBar position="static" className={classes.appBar}>
                <Tabs
                    className={classes.tabs}
                    value={valueTabs}
                    onChange={handleChange}
                    aria-label="nav tabs example"
                >
                    <LinkTab label="Profile" to={`${url}/profile`} {...a11yProps(0)} />
                    <LinkTab label="API" to={`${url}/api`} {...a11yProps(1)} />
                    <LinkTab label="Billing" to={`${url}/billing`} {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <hr style={{ margin: "0px", border: "1px solid #E5E5E5" }} />
            <Switch>
                <Route path={`${path}/profile`} component={Profile} />
                <Route path={`${path}/api`} component={ApiSettings} />
                <Route path={`${path}/billing`} component={Billing} />
            </Switch>
        </div>
    );
}

export default IndexSettings;
