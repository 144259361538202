import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
    root: {
        padding: "38px 40px",
    },
    appBar: {
        backgroundColor: '#fff',
        boxShadow: 'none',
        borderBottom: 'none',
        color: "#8122B4",
        marginBottom: "12px",
    },
    tabs: {
        minHeight: "0px",
        '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
        },
    },
    tab: {
        minWidth: "0px",
        fontSize: "14px",
        padding: "0px",
        textTransform: "none",
        minHeight: "0px",
        marginRight: "40px",
        color: "#000000",
        '&.Mui-selected': {
            color: '#8122B4',
            fontWeight: 500,
        },
    },
}));