// react and components imports
import React from 'react'
import moment from 'moment'
// material ui and styles imports
import { Grid, Typography, DialogContent, IconButton } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import { Rating } from '@material-ui/lab'
import './productRecords.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import CloseIcon from '@material-ui/icons/Close'
// all these props are common from the index(the parent)
export default function ProductRecords({ open, imageProduct, previousImage, nextImage, descriptionState, previousDescription, nextDescription, previousReview, nextReview, infoModel, handleClose, reviewState, featureSliceState, nextFeature, previousFeature, sourceUrlSliceState, previousSourceUrl, nextSourceUrl, priceState, skusState, previousSku, nextSku, previousPrice, nextPrice }) {
  const model = infoModel?.column
  return (
    <Dialog className='dialog-material' open={open} maxWidth='xl' onClose={handleClose} arialabelledby='responsive-dialog-title'>
      <DialogContent className='dialog-material'>
        <Grid container>
          <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', alignContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <label className='title-product-modal'>Expanded Record</label>
            <IconButton style={{ marginTop: '0px' }} onClick={handleClose}><CloseIcon /></IconButton>
          </div>
          <Grid container direction='row' justifyContent='space-between'>
            <label style={{ width: '60%' }} className='name-product-modal'>{model?.name || 'N/A'}</label>
            <Grid style={{ marginTop: '3px' }}>
              <Typography display='inline' className='expanded-record-data'>Added:{' '}{!model?.dateAdded ? 'N/A' : moment(model?.dateAdded).format('MMM DD, YYYY')}</Typography>
              <Typography display='inline' className='expanded-record-data' style={{ paddingLeft: '30px' }}>Updated: {!model?.dateUpdated ? 'N/A' : moment(model?.dateUpdated).format('MMM DD, YYYY')}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <div className='flex-body'>
          <div className='flex-row-arrows' style={{ width: '320px', height: '240px' }}>
            <div><FontAwesomeIcon icon={faAngleLeft} onClick={previousImage} className={imageProduct === 0 ? 'disabled-product-arrow-left' : 'enable-product-arrow-left'} /> </div>
            <img src={model?.primaryImageURLs ? model?.primaryImageURLs[imageProduct] : model.imageURLs ? model.imageURLs[imageProduct] : require('../../img/noValidImageFound.jpeg')} height='240' width='240' className='flex-row-image' alt='' />
            <div><FontAwesomeIcon icon={faAngleRight} onClick={nextImage} className={!model?.primaryImageURLs ? 'disabled-product-arrow-right' : imageProduct >= model?.primaryImageURLs?.length - 1 ? 'disabled-product-arrow-right' : 'enable-product-arrow-right'} /> </div>
          </div>
          <div className='flex-column' style={{ paddingLeft: '30px' }}>
            <div style={{ marginBottom: '-5px' }} className='flex-row-first'>
              <div style={{ width: '244px', marginRight: '40px' }} className='flex-row-first'>
                <div style={{ marginRight: '77.5px' }} className='expanded-record-title'>Brand</div>
                <div className='expanded-record-data'>{model?.brand || 'N/A'}</div>
              </div>
              <div style={{ width: '171.5px', marginRight: '40px' }} className='flex-row-first'>
                <div className='expanded-record-title' style={{ marginRight: '23px', minWidth: '47px' }}>UPC-A</div>
                <div className='expanded-record-data'>{model?.upca || 'N/A'}</div>
              </div>
              <div style={{ width: '200px', marginRight: '40px' }} className='flex-row-first'>
                <div style={{ marginRight: '29px' }} className='expanded-record-title'>EAN-8</div>
                <div className='expanded-record-data'>{model?.ean8 || 'N/A'}</div>
              </div>
              <div className='flex-row-first'>
                <div style={{ marginRight: '27px' }} className='expanded-record-title'>ASIN</div>
                <div className='expanded-record-data'>{model?.asins || 'N/A'}</div>
              </div>
            </div>
            <div style={{ marginBottom: '-5px' }} className='flex-row-first'>
              <div style={{ width: '245px', marginRight: '39.5px' }} className='flex-row-first'>
                <div style={{ marginRight: '30px' }} className='expanded-record-title'>Manufacturer</div>
                <div className='expanded-record-data'>{model?.manufacturer || 'N/A'}
                </div>
              </div>
              <div className='flex-row-first' style={{ width: '171.5px', marginRight: '40px' }}>
                <div style={{ marginRight: '29px' }} className='expanded-record-title'>UPC-E</div>
                <div className='expanded-record-data'>{model?.upce || 'N/A'}</div>
              </div>
              <div className='flex-row-first'>
                <div style={{ marginRight: '20px' }} className='expanded-record-title'>EAN-13</div>
                <div className='expanded-record-data'>{model?.ean13 || 'N/A'}</div>
              </div>
            </div>
            <div style={{ marginBottom: '-5px' }} className='flex-row-first'>
              <div className='flex-row-first'>
                <div style={{ marginRight: '17px' }} className='expanded-record-title'>Manufacturer #</div>
                <div className='expanded-record-data'>{model?.manufacturerNumber || 'N/A'}</div>
              </div>
            </div>
            {/*  */}
            {model?.mostRecentPriceDomain ?
              <div style={{ marginTop: '25px', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '15px' }}>
                  <div style={{ marginRight: '40px' }}>
                    <div style={{ minWidth: '215px' }} className='expanded-record-title'>Most Recent Price</div>
                    <div className='expanded-record-data'>{model?.mostRecentPriceAmount || 'N/A'}</div>
                  </div>
                  <div style={{ marginRight: '40px' }}>
                    <div style={{ minWidth: '215px' }} className='expanded-record-title'>Most Recent Price Availability</div>
                    <div className='expanded-record-data'>{model?.mostRecentPriceAvailability || 'N/A'}</div>
                  </div>
                  <div style={{ marginRight: '40px' }}>
                    <div style={{ minWidth: '215px' }} className='expanded-record-title'>Most Recent Price Currency</div>
                    <div className='expanded-record-data'>{model?.mostRecentPriceCurrency || 'N/A'}</div>
                  </div>
                </div>
                {/*  */}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '40px' }}>
                    <div style={{ minWidth: '215px' }} className='expanded-record-title'>Most Recent Price Date</div>
                    <div className='expanded-record-data'>{model?.mostRecentPriceDate ? moment(model?.mostRecentPriceDate).format('MMM DD, YYYY') : 'N/A'}</div>
                  </div>
                  <div style={{ marginRight: '40px' }}>
                    <div style={{ minWidth: '215px' }} className='expanded-record-title'>Most Recent Price Domain</div>
                    <div className='expanded-record-data'>{model?.mostRecentPriceDomain || 'N/A'}</div>
                  </div>
                  <div style={{ marginRight: '40px' }}>
                    <div style={{ minWidth: '215px' }} className='expanded-record-title'>Most Recent Price Source URL</div>
                    <div style={{ color: model?.mostRecentPriceSourceURL ? '#065E88' : '', cursor: model?.mostRecentPriceSourceURL ? 'pointer' : '' }} onClick={!model?.mostRecentPriceSourceURL ? () => { } : () => window.open(model?.mostRecentPriceSourceURL ? model?.mostRecentPriceSourceURL : '', '_blank')}>{model?.mostRecentPriceSourceURL || 'N/A'}</div>
                  </div>
                </div>
              </div>
              : ''}
            {/*  */}
            <div className='expanded-record-title' style={{ marginTop: '15px', marginBottom: '10px' }}>Categories</div>
            <div className='expanded-record-data'>{model?.primaryCategories && model?.secondaryCategories ? model?.primaryCategories + ' > ' + model?.secondaryCategories : model?.primaryCategories ? model?.primaryCategories : 'N/A'} </div>
            <div className='expanded-record-title' style={{ marginTop: '18px', marginBottom: '10px' }}>Raw Categories</div>
            <div className='expanded-record-data'>
              {!model?.categories ? 'N/A' : model?.categories?.map((categorie, index) => (<Typography style={{ display: 'inline' }} key={index}>{index === model?.categories?.length - 1 ? categorie + '.' : categorie + ', '}</Typography>))}
            </div>
          </div>
        </div>
        <div className='expanded-record-title' style={{ marginTop: '28px', marginBottom: '8px' }}>Descriptions</div>
        <div className='expanded-record-data'>{!model?.descriptions ? 'N/A' : model?.descriptions[descriptionState]?.value}</div>
        <div style={{ fontStyle: 'italic', marginBottom: '8px', marginTop: '8px' }}>{!model?.descriptions ? '' : moment(model?.descriptions[descriptionState]?.dateSeen).format('MMM DD, YYYY')}</div>
        <div onClick={() => window.open(model?.descriptions[descriptionState]?.sourceURLs, '_blank')} style={{ marginBottom: '20px', color: '#065E88', cursor: 'pointer' }}> {!model?.descriptions ? '' : model?.descriptions[descriptionState]?.sourceURLs}</div>
        <div className='container-buttons'>
          <FontAwesomeIcon icon={faAngleLeft} onClick={previousDescription} className={descriptionState === 0 ? 'disabled-product-arrow-left' : 'enable-product-arrow-left'} />
          <FontAwesomeIcon style={{ marginLeft: '20px' }} icon={faAngleRight} onClick={nextDescription} className={!model?.descriptions ? 'disabled-product-arrow-right' : descriptionState >= model?.descriptions?.length - 1 ? 'disabled-product-arrow-right' : 'enable-product-arrow-right'} />
        </div>

        <div className='flex-row' style={{ marginTop: '9px' }}>
          <div style={{ marginRight: '59px' }} className='flex-column'>
            <div className='expanded-record-title' style={{ marginBottom: '5px' }}>Prices</div>
            <div className='flex-row' style={{ maxHeight: '75px', height: '75px' }}>
              {!model?.prices ? 'N/A' : model?.prices.slice(priceState?.initialState, priceState?.finalState).map((price, index) => (
                <div className='flex-column' key={index} style={{ marginRight: '20px' }}>
                  <div className='expanded-record-data'>${price?.amountMin}</div>
                  <div className='date-price-product'>{price?.dateSeen[0] ? moment(price?.dateSeen[0]).format('MMM DD, YYYY') : ''}</div>
                  <div onClick={() => window.open(`http://${model?.domains[0]}`, '_blank')} className='expanded-record-data' style={{ color: '#065E88', cursor: 'pointer' }}>{model?.domains[0].slice(4)}</div>
                </div>))}
            </div>
            <Grid container justifyContent='flex-end' style={{ marginTop: '10px' }} alignItems='flex-end'>
              <FontAwesomeIcon icon={faAngleLeft} onClick={previousPrice} className={priceState?.initialState === 0 ? 'disabled-product-arrow-left' : 'enable-product-arrow-left'} />
              <FontAwesomeIcon style={{ marginLeft: '20px' }} icon={faAngleRight} onClick={nextPrice} className={!model?.prices ? 'disabled-product-arrow-right' : priceState?.finalState >= model?.prices?.length ? 'disabled-product-arrow-right' : 'enable-product-arrow-right'} />
            </Grid>
          </div>
          <div className='flex-column'>
            <div className='expanded-record-title' style={{ marginBottom: '5px' }}>Skus</div>
            <div className='flex-row' style={{ maxHeight: '75px', height: '75px' }}>
              {!model?.skus ? 'N/A' : model?.skus.slice(skusState?.initialState, skusState?.finalState).map((skus, index) => (
                <div className='flex-column' key={index} style={{ marginRight: '22px' }}>
                  <div className='expanded-record-data'>{skus?.value}</div>
                  <div onClick={() => window.open(`http://${model?.domains[0]}`, '_blank')} style={{ color: '#065E88', cursor: 'pointer' }} className='expanded-record-data'>{model?.domains[0].slice(4)}</div>
                </div>))}
            </div>
            <Grid container style={{ marginTop: '10px' }} justifyContent='flex-end' alignItems='flex-end'>
              <FontAwesomeIcon icon={faAngleLeft} onClick={previousSku} className={skusState?.initialState === 0 ? 'disabled-product-arrow-left' : 'enable-product-arrow-left'} />
              <FontAwesomeIcon style={{ marginLeft: '20px' }} icon={faAngleRight} onClick={nextSku} className={!model?.skus ? 'disabled-product-arrow-right' : skusState?.finalState >= model?.skus?.length ? 'disabled-product-arrow-right' : 'enable-product-arrow-right'} />
            </Grid>
          </div>
        </div>

        <p className='expanded-record-title' style={{ marginTop: '18px', marginBottom: '6px' }}>Reviews</p>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {!model?.reviews ? <label>N/A</label>
            : <Rating className='rating' style={{ marginRight: '30.2px' }} value={!model?.reviews ? 0 : model?.reviews[reviewState]?.rating} readOnly />}
          <p className='expanded-record-data' style={{ marginTop: '-4px' }}>{!model?.reviews ? '' : model?.reviews[reviewState]?.title ? model?.reviews[reviewState]?.title : ''}</p>
        </div>
        <p className='expanded-record-data' style={{ marginTop: '0px' }}>{!model?.reviews ? '' : model?.reviews[reviewState]?.text}</p>
        <Typography style={{ marginTop: '10px', display: 'inline', marginRight: '52px', fontStyle: 'italic' }}>{!model?.reviews ? '' : moment(model?.reviews[reviewState]?.date).format('MMM DD, YYYY')}</Typography>
        <Typography style={{ marginTop: '10px', display: 'inline' }}>{!model?.reviews ? '' : model?.reviews[reviewState]?.username}</Typography>
        <Typography onClick={() => window.open(model?.reviews[reviewState]?.sourceURLs[0], '_blank')} style={{ marginBottom: '10px', color: '#065E88', cursor: 'pointer' }}>{!model?.reviews ? '' : model?.reviews[reviewState]?.sourceURLs[0]}</Typography>
        <div className='container-buttons'>
          <FontAwesomeIcon icon={faAngleLeft} onClick={previousReview} className={reviewState === 0 ? 'disabled-product-arrow-left' : 'enable-product-arrow-left'} />
          <FontAwesomeIcon style={{ marginLeft: '20px' }} icon={faAngleRight} onClick={nextReview} className={!model?.reviews ? 'disabled-product-arrow-right' : reviewState >= model?.reviews?.length - 1 ? 'disabled-product-arrow-right' : 'enable-product-arrow-right'} />
        </div>
        <div style={{ marginBottom: '3px', marginTop: '18px' }} className='expanded-record-title'>Features</div>
        <div>
          {!model?.features ? 'N/A' : model?.features.slice(featureSliceState?.initialState, featureSliceState?.finalState).map((feature, index) =>
          (<div style={{ display: 'inline-block', marginBottom: '10px', marginRight: '50px' }} key={index}>
            <label style={{ color: '#43425D', fontWeight: '700' }}>{feature?.key}: </label> <label style={{ color: '#43425D' }}>{feature?.value} </label>
          </div>
          ))}

        </div>
        <div className='container-buttons'>
          <FontAwesomeIcon icon={faAngleLeft} onClick={previousFeature} className={featureSliceState?.initialState === 0 ? 'disabled-product-arrow-left' : 'enable-product-arrow-left'} />
          <FontAwesomeIcon style={{ marginLeft: '20px' }} icon={faAngleRight} onClick={nextFeature} className={!model?.features ? 'disabled-product-arrow-right' : featureSliceState?.finalState >= model?.features?.length - 1 ? 'disabled-product-arrow-right' : 'enable-product-arrow-right'} />
        </div>
        <p className='expanded-record-title' style={{ marginTop: '20px', marginBottom: '6px' }}>Source URLs</p>
        <div>{!model?.sourceURLs ? 'N/A' : model?.sourceURLs.slice(sourceUrlSliceState?.initialState, sourceUrlSliceState?.finalState).map((source, index) => (<p onClick={() => window.open(source, '_blank')} style={{ display: 'block', color: '#065E88', cursor: 'pointer', margin: '0px 0px 0px 0px' }} key={index}>{source}</p>))}</div>
        <div className='container-buttons'>
          <FontAwesomeIcon icon={faAngleLeft} onClick={previousSourceUrl} className={sourceUrlSliceState?.initialState === 0 ? 'disabled-product-arrow-left' : 'enable-product-arrow-left'} />
          <FontAwesomeIcon style={{ marginLeft: '20px' }} icon={faAngleRight} onClick={nextSourceUrl} className={!model?.sourceURLs ? 'disabled-product-arrow-right' : sourceUrlSliceState?.finalState >= model?.sourceURLs?.length - 1 ? 'disabled-product-arrow-right' : 'enable-product-arrow-right'} />
        </div>
      </DialogContent>
    </Dialog >
  )
}
