import React, { useState, useEffect } from "react";
import Address from "./Address"; // Import the Address component
import AllFilters from "./Filters";
import PropertyType from "./PropertyType/index";
import MostRecentStatus from "./MostRecentStatus/index";
import useStore from "../Store/Store"; // Import the store
import buildQuery from "./newQueryBuilder"; // Import buildQuery
import { autoComplete, postPropertyPaginatedDr6 } from "../../Api/index";
import { useMutation } from "react-query";
import CircularProgress from "@mui/material/CircularProgress";
import useFilterStore from "../Store/filterStore";
import SnackbarComponent from "../Snackbar/index";
import ViewApiCall from "../ViewApiCall";
import codeIcon from "../SearchFilters/images/xmark.svg";
import styles2 from "./styles.module.css";


const styles = {
  container: { display: "flex", flexDirection: "column", marginBottom: "50px", },
  header: {
    display: "flex", flexDirection: "row", flexWrap: "wrap", rowGap: "10px"
  },
  divStyles: {
    width: "1.5px", height: "23.5px", backgroundColor: "#ABABAB", margin: "0px 12.5px 2.5px 12.5px", display: "block", alignSelf: "end", paddingBottom: "5px"
  }
}

export default function SearchFilters() {
  const [snackMessage, setSnackMessage] = useState({ type: "error" });
  const { propertyType, mostRecentStatus, page, setIsQueryLoading, isQueryLoading, setPage, setQueryStore, setDownloadQueryStore, queryStore, resetStore } = useStore();
  const { setDataResults } = useStore();
  const { firstRender, setFirstRender } = useStore();

  const { allFilters, resetFilters } = useFilterStore();
  const [query, setQuery] = useState(); // State to store the query string

  const [suggestions, setSuggestions] = useState([]);

  const handleClearFilters = () => {
    if (queryStore) {
      resetFilters();
      resetStore()
    }
  };

  useEffect(() => {
    if (!firstRender) {
      const newQuery = buildQuery(
        propertyType,
        mostRecentStatus,
        allFilters
      );
      setQuery({ query: newQuery });
      setPage(1);
    } else {
      setFirstRender(false);
    }

  }, [propertyType, mostRecentStatus, allFilters]); /* eslint-disable-line react-hooks/exhaustive-deps */


  const [searchFilterRequest] = useMutation(
    ({ page, query }) => postPropertyPaginatedDr6(page, query),
    {
      onMutate: () => {
        // This will fire just before the mutation function is fired, so we can set isLoading to true here.
        setIsQueryLoading(true);
      },
      onSuccess: (data) => {

        if (data?.data?.errors || data.status >= 400) {
          setQueryStore(query);
          // setDataResults();
          setSnackMessage({
            type: "error",
            myMessage: data?.data?.errors[0],
            update: Date.now(),
          });
        } else if (!data?.data?.errors) {
          setQueryStore(query);
          setDataResults(data);
          setDownloadQueryStore({ query: "", id: "" });
        }
      },
      onError: (error) => { },
      onSettled: () => {
        // This fires when onSuccess or onError is done, so we can set isLoading to false here.
        setIsQueryLoading(false);
      },
    }
  );

  useEffect(() => {
    if (!firstRender) {
      const searchFilterRequestFunction = () => {
        searchFilterRequest({ page, query });
      };

      if (query) { // Ensure query is set before making the request
        searchFilterRequestFunction();
      }
    }

  }, [query, searchFilterRequest, page]); /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    return () => setFirstRender(true);
  }, [setFirstRender])

  // auto complete mutation
  const [searchFilterAutoComplete] = useMutation(
    (query) => autoComplete(query),
    {
      onMutate: () => {
      },
      onSuccess: (data) => {
        const records = data?.data?.records;
        const errors = data?.data?.errors;
        if (errors || data.status >= 400) {
          setSnackMessage({
            type: "error",
            myMessage: errors[0],
            update: Date.now(),
          });
        } else if (!errors) {
          setSuggestions(records);
        }
      },
      onError: (error) => { },
      onSettled: () => {
      },
    }
  );

  const isDisabled = !queryStore || isQueryLoading;

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <span
          style={{ fontSize: "14px", fontWeight: "500", marginBottom: "14px" }}
        >
          Search filters
        </span>
      </div>
      {/* All the other elements */}
      <div style={styles.header}>
        {/* Address */}
        <Address />
        {/* End of Address */}

        {/* Property Type */}
        <PropertyType />
        {/* End Property Type */}

        {/* MostRecentStatus */}
        <MostRecentStatus />

        {/* End MostRecentStatus */}

        {/* AllFilters */}
        {/* <section style={{ display: "flex", flexWrap: "wrap" }}> */}

        <AllFilters
          searchFilterAutoComplete={searchFilterAutoComplete}
          suggestions={suggestions}
          setSuggestions={setSuggestions}
        />

        <div style={styles.divStyles}></div>
        {/* End AllFilters */}

        <div style={{ display: "flex", alignItems: "flex-end", paddingBottom: "5px" }}>
          <span onClick={queryStore === "" ? undefined : handleClearFilters} className={styles2.clearFiltersSearch} style={{ cursor: isDisabled ? "not-allowed" : "pointer", opacity: isDisabled ? 0.5 : 1 }}>
            Clear filters
          </span>
          <img
            onClick={queryStore === "" ? undefined : handleClearFilters}
            src={codeIcon}
            alt="codeIcon"
            style={{
              height: "14px",
              // width: "15px",
              marginBottom: "1.5px",
              cursor: isDisabled ? "not-allowed" : "pointer",
              opacity: isDisabled ? 0.5 : 1, // Reduce opacity if disabled
            }}
          />

        </div>

        <div style={styles.divStyles}></div>

        <ViewApiCall />

        <div style={{ display: "flex", alignItems: "flex-end", marginLeft: "12.5px" }}>
          {isQueryLoading ? (
            <CircularProgress disableShrink size={25} style={{ color: "#065E88", marginBottom: "5px" }} />
          ) : ("")}
        </div>
        {/* </section> */}
      </div>
      <SnackbarComponent snackData={snackMessage} />
    </div >
  );
}
