import React from 'react';
import styles from "./styles.module.css"; // Ensure this is the correct path to your CSS module

function MostRecentPriceDate({ mostRecentPriceDateFilter, setMostRecentPriceDateFilter }) {

    const today = new Date();
    const maxDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

    const handleStartDateChange = (e) => {
        setMostRecentPriceDateFilter({ ...mostRecentPriceDateFilter, startDate: e.target.value });
    };

    const handleEndDateChange = (e) => {
        setMostRecentPriceDateFilter({ ...mostRecentPriceDateFilter, endDate: e.target.value });
    };

    return (
        <div className={styles.RecentStatusDateContainer}>
            <h1 className={styles.RecentStatusDateTitle}>Most recent price date</h1>
            <div className={styles.RecentStatusDateInputContainer}>
                <input
                    className={styles.RecentStatusDateInput}
                    type="date"
                    name="startDate"
                    max={maxDate}
                    value={mostRecentPriceDateFilter.startDate}
                    onChange={handleStartDateChange}
                    required
                />
                <span className={styles.RecentStatusDateLabel}>to</span>
                <input
                    className={styles.RecentStatusDateInput}
                    type="date"
                    name="endDate"
                    max={maxDate}
                    value={mostRecentPriceDateFilter.endDate}
                    onChange={handleEndDateChange}
                    required
                />
            </div>
        </div>
    );
}

export default MostRecentPriceDate;
