// React, Local Context  imports
import React, { useEffect, useState } from 'react'
// Material Ui, styles imports
import { Typography, Grid, AppBar, Tabs, Tab } from '@material-ui/core'
import * as styles from './styles'
import { makeStyles, withStyles } from '@material-ui/core/styles'
// Json component import
import JsonView from '../JsonView'
import CsvViewer from '../CsvViewer'
import TableView from '../TableView'
import GridView from '../GridView'
import MapView from '../MapView'

function TabPanel(props) {
  const { children, value, index } = props
  return (
    <>
      {value === index && (
        <><Typography component='span'>{children}</Typography></>
      )}
    </>
  )
}
function a11yProps(index) { return {} }
// styles
const AntTabs = withStyles({ indicator: { backgroundColor: '#18D4D4' } })(Tabs)
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontSize: '15px',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: '2%',
    fontFamily: ['Roboto'].join(','),
    '&:focus': {}
  },
  selected: { opacity: '1 !important', color: '#000000 !important' }
}))((props) => <Tab disableRipple {...props} />)

export default function RecordsPreview({ value, apiData, dataSelector }) {
  // Styles
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  // Value Tabs value
  const [valueTabs, setValueTabs] = useState(0)
  useEffect(() => {
    if (dataSelector === 'people') {
      setValueTabs(1)
    }
  }, [])/* eslint-disable-line react-hooks/exhaustive-deps */
  // onChange tabs
  const handleChange = (event, newValue) => { setValueTabs(newValue) }
  return (
    <Grid className={classes.containerRecordsPreview}>
      <Typography className={classes.recordsTitle}>Records Preview</Typography>
      <AppBar style={{ borderRadius: '5px 5px 0px 0px' }} position='static' elevation={0} className={classes.jsonBar}>
        <AntTabs value={valueTabs} onChange={handleChange} aria-label='simple tabs example'>
          <AntTab style={{ display: dataSelector === 'people' ? 'none' : "" }} label={dataSelector === 'product' ? 'Grid' : 'Map'} {...a11yProps(0)} />
          {/* CSV view will cost credits equal to you query above */}
          <AntTab label={dataSelector === 'product' || 'people' ? 'Table' : 'Visual'} {...a11yProps(1)} />
          <AntTab label='CSV' {...a11yProps(2)} />
          <AntTab label='JSON' {...a11yProps(3)} />
        </AntTabs>
      </AppBar>
      <TabPanel value={valueTabs} index={0}>
        {dataSelector === 'product' ? <GridView context={value} /> : dataSelector === 'people' ? <div></div> : <MapView apiData={apiData} dataSelector={dataSelector} />}
      </TabPanel>
      <TabPanel value={valueTabs} index={1}>
        <TableView apiData={apiData} dataSelector={dataSelector} />
      </TabPanel>
      <TabPanel value={valueTabs} index={2}>
        <CsvViewer apiData={apiData} />
      </TabPanel>
      <TabPanel value={valueTabs} index={3}>
        <JsonView apiData={apiData} />
      </TabPanel>
    </Grid >
  )
}
