import React from 'react'
import styles from "./styles.module.css";

function OwnerExpandedRecord({ record }) {
    return (
        <section className={styles.ownerMainContainer}>
            <div className={styles.containerOwner}>
                <span className={styles.spanTitle}>
                    Owner
                </span>
                <span className={styles.spanTypeValue}>
                    {record?.people && record.people.length > 0 ? record.people[0].name : "N/A"}
                </span>
            </div>
            <div className={styles.containerOwnerType}>
                <span className={styles.spanTitle}>
                    Owner type
                </span>
                <span className={styles.spanTypeValue}>
                    {record?.currentOwnerType ? record.currentOwnerType : "N/A"}
                </span>
            </div>
            <div className={styles.containerOwnerOccupied}>
                <span className={styles.spanTitle}
                >
                    Owner occupied
                </span>
                <span className={styles.spanTypeValue}>
                    {record?.ownerOccupied ? "True" : "N/A"}
                </span>
            </div>
        </section>
    )
}

export default OwnerExpandedRecord