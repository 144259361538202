import React from 'react';
import styles from "./styles.module.css";

function TransactionsHistory({ record }) {
    // Ensure record.transactions is an array and sort it by saleDate in descending order
    const sortedTransactions = Array.isArray(record.transactions) ? record.transactions.sort((a, b) => {
        return new Date(b.saleDate) - new Date(a.saleDate);
    }) : [];

    const hasTransactions = sortedTransactions.length > 0;

    return (
        <section className={styles.containerTransactionHistory}>
            <span className={styles.transactionsTitle}>Transaction history</span>

            {hasTransactions ? (
                <div className={styles.transactionHistory}>
                    {/* Header */}
                    <div className={styles.headerRow}>
                        <span className={styles.header}>Date</span>
                        <span className={styles.header}>Price</span>
                        <span className={styles.header}>Loan</span>
                        <span className={styles.header}>Term</span>
                        <span className={styles.header}>Seller</span>
                        <span className={styles.header}>Buyer</span>
                        <span className={styles.header}>Lender</span>
                    </div>

                    {/* Transaction Rows */}
                    {sortedTransactions.map((transaction, index) => (
                        <div key={index} className={styles.transactionRow}>
                            <span className={styles.cell}>
                                {transaction.saleDate ?
                                    new Intl.DateTimeFormat('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit'
                                    }).format(new Date(transaction.saleDate)) :
                                    "N/A"
                                }
                            </span>
                            <span className={styles.cell}>
                                {transaction.price ?
                                    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(transaction.price) :
                                    "N/A"
                                }
                            </span>
                            <span className={styles.cell}>
                                {transaction.loanAmount ?
                                    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(transaction.loanAmount) :
                                    "N/A"
                                }
                            </span>
                            <span className={styles.cell}>
                                {transaction.mortgageTerm || "N/A"}
                            </span>
                            <span className={styles.cell}>
                                {transaction.sellerFirstName && transaction.sellerLastName ?
                                    `${transaction.sellerFirstName} ${transaction.sellerLastName}` : "N/A"}
                            </span>
                            <span className={styles.cell}>
                                {transaction.buyerFirstName && transaction.buyerLastName ?
                                    `${transaction.buyerFirstName} ${transaction.buyerLastName}` : "N/A"}
                            </span>
                            <span className={styles.cell}>
                                {transaction.lenderName || "N/A"}
                            </span>
                        </div>
                    ))}
                </div>
            ) : (
                <div className={styles.noTransactions}>N/A</div>
            )}
        </section>
    );
}

export default TransactionsHistory;
