// react imports and components
import React, { useEffect, useState } from 'react'
import ProductRecords from './ProductRecords'
import BusinessRecords from './BusinessRecords'
import PeopleRecords from './PeopleRecords'
import PropertyRecords from './PropertyRecords'

// material ui imports and styles
import Dialog from '@material-ui/core/Dialog'
import './styles.css'

export default function ExpandedRecord({ openModal, infoModel, dataSelector, mapView }) {
  // states of the model are used to modify the state of the model itself(english at the highest lvl =)
  const [open, setOpen] = useState(false)
  const [imageProduct, setImageProduct] = useState(0)
  const [descriptionState, setDescriptionState] = useState(0)
  const [reviewState, setReviewState] = useState(0)
  const [featureSliceState, setFeatureSliceState] = useState({ initialState: 0, finalState: 5 })
  const [sourceUrlSliceState, setSourceUrlSliceState] = useState({ initialState: 0, finalState: 5 })
  const [priceState, setPriceState] = useState({ initialState: 0, finalState: 6 })
  const [skusState, setSkusState] = useState({ initialState: 0, finalState: 2 })
  // event the handle the open and close of the modal
  const handleClose = () => { setOpen(false) }
  useEffect(() => { if (openModal === true) { setOpen(true) } }, [openModal])
  /// all of these are click events to increment and decrement the states for the next and previous logic inside the modal
  const previousImage = () => { setImageProduct(imageProduct - 1) }
  const nextImage = () => { setImageProduct(imageProduct + 1) }
  const previousDescription = () => { setDescriptionState(descriptionState - 1) }
  const nextDescription = () => { setDescriptionState(descriptionState + 1) }
  const previousReview = () => { setReviewState(reviewState - 1) }
  const nextReview = () => { setReviewState(reviewState + 1) }
  const nextFeature = () => { setFeatureSliceState({ initialState: featureSliceState.initialState + 5, finalState: featureSliceState.finalState + 5 }) }
  const nextSku = () => { setSkusState({ initialState: skusState.initialState + 2, finalState: skusState.finalState + 2 }) }
  const previousSku = () => { setSkusState({ initialState: skusState.initialState - 2, finalState: skusState.finalState - 2 }) }
  const nextPrice = () => { setPriceState({ initialState: priceState.initialState + 5, finalState: priceState.finalState + 5 }) }
  const previousPrice = () => { setPriceState({ initialState: priceState.initialState - 5, finalState: priceState.finalState - 5 }) }
  const previousFeature = () => { setFeatureSliceState({ initialState: featureSliceState.initialState - 5, finalState: featureSliceState.finalState - 5 }) }
  const nextSourceUrl = () => { setSourceUrlSliceState({ initialState: sourceUrlSliceState.initialState + 5, finalState: sourceUrlSliceState.finalState + 5 }) }
  const previousSourceUrl = () => { setSourceUrlSliceState({ initialState: sourceUrlSliceState.initialState - 5, finalState: sourceUrlSliceState.finalState - 5 }) }
  // this event is used to reset the states when the component mounts
  const resetStateValues = () => {
    setImageProduct(0)
    setDescriptionState(0)
    setReviewState(0)
    setFeatureSliceState({ initialState: 0, finalState: 5 })
    setSourceUrlSliceState({ initialState: 0, finalState: 5 })
    setPriceState({ initialState: 0, finalState: 5 })
    setSkusState({ initialState: 0, finalState: 2 })
  }
  useEffect(() => { if (openModal === true) { setOpen(true) } resetStateValues() }, [openModal])
  return (
    <Dialog className='dialog-material' open={open} maxWidth='xl' onClose={handleClose} arialabelledby='responsive-dialog-title'>
      {dataSelector === 'product'
        ? <ProductRecords open={open} descriptionState={descriptionState} imageProduct={imageProduct} previousImage={previousImage} nextImage={nextImage} previousDescription={previousDescription} nextDescription={nextDescription} previousReview={previousReview} nextReview={nextReview} infoModel={infoModel} handleClose={handleClose} reviewState={reviewState} featureSliceState={featureSliceState} nextFeature={nextFeature} previousFeature={previousFeature} sourceUrlSliceState={sourceUrlSliceState} previousSourceUrl={previousSourceUrl} nextSourceUrl={nextSourceUrl} priceState={priceState} skusState={skusState} previousSku={previousSku} nextSku={nextSku} previousPrice={previousPrice} nextPrice={nextPrice} />

        : dataSelector === 'business' ?
          // || dataSelector === 'property'
          <BusinessRecords open={open} handleClose={handleClose} infoModel={infoModel} descriptionState={descriptionState} previousDescription={previousDescription} nextDescription={nextDescription} previousImage={previousImage} reviewState={reviewState} previousReview={previousReview} nextReview={nextReview} featureSliceState={featureSliceState} nextFeature={nextFeature} previousFeature={previousFeature} sourceUrlSliceState={sourceUrlSliceState} previousSourceUrl={previousSourceUrl} nextSourceUrl={nextSourceUrl} mapView={mapView} />

          : dataSelector === 'property' ? <PropertyRecords open={open} handleClose={handleClose} infoModel={infoModel} mapView={mapView} priceState={priceState} previousPrice={previousPrice} nextPrice={nextPrice} descriptionState={descriptionState} previousDescription={previousDescription} nextDescription={nextDescription} reviewState={reviewState} previousReview={previousReview} nextReview={nextReview} featureSliceState={featureSliceState} nextFeature={nextFeature} previousFeature={previousFeature} />


            : dataSelector === 'people' ? <PeopleRecords open={open} handleClose={handleClose} infoModel={infoModel} /> : ''}
    </Dialog>
  )
}
