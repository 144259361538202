/* React, ReactDOM, serviceWorker imports */
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import App from './App'
/* Theme material ui, css, imports */
import { MuiThemeProvider } from '@material-ui/core'
import { theme } from './Frontend/Login/theme'
import './index.css'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// import { ReactQueryDevtools } from 'react-query-devtools'
import { QueryCache, ReactQueryCacheProvider } from 'react-query'


// const environment = process.env.NODE_ENV
// stripe keys
// const stripeENV = {
//   development: 'pk_hVVkmHqawzjU4zVcBbsjsEKldDMml',
//   qa: 'pk_hVVkmHqawzjU4zVcBbsjsEKldDMml',
//   production: 'pk_kB28CGfcte39S7jmZrVeGCyGMIxt7'
//   // testing: 'pk_hVVkmHqawzjU4zVcBbsjsEKldDMml'
//   // production: 'pk_kB28CGfcte39S7jmZrVeGCyGMIxt7'
// }
// const stripeKey = stripeENV[environment]
// QA
// const stripeKey = "pk_hVVkmHqawzjU4zVcBbsjsEKldDMml"
// production
const stripeKey = "pk_kB28CGfcte39S7jmZrVeGCyGMIxt7"

// Must make the stripe key enviroment dependable so it's not exposed and it's dinamically seeded
const stripePromise = loadStripe(stripeKey)

const queryCache = new QueryCache({ defaultConfig: { queries: { staleTime: 1 * 60 * 10, retry: 1 } } })

ReactDOM.render(
  /* Theme Material */
  <ReactQueryCacheProvider queryCache={queryCache}>
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    <Elements stripe={stripePromise}>
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>
    </Elements>
  </ReactQueryCacheProvider>,
  document.getElementById('root')
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
