// react and components imports
import React, { useState, useEffect, useContext } from 'react'
// Font Awesome and material ui imports
import { Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import * as stylesBusiness from './TableBusiness.module.css'
// Mutation react query
import { useMutation } from 'react-query'
import { columnsBusinessView } from './ColumnsBusiness'
import ExpandedRecord from '../../ExpandedRecord'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress';
import { LocalContext } from '../../LocalContext'


export default function TableBusiness({ dataQuery, apiData, dataSelector }) {
  const [openModal, setOpenModal] = useState(false)
  const [infoModel, setInfoModel] = useState({})
  const { value, setValue } = useContext(LocalContext)
  const [isLoadingTable, setIsLoadingTable] = useState(false)
   // page State
   const pageValue =  value?.pageTableView
  // Google maps variables
  const zoom = 'zoom=13'
  const size = 'size=160x160'
  const maptype = 'maptype=roadmap'
  // mutation
  const [tableViewSum] = useMutation(({ page, queryData }) => apiData(page, queryData), { onSuccess: handleSuccessSum, onError: handleErrorSum })
  function handleSuccessSum(data) {
    const newPage = pageValue + 1
    setValue({...value, data, pageTableView: newPage})
    setIsLoadingTable(false)
   } function handleErrorSum() { }
     // mutation
  const [tableViewSubtraction] = useMutation(({ page, queryData }) => apiData(page, queryData), { onSuccess: handleSuccessSubstration, onError: handleErrorSubstration})
  function handleSuccessSubstration(data) {
    const newPage = pageValue - 1
    setValue({...value, data, pageTableView: newPage })
    setIsLoadingTable(false)
   } function handleErrorSubstration() { }

  // pagination next onClick
  const nextSearchOnClick = () => {
    setIsLoadingTable(true)
    const newPage = pageValue + 1
    const { queryData } = value
    tableViewSum({ page: newPage, queryData })
  }
  // pagination previous onClick
  const prevSearchOnClick = () => {
    setIsLoadingTable(true)
    const newPage = pageValue - 1
    const { queryData } = value
    tableViewSubtraction({ page: newPage, queryData })
  }
  useEffect(() => {
    if (openModal === true) { setTimeout(() => { setOpenModal(false) }, 100) }
  }, [openModal])

  if (isLoadingTable === true) {
    return <div className={stylesBusiness.noTableContainer} ><label className={stylesBusiness.noTableView} style={{ marginRight: '3px' }} >Loading...</label><CircularProgress color="secondary" /></div>
  }
  if (value?.data?.data === undefined && isLoadingTable === false) return <div className={stylesBusiness.noTableContainer} ><label className={stylesBusiness.noTableView} >No Table View Available</label></div>
  if (value?.data?.data === undefined && isLoadingTable === true) return <div className={stylesBusiness.noTableContainer}><label style={{ marginRight: '3px' }} className={stylesBusiness.noTableView} >Loading...</label><CircularProgress color="secondary" /></div>

  return (
    <Grid>
      <Grid className={stylesBusiness.containerTableBusiness}>
        {value?.data?.data?.records?.map((column, i) => (
          <table key={i}>
            <tbody>
              <tr>
                <td>
                  <img src={column.latitude && column.longitude ? `https://maps.googleapis.com/maps/api/staticmap?center=${column.latitude + ',' + column.longitude}&${zoom}&${size}&${maptype}&markers=${column.latitude + ',' + column.longitude}&key=AIzaSyD0AKt7KKjLie_FUX7rH1HAFXD9svOE6Go` : column.address && column.city ? `https://maps.googleapis.com/maps/api/staticmap?center=${column.address + ',' + column.city + ',' + column.province + ',' + column.country + ',' + column.postalCode}&${zoom}&${size}&${maptype}&markers=${column.address + ',' + column.city + ',' + column.province + ',' + column.country + ',' + column.postalCode}&key=AIzaSyD0AKt7KKjLie_FUX7rH1HAFXD9svOE6Go` : require('../../img/map-not-available.png')} alt='' className={stylesBusiness.imagesBusiness} />
                </td>
                <td>
                  <table className={stylesBusiness.contentTableBusiness} key={i} style={{ marginBottom: '25px' }}>
                    <tbody>
                      <tr>
                        <th
                          onClick={() => {
                            setInfoModel({ column })
                            setOpenModal(true)
                          }} style={{ cursor: 'pointer' }} className={stylesBusiness.nameItem}
                        >{column.name.length >= 70 ? column.name.substring(0, 73) + '...' : column.name.length <= 70 ? column.name : 'N/A'}
                        </th>
                        <th /><th /><th />
                        <th style={{ fontWeight: 300 }}><p>Updated: {column?.dateUpdated ? moment(column?.dateUpdated).format('MMM DD, YYYY') : 'N/A'}</p> </th>
                        <th style={{ color: '#065E88', cursor: 'pointer' }}>
                          <p onClick={() => {
                            setInfoModel({ column })
                            setOpenModal(true)
                          }}
                          >See full record
                          </p>
                        </th>
                      </tr>
                      <tr>
                        {columnsBusinessView.map((columnTable) => (
                          <th style={{ fontSize: '16px' }} className={stylesBusiness.headers} key={columnTable.id}>{columnTable.label}</th>
                        ))}
                      </tr>
                      <tr>
                        <td>{column?.address || 'N/A'}</td>
                        <td>{column?.emails ? column?.emails : 'N/A'}</td>
                        <td>{!column?.primaryCategories && !column?.categories ? 'N/A' : !column.primaryCategories ? 'N/A' : column?.primaryCategories[0] && !column?.primaryCategories[1] ? column?.primaryCategories[0] : column?.primaryCategories[0] && column?.primaryCategories[1] ? `${column?.primaryCategories[0]}, ${column?.primaryCategories[1]}` : 'N/A'} </td>
                        <td>{!column?.websites && !column.facebookPageURL && !column.twitter ? 'N/A' : !column?.websites ? 'N/A' : column?.websites ? column?.websites[0] : ''}</td>
                        <td>{!column?.domains ? 'N/A' : column?.domains[0] ? column?.domains[0] : 'N/A'}</td>
                        <td>{!column?.reviews ? 'N/A' : column?.reviews[0]?.text ? column?.reviews[0]?.text : 'N/A'}</td>
                      </tr>
                      <tr>
                        <td>{column?.city ? column?.city : 'N/A'} {column?.postalCode || ''}</td>
                        <td>{column?.phones ? column?.phones[0] : 'N/A'}</td>
                        <td>{!column.categories ? 'N/A' : column?.categories[0] && !column?.categories[1] ? column?.categories[0] : column?.categories[0] && column?.categories[1] ? `${column?.categories[0]}, ${column?.categories[1]}` : ''}</td>
                        <td>{column?.facebookPageURL || ''}</td>
                        <td>{!column?.domains ? '' : column?.domains[1] ? column?.domains[1] : 'N/A'}
                        </td>
                        <td>{!column?.reviews ? null : !column?.reviews[1]?.text ? 'N/A' : column?.reviews[1]?.text}</td>
                      </tr>
                      <tr>
                        <td>{column.country || 'no available'}</td>
                        <td>{!column?.phones ? '' : column?.phones[1] ? column?.phones[1] : ''}</td>
                        <td />
                        <td>{column.twitter || ''}
                        </td>
                        <td>{!column?.domains ? '' : column?.domains[2] ? column?.domains[2] : 'N/A'}
                        </td>
                        <td>{!column?.reviews ? null : !column?.reviews[2]?.text ? 'N/A' : column?.reviews[2]?.text}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        ))}
        <ExpandedRecord openModal={openModal} infoModel={infoModel} dataSelector={dataSelector} />
      </Grid>
      <Grid className={stylesBusiness.arrowsContainer} >
         {value?.data?.data ? <FontAwesomeIcon onClick={prevSearchOnClick} icon={faAngleLeft} className={pageValue === 1 ? stylesBusiness.disabledArrowLeft : stylesBusiness.enableArrowLeft} /> : ''}

        {value?.data?.data ? <FontAwesomeIcon onClick={nextSearchOnClick} icon={faAngleRight} className={value?.data?.data?.total_cost !== 10 ? stylesBusiness.disabledArrowRight : stylesBusiness.enableArrowRight} /> : ''} 
      </Grid>
    </Grid>
  )
}
