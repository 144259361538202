import React, { useState, useEffect, useContext } from 'react'
// Mutation react query
import { useMutation } from 'react-query'
// import div from 'react-responsive-scrollbox'
import { Grid } from '@material-ui/core'
import moment from 'moment'
import { columnsPropertyView } from './ColumnsProperty'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ExpandedRecord from '../../ExpandedRecord'
import * as stylesTableProperty from './TableProperty.module.css'
import CircularProgress from '@mui/material/CircularProgress';
import { LocalContext } from '../../LocalContext'


export default function TableProperty({ dataQuery, apiData, dataSelector }) {
    const { value, setValue } = useContext(LocalContext)
    const [openModal, setOpenModal] = useState(false)
    const [infoModel, setInfoModel] = useState({})
    const [isLoadingTable, setIsLoadingTable] = useState(false)
    // Google maps variables
    const zoom = 'zoom=13'
    const size = 'size=160x160'
    const maptype = 'maptype=roadmap'
    // page State
    const pageValue =  value?.pageTableView
    // tableProperty
    const [tablePropertySum] = useMutation(({ page, queryData }) => apiData(page, queryData), { onSuccess: handleSuccessSum, onError: handleErrorSum })
    function handleSuccessSum(data) {
      const newPage = pageValue + 1
      setValue({...value, data, pageTableView: newPage})
      setIsLoadingTable(false)
     } function handleErrorSum() { }
       // mutation
    const [tablePropertySubtraction] = useMutation(({ page, queryData }) => apiData(page, queryData), { onSuccess: handleSuccessSubstration, onError: handleErrorSubstration})
    function handleSuccessSubstration(data) {
      const newPage = pageValue - 1
      setValue({...value, data, pageTableView: newPage })
      setIsLoadingTable(false)
     } function handleErrorSubstration() { }

    const nextSearchOnClick = () => {
        setIsLoadingTable(true)
        const newPage = pageValue + 1
        const { queryData } = value
        tablePropertySum({ page: newPage, queryData })
    }
    // pagination previous onClick
    const prevSearchOnClick = () => {
        setIsLoadingTable(true)
        const newPage = pageValue - 1
        const { queryData } = value
        tablePropertySubtraction({ page: newPage, queryData })
    }

    // when the component mounts and the query changes, it mutates the data
    useEffect(() => {
        if (openModal === true) { setTimeout(() => { setOpenModal(false) }, 50) }
    }, [openModal])

        if (isLoadingTable === true) {
        return <div className={stylesTableProperty.noTablePropertyContainer} ><label className={stylesTableProperty.noTablePropertyView} style={{ marginRight: '3px' }} >Loading...</label><CircularProgress color="secondary" /></div>
      }
    if (value?.data?.data === undefined && isLoadingTable === false) return <div className={stylesTableProperty.noTablePropertyContainer}><label className={stylesTableProperty.noTablePropertyView}>No Table View Available</label></div>
    if (value?.data?.data === undefined && isLoadingTable === true) return <div className={stylesTableProperty.noTablePropertyContainer}><label style={{ marginRight: '3px' }} className={stylesTableProperty.noTablePropertyView}>Loading...</label><CircularProgress color="secondary" /></div>


    // if (isLoadingTable === true) {
    //     return <div className={stylesBusiness.noTableContainer} ><label className={stylesBusiness.noTableView} style={{ marginRight: '3px' }} >Loading...</label><CircularProgress color="secondary" /></div>
    //   }
    //   if (value?.data?.data === undefined && isLoadingTable === false) return <div className={stylesBusiness.noTableContainer} ><label className={stylesBusiness.noTableView} >No Table View Available</label></div>
    //   if (value?.data?.data === undefined && isLoadingTable === true) return <div className={stylesBusiness.noTableContainer}><label style={{ marginRight: '3px' }} className={stylesBusiness.noTableView} >Loading...</label><CircularProgress color="secondary" /></div>
    

    return (
        <div >
            <div style={{ height: '50vh', width: '100%', overflowX: 'auto' }}>
                {value?.data?.data?.records?.map((column, i) => (
                    <table key={i}>
                        <thead>
                            <tr>
                                <td>
                                    <img src={column.latitude && column.longitude ? `https://maps.googleapis.com/maps/api/staticmap?center=${column.latitude + ',' + column.longitude}&${zoom}&${size}&${maptype}&markers=${column.latitude + ',' + column.longitude}&key=AIzaSyD0AKt7KKjLie_FUX7rH1HAFXD9svOE6Go` : column.address && column.city ? `https://maps.googleapis.com/maps/api/staticmap?center=${column.address + ',' + column.city + ',' + column.province + ',' + column.country + ',' + column.postalCode}&${zoom}&${size}&${maptype}&markers=${column.address + ',' + column.city + ',' + column.province + ',' + column.country + ',' + column.postalCode}&key=AIzaSyD0AKt7KKjLie_FUX7rH1HAFXD9svOE6Go` : require('../../img/map-not-available.png')} alt='' className='images-business' />
                                </td>
                                <td>
                                    <table key={i} className={stylesTableProperty.contentTableProperty}>
                                        <tbody>
                                            <tr>
                                                <th onClick={() => {
                                                    setInfoModel({ column })
                                                    setOpenModal(true)
                                                }} style={{ cursor: 'pointer', color: '#3F3B88', fontSize: '20px', fontFamily: 'Roboto' }} >{column?.address}</th>
                                                <th /><th /><th />
                                                <th style={{ fontWeight: 300 }}><p style={{}}>Updated: {column?.dateUpdated ? moment(column?.dateUpdated).format('MMM DD, YYYY') : 'N/A'}</p> </th>
                                                <th style={{ color: '#065E88', cursor: 'pointer' }}>
                                                    <p onClick={() => {
                                                        setInfoModel({ column })
                                                        setOpenModal(true)
                                                    }}
                                                    >See full record
                                                    </p>
                                                </th>
                                            </tr>
                                            <tr>
                                                {columnsPropertyView.map((columnTable) => (
                                                    <th style={{ fontSize: '16px' }} className='headers' key={columnTable.id}>{columnTable.label}</th>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td>{column?.propertyType || 'N/A'}</td>
                                                <td>{column?.mostRecentStatus ? column?.mostRecentStatus : 'N/A'}</td>
                                                <td>{!column?.prices ? 'N/A' : column?.prices[0] ? `$${column?.prices[0].amountMax}` : 'N/A'} </td>
                                                <td>{!column?.numBedroom && !column.numBedroom ? 'N/A' : `${column?.numBedroom} beds | ${column?.numBathroom} baths`}</td>
                                                <td>{!column?.domains ? 'N/A' : column?.domains[0] ? column?.domains[0] : 'N/A'}</td>
                                                <td>{!column?.reviews ? 'N/A' : column?.reviews[0]?.text ? column?.reviews[0]?.text : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>{column?.mostRecentStatusDate ? moment(column?.mostRecentStatusDate).format('MMM DD, YYYY') : 'N/A'}</td>
                                                <td>{!column?.prices ? 'N/A' : column?.prices[0] ? `${moment(column?.prices[0]?.dateSeen[0]).format('MMM DD, YYYY')}` : 'N/A'} </td>
                                                <td>{!column?.floorSizeUnit && !column?.floorSizeValue ? 'N/A' : `Floor size: ${column?.floorSizeValue} ${column?.floorSizeUnit}`}</td>
                                                <td>{!column?.domains ? '' : column?.domains[1] ? column?.domains[1] : 'N/A'}
                                                </td>
                                                <td>{!column?.reviews ? null : !column?.reviews[1]?.text ? 'N/A' : column?.reviews[1]?.text}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>{!column?.numUnit ? 'N/A' : `${column?.numUnit} more available`}</td>
                                                <td />
                                                <td>{!column?.lotSizeUnit && !column?.lotSizeValue ? 'N/A' : `Lot size: ${column?.lotSizeValue} 
                                                ${column?.lotSizeUnit}`}</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </thead>
                    </table>
                ))}
            </div>
            <Grid className={stylesTableProperty.arrowsContainer}>
                {value?.data?.data ? <FontAwesomeIcon onClick={prevSearchOnClick} icon={faAngleLeft} className={pageValue === 1 ? stylesTableProperty.disabledArrowLeft : stylesTableProperty.enableArrowLeft} /> : ''}

                {value?.data?.data ? <FontAwesomeIcon onClick={nextSearchOnClick} icon={faAngleRight} className={value?.data?.data?.total_cost !== 10 ? stylesTableProperty.disabledArrowRight : stylesTableProperty.enableArrowRight} /> : ''}
            </Grid>
            <ExpandedRecord openModal={openModal} infoModel={infoModel} dataSelector={dataSelector} />
        </div >

    )
}
