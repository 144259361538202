// Dashboard Main Container
export const dashboardMainContainer = {
  marginLeft: '1.5rem',
  marginRight: '1.5rem',
  paddingTop: '30px',
}
// Available data styles
export const availableDataContainer = {
  '@media (max-width: 769px)': { marginLeft: '0%' }
}
// Available Data title
export const availableData = {
  marginBottom: '1.25rem',
  fontFamily: 'Roboto',
  fontSize: '24px',
  fontWeight: '500',
  display: 'block',
  '@media (max-width: 769px)': { marginBottom: '0.25rem', paddingRight: '0.2rem' }
}
// Available Data title
export const boxSpacing = {
  marginBottom: '1%',
  fontFamily: 'Roboto',
  fontSize: '28px',
  '@media (max-width: 769px)': { marginLeft: '0%' }
}
export const boxAvailable = {
  padding: '20px 20px',
  width: '23rem',
  background: '#FFFFFF',
  '@media (max-width: 769px)': { marginLeft: '2%' }
}
export const box1 = {
  padding: '0px 16px',
  paddingTop: '14px',
  paddingBottom: '16px',
  width: '16.5rem',
  height: '6rem',
  background: '#FFFFFF',
  cursor: 'pointer',
  borderRadius: '5px'
}
// Dashboard Component styles
export const box2 = {
  padding: '0px 16px',
  paddingTop: '14px',
  paddingBottom: '16px',
  width: '16.5rem',
  height: '6rem',
  borderRadius: '5px',
  background: '#FFFFFF',
  marginLeft: '1.25rem',
  cursor: 'pointer',
  '@media (max-width:1138px)': {
    marginRight: '0rem',
  },
  '@media (max-width: 856px)': {
    marginTop: '1rem',
    marginRight: '0rem',
    marginLeft: '0rem',
  },
}
export const box3 = {
  padding: '0px 16px',
  paddingTop: '14px',
  paddingBottom: '16px',
  borderRadius: '5px',
  width: '16.5rem',
  height: '6rem',
  background: '#FFFFFF',
  marginLeft: '1.25rem',
  marginRight: '1.25rem',
  cursor: 'pointer',
  '@media (max-width: 1158px)': {
    marginTop: '1rem'
  },
  '@media (max-width: 900px)': {
    marginLeft: '0rem',
  },
  '@media (max-width: 856px)': {
    marginRight: '0rem',
  }

}
export const box4 = {
  padding: '0px 16px',
  paddingTop: '14px',
  paddingBottom: '16px',
  borderRadius: '5px',
  width: '16.5rem',
  height: '6rem',
  background: '#FFFFFF',
  cursor: 'pointer',
  '@media (max-width: 1422px)': {
    marginTop: '1rem'
  },
  '@media (max-width: 1158px)': {
    marginRight: '1.25rem'
  },
  '@media (max-width: 900px)': {
    marginRight: '0rem'
  }
}
export const boxDisabled = {
opacity:"0.6",
pointerEvents:"none !important",
}
// styles the titles of the dashboard
export const title = {
  fontSize: 20,
  fontWeight: 'bold',
  fontFamily: 'Roboto',
  color: '#000000',
}
// style numbers of dashboard
export const number = {
  fontSize: 24,
  fontFamily: 'Roboto',
  color: '#4D4F5C',
  '@media (max-width: 280px)': {
    fontSize: 23
  }
}
// style search icon
export const searchIcon = {
  color: '#18D4D4',
  marginLeft: '8rem',
  height: '32px',
  marginTop: '2rem',
  '@media (max-width: 598px)': {
    marginLeft: '6.5rem'
  }
}
// Dashboard styles ENDS
/// ////// ACCOUNT Style
export const accountBox1 = {
  padding: '0px 16px',
  paddingTop: '14px',
  paddingBottom: '16px',
  borderRadius: '5px',
  width: '16.5rem',
  background: '#FFFFFF',
}
export const accountBox2 = {
  padding: '0px 16px',
  paddingTop: '14px',
  paddingBottom: '16px',
  width: '16.5rem',
  background: '#FFFFFF',
  borderRadius: '5px',
  marginLeft: '1.25rem',
  '@media (max-width: 856px)': {
    marginLeft: '0px',
    marginTop: '1rem'
  },
  '@media (max-width: 769px)': { marginRight: '0rem' },
}
// Account title styles
export const accountData = {
  marginBottom: '1.25rem',
  fontFamily: 'Roboto',
  fontSize: '24px',
  fontWeight: '500',
  marginTop: '1.75rem',
  '@media (max-width: 769px)': { marginBottom: '0.25rem', paddingRight: '0.2rem' }
}
export const titleAccount = {
  fontSize: 16,
  fontFamily: 'Roboto',
  color: '#000000',
  fontWeight: '500',
  '@media (max-width: 280px)': {
    fontSize: 15
  }
}
export const numberAccount = {
  fontSize: 24,
  fontFamily: 'Roboto',
  color: '#4D4F5C',
  '@media (max-width: 959px)': {
    width: '20rem'
  }
}
//   DOWNLOADS STYLES
export const noDownloadAvailableContainer = {
  backgroundColor: 'white', height: '25vh', display: 'flex', justifyContent: 'center', alignItems: 'center', borderTop: '1px solid #7777'
}
export const noDownloadTitle = {
  fontSize: '18px',
  fontFamily: 'Roboto',
  fontWeight: 500
}
export const titleDownload = {
  paddingTop: '1rem', paddingBottom: '1rem', paddingLeft: '1.25rem', fontSize: '18px', fontWeight: '500', fontFamily: 'Roboto'
}
export const activityData = {
  marginBottom: '1.25rem',
  fontFamily: 'Roboto',
  fontWeight: '500',
  fontSize: '24px',
  '@media (max-width: 1158px)': { textAlign: 'center' },
  '@media (max-width: 769px)': { marginBottom: '0.25rem', paddingRight: '0.2rem' }

}
export const buttonViewAllDownloads = {
  textTransform: 'none', fontFamily: 'Roboto', fontSize: '16x', color: '#3F3B88', marginBottom: '14px'
}
export const view = {
  cursor: 'pointer',
  color: '#3F3B88'
}
