// Business Data
// Business Data Container
export const containerBusinessData = {
  marginLeft: '1.5rem',
  marginRight: '1.5rem',
  marginTop: '1.5rem',
  paddingBottom: '25px',
  '@media (max-width: 638px)': { marginLeft: '2rem', marginRight: '1rem', },
  '@media (max-width: 598px)': { marginLeft: '1rem', marginRight: '1rem', },
  '@media (max-width: 320px)': {
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  }
}
// Search Title style
export const searchTitle = {
  fontFamily: 'Roboto',
  fontSize: '24px',
  marginBottom: '0.85rem',
  fontWeight: '500',
  color: '#000000',
  '@media (max-width: 600px)': { marginLeft: '0rem' }
}
// AppBarSearchApi style
export const appBarSearchApi = {
  backgroundColor: '#FFFFFF',
  color: '#43425D',
  borderBottom: '1px solid #E9E9F0',
  borderRadius: '5px 5px 0px 0px',
}
// numberApiSearch styles
export const numberApiSearch = {
  fontFamily: 'Roboto',
  fontSize: '26px',
  color: '#43425D'
}
// buttonDownload styles
export const buttonDownload = {
  marginRight: '1%',
  width: '11.6rem'
}
// records title styles
export const recordsTitle = {
  fontFamily: 'Roboto',
  fontSize: '24px',
  color: '#43425D',
  fontWeight: '500',
  marginTop: '1%',
  marginBottom: '1%'
}
