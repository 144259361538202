import React, { useState, useEffect } from 'react'
import styles from "../../modal.module.css";
import { autoComplete } from "../../../../../Api/index";
import { useMutation } from "react-query";
import SnackbarComponent from "../../../../Snackbar/index";
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import useFilterStore from '../../../../Store/filterStore';
import { useStyles } from './utils/materialUI'
import customDeleteIconSvg from './images/square-xmark.svg';

function toTitleCase(str) {
    return str.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}

function Address({ setLocalFilters, setLocationChanged }) {
    const { allFilters: { streetAddress: initialStreets } } = useFilterStore();

    const [loading, setLoading] = useState(false);
    const customDeleteIcon = <img src={customDeleteIconSvg} alt="delete" style={{ width: 11, height: 13 }} />;

    const classes = useStyles();
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [streetAddressSuggestions, setStreetAddressSuggestions] = useState([]);
    const [selectedStreetAddress, setSelectedStreetAddress] = useState([]);
    const [snackMessage, setSnackMessage] = useState({ type: "error" });
    const [inputValue, setInputValue] = useState("");


    const [searchFilterAutoCompleteStreetAddress] = useMutation(
        (query) => autoComplete(query),
        {
            onSuccess: (data) => {
                const records = data?.data?.records;
                const errors = data?.data?.errors;
                if (errors || data.status >= 400) {
                    setSnackMessage({ type: "error", myMessage: errors[0], update: Date.now(), });
                } else if (!errors && records) {
                    const streetValues = records
                        .filter(record => record.source && record.source.address) // Ensure the address property exists
                        .map(record => toTitleCase(record.source.address.toLowerCase())); // Convert each address to title case
                    const uniqueStreetValues = [...new Set(streetValues)]; // Remove duplicates
                    setStreetAddressSuggestions(uniqueStreetValues);

                }
            },
            onError: (error) => {
                setSnackMessage({ type: "error", myMessage: "An error occurred while fetching suggestions.", update: Date.now() });
            },
            onSettled: () => {
                setLoading(false);
            },

        }
    );

    const handleChange = (event, newValue) => {
        setLocationChanged(true)
        setSelectedStreetAddress(newValue); // newValue contains all selected options
        setLocalFilters(prevFilters => ({ ...prevFilters, streetAddress: newValue.map(streetAddress => streetAddress) }));
        setStreetAddressSuggestions([]); // Clear the suggestions
    };

    // Adjust useEffect to depend on inputValue for fetching suggestions
    useEffect(() => {
        if (inputValue.length >= 2) {
            if (typingTimeout) clearTimeout(typingTimeout);
            // Set a new timeout
            setTypingTimeout(
                setTimeout(() => {
                    setLoading(true);
                    const formattedQueryData = { query: `address:"${inputValue}"` };
                    searchFilterAutoCompleteStreetAddress(formattedQueryData);
                    // searchFilterAutoCompleteStreetAddress({ query: inputValue });
                }, 500) // 500 milliseconds delay
            );
        } else {

            if (typingTimeout) clearTimeout(typingTimeout);
            // Optionally, clear the options when the input value is less than 2 characters
            setStreetAddressSuggestions([]);
            setLoading(false);
        }
    }, [searchFilterAutoCompleteStreetAddress, inputValue]); /* eslint-disable-line react-hooks/exhaustive-deps */


    // Use useEffect to update component state if the store's cities change externally
    useEffect(() => {
        setSelectedStreetAddress(initialStreets);
    }, [initialStreets]);

    return (
        <div className={styles.inputColumn}>
            <span className={styles.labelLocation} htmlFor="city">
                Street Address
            </span>
            <Autocomplete
                style={{ padding: "0px", fontSize: "12px", color: "red" }}
                multiple
                closeIcon={false} popupIcon={false}
                value={selectedStreetAddress} onChange={handleChange}
                deleteicon={customDeleteIcon} options={streetAddressSuggestions} loading={loading}
                getOptionSelected={(option, value) => option === value}
                getOptionLabel={(option) => option}
                onInputChange={(event, newInputValue, reason) => {
                    if (reason === 'input') {
                        setInputValue(newInputValue);
                    }
                }}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <Chip
                            label={option}
                            {...getTagProps({ index })}
                            deleteIcon={customDeleteIcon} // Use your custom SVG as the delete icon
                        />
                    ))
                }
                classes={{ option: classes.option }}
                renderInput={(params) => (
                    <TextField
                        style={{ padding: "0px" }} placeholder='Text'
                        variant="outlined"
                        {...params}
                        onBlur={() => {
                            // Clear the suggestions when the autocomplete loses focus
                            setStreetAddressSuggestions([]);
                        }}
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classes.inputRoot, focused: classes.focused,
                                notchedOutline: classes.notchedOutline,
                            },
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress style={{ position: "absolute", right: "7px" }} color="secondary" size={14} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
                noOptionsText={<div className={classes.noOptions}>No matches</div>}
                loadingText={<div className={classes.loading}>Loading...</div>}
            />
            <SnackbarComponent snackData={snackMessage} />
        </div>
    );
}

export default Address