import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { sendEmailVerification } from '../../../Api'; // Adjust the import path as needed
import styles from './styles.module.css';
import DatafinitiLogo from '../../HomeDR12/images/datafiniti-logo-white.svg';
import jwtDecode from 'jwt-decode';
import { useLocation } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check'; // You can use any icon you prefer

function Step1({ isActive }) {
    const location = useLocation();
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false); // Add state for checkbox

    const [mutate] = useMutation(sendEmailVerification);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');

        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                if (decodedToken && decodedToken.sub) {
                    setEmail(decodedToken.sub);
                    setIsEmailSent(true);
                    setIsCheckboxChecked(true);
                }
            } catch (error) {
                console.error('Invalid token:', error);
            }
        }

    }, [location]);

    const handleEmailChange = (e) => {
        const emailInput = e.target.value;
        setEmail(emailInput);

        // Reset the isEmailSent state whenever the email input changes
        setIsEmailSent(false);

        // Basic email validation
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
        setIsEmailValid(emailPattern.test(emailInput));
    };

    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
    };

    const emailSending = async (e) => {
        e.preventDefault()
        setIsLoading(true);
        setErrorMessage(""); // Reset error message
        try {
            const { status, data } = await mutate(email);
            if (status < 400) {
                setIsLoading(false);
                setIsEmailSent(true); // Email sent successfully
            } else {
                setIsLoading(false);
                setErrorMessage(data.errors || "An error occurred while sending the email.");
            }
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message || "An error occurred while sending the email.");
        }
    };

    return (
        <section className={styles.background}>
            <div className={styles.mainContainer}>
                <img
                    src={DatafinitiLogo}
                    alt="DatafinitiLogo"
                    className={styles.logo}
                    style={{ opacity: isActive ? 1 : 0 }}
                />
                <div>
                    <h1 className={styles.step1Title}>Step 1: Email</h1>
                    <span className={styles.provideEmailSpan}>Provide email and validate.</span>
                    <form onSubmit={emailSending}>
                        <input
                            autoFocus
                            autoComplete='off'
                            type="text"
                            className={styles.inputEmailStep1}
                            placeholder='Email'
                            value={email}
                            onChange={handleEmailChange}
                            disabled={!isActive}
                        />
                        <div className={styles.checkboxContainer}>
                            <input
                                type="checkbox"
                                id="terms"
                                className={styles.checkbox}
                                disabled={!isActive}
                                onChange={handleCheckboxChange}
                                checked={isCheckboxChecked}
                            />
                            <label htmlFor="terms" className={styles.checkboxLabel}>
                                I agree to the <a href="https://datafiniti.co/terms/" target="_blank" rel="noopener noreferrer" className={`${styles.policy} ${!isActive ? styles.disabled : ''}`} onClick={(e) => !isActive && e.preventDefault()}>Terms of Use</a> and <a href="https://datafiniti.co/privacy-policy/" target="_blank" rel="noopener noreferrer" className={`${styles.policy} ${!isActive ? styles.disabled : ''}`} onClick={(e) => !isActive && e.preventDefault()}>Privacy Policy</a>
                            </label>
                        </div>
                        <button
                            type='submit'
                            className={`${styles.buttonStep1} ${isEmailSent ? styles.buttonSuccess : isEmailValid && isCheckboxChecked ? styles.buttonEnabled : styles.buttonDisabled}`}
                            disabled={!isEmailValid || isLoading || isEmailSent || !isActive || !isCheckboxChecked}
                        >
                            {isEmailSent ? <CheckIcon className={styles.iconInsideButton} /> : (isLoading ? "Sending..." : "Continue")}
                        </button>
                    </form>

                    <div className={styles.emailVerificationContainer}>
                        {(isEmailSent && isActive === true) && <span className={styles.emailVerificationMessage}>Check your email to verify your information</span>}
                        {errorMessage && <span className={styles.emailVerificationMessage}>{errorMessage}</span>}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Step1;
