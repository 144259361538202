import React, { useContext, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { LocalContext } from '../LocalContext'
// Mutation react query
import TableProduct from './TableProduct/TableProduct'
import TableBusiness from './TableBusiness/TableBusiness'
import TableProperty from './TableProperty/index'
import PeopleTable from './TablePeople/index'

export default function TableView({ apiData, dataSelector }) {
  const { value } = useContext(LocalContext)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Grid style={{ backgroundColor: 'white' }}>
      {dataSelector === 'product' ? <TableProduct dataQuery={value} apiData={apiData} dataSelector={dataSelector} /> : dataSelector === 'business' ? <TableBusiness dataQuery={value} apiData={apiData} dataSelector={dataSelector} /> : dataSelector === 'property' ? <TableProperty dataQuery={value} apiData={apiData} dataSelector={dataSelector} /> :
        dataSelector === 'people' ? <PeopleTable dataQuery={value} apiData={apiData} dataSelector={dataSelector} /> : ''}
    </Grid>
  )
}
