// react imports, components, local context imports
import React, { useContext, useEffect, useState } from 'react'
import ApiSearch from '../ApiSearch/index'
import { postPeoplePaginated, postPeople } from '../../Api/index'
import MatchingRecords from '../MatchingRecords'
import RecordsPreview from '../RecordsPreview'
import { LocalContext } from '../LocalContext'
// material  ui and stlyes imports
import * as styles from './styles'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import { Typography, Grid } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import FilterBuilder from '../FilterBuilder'
import DescriptionIcon from '@material-ui/icons/Description';

// Function to create the tab panel
function TabPanel(props) {
  const { children, value, index } = props

  return (
    <Grid>
      {value === index && (
        <Box>
          <Typography component='span'>{children}</Typography>
        </Box>
      )}
    </Grid>
  )
}
// Ant Tabs
const AntTabs = withStyles({ indicator: { backgroundColor: '#18D4D4' } })(Tabs)
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 131,
    fontSize: '15px',
    opacity: 0.5,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: '1%',
    fontFamily: ['Roboto'].join(','),
    '&:focus': {}
  },
  selected: { opacity: '0.5 !important' }
}))((props) => <Tab disableRipple {...props} />)

export default function PeopleData({ containerState, setContainerState }) {
  const dataSelector = 'people'
  // Value of the local context State
  const { setValue } = useContext(LocalContext)
  // styles material ui
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  // value tabs
  const [valueTabs, setValueTabs] = useState(0)
  // sets the value of the context to null when the component mounts
  useEffect(() => {
    localStorage.setItem("dfp/dataTypeNavigation", "people");
    document.title = "Datafiniti Portal | People"
    setValue(null)
    window.scrollTo(0, 0)
    return () => {
      setValue(null)
    }
  }, []) /* eslint-disable-line react-hooks/exhaustive-deps */
  // onClick Change tabs
  const clickChangeApi = () => { setValueTabs(1) }
  const clickChangeFilter = () => { setValueTabs(0) }
  return (
    <Grid className={classes.containerPeopleData}>
      <Typography className={classes.searchTitle}>
        Search
      </Typography>
      <AppBar position='static' className={classes.appBarSearchApi} elevation={0}>
        <AntTabs value={valueTabs} aria-label='simple tabs example' indicatorColor='primary'>
          <AntTab label='Filter Builder' onClick={clickChangeFilter} />
          <AntTab label='API' onClick={clickChangeApi} />
          <AntTab icon={<HelpOutlineIcon />} onClick={() => window.open('https://docs.datafiniti.co/docs/people-data-portal')} />
          <AntTab icon={<DescriptionIcon />} onClick={() => window.open('https://docs.datafiniti.co/docs/people-data-schema')} />
        </AntTabs>
      </AppBar>
      <TabPanel value={valueTabs} index={0}>
        <FilterBuilder dataSelector={dataSelector} apiData={postPeoplePaginated} containerState={containerState} setContainerState={setContainerState} />
      </TabPanel>
      <TabPanel value={valueTabs} index={1}>
        <ApiSearch apiData={postPeoplePaginated} dataSelector={dataSelector} />
      </TabPanel>
      <TabPanel />
      <MatchingRecords downloadData={postPeople} dataSelector={dataSelector} />
      <RecordsPreview apiData={postPeoplePaginated} dataSelector={dataSelector} />
    </Grid>
  )
}
