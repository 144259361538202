import React, { useState } from 'react';
import styles from "./styles.module.css";

function StatusHistory({ record }) {
    const [isExpanded, setIsExpanded] = useState(false);

    // Helper function to parse the date for sorting
    const parseDate = (status) => {
        if (status.date) return new Date(status.date);
        return new Date(0); // Use an early date for missing values
    };

    // Sort statuses by date or lastDateSeen in descending order
    const sortedStatuses = record?.statuses?.sort((a, b) => parseDate(b) - parseDate(a)) || [];

    // Filter out duplicates by creating a Set of dates
    const uniqueStatuses = sortedStatuses.filter(
        (status, index, self) =>
            index === self.findIndex((s) => s.date === status.date)
    );

    return (
        <section className={styles.container}>
            <h2 className={styles.statusHistory}>Status history</h2>
            <ul className={styles.listStatus}>
                {uniqueStatuses.slice(0, isExpanded ? uniqueStatuses.length : 5).map((status, index) => (
                    <li key={index} className={styles.statusHistoryContainer}>
                        <p className={styles.statusType}>
                            {status?.type}
                        </p>
                        <time className={styles.statusDate} dateTime={status?.date}>
                            {(status?.date) ? (
                                new Intl.DateTimeFormat("en-US", {
                                    month: "long",
                                    day: "numeric",
                                    year: "numeric",
                                }).format(new Date(status.date))
                            ) : (
                                "N/A"
                            )}
                        </time>
                    </li>
                ))}
            </ul>

            {uniqueStatuses.length > 5 && (
                <button onClick={() => setIsExpanded(!isExpanded)} className={styles.expandButton} type="button">
                    {isExpanded ? 'Show Less' : `Expand All (${uniqueStatuses.length})`}
                </button>
            )}
        </section>
    );
}

export default StatusHistory;
