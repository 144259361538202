import React from 'react';
import styles from "./styles.module.css";

function Parking({ record }) {

    const parkingItems = Array.isArray(record?.parking) ? record.parking.join(', ') : 'N/A';

    const parkingTypes = Array.isArray(record?.parkingTypes) ? record.parkingTypes.join(', ') : 'N/A';

    return (
        <section>
            <span className={styles.parkingTitle}>Parking</span>
            <div className={styles.parkingContainer}>
                <span className={styles.parkingTitleValues}>Number of spaces</span>
                <div className={styles.parkingValuesContainer}>
                    {`${record?.numParkingSpaces}` || "N/A"}
                </div>
            </div>
            <div className={styles.parkingContainer}>
                <span className={styles.parkingTitleValues}>Types</span>
                <div className={styles.parkingValuesContainer}>
                    {parkingTypes}
                </div>
            </div>
            <div className={styles.parkingContainer}>
                <span className={styles.parkingTitleValues}>Additional information</span>
                <div className={styles.parkingValuesContainer}>
                    {parkingItems}
                </div>
            </div>
        </section>
    );
}

export default Parking;
