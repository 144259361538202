import React, { useEffect } from "react";
import styles from "../modal.module.css";

import { mostRecentStatusOptions } from "../../MostRecentStatus";
// Assume that `mostRecentStatusOptions` is an array of status options like ['For Sale', 'Rental', ...]

function MostRecentStatus({ localMostRecentStatus, setLocalMostRecentStatus }) {
  const handleStatusClick = (status) => {
    let newStatuses;
    if (localMostRecentStatus.includes(status)) {
      // Remove the status if it's already selected
      newStatuses = localMostRecentStatus.filter((s) => s !== status);
    } else {
      // Add the status if it's not already selected
      newStatuses = [...localMostRecentStatus, status];
    }

    // If no statuses are selected, default back to ["Any"]
    if (newStatuses.length === 0) {
      newStatuses = ["Any"];
    }

    setLocalMostRecentStatus(newStatuses);
  };

  useEffect(() => {
    // Remove "Any" from the array if it exists
    if (localMostRecentStatus.includes("Any")) {
      const filteredTypes = localMostRecentStatus.filter(
        (type) => type !== "Any"
      );
      setLocalMostRecentStatus(filteredTypes);
    }
  }, [localMostRecentStatus, setLocalMostRecentStatus]);

  return (
    <div>
      <section className={styles.mostRecentStatusSection}>
        <h1 className={styles.sectionHeading}>Most Recent Status</h1>
        <h2 className={styles.mostRecentStatusLabelTitle}>
          Select multiple options if needed.
        </h2>
        <div className={styles.mostRecentStatusButtons}>
          {mostRecentStatusOptions
            .filter((option) => option !== "Any") // This will exclude the "Any" option
            .map((status) => (
              <button
                key={status}
                id={status}
                name={status}
                className={`${styles.mostRecentStatusButton} ${
                  localMostRecentStatus.includes(status) ? styles.active : ""
                }`}
                onClick={() => handleStatusClick(status)}
              >
                {status}
              </button>
            ))}
        </div>
      </section>
      {/* <hr className={styles.hrStyle} /> */}
    </div>
  );
}

export default MostRecentStatus;
