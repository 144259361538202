// react and components imports
import React, { useState, useEffect, useContext } from 'react'
import { columnsTableView } from './ColumsTableView'
// Font Awesome and material ui imports
import { Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import * as stylesTableProduct from './TableProduct.module.css'
// Mutation react query
import { useMutation } from 'react-query'
import ExpandedRecord from '../../ExpandedRecord'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress';
import { LocalContext } from '../../LocalContext'

export default function TableProduct({ dataQuery, apiData, dataSelector }) {
  // States modal
  const [openModal, setOpenModal] = useState(false)
  const [infoModel, setInfoModel] = useState({})
  const { value, setValue } = useContext(LocalContext)
  const [isLoadingTable, setIsLoadingTable] = useState(false)
  // Page State
  const pageValue =  value?.pageTableView
  // mutation
  const [tableViewSum] = useMutation(({ page, queryData }) => apiData(page, queryData), { onSuccess: handleSuccessSum, onError: handleErrorSum })
  function handleSuccessSum(data) {
    const newPage = pageValue + 1
    setValue({...value, data, pageTableView: newPage})
    setIsLoadingTable(false)
   } function handleErrorSum() { }
     // mutation
  const [tableViewSubtraction] = useMutation(({ page, queryData }) => apiData(page, queryData), { onSuccess: handleSuccessSubstration, onError: handleErrorSubstration})
  function handleSuccessSubstration(data) {
    const newPage = pageValue - 1
    setValue({...value, data, pageTableView: newPage })
    setIsLoadingTable(false)
   } function handleErrorSubstration() { }

  // pagination next onClick
  const nextSearchOnClick = () => {
    setIsLoadingTable(true)
    const newPage = pageValue + 1
    const { queryData } = dataQuery
    tableViewSum({ page: newPage, queryData })
    window.scrollTo(0, 555)
  }
  // pagination previous onClick
  const prevSearchOnClick = () => {
    setIsLoadingTable(true)
    const newPage = pageValue - 1
    const { queryData } = dataQuery
    tableViewSubtraction({ page: newPage, queryData })
    window.scrollTo(0, 555)
  }
  // when the component mounts and the query changes, it mutates the data
  useEffect(() => {
    if (openModal === true) { setTimeout(() => { setOpenModal(false) }, 50) }
  }, [openModal])


  if (isLoadingTable === true) {
    return <div className={stylesTableProduct.noTableProductContainer}><label className={stylesTableProduct.noTableProductView} style={{ marginRight: '3px' }} >Loading...</label><CircularProgress color="secondary" /></div>
  }

  if (value?.data?.data === undefined && isLoadingTable === false) return <div className={stylesTableProduct.noTableProductContainer}><label className={stylesTableProduct.noTableProductView}>No Table Product Available</label></div>
  if (value?.data?.data === undefined && isLoadingTable === true) return <div className={stylesTableProduct.noTableProductContainer} ><label className={stylesTableProduct.noTableProductView} style={{ marginRight: '3px' }}>Loading...</label><CircularProgress color="secondary" /></div>

  return (
    <Grid className={stylesTableProduct.containerTableView}>
      <div style={{ height: '50vh', width: '100%', overflowX: 'auto' }}>
        <table>
          {value?.data?.data?.records?.map((column, i) => (
            <tbody key={i}>
              <tr>
                <td style={{ maxWidth: '160px' }}><img src={column.primaryImageURLs ? column.primaryImageURLs[0] : column.imageURLs ? column.imageURLs[0] : require('../../img/noValidImageFound.jpeg')} className={stylesTableProduct.images} alt='' />
                </td>
                <td>
                  <table key={i} className={stylesTableProduct.contentProductTable}>
                    <tbody>
                      <tr>
                        <th
                          onClick={() => {
                            setInfoModel({ column })
                            setOpenModal(true)
                          }} style={{ cursor: 'pointer' }} className={stylesTableProduct.nameItem}
                        >{column.name.length >= 70 ? column.name.substring(0, 73) + '...' : column.name.length <= 70 ? column.name : 'N/A'}
                        </th><th /><th /><th />
                        <th style={{ fontWeight: 300 }}><p>Updated: {column?.dateUpdated ? moment(column?.dateUpdated).format('MMM DD, YYYY') : 'N/A'}</p></th>
                        <th style={{ color: '#065E88', cursor: 'pointer' }}>
                          <p onClick={() => {
                            setInfoModel({ column })
                            setOpenModal(true)
                          }}
                          >See full record
                          </p>
                        </th>
                      </tr>
                      <tr>
                        {columnsTableView.map((columnTable) => (
                          <th key={columnTable.id}>{columnTable.label}</th>
                        ))}
                      </tr>
                      <tr>
                        <td>Brand: {column.brand || 'N/A'}</td>
                        <td>Min: {!column?.prices ? 'N/A' : '$' + column?.prices[0]?.amountMin}</td>
                        <td>{column.primaryCategories || 'N/A'}</td>
                        <td>GTINs: {column?.gtins || 'N/A'}</td>
                        <td>{!column.domains ? 'N/A' : column.domains[0]}</td>
                        <td>{!column?.reviews ? 'N/A' : column?.reviews[0]?.username && column?.reviews[0]?.text}</td>
                      </tr>
                      <tr>
                        <td>Model: {column.manufacturerNumber || 'no available'}</td>
                        <td>Max: {!column?.prices ? 'N/A' : '$' + column?.prices[0]?.amountMax}</td>
                        <td>{column.categories || 'N/A'}</td>
                        <td>SKUs: {!column?.skus ? 'N/A' : column?.skus[0]?.value}</td>
                        <td>{!column?.domains ? null : !column?.domains[1] ? 'N/A' : column?.domains[1]}</td>
                        <td>{!column?.reviews ? null : !column?.reviews[1]?.text ? 'N/A' : column?.reviews[1]?.text}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
      <Grid className={stylesTableProduct.arrowsContainer}>
        {value?.data?.data ? <FontAwesomeIcon onClick={prevSearchOnClick} icon={faAngleLeft} className={pageValue === 1  ? stylesTableProduct.disabledArrowLeft : stylesTableProduct.enableArrowLeft} /> : ''}

        {value?.data?.data ? <FontAwesomeIcon onClick={nextSearchOnClick} icon={faAngleRight} className={value?.GridViewDataPagination?.data?.total_cost !== 12 ? stylesTableProduct.disabledArrowRight : stylesTableProduct.enableArrowRight} /> : ''}
        {/* value?.GridViewDataPagination */}
      </Grid>
      <ExpandedRecord openModal={openModal} infoModel={infoModel} dataSelector={dataSelector} />
    </Grid>
  )
}
