import { useMutation } from 'react-query';
import { postProperty } from "../../Api/index";
import { useQuery } from 'react-query';
import useStore from '../Store/Store';
import { getDownloadsById } from '../../Api/index.js'
import { useState, useEffect } from 'react';


export const useCreateDownloadMutationProperty = (setSnackMessage, query) => {
    const setIsQueryLoading = useStore((state) => state.setIsQueryLoading);
    const setDownloadQueryStore = useStore((state) => state.setDownloadQueryStore);

    return useMutation(
        ({ query }) => postProperty(query),
        {
            onMutate: async () => {
                setIsQueryLoading(true);
            },
            onSuccess: (data) => {
                // Handle a successful mutation
                if (data?.data?.errors || data.status >= 400) {
                    setSnackMessage({
                        type: "error",
                        myMessage: data?.data?.errors[0] ?? "An error occurred",
                        update: Date.now(),
                    });
                } else {
                    setDownloadQueryStore({ query, id: data.data.id });
                    setSnackMessage({ type: "success", myMessage: "Download queued", update: Date.now(), });
                }
            },
            onError: (error) => {
                // Handle a mutation error
                setSnackMessage({
                    type: "error",
                    myMessage: "An error occurred",
                    update: Date.now(),
                });
            },
            onSettled: () => {
                // Cleanup or final actions after mutation success or failure
                setIsQueryLoading(false);
            },
        }
    );
};

const fetchDownloadById = async (downloadId) => {
    const data = await getDownloadsById(downloadId);
    return data;
};

export const useDownloadDataById = (downloadId) => {
    const [isPolling, setIsPolling] = useState(true);

    const query = useQuery(
        ['downloadData', downloadId],
        () => fetchDownloadById(downloadId),
        {
            enabled: !!downloadId,
            refetchInterval: isPolling ? 3000 : false,
        }
    );

    useEffect(() => {
        // This effect adjusts the polling based on the download status.
        if (query.data?.data?.status === 'completed') {
            setIsPolling(false);
        }
    }, [query.data]);

    return query;
};