import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CheckIcon from '@material-ui/icons/Check';
import styles from './styles.module.css';
import { useLocation } from 'react-router-dom';
import DatafinitiLogo from '../../HomeDR12/images/datafiniti-logo-white.svg';

function Step2({ onNext, isActive }) {
    const { register, handleSubmit, formState: { errors, isValid }, setValue } = useForm({
        mode: 'onChange'
    });
    const location = useLocation();
    const [isDataSaved, setIsDataSaved] = useState(false);
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    useEffect(() => {
        const savedData = localStorage.getItem('dfp/step2_data');
        if (savedData && token) {
            const parsedData = JSON.parse(savedData);
            setValue('firstName', parsedData.firstName);
            setValue('lastName', parsedData.lastName);
            setValue('organization', parsedData.organization);
            setValue('password', parsedData.password);
            setIsDataSaved(true);
        }
    }, [setValue, token]);

    const onSubmit = (data) => {
        localStorage.setItem('dfp/step2_data', JSON.stringify(data));
        setIsDataSaved(true);
        onNext();
    };

    return (
        <section className={styles.section}>
            <img
                src={DatafinitiLogo}
                alt="DatafinitiLogo"
                className={styles.logo}
                style={{ opacity: isActive ? 1 : 0 }}
            />
            <div>
                <h1 className={styles.heading}>Step 2: Account Information</h1>
                <span className={styles.subheading}>Provide account information and password.</span>
                <div>
                    <form autoComplete='off' className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                        <input
                            autoFocus
                            type="text"
                            placeholder="First name"
                            className={styles.inputStep2}
                            disabled={!isActive || isDataSaved}
                            autoComplete="given-name"
                            {...register('firstName', { required: 'First name is required' })}
                        />
                        {errors.firstName && <p className={styles.error}>{errors.firstName.message}</p>}

                        <input
                            type="text"
                            placeholder="Last name"
                            className={styles.inputStep2}
                            disabled={!isActive || isDataSaved}
                            autoComplete="family-name"
                            {...register('lastName', { required: 'Last name is required' })}
                        />
                        {errors.lastName && <p className={styles.error}>{errors.lastName.message}</p>}

                        <input
                            type="text"
                            id="organization-field"
                            name="organization"
                            placeholder="Organization"
                            className={styles.inputStep2}
                            disabled={!isActive || isDataSaved}
                            autoComplete="organization-title"
                            {...register('organization', { required: 'Organization is required' })}
                        />
                        {errors.organization && <p className={styles.error}>{errors.organization.message}</p>}

                        <input
                            type="password"
                            placeholder="Password"
                            className={styles.inputStep2}
                            disabled={!isActive || isDataSaved}
                            autoComplete="password"
                            {...register('password', {
                                required: 'Password is required',
                                minLength: {
                                    value: 8,
                                    message: 'Password must be at least 8 characters long'
                                }
                            })}
                        />
                        {errors.password && <p className={styles.error}>{errors.password.message}</p>}

                        <button
                            className={`${styles.buttonStep2} ${isDataSaved ? styles.buttonSuccess : isValid && isActive ? styles.buttonEnabled : styles.buttonDisabled}`}
                            disabled={!isValid || !isActive || isDataSaved}
                        >
                            {isDataSaved ? <CheckIcon className={styles.iconInsideButton} /> : 'Continue'}
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default Step2;
