import React, { useState, useEffect } from 'react';
import * as styles from './styles.module.css';
import { useMutation, useQuery } from 'react-query';
import CircularProgress from '@material-ui/core/CircularProgress';
import SnackbarComponent from '../../../../../Snackbar/index';
import ModalSettings from '../../modal/ModalSettings';

function BoxComponent({
    getAccount,
    updateAccountPlan,
    title,
    plans,
    creditsKey,
    planTypeKey,
}) {
    const { data: dataAccount, isLoading } = useQuery('settings', getAccount, {
        onError: (error) => {
            setSnackMessage({ type: 'error', myMessage: error.message, update: Date.now() });
        }
    });
    const [snackMessage, setSnackMessage] = useState({ type: 'error' });
    const [onLoadingPlansChange, SetOnLoadingPlansChange] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [currentPlanId, setCurrentPlanId] = useState(null);
    const [availableCredits, setAvailableCredits] = useState(0);
    const [isYearly, setIsYearly] = useState(false); // New state for pricing model

    const creditsUsed = dataAccount?.data?.[creditsKey];

    const [profileUpdatePlan] = useMutation(
        (planState) => updateAccountPlan(planState),
        { onSuccess: handleSuccessUpdatePlan, onError: handleErrorUpdatePlan }
    );

    function handleSuccessUpdatePlan(data) {
        const { data: dataPlan, status } = data;
        if (status >= 400) {
            setSnackMessage({ type: 'error', myMessage: dataPlan.errors, update: Date.now() });
            SetOnLoadingPlansChange(false);
        } else {
            handleClickOpen(true);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    }

    function handleErrorUpdatePlan(error) {
        setSnackMessage({ type: 'error', myMessage: error.message, update: Date.now() });
        SetOnLoadingPlansChange(false);
    }

    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false); };

    useEffect(() => {
        if (!isLoading && dataAccount?.data?.plans) {
            const dataPlan = dataAccount.data.plans.find(plan => plan.id.includes(planTypeKey));

            if (dataPlan) {
                const planLowerCase = dataPlan.id.toLowerCase();
                setSelectedPlan(planLowerCase);
                setCurrentPlanId(planLowerCase);

                if (planLowerCase.includes('annual') || planLowerCase.includes("60k")) {
                    setIsYearly(true);
                }

                if (dataPlan.credits_allotted) {
                    setAvailableCredits(dataPlan.credits_allotted - creditsUsed);
                }
            }
        }
    }, [dataAccount, isLoading, creditsUsed, planTypeKey]);

    const getBoxStyle = (planId) => {
        return planId === selectedPlan ? styles.SelectedBoxProperty : styles.BoxProperty;
    };

    const handlePlanClick = (planId) => {
        setSelectedPlan(planId === selectedPlan ? null : planId);
    };

    const onClickUpdatePlans = () => {
        SetOnLoadingPlansChange(true);
        profileUpdatePlan({ plan_ids: [selectedPlan] });
    };

    const isPlanUnchanged = selectedPlan === currentPlanId;

    // Filter plans based on the pricing model (monthly or yearly)
    const filteredPlans = isYearly
        ? plans.filter(plan => plan.yearlyPrice !== 'Free').map(plan => ({ ...plan, id: plan.yearlyId, price: plan.yearlyPrice }))
        : plans;

    return (
        <section className={styles.PropertyDataSettingsContainer}>
            <div className={styles.FirstContainerSettings}>
                <h1 className={styles.TitlePropertyDataSettings}>{title}</h1>
                <div className={styles.PricingToggle}>
                    <span
                        className={!isYearly ? styles.Active : styles.Inactive}
                        onClick={() => setIsYearly(false)}
                    >
                        Monthly
                    </span>
                    <span
                        className={isYearly ? styles.Active : styles.Inactive}
                        onClick={() => setIsYearly(true)}
                    >
                        Annual
                    </span>
                </div>
            </div>
            <div className={styles.SecondContainerSettings}>
                <div>
                    <span className={styles.CreditsAvailableNumber}>{availableCredits}</span>
                    <span className={styles.CreditsAvailableSpan}>credits available</span>
                </div>
            </div>
            {filteredPlans.map(plan => (
                <div key={plan.id} className={getBoxStyle(plan.id)} onClick={() => handlePlanClick(plan.id)}>
                    <div className={styles.BoxPropertyDirection}>
                        <span className={plan.id === selectedPlan ? styles.SelectedSpanTitleBoxProperty : styles.SpanTitleBoxProperty}>
                            {plan.name}
                        </span>
                        <span className={plan.id === selectedPlan ? styles.SelectSpanBoxProperty : styles.SpanBoxProperty}>
                            {plan.records}
                        </span>
                    </div>
                    <div className={styles.BoxPropertySpacing}>
                        <div>
                            <span style={{ display: "block" }} className={plan.id === selectedPlan ? styles.SelectSpanBoxProperty : styles.SpanBoxProperty}>
                                {plan.price}
                            </span>
                            <span className={plan.id === selectedPlan ? styles.SelectSpanBoxProperty : styles.SpanBoxProperty}>
                                per {isYearly ? 'year' : 'month'}
                            </span>
                        </div>
                        <div>
                            <span className={plan.id === selectedPlan ? styles.SelectSpanBoxProperty : styles.SpanBoxProperty}>
                                {selectedPlan === plan.id ? 'Selected' : 'Select'}
                            </span>
                        </div>
                    </div>
                </div>
            ))}
            {onLoadingPlansChange ?
                <button className={styles.spinnerDisabled}>
                    <CircularProgress style={{ width: '25px', height: '30px', color: 'white' }} />
                </button>
                :
                <button disabled={onLoadingPlansChange || isPlanUnchanged || selectedPlan === null} onClick={onClickUpdatePlans} className={styles.buttonSettingsUpdate}>
                    Update
                </button>}
            <ModalSettings open={open} onClose={handleClose} />
            <SnackbarComponent snackData={snackMessage} />
        </section>
    );
}

export default BoxComponent;
