import React, { useState, useEffect } from "react";
import styles from "./address.module.css";
import useStore from "../../Store/Store.js";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation } from "react-query";
import { autoComplete } from "../../../Api/index";
import { useStyles, listSubheaderStyle, listStyle } from "./materialUI.js"
import customDeleteIconSvg from '../Filters/modal/LocationSection/images/square-xmark.svg';
import SnackbarComponent from "../../Snackbar/index.jsx";
import useFilterStore from '../../Store/filterStore.js';
import { processRecords, handleSelection } from './utils.js'
import { Paper } from '@material-ui/core';

function CustomPaper(props) {
  const classes = useStyles();
  return <Paper {...props} className={classes.paper} />;
}

function Address() {
  const classes = useStyles();
  const { setAllFilters, resetAddressFilters } = useFilterStore();
  const { address, setAddress, isQueryLoading } = useStore();
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [inputValue, setInputValue] = useState("")
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState({ type: "error" });
  const customDeleteIcon = <img src={customDeleteIconSvg} alt="delete" style={{ width: 11, height: 13 }} />;

  const [searchFilterAutoCompleteAddress, { isLoading }] = useMutation(
    (query) => autoComplete(query),
    {
      onSuccess: (data) => {
        const records = data?.data?.records;
        const errors = data?.data?.errors;
        if (errors || data.status >= 400) {
          setSnackMessage({ type: "error", myMessage: errors[0], update: Date.now(), });
        } else if (!errors && records) {
          const updatedHighLightState = processRecords(records);
          // Prepend an identifier to each item
          const addressOptions = updatedHighLightState.address.map(item => `Address: ${item}`);
          const cityOptions = updatedHighLightState.city.map(item => `City: ${item}`);
          const postalCodeOptions = updatedHighLightState.postalCode.map(item => `Postal Code: ${item}`);
          const provinceOptions = updatedHighLightState.province.map(item => `Province: ${item}`);

          // Combine and deduplicate the options
          const combinedOptions = [...addressOptions, ...postalCodeOptions, ...cityOptions, ...provinceOptions];
          const uniqueOptions = Array.from(new Set(combinedOptions));
          // Set the combined and unique options
          setOptions(uniqueOptions);
        }
      },
      onError: (error) => {
        setSnackMessage({ type: "error", myMessage: "An error occurred while fetching suggestions.", update: Date.now() });
      },
    }
  );

  useEffect(() => {
    if (inputValue.length >= 2 && open) {
      // Clear any existing timeout to reset the debounce timer
      if (typingTimeout) clearTimeout(typingTimeout);

      // Set a new timeout
      setTypingTimeout(
        setTimeout(() => {
          // Perform the search operation after the delay
          searchFilterAutoCompleteAddress({ query: inputValue });
        }, 500) // 500 milliseconds delay
      );
    } else {
      // Immediately clear any pending timeouts to prevent triggering the search
      if (typingTimeout) clearTimeout(typingTimeout);
      // Optionally, clear the options when the input value is less than 2 characters
      setOptions([]);
    }
  }, [inputValue, open, searchFilterAutoCompleteAddress]);/* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
    return () => {
      setInputValue(address)
    }
  }, [open, address]);

  useEffect(() => {

    setInputValue(address ?? '');
  }, [address]);


  return (
    <div style={{ marginRight: "20px" }}>
      <span className={styles.label} htmlFor="city">
        Address
      </span>
      <Autocomplete
        id="asynchronous-demo"
        style={{ padding: "0px", fontSize: "12px" }}
        closeIcon={false} popupIcon={false}
        deleteicon={customDeleteIcon}
        open={open}
        disabled={isQueryLoading}
        groupBy={(option) => option.split(':')[0]}
        renderGroup={(params) => (
          <div key={params.key}>
            <span style={listSubheaderStyle}>
              {params.group}
            </span>
            <span style={listStyle}>
              {params.children}
            </span>
          </div>
        )}
        renderOption={(option) => (
          <div>
            {option.split(/(<strong>|<\/strong>)/g).map((part, index, array) => {
              if (part === '<strong>' || part === '</strong>') {
                return null;
              } else if (index > 0 && array[index - 1] === '<strong>') {
                const textParts = part.split(':');
                // Check if the part after the colon exists before trimming
                const textToDisplay = textParts.length > 1 ? textParts[1] : textParts[0];
                return <strong key={index} style={{ fontWeight: 'bold' }}>{textToDisplay}</strong>;
              } else {
                const textParts = part.split(':');
                // Check if the part after the colon exists before trimming
                const textToDisplay = textParts.length > 1 ? textParts[1] : textParts[0];
                return <span key={index}>{textToDisplay}</span>;
              }
            })}
          </div>
        )}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        getOptionLabel={(option) => {
          const parts = option.split(':');
          return parts.length > 1 ? parts[1].trim() : parts[0].trim();
        }}
        options={options}
        loading={isLoading}
        inputValue={inputValue}
        PaperComponent={CustomPaper}

        onInputChange={(_, newInputValue) => {
          if (newInputValue !== inputValue) {

            setInputValue(newInputValue.replace(/<\/?strong>/g, ''));
          }
        }}
        onChange={(_, newValue) => {
          if (newValue !== null) {
            resetAddressFilters()
            setAddress(newValue.replace(/^[^:]*:\s*|<\/?strong>/g, ''));
            handleSelection(newValue, setAllFilters);
          }
        }}
        filterOptions={(options) => options}
        noOptionsText={<div className={classes.noOptions}>No matches</div>}
        loadingText={<div className={classes.loading}>Loading...</div>}
        classes={{ option: classes.option }}
        renderInput={(params) => (
          <TextField
            autoFocus={true}
            {...params}
            placeholder="City, Address, State/Province, Zip"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              classes: {
                root: classes.inputRoot, focused: classes.focused,
                notchedOutline: classes.notchedOutline,
              },
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress style={{ position: "absolute", right: "7px" }} color="secondary" size={14} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
          //  <SnackbarComponent snackData={snackMessage} />
        )}
      />
      <SnackbarComponent snackData={snackMessage} />
    </div>
  );
}
export default Address;
