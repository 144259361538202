export const trimCSV = csvResult => {
  let currentId = ''
  let ammountOfId = 0

  return csvResult.filter(element => {
    const elementId = element[0]
    if (elementId !== currentId) {
      currentId = elementId
      ammountOfId = 1

      return true
    } else {
      if (ammountOfId === 5) {
        return false
      } else {
        ammountOfId++
        return true
      }
    }
  })
}
