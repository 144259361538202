import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

function ModalSettings({ open, handleClose }) {

    return (
        <div>
            <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose}>
                <DialogTitle style={{ color: '#43a047' }}>The Change was successful</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The page will refresh for your security
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ModalSettings