// React imports and components and style
import React, { useState, useContext } from 'react'
import { LocalContext } from '../LocalContext'
import * as styles from './styles'
// Material UI imports and font awesome
import { Button, Grid, CircularProgress, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
// useMutation from react query
import { useMutation } from 'react-query'
import SnackbarComponent from '../Snackbar'

export default function ApiSearch({ apiData, dataSelector }) {
  // Snackbar message state
  const [snackMessage, setsnackMessage] = useState({ type: 'error' })
  const { value, setValue } = useContext(LocalContext)
  // query value
  const [valueQuery] = React.useState('')
  // query to downloads
  const [downloadValue, setDownloadValue] = useState('')
  // styles
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  // page state
  const [page] = useState(1)
  const [pageTableView] = useState(1)
  const [pageMapView] = useState(1)
  const [pageGridView] = useState(1)
  const [pageCSVView] = useState(1)
  // Query Data State to Json View and CSV View
  const [queryData, setQueryData] = useState({ query: valueQuery })
  const [queryDataCsv, setQueryDataCsv] = useState({ query: valueQuery, format: 'csv' })
  // onChange input query
  const handleInputChange = ({ target }) => {
    const queryClean = target.value.replace(/[/\\]/g, '');
    setQueryData({ query: queryClean })
    setQueryDataCsv({ ...queryDataCsv, query: queryClean })
    setDownloadValue(queryClean)
  }
  // Mutation apiSearch
  const [apiSearch, { isLoading }] = useMutation(
    ({ page, queryData }) => apiData(page, queryData),
    { onSuccess: handleSuccess, onError: handleError })
  // handles the success and the error of the query mutation
  function handleSuccess(data) {
    if (data?.data?.errors) {
      setsnackMessage({ type: 'error', myMessage: data?.data?.errors[0], update: Date.now() })
    } else if (!data?.data?.errors) {
      setValue({ ...value, data, queryData, queryDataCsv, downloadValue, page, pageTableView, pageMapView, pageGridView, pageCSVView })
    }
  }
  function handleError() { }
  // onClick api search
  const apiSearchOnClick = () => {
    apiSearch({ page, queryData })
  }
  return (
    <Box>
      <Grid style={{ borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }} container direction='row' justifyContent='space-between' alignItems='flex-start' className={classes.textFieldContainer}>
        <textarea style={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }} cols='40' rows='5' autoComplete='off' placeholder='Start Typing' className={classes.inputApiSearch} name='query' onChange={handleInputChange} />

        <div>
          <input style={{ position: 'absolute', height: '0px', width: '0px', border: 'none', padding: '0px' }} type='input' autoFocus hidefocus='true' />
          <Button disabled={isLoading} onClick={apiSearchOnClick} variant='contained' className={classes.buttonSearch} endIcon={isLoading ? '' : <FontAwesomeIcon style={{ marginLeft: '3%' }} icon={faSearch} />}>
            {isLoading === true ? <CircularProgress size='2rem' style={{ color: '#3F3B88' }} /> : 'Search'}
          </Button>
        </div>

      </Grid>
      <SnackbarComponent snackData={snackMessage} />
    </Box >
  )
}
