import React, { useEffect } from "react";
import styles from "../modal.module.css";
import { propertyTypeOptions, propertyTypeValueMap } from "../../PropertyType";

// function PropertyType({ onApplyFilters }) {
function PropertyType({ localPropertyType, setLocalPropertyType }) {

  const handlePropertyTypeClick = (typeLabel) => {
    const typeValue = propertyTypeValueMap[typeLabel] || typeLabel;

    let newTypes;
    if (localPropertyType.includes(typeValue)) {
      // Remove the type if it's already selected
      newTypes = localPropertyType.filter((t) => t !== typeValue);
    } else {
      // Add the type if it's not already selected
      newTypes = [...localPropertyType, typeValue];
    }

    // If no types are selected, default back to ["Any"]
    if (newTypes.length === 0) {
      newTypes = ["Any"];
    }

    setLocalPropertyType(newTypes);
  };

  useEffect(() => {
    // Remove "Any" from the array if it exists
    if (localPropertyType.includes("Any")) {
      const filteredTypes = localPropertyType.filter((type) => type !== "Any");
      setLocalPropertyType(filteredTypes);
    }
  }, [localPropertyType, setLocalPropertyType]);

  // Rest of your component...

  return (
    <div>
      <section className={styles.propertyTypeSection}>
        <h1 className={styles.sectionHeading}>Property Type</h1>
        <h2 className={styles.propertyTypeLabelTitle}>
          Select multiple options if needed.
        </h2>
        <div className={styles.propertyTypeButtons}>
          {propertyTypeOptions
            .filter((option) => option !== "Any")
            .map((typeLabel) => (
              <button
                key={typeLabel}
                id={typeLabel}
                name={typeLabel}
                className={`${styles.propertyTypeButton} ${
                  localPropertyType.includes(
                    propertyTypeValueMap[typeLabel] || typeLabel
                  )
                    ? styles.active
                    : ""
                }`}
                onClick={() => handlePropertyTypeClick(typeLabel)}
              >
                {typeLabel}
              </button>
            ))}
        </div>
      </section>
      <hr className={styles.hrStyle} />
    </div>
  );
}

export default PropertyType;
