import React, { useState } from 'react';
import styles from "./styles.module.css";

function PriceExpandedRecords({ record }) {
    // State to manage the expanded view
    const [isExpanded, setIsExpanded] = useState(false);

    // Define the getStatus function before it's used
    const getStatus = (price) => {
        if (price?.period === "Per Month") {
            return "For Rent";
        } else if (price?.isSale === true || price?.isSale === "true" ||
            price?.availability === true || price?.availability === "true" ||
            price?.isSold === false || price?.isSold === "false") {
            return "For Sale";
        } else if (price?.isSale === false || price?.isSale === "false" ||
            price?.availability === false || price?.availability === "false" ||
            price?.isSold === true || price?.isSold === "true") {
            return "Sold";
        } else {
            return "N/A";
        }
    };

    // Filter out prices without a date, sort by date, and keep only the latest unique date entry
    const sortedPrices = record && Array.isArray(record.prices)
        ? record.prices
            .filter(price => price.date) // Filter out entries without a date
            .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date descending
            .filter((price, index, self) => // Remove duplicate entries
                index === self.findIndex(p => (
                    p.date === price.date
                ))
            )
        : [];

    const formatDate = (dateString) => {
        if (!dateString) return "N/A";
        const date = new Date(dateString);
        if (isNaN(date)) return "Invalid Date";
        return new Intl.DateTimeFormat("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
        }).format(date);
    };

    return (
        <section>
            <span className={styles.priceHistory}>Price history</span>
            <div className={styles.priceHistoryContainer}>
                {sortedPrices.length > 0 ? (
                    sortedPrices.slice(0, isExpanded ? sortedPrices.length : 5).map((price, index) => (
                        <div key={index} className={styles.priceHistoryContainerFields}>
                            <span className={styles.priceHistoryAmount}>
                                {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                }).format(price?.amountMax)}
                            </span>
                            <span className={styles.priceHistoryDate}>
                                {formatDate(price?.date)}
                            </span>
                            <span className={styles.priceHistorySale}>
                                {getStatus(price)}
                            </span>
                        </div>
                    ))
                ) : (
                    <span>N/A</span>
                )}
            </div>

            {sortedPrices.length > 5 && (
                <button className={styles.expandButton} onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? 'Show less' : `Expand All (${sortedPrices.length})`}
                </button>
            )}
        </section>
    );
}

export default PriceExpandedRecords;
